const PREFIX = 'LESSON_PROJECT';

export const types = {
  CANCEL_ENCODING: PREFIX + 'CANCEL_ENCODING',
  CANCEL_ENCODING_FAILURE: PREFIX + 'CANCEL_ENCODING_FAILURE',
  CANCEL_ENCODING_SUCCESS: PREFIX + 'CANCEL_ENCODING_SUCCESS',

  MARK_AS_DONE: PREFIX + 'MARK_AS_DONE',
  MARK_AS_DONE_FAILURE: PREFIX + 'MARK_AS_DONE_FAILURE',
  MARK_AS_DONE_SUCCESS: PREFIX + 'MARK_AS_DONE_SUCCESS',

  REMOVE_PROJECT: PREFIX + 'REMOVE_PROJECT',
  REMOVE_PROJECT_FAILURE: PREFIX + 'REMOVE_PROJECT_FAILURE',
  REMOVE_PROJECT_SUCCESS: PREFIX + 'REMOVE_PROJECT_SUCCESS',

  PROJECT_CREATED: `${PREFIX}/PROJECT_CREATED`,
  PROJECT_EDITOR_CLOSE: `${PREFIX}/PROJECT_EDITOR_CLOSE`,
  PROJECT_EDITOR_OPEN: `${PREFIX}/PROJECT_EDITOR_OPEN`,
  PROJECT_UPDATED: `${PREFIX}/PROJECT_UPDATED`,

  REVIEW_ADDED: `${PREFIX}/REVIEW_ADDED`,
  REVIEW_EDITOR_OPEN: `${PREFIX}/REVIEW_EDITOR_OPEN`,
  REVIEW_EDITOR_CLOSE: `${PREFIX}/REVIEW_EDITOR_CLOSE`,
  REVIEW_VIEWER_OPEN: `${PREFIX}/REVIEW_VIEWER_OPEN`,
  REVIEW_VIEWER_CLOSE: `${PREFIX}/REVIEW_VIEWER_CLOSE`,
  REVIEW_REMOVED: `${PREFIX}/REVIEW_REMOVED`,

  SCREENPLAY_CREATED: `${PREFIX}/SCREENPLAY_CREATED`,
  SCREENPLAY_EDITOR_CLOSE: `${PREFIX}/SCREENPLAY_EDITOR_CLOSE`,
  SCREENPLAY_EDITOR_OPEN: `${PREFIX}/SCREENPLAY_EDITOR_OPEN`,
  SCREENPLAY_UPDATED: `${PREFIX}/SCREENPLAY_UPDATED`,

  IMAGE_PROJECT_CREATED: `${PREFIX}/IMAGE_PROJECT_CREATED`,
  IMAGE_EDITOR_CLOSE: `${PREFIX}/IMAGE_EDITOR_CLOSE`,
  IMAGE_EDITOR_OPEN: `${PREFIX}/IMAGE_EDITOR_OPEN`,

  OBJECT_3D_PROJECT_CREATED: `${PREFIX}/OBJECT_3D_PROJECT_CREATED`,
  OBJECT_3D_EDITOR_OPEN: `${PREFIX}/OBJECT_3D_EDITOR_OPEN`,
  OBJECT_3D_EDITOR_CLOSE: `${PREFIX}/OBJECT_3D_EDITOR_CLOSE`,

  NON_SUPPORTED_FILE_CREATED: `${PREFIX}/NON_SUPPORTED_FILE_CREATED`,
  NON_SUPPORTED_FILE_EDITOR_CLOSE: `${PREFIX}/NON_SUPPORTED_FILE_EDITOR_CLOSE`,
  NON_SUPPORTED_FILE_EDITOR_OPEN: `${PREFIX}/NON_SUPPORTED_FILE_EDITOR_OPEN`
};
