import { gql } from '@apollo/client';

export const unassignMentorForDiscipline = apolloClient => async (
  studentUp,
  discipline
) => {
  const mutation = gql`
    mutation unassignMentorForDiscipline(
      $input: UnassignMentorForDisciplineInput!
    ) {
      unassignMentorForDiscipline(input: $input) {
        id
        mentors {
          discipline
          enrollee {
            id
          }
        }
        students {
          id
        }
      }
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        studentUpId: studentUp.id,
        discipline
      }
    }
  });
};
