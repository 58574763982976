import { get } from 'lodash';
import BadgeNotifications from './BadgeNotifications';
import React, { useState } from 'react';
import SkipNavigationButton from './SkipNavigationButton';
import styles from './Header.scss';
import TopNav, { openInBrowser } from './TopNav';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCurrentUser, isFreeAccount } from 'smashcut-client-lib/selectors';
import { HeaderLogo } from 'themes/HeaderLogo';
import { HelpChatButton } from './HelpChatButton';
import { getString } from '../../common/getString';
import { withRouter } from 'react-router-dom';
import CrewHubButton from './CrewHubButton';
import { MainButton, SecondaryButton } from 'components/common/button';
import { simulateClickOnFocusedElement } from 'utils/simulateClickOnFocusedElement';
import { withDeviceDetector } from 'components/common/withDeviceDetector';
import DrawerMenu from './DrawerMenu';
import { topNavLinks } from 'themes/partnerConfig';
import classnames from 'classnames';
import { actions as mobileBetaWarningActions } from 'reducers/mobileBetaWarning';
import { withHiddenTabs } from 'components/common/withHiddenTabs';

export const Header = props => {
  const {
    gotoAdmin,
    gotoCrew,
    gotoDashboard,
    params,
    user,
    onSelectItem,
    isFreeAccount,
    gotoAccountDetails,
    gotoAvailability,
    gotoBlog,
    gotoLogout,
    gotoProfile,
    gotoCourses,
    isMobile,
    push,
    openMobileBetaWarning,
    hidden
  } = props;
  const title = (user && getString(user.roleLabel) + ' ' + user.fullName) || '';
  const [menuOpen, setMenuOpen] = useState(false);
  const closeMenu = () => setMenuOpen(false);

  const handleSelect = path => {
    onSelectItem(path);
    const goToComponent = {
      accountDetails: gotoAccountDetails,
      availability: gotoAvailability,
      blog: gotoBlog,
      logout: gotoLogout,
      profile: gotoProfile,
      browseCourses: gotoCourses
    }[path]();
    closeMenu();
  };

  const handleDrawerMenuClick = (location, isDashboardMenu) => {
    push(isDashboardMenu ? `/dashboard/${location}` : `/${location}`);
    closeMenu();
  };

  return (
    <header className={styles.header}>
      {user && (
        <div className={styles.header}>
          {isMobile && (
            <div
              className={styles.drawerMenuButton}
              onClick={() => setMenuOpen(!menuOpen)}
              onKeyUp={simulateClickOnFocusedElement}
              role="button"
              aria-label="Menu"
              tabIndex="0"
              aria-expanded={menuOpen}
            >
              <i className="fas fa-bars" />
            </div>
          )}
          <Link to="/">
            <HeaderLogo />
          </Link>
          {isMobile && (
            <SecondaryButton
              icon="far fa-question-circle"
              className={classnames(
                'launch-help-chat',
                styles.intercomHelpButton
              )}
            />
          )}
          <SkipNavigationButton />
          <nav className={styles.buttonContainer}>
            <div className={styles.buttons}>
              {user.isAdmin && (
                <MainButton
                  onClick={gotoAdmin}
                  label="Admin"
                  aria-label="Admin"
                  data-cy="adminButton"
                  className={styles.menuButton}
                />
              )}
              <MainButton
                onClick={gotoDashboard}
                className={styles.menuButton}
                label="Dashboard"
                data-cy="dashboardButton"
                aria-label="Dashboard"
                tabIndex="0"
              />
              <CrewHubButton
                userId={get(user, 'id')}
                currentCrewId={get(params, 'crewId')}
                onOpenCrew={gotoCrew}
              />
              <HelpChatButton />
              {!isFreeAccount && <BadgeNotifications {...props} />}
              {!isMobile && (
                <TopNav
                  title={title}
                  onSelectItem={onSelectItem}
                  menuOpen={menuOpen}
                  setMenuOpen={setMenuOpen}
                  closeMenu={closeMenu}
                  handleSelect={handleSelect}
                />
              )}
            </div>
          </nav>
          {isMobile && (
            <DrawerMenu
              menuOpen={menuOpen}
              location={location}
              setMenuOpen={setMenuOpen}
              handleDrawerMenuClick={handleDrawerMenuClick}
              handleSelect={handleSelect}
              closeMenu={closeMenu}
              user={user}
              title={title}
              openMobileBetaWarning={openMobileBetaWarning}
              hidden={hidden}
            />
          )}
        </div>
      )}
    </header>
  );
};

export const mapStateToProps = state => ({
  user: getCurrentUser(state),
  isFreeAccount: isFreeAccount(state)
});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  push: payload => ownProps.history.push(payload),
  gotoDashboard: () => ownProps.history.push('/'),
  gotoAdmin: () => ownProps.history.push('/admin'),
  gotoCrew: id => ownProps.history.push(`/crewHub/${id}`),
  gotoAccountDetails: () => ownProps.history.push('/account-details'),
  gotoAvailability: () => ownProps.history.push('/availability'),
  gotoBlog: () => openInBrowser(topNavLinks.blog.blogUrl, 'smashcutFilmBlog'),
  gotoLogout: () => ownProps.history.push('/logout'),
  gotoProfile: () => ownProps.history.push('/profile'),
  gotoCourses: () => ownProps.history.push('/courses'),
  openMobileBetaWarning: () => dispatch(mobileBetaWarningActions.showWarning())
});

export default withHiddenTabs(
  withDeviceDetector(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
  )
);
