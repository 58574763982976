import { addMyFile } from './addMyFile';
import { createFolder } from './createFolder';
import { apolloClient } from '../../apolloClient';
import { deleteMyFiles } from './deleteMyFiles';
import { moveToFolder } from './moveToFolder';
import { updateMyFileDetails } from './updateMyFile';
import { handleMyFileEncodingError } from './handleMyFileEncodingError';

const api = (/*config*/) => {
  return {
    addMyFile: addMyFile(apolloClient),
    deleteMyFiles: deleteMyFiles(apolloClient),
    handleMyFileEncodingError: handleMyFileEncodingError(apolloClient),
    updateMyFile: updateMyFileDetails(apolloClient),
    createFolder: createFolder(apolloClient),
    moveToFolder: moveToFolder(apolloClient)
  };
};

export default api;
