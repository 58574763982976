import React from 'react';
import styles from './SelectProgramMobileButton.scss';
import { actions as selectProgramActions } from './selectProgramReducer';
import { connect } from 'react-redux';
import { selectors as selectProgramSelectors } from './selectProgramReducer';

const SelectProgramMobileButton = ({
  canSelect,
  onClick,
  showPopup,
  selectedProgramTitle
}) =>
  canSelect ? (
    <div
      className={styles.topContainer}
      onClick={() => {
        showPopup();
        onClick();
      }}
    >
      <div className={styles.info}>
        <div className={styles.program}>{selectedProgramTitle}</div>
        <div className={styles.prompt}>Tap here to change program</div>
      </div>
      <div className={styles.icon}>
        <i className="fas fa-exchange-alt" />
      </div>
    </div>
  ) : null;

export const mapStateToProps = state => {
  const options = selectProgramSelectors.getOptions(state);
  const selectedOptionId = selectProgramSelectors.getSelectedOptionId(state);
  const selectedOption = options.find(o => o.id === selectedOptionId);
  return {
    canSelect: options.length > 1,
    selectedProgramTitle: (selectedOption && selectedOption.label) || ''
  };
};

export const mapDispatchToProps = {
  showPopup: selectProgramActions.showPopup
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectProgramMobileButton);
