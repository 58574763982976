export const SORT = {
  OLDEST: 'OLDEST',
  NEWEST: 'NEWEST'
};

export const FILTER = {
  ALL: 'ALL',
  VIDEOS: 'VIDEOS',
  IMAGES: 'IMAGES',
  SCREENPLAYS: 'SCREENPLAYS',
  PDF: 'PDF',
  GLB: 'GLB',
  OBJECT3D: 'OBJECT3D',
  PROJECTS: 'PROJECTS',
  MY_FILES: 'MY_FILES'
};

export const RESPONSE_TYPE = {
  NONE: '',
  CONFIRM: 'CONFIRM',
  VIDEO: 'VIDEO',
  SCREENPLAY: 'SCREENPLAY',
  IMAGE: 'IMAGE',
  OBJECT3D: 'OBJECT3D',
  NONSUPPORTED: 'NONSUPPORTED'
};

export const UPLOAD_RESPONSE_TYPES = [
  RESPONSE_TYPE.IMAGE,
  RESPONSE_TYPE.NONSUPPORTED,
  RESPONSE_TYPE.OBJECT3D,
  RESPONSE_TYPE.SCREENPLAY,
  RESPONSE_TYPE.VIDEO
];

export const UPLOAD_TYPE = {
  VIDEO: 'VIDEO',
  SCREENPLAY: 'SCREENPLAY',
  UNKNOWN: 'UNKNOWN',
  IMAGE: 'IMAGE',
  GLB: 'GLB',
  OBJECT3D: 'OBJECT3D',
  MY_FILE: 'MY_FILE',
  INTRO_VIDEO: 'INTRO_VIDEO',
  VIDEO_MESSAGE: 'VIDEO_MESSAGE'
};

export const FILE_TYPE = {
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
  MULTI_IMAGE: 'MULTI_IMAGE',
  FOUNTAIN: 'FOUNTAIN',
  PDF: 'PDF',
  GLB: 'GLB',
  OBJECT3D: 'OBJECT3D',
  UNKNOWN: 'UNKNOWN',
  NONSUPPORTED: 'NONSUPPORTED',
  FOLDER: 'FOLDER'
};

export const ASSIGNMENT_STATUS = {
  ENCODING: 'ENCODING',
  READY_FOR_REVIEW: 'READY_FOR_REVIEW',
  REJECTED: 'REJECTED',
  REVIEWED: 'REVIEWED',
  SUBMITTED: 'SUBMITTED',
  UPLOADED: 'UPLOADED',
  UPLOADING: 'UPLOADING'
};
