import config from 'config';
import LogRocket from 'logrocket';
import version from './version';
import setupLogRocketReact from 'logrocket-react';

if (config.logRocketEnabled) {
  setupLogRocketReact(LogRocket);
  LogRocket.init(config.logrocket, {
    release: version.appVersion
  });
}
