import { gql } from '@apollo/client';

const MY_FILE_OR_PRELOADED_FILE_QUERY = gql`
  query myFile($input: MyFileOrPreloadedFileInput) {
    myFileOrPreloadedFile(input: $input) {
      id
      assetId
      assetName
      assetStoragePath
      baseUrl
      commentCount
      created
      dashUrl
      description
      downloadUrl
      duration
      fileType
      isPreloaded
      hlsUrl
      name
      ownerId
      status
      screenplayText
      spriteUrl
      thumbnailUrl
      vttUrl
      images {
        uploadId
        downloadUrl
      }
    }
  }
`;

export const loadMyFile = apolloClient => (id, currentUserProgramId) => {
  return apolloClient
    .query({
      query: MY_FILE_OR_PRELOADED_FILE_QUERY,
      variables: {
        input: {
          myFileId: id,
          enrolleeId: currentUserProgramId
        }
      }
    })
    .then(response => {
      const myFile = response.data.myFileOrPreloadedFile;
      return myFile;
    });
};
