// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SelectProgramMobileButton--topContainer--2NKSAtVM{display:-webkit-box;display:-ms-flexbox;display:flex;background:#ffffff;padding:20px;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.SelectProgramMobileButton--topContainer--2NKSAtVM .SelectProgramMobileButton--info--Beod9w3F{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}.SelectProgramMobileButton--topContainer--2NKSAtVM .SelectProgramMobileButton--info--Beod9w3F .SelectProgramMobileButton--program--2Uad4npS{font-weight:500;font-size:14px;line-height:16px;color:#000000;margin-bottom:3px}.SelectProgramMobileButton--topContainer--2NKSAtVM .SelectProgramMobileButton--info--Beod9w3F .SelectProgramMobileButton--prompt--UFSxHDUD{font-size:12px;line-height:14px;color:rgba(0, 0, 0, .6)}.SelectProgramMobileButton--topContainer--2NKSAtVM .SelectProgramMobileButton--icon--2g9ggUjH{color:rgba(0, 0, 0, .6)}\n", ""]);
// Exports
exports.locals = {
	"topContainer": "SelectProgramMobileButton--topContainer--2NKSAtVM",
	"info": "SelectProgramMobileButton--info--Beod9w3F",
	"program": "SelectProgramMobileButton--program--2Uad4npS",
	"prompt": "SelectProgramMobileButton--prompt--UFSxHDUD",
	"icon": "SelectProgramMobileButton--icon--2g9ggUjH"
};
module.exports = exports;
