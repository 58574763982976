import { useEffect, useState } from 'react';
import { isEmpty, get } from 'lodash';
import { ANY_FILE_UPLOAD_LIMIT_BYTES } from 'components/common/UploadLimits';

export const useEditorFormHook = (
  allowedFormats,
  fileDescription,
  onSubmit,
  file,
  isEditing,
  onClose,
  uploadData,
  myFiles,
  uploadType
) => {
  const [description, setDescription] = useState(fileDescription);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileError, setSelectedFileError] = useState('');
  const [selectedFileName, setSelectedFileName] = useState('');
  const [uploadTask, setUploadTask] = useState(null);
  const [fileTooBigWarning, setFileTooBigWarning] = useState(false);
  const [hasFileCreated, setHasFileCreated] = useState(false);
  const [myFileName, setMyFileName] = useState('');
  const [myFileNameError, setMyFileNameError] = useState('');

  const handleCancel = () => {
    setDescription('');
    setSelectedFile(null);
    setSelectedFileError('');
    setSelectedFileName('');
    setMyFileName('');
    setMyFileNameError('');
    setHasFileCreated(false);
    setUploadTask(null);

    const uploadTsk = uploadData && uploadData.uploadTask;
    if (uploadTsk && uploadTsk.state === 'running') {
      uploadTsk.cancel();
    }
    onClose();
  };

  useEffect(() => {
    uploadData && uploadData.uploadTask && setUploadTask(uploadData.uploadTask);
    (uploadData || !uploadData.uploading) && setUploadTask(null);
  }, [uploadData]);

  useEffect(() => {
    const status = get(myFiles, '[0].status');
    if (
      (!isEmpty(myFiles) && status === 'Encoding') ||
      (!isEmpty(myFiles) && status === 'Ready')
    ) {
      handleCancel();
    }

    if (!isEmpty(myFiles) && status === 'Error') {
      alert(
        'Upload Failed\n' +
          'This can happen if your network is unstable or you lose connection mid-upload. Please check your network and try your upload again. '
      );
      handleCancel();
    }
  }, [myFiles]);

  const handleValidateSubmit = () => {
    const endsWith = (s, endings) =>
      endings.some(e => s.toLowerCase().endsWith(e));

    let hasErrors = false;
    let selectedFileError = '';
    if (!selectedFile) {
      if (!isEditing) {
        selectedFileError = 'File is required';
        hasErrors = true;
      }
      if (!myFileName) {
        hasErrors = true;
        selectedFileError = 'Title is required';
      }
    } else {
      if (
        !isEmpty(allowedFormats) &&
        !endsWith(selectedFile.name, allowedFormats)
      ) {
        hasErrors = true;
        selectedFileError = `Please try again! This project requires a ${uploadType} file. Supported file types: ${allowedFormats.join(
          ', '
        )}`;
      }
    }

    setSelectedFileError(selectedFileError);
    return !hasErrors;
  };

  const handleFinalSubmit = () => {
    if (handleValidateSubmit()) {
      onSubmit(file, description, selectedFile);
      setHasFileCreated(true);
    }
  };

  const handleFileChosen = file => {
    setSelectedFile(file);
    setSelectedFileName(file.name);
    setSelectedFileError('');
    setMyFileName(file.name);
    setMyFileNameError('');

    if (file.size > ANY_FILE_UPLOAD_LIMIT_BYTES) {
      setFileTooBigWarning(true);
      return;
    }
  };

  const handleChangeDescription = value => setDescription(value);

  const isSubmitDisabled =
    uploadData.uploading ||
    (isEditing && !selectedFileName && description === fileDescription) ||
    (!isEditing && !selectedFileName);

  return {
    description,
    setDescription,
    selectedFile,
    setSelectedFile,
    selectedFileError,
    setSelectedFileError,
    selectedFileName,
    setSelectedFileName,
    fileTooBigWarning,
    setFileTooBigWarning,
    myFileName,
    setMyFileName,
    myFileNameError,
    setMyFileNameError,
    handleCancel,
    handleFinalSubmit,
    handleFileChosen,
    handleChangeDescription,
    handleValidateSubmit,
    hasFileCreated,
    setHasFileCreated,
    submitButtonLabel: isEditing ? 'Save' : 'Upload',
    isSubmitDisabled
  };
};
