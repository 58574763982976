import { gql } from '@apollo/client';
import { uid } from '../../utils/uid';

const CREATE_MY_FILE = gql`
  mutation createFolder($input: AddMyFileInput!) {
    createFolder(input: $input) {
      id
      name
      fileType
      ownerId
      parentId
    }
  }
`;

export function createFolder(apolloClient) {
  return input => {
    const newFilePrefix = input.isSharedFile ? input.isSharedFile : ``;
    const newId = `${newFilePrefix}${uid()}`;
    return apolloClient
      .mutate({
        mutation: CREATE_MY_FILE,
        variables: {
          input: {
            id: newId,
            name: input.name,
            ownerId: input.ownerId,
            parentId: input.parentId,
            isSharedFile: !!input.isSharedFile
          }
        }
      })
      .then(response => response.data.createFolder);
  };
}
