// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".StickyImpersonateWarning--rnd--5G2OXomJ{z-index:300}.StickyImpersonateWarning--topContainer--bJ3v9Mdv{padding:20px;border-radius:4px;background-color:#363636;-webkit-box-shadow:0 8px 10px 0 rgba(0, 0, 0, .24);box-shadow:0 8px 10px 0 rgba(0, 0, 0, .24);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.StickyImpersonateWarning--topContainer--bJ3v9Mdv.StickyImpersonateWarning--color0--2D_KEdbD{border-left:10px solid #595959}.StickyImpersonateWarning--topContainer--bJ3v9Mdv.StickyImpersonateWarning--color1--3oMvbM51{border-left:10px solid #43a047}.StickyImpersonateWarning--topContainer--bJ3v9Mdv.StickyImpersonateWarning--color2--12FPTpSL{border-left:10px solid #e53935}.StickyImpersonateWarning--topContainer--bJ3v9Mdv.StickyImpersonateWarning--color3--2yvveHg3{border-left:10px solid #2196f3}.StickyImpersonateWarning--topContainer--bJ3v9Mdv.StickyImpersonateWarning--color4--2o_LNN-j{border-left:10px solid #e9a300}.StickyImpersonateWarning--topContainer--bJ3v9Mdv .StickyImpersonateWarning--textBlock--TWUpodrN{padding-left:20px;padding-right:20px;font-size:14px;font-weight:bold;color:#ffffff}.StickyImpersonateWarning--topContainer--bJ3v9Mdv .StickyImpersonateWarning--reloadButton--2xl9NnC3{width:220px}\n", ""]);
// Exports
exports.locals = {
	"rnd": "StickyImpersonateWarning--rnd--5G2OXomJ",
	"topContainer": "StickyImpersonateWarning--topContainer--bJ3v9Mdv",
	"color0": "StickyImpersonateWarning--color0--2D_KEdbD",
	"color1": "StickyImpersonateWarning--color1--3oMvbM51",
	"color2": "StickyImpersonateWarning--color2--12FPTpSL",
	"color3": "StickyImpersonateWarning--color3--2yvveHg3",
	"color4": "StickyImpersonateWarning--color4--2o_LNN-j",
	"textBlock": "StickyImpersonateWarning--textBlock--TWUpodrN",
	"reloadButton": "StickyImpersonateWarning--reloadButton--2xl9NnC3"
};
module.exports = exports;
