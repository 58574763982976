import React from 'react';
import { DefaultButton } from './DefaultButton';

export const MainButton = (
  { type, ...rest } // eslint-disable-line
) => <DefaultButton type="main" {...rest} />;
export const SecondaryButton = (
  { type, ...rest } // eslint-disable-line
) => <DefaultButton type="secondary" {...rest} />;
export const SecondaryToggleButton = (
  { type, ...rest } // eslint-disable-line
) => <DefaultButton type="secondary-toggle" {...rest} />;
export const SuccessButton = (
  { type, ...rest } // eslint-disable-line
) => <DefaultButton type="success" {...rest} />;
export const InfoButton = (
  { type, ...rest } // eslint-disable-line
) => <DefaultButton type="info" {...rest} />;
export const ErrorButton = (
  { type, ...rest } // eslint-disable-line
) => <DefaultButton type="error" {...rest} />;
export const AlertButton = (
  { type, ...rest } // eslint-disable-line
) => <DefaultButton type="alert" {...rest} />;
export const EmptyButton = (
  { type, ...rest } // eslint-disable-line
) => <DefaultButton type="empty" {...rest} />;
