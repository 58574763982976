import { deleteUserReducer } from './deleteUserReducer';

export const adminActionsReducer = (state = {}, action, fullState) => {
  let deleteUser = deleteUserReducer(state.deleteUser, action, fullState);

  return deleteUser !== state.deleteUser
    ? {
        ...state,
        deleteUser
      }
    : state;
};
