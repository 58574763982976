import { receiveEntity } from './receiveEntity';
import { gql } from '@apollo/client';

let subscription;

const listenToProgramInstanceChange = gql`
  subscription onProgramInstanceChange {
    onProgramInstanceChange {
      id
      hasEnded
      disabledDashboardTabs
    }
  }
`;

const subscribe = apolloClient => async callback => {
  if (subscription) {
    return;
  }
  subscription = apolloClient
    .subscribe({
      query: listenToProgramInstanceChange
    })
    .subscribe({
      next(data) {
        const programInstance = data.data.onProgramInstanceChange;
        if (programInstance) {
          callback(programInstance);
        }
      },
      error(err) {
        console.error('Error subscribing to programInstance change: ', err);
      }
    });
};

export const unsubscribeFromProgramInstances = () => {
  if (subscription) {
    subscription.unsubscribe();
    subscription = undefined;
  }
};

export const subscribeToProgramInstances = (apolloClient, dispatch) => {
  subscribe(apolloClient)(programInstance =>
    dispatch(
      receiveEntity(`programInstances`)(programInstance.id, programInstance)
    )
  );
};
