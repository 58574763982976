import { gql } from '@apollo/client';

export const deleteReply = apolloClient => (commentSectionId, replyId) => {
  const mutation = gql`
    mutation deleteReply($input: DeleteReplyInput!) {
      deleteReply(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        commentSectionId,
        replyId
      }
    }
  });
};
