import { gql } from '@apollo/client';

export const removeCrewMessage = apolloClient => (crewId, messageId) => {
  const mutation = gql`
    mutation removeCrewMessage($input: RemoveCrewMessageInput!) {
      removeCrewMessage(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: { crewId, messageId }
    }
  });
};
