import { addIntroVideo } from './addIntroVideo';
import { assignMentorForDiscipline } from './assignMentorForDiscipline';
import { confirmPasswordReset } from './confirmPasswordReset';
import { createUser } from './createUser';
import { deleteUser } from './deleteUser';
import { finalizeIntroVideo } from './finalizeIntroVideo';
import { isEnrolled } from './isEnrolled';
import { hasUploads } from './hasUploads';
import { loadUser } from './loadUser';
import { observeUser } from './observeUser';
import { removeIntroVideo } from './removeIntroVideo';
import { resetPassword } from './resetPassword';
import { restoreIntroVideo } from './restoreIntroVideo';
import { sendEmailVerification } from './sendEmailVerification';
import { setCurrentUserProgramId } from './setCurrentUserProgramId';
import { setIntroVideoEncodingError } from './setIntroVideoEncodingError';
import { setIsHidden } from './setIsHidden';
import { setIsMentor } from './setIsMentor';
import { setIsStaff } from './setIsStaff';
import { unassignMentorForDiscipline } from './unassignMentorForDiscipline';
import { updateAvatar } from './updateAvatar';
import { updateEmail } from './updateEmail';
import { updateIntercom } from './updateIntercom';
import { updateUser } from './updateUser';
import { updateUserCrewLastSeen } from './updateUserCrewLastSeen';
import { updateUserSettings } from './updateUserSettings';
import { updateUserSocial } from './updateUserSocial';
import {
  startUpdatingOnlineStatus,
  stopUpdatingOnlineStatus
} from './onlineStatus';

function initUserApi(authService, apolloClient, storageService) {
  return {
    user: {
      addIntroVideo: addIntroVideo(apolloClient),
      assignMentorForDiscipline: assignMentorForDiscipline(apolloClient),
      createUser: createUser(apolloClient),
      confirmPasswordReset: confirmPasswordReset(authService),
      deleteUser: deleteUser(apolloClient),
      finalizeIntroVideo: finalizeIntroVideo(apolloClient),
      isEnrolled: isEnrolled(apolloClient),
      hasUploads: hasUploads(apolloClient),
      loadUser: loadUser(apolloClient),
      observeUser: observeUser(apolloClient),
      removeIntroVideo: removeIntroVideo(apolloClient),
      resetPassword: resetPassword(authService),
      restoreIntroVideo: restoreIntroVideo(apolloClient),
      sendEmailVerification: sendEmailVerification(authService),
      setCurrentUserProgramId: setCurrentUserProgramId(apolloClient),
      setIntroVideoEncodingError: setIntroVideoEncodingError(apolloClient),
      setIsHidden: setIsHidden(apolloClient),
      setIsMentor: setIsMentor(apolloClient),
      setIsStaff: setIsStaff(apolloClient),
      startUpdatingOnlineStatus: startUpdatingOnlineStatus(
        apolloClient,
        document
      ),
      stopUpdatingOnlineStatus: stopUpdatingOnlineStatus(document),
      unassignMentorForDiscipline: unassignMentorForDiscipline(apolloClient),
      updateAvatar: updateAvatar(apolloClient),
      updateCrewLastSeen: updateUserCrewLastSeen(apolloClient),
      updateEmail: updateEmail(apolloClient),
      updateIntercom: updateIntercom(apolloClient),
      updateSettings: updateUserSettings(apolloClient),
      updateSocial: updateUserSocial(apolloClient),
      updateUser: updateUser(apolloClient)
    }
  };
}

export default initUserApi;
