import { gql, useQuery } from '@apollo/client';
import dropdownStyle from './TopNav.scss';
import styles from './Header.scss';
import _, { get, isEmpty, map } from 'lodash';
import onClickOutside from 'react-onclickoutside';
import classnames from 'classnames';
import config from 'config';
import React, { useState } from 'react';
import { simulateClickOnFocusedElement } from 'utils/simulateClickOnFocusedElement';

const DropContent = ({
  sortedCrews,
  handleOpenCrew,
  unreadCountForCrew,
  currentCrewId,
  isOpen,
  setIsOpen
}) => {
  DropContent.handleClickOutside = () => setIsOpen(false);

  return (
    <div
      className={classnames(dropdownStyle.dropContent, styles.drop, {
        [dropdownStyle.open]: isOpen
      })}
    >
      <ul className={styles.dropList}>
        {map(sortedCrews, c => (
          <li
            key={c.id}
            onClick={() => handleOpenCrew(c)}
            className={currentCrewId === c.id ? styles.highlighted : ''}
            tabIndex="0"
          >
            <div className={styles.label}>{c.name}</div>
            {unreadCountForCrew(c.id) > 0 && (
              <div
                className={classnames(
                  styles.unreadCounter,
                  styles.subMenuBadge
                )}
              >
                {unreadCountForCrew(c.id)}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => DropContent.handleClickOutside
};

const DropContentWithOutsideClick = onClickOutside(
  DropContent,
  clickOutsideConfig
);

const GET_CREWS = gql`
  query getCrewsForCrewHubButton($userId: String!) {
    user(id: $userId) {
      id
      crews {
        id
        name
        archived
        unreadMessageCount
      }
    }
  }
`;

const nonArchivedCrews = data =>
  (get(data, 'user.crews') || []).filter(c => !c.archived);

const CrewHubButton = ({ userId, currentCrewId, onOpenCrew }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { loading, error, data, refetch } = useQuery(GET_CREWS, {
    variables: { userId },
    skip: !userId,
    pollInterval: 60000,
    fetchPolicy: 'cache-and-network'
  });

  const crews = nonArchivedCrews(data);

  const handleOpenCrew = crew => {
    setIsOpen(false);
    onOpenCrew(crew.id);
  };

  const handleCrewHubClick = () => {
    refetch && refetch();
    if (crews && crews.length > 1) {
      setIsOpen(!isOpen);
    } else if (crews) {
      onOpenCrew(crews[0].id);
    }
  };

  const unreadCountForCrew = crewId =>
    crews.find(c => c.id == crewId).unreadMessageCount;

  const unreadCount =
    crews &&
    crews.length &&
    crews.reduce((acc, val) => {
      return acc + (val.unreadMessageCount || 0);
    }, 0);

  const sortedCrews = _(crews)
    .orderBy(['name', 'asc'])
    .value();

  return isEmpty(crews) ? (
    <span />
  ) : (
    <div
      className={styles.dropMenu}
      onClick={handleCrewHubClick}
      onKeyUp={simulateClickOnFocusedElement}
      data-cy="crewHubMenu"
      role="button"
      aria-label="CrewHub Menu"
      tabIndex="0"
      aria-expanded={isOpen}
    >
      <div
        className={classnames(
          dropdownStyle.dropButton,
          styles.menuButton,
          styles.drop
        )}
      >
        <span className={styles.label}>
          Crew Hub
          {unreadCount > 0 && (
            <div className={styles.unreadCounter}>{unreadCount}</div>
          )}
        </span>
        {isOpen ? (
          <i className="fas fa-angle-up" />
        ) : (
          <i className="fas fa-angle-down" />
        )}
      </div>
      <DropContentWithOutsideClick
        sortedCrews={sortedCrews}
        handleOpenCrew={handleOpenCrew}
        unreadCountForCrew={unreadCountForCrew}
        currentCrewId={currentCrewId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

export default CrewHubButton;
