import { gql } from '@apollo/client';

export const removeIntroVideo = apolloClient => async userId => {
  const mutation = gql`
    mutation removeIntroVideo($input: RemoveIntroVideoInput!) {
      removeIntroVideo(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        userId
      }
    }
  });
};
