export function isMobile() {
  const regExp = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];
  return regExp.some(re => navigator.userAgent.match(re));
}
