import { all, takeEvery, call, put } from 'redux-saga/effects';
import { types } from '../reducers/fileDetails';

export function* updateFile(myFilesApi, { updates }) {
  try {
    yield call(myFilesApi.updateMyFile, {
      id: updates.id,
      name: updates.name,
      description: updates.description
    });
    yield put({ type: types.UPDATE_FILE_SUCCESS, newFile: updates });
  } catch (e) {
    console.warn('nok', e);
    yield put({
      type: types.UPDATE_FILE_FAILURE,
      error: 'An error occurred while updating, please try again'
    });
  }
}

export function* updateIsVisible(myFilesApi, { updates }) {
  try {
    yield call(myFilesApi.updateMyFile, {
      id: updates.id,
      isVisible: updates.value
    });
    yield put({
      type: types.UPDATE_ISVISIBLE_SUCCESS,
      id: updates.id,
      newIsVisible: updates.value
    });
  } catch (e) {
    console.warn('nok', e);
    yield put({
      type: types.UPDATE_ISVISIBLE_FAILURE,
      error: 'An error occurred while updating, please try again'
    });
  }
}

export function* fileDetailsSaga({ apis }) {
  const myFilesApi = apis.myFilesApi;
  yield all([
    takeEvery(types.UPDATE_FILE_REQUEST, updateFile.bind(this, myFilesApi)),
    takeEvery(
      types.UPDATE_ISVISIBLE_REQUEST,
      updateIsVisible.bind(this, myFilesApi)
    )
  ]);
}
