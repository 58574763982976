import { gql } from '@apollo/client';

const changePass = gql`
  mutation changePassword($input: ChangePasswordInput!) {
    changePassword(input: $input)
  }
`;

export const changePassword = apolloClient => (
  userId,
  oldPassword,
  newPassword
) =>
  apolloClient
    .mutate({
      mutation: changePass,
      variables: { input: { userId, oldPassword, newPassword } }
    })
    .then(response => response.data.changePassword);
