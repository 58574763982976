import { actions as lessonActions } from 'reducers/lesson';
import { put, takeEvery } from 'redux-saga/effects';
import { types } from 'reducers/dashboard/projectsForReview';

// this is the meat of the saga, the side effects are taking place here
export function* startProjectReviewSaga(action) {
  let project = (action.item && action.item.project) || {};
  console.log('startProjectReviewSaga', action.item);
  yield put(
    lessonActions.gotoLesson({
      programId: project.userProgramId,
      courseId: project.courseId,
      lessonId: project.lessonId,
      itemId: project.assignmentId,
      itemType: action.item.parentAssignmentId ? 'subAssignment' : 'assignment',
      mainAreaType: action.item.mainAreaType,
      mainAreaId: project.id
    })
  );
}

// the watcher ties the action type to the saga
export function* watchStartProjectReview() {
  yield takeEvery(types.START_PROJECT_REVIEW, startProjectReviewSaga);
}

// collection of all watchers
// it is used to launch all watchers at once
// it is named after the file name
export const projectsForReviewSagas = [watchStartProjectReview];
