import { gql } from '@apollo/client';

export const updateUser = apolloClient => (userId, newValues) => {
  const mutation = gql`
    mutation updateUser($input: UpdateUserInput!) {
      updateUser(input: $input) {
        id
        avatar
        email
        fullName
        isPrivate
        location
        notificationsLastOpenTime
        shortBio
        smsPhoneNumber
        social {
          facebook
          instagram
          twitter
          vimeo
          website
          youtube
        }
      }
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        userId,
        ...newValues
      }
    },
    refetchQueries: ['adminUsers']
  });
};
