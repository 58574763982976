import { actionType } from '../constants/actionTypes';
import { logAndSendError } from 'utils/sentryHelper';

export const removeEnrollee = input => {
  return (dispatch, getState, { adminApi }) => {
    dispatch({ type: actionType.removeEnrollee });
    return adminApi
      .removeEnrollee(input)
      .then(() => dispatch({ type: actionType.removeEnrolleeSuccess }))
      .catch(e => logAndSendError(e, 'error removing enrollee'));
  };
};
