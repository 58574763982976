// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ErrorFallbackComponent--error--2A6LEq2r{padding:20px}.ErrorFallbackComponent--error--2A6LEq2r .ErrorFallbackComponent--buttons--25IkfDFY{display:-webkit-box;display:-ms-flexbox;display:flex}\n", ""]);
// Exports
exports.locals = {
	"error": "ErrorFallbackComponent--error--2A6LEq2r",
	"buttons": "ErrorFallbackComponent--buttons--25IkfDFY"
};
module.exports = exports;
