import { actions as videoAppTabActions } from 'reducers/videoAppTabs';
import { actionType } from 'smashcut-client-lib/constants';
import { put, takeEvery } from 'redux-saga/effects';

export function* handleMarkerClick(action) {
  if (action.marker && action.marker.commentId) {
    yield put({ type: actionType.gotoComment, id: action.marker.commentId });
  }
}

export function* watchMarkerClick() {
  yield takeEvery(actionType.MARKER_CLICK, handleMarkerClick);
}

export const markerClickSaga = [watchMarkerClick];
