export const getReplyTitle = reply => {
  if (reply.title) {
    return reply.title;
  }
  let locale = 'en-US'; //TODO get current locale from state
  let d = new Date(reply.created);
  let time = d.toLocaleTimeString(locale);
  let date = d.toLocaleDateString(locale);
  return `${time} (${date})`;
};
