import { gql } from '@apollo/client';

export const addImageProject = apolloClient => async (
  item,
  description,
  uploadRecords
) => {
  console.log('addImageProject', item, description, uploadRecords);

  const asmt = item.assignment;

  const mutation = gql`
    mutation addImageProject($input: AddImageProjectInput!) {
      addImageProject(input: $input) {
        imageProject {
          id
          assignmentId
          courseId
          created
          description
          images {
            downloadUrl
            fileName
            uploadId
          }
          lessonId
          name
          ownerId
          responseType
          userProgramId
        }
        lessonRecord {
          id
          userProgramId
          lessonId
          courseId
          assignmentId
          created
          status
          assignmentResponse {
            type
            projectId
          }
        }
      }
    }
  `;

  return apolloClient
    .mutate({
      mutation,
      variables: {
        input: {
          userProgramId: asmt.userProgramId,
          courseId: asmt.courseId,
          lessonId: asmt.lessonId,
          assignmentId: asmt.id,
          title: asmt.title,
          description,
          uploadRecords: uploadRecords.map(ur => ({
            id: ur.id,
            ownerId: ur.ownerId,
            downloadUrl: ur.downloadUrl,
            fileName: ur.fileName,
            status: ur.status
          }))
        }
      },
      refetchQueries: ['lessonUpdate']
    })
    .then(response => response.data.addImageProject);
};
