import { gql } from '@apollo/client';

export function removeEnrollee(apolloClient) {
  const REMOVE_ENROLLEE = gql`
    mutation removeEnrollee($input: RemoveEnrolleeInput!) {
      removeEnrollee(input: $input)
    }
  `;

  return async input => {
    await apolloClient.mutate({
      mutation: REMOVE_ENROLLEE,
      variables: {
        input
      },
      refetchQueries: ['getAdminData', 'getAllUserPrograms', 'userData']
    });
  };
}
