import { actionType } from '../constants';

const recordVideoPopupReducer = (state, action) => {
  let nextState = state;
  switch (action.type) {
    case actionType.updateVideoUploadProgress:
      if (state.open) {
        nextState = {
          ...state,
          percent: action.percent
        };
      }
      break;

    case actionType.LOCATION_CHANGE:
    case actionType.addVideoComment:
    case actionType.addVideoReply:
    case actionType.cancelRecordVideo:
      if (state.open) {
        nextState = {
          ...state,
          open: false
        };
      }
      break;

    case actionType.startRecordingVideo:
      if (!state.open) {
        nextState = {
          ...state,
          open: true,
          payload: action.payload
        };
      }
      break;
  }
  return nextState;
};

export default recordVideoPopupReducer;
