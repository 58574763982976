import moment from 'moment-timezone';
import _ from 'lodash';
import { getUser, getCurrentUser } from '../../smashcut-client-lib/selectors';
import { actionType as sclTypes } from '../../smashcut-client-lib/constants';
import {
  LOAD_LOGGED_IN_USER,
  LOGOUT
} from '../../smashcut-client-lib/reducers/authReducer';

const PREFIX = 'DASHBOARD_NOTIFICATIONS';

export const types = {
  NOTIFICATIONS_RECEIVED: `${PREFIX}/NOTIFICATIONS_RECEIVED`,
  TOOLTIP_NOTIFICATION_RECEIVED: `${PREFIX}/TOOLTIP_NOTIFICATION_RECEIVED`,
  UPCOMING_LMS_RECEIVED: `${PREFIX}/UPCOMING_LMS_RECEIVED`,
  MARK_NOTIFICATION_SEEN: `${PREFIX}/MARK_NOTIFICATION_SEEN`,
  SET_NOTIFICATIONS_LAST_OPEN_TIME: `${PREFIX}/SET_NOTIFICATIONS_LAST_OPEN_TIME`,
  NOTIFICATIONS_PANEL_CLOSED: `${PREFIX}/NOTIFICATIONS_PANEL_CLOSED`,
  SHOW_NOTIFICATIONS_SETTINGS: `${PREFIX}/SHOW_NOTIFICATIONS_SETTINGS`,
  HIDE_NOTIFICATIONS_SETTINGS: `${PREFIX}/HIDE_NOTIFICATIONS_SETTINGS`
};

const initialState = {
  lastOpenTimes: []
};

const calculateUnseenCount = state => {
  if (state.user && state.entries) {
    const lastOpenTime1 = state.lastOpenTimes[0];
    const lastOpenTime2 = state.lastOpenTimes[1] || lastOpenTime1;
    const unseenCount = _.sumBy(state.entries, n =>
      moment(n.created).unix() >= lastOpenTime1 ? 1 : 0
    );
    const oldNotifications = _.filter(
      state.entries,
      n => moment(n.created).unix() < lastOpenTime2
    );
    const newNotifications = _.filter(
      state.entries,
      n => moment(n.created).unix() >= lastOpenTime2
    );

    return {
      ...state,
      unseenCount,
      oldNotifications,
      newNotifications
    };
  } else {
    return state;
  }
};

export const notificationsReducer = (
  state = initialState,
  action,
  fullState
) => {
  let nextState = state;

  switch (action.type) {
    case types.NOTIFICATIONS_RECEIVED:
      nextState = {
        ...state,
        entries: action.payload
      };

      break;

    case types.UPCOMING_LMS_RECEIVED:
      nextState = {
        ...state
      };
      nextState.bookings = nextState.bookings || {};
      nextState.bookings[action.userSelector] = action.bookings;
      const allBookings = _.union(
        nextState.bookings.first,
        nextState.bookings.second
      );
      const lms = _.head(allBookings);
      if (lms) {
        const now = moment.utc().add(30, 'seconds');
        const from = lms.from;
        if (from.isBefore(now)) {
          nextState.lmsInProgress = lms;
          nextState.upcomingLms = undefined;
        } else {
          nextState.upcomingLms = lms;
          nextState.lmsInProgress = undefined;
        }
      } else {
        nextState.lmsInProgress = undefined;
        nextState.upcomingLms = undefined;
      }

      break;

    case types.TOOLTIP_NOTIFICATION_RECEIVED:
      let n = action.notification;
      const user = getCurrentUser(fullState);
      if (
        n &&
        user &&
        n.created &&
        moment(n.created).unix() < user.notificationsLastOpenTime
      ) {
        n = undefined;
      }
      nextState = {
        ...state,
        tooltipNotification: n && !n.seen ? n : undefined
      };
      break;

    case LOAD_LOGGED_IN_USER:
      console.log('load logged in', action.payload.user);
      nextState = {
        ...state,
        user: action.payload.user,
        lastOpenTimes: [action.payload.user.notificationsLastOpenTime || 0]
      };
      break;

    case LOGOUT:
      nextState = {
        ...state,
        notificationsSettingsVisible: false
      };
      break;

    case 'UPDATE_ENTITY_USERS':
      if (state.user && action.id == state.user.id) {
        nextState = {
          ...state,
          user: action.entity,
          lastOpenTimes: _.concat(
            action.entity.notificationsLastOpenTime,
            state.lastOpenTimes
          )
        };
        if (
          state.tooltipNotification &&
          moment(state.tooltipNotification.created).unix() <
            action.entity.notificationsLastOpenTime
        ) {
          nextState.tooltipNotification = undefined;
        }
      }
      break;

    case types.NOTIFICATIONS_PANEL_CLOSED:
      nextState = {
        ...state,
        lastOpenTimes: [_.head(state.lastOpenTimes)]
      };
      break;

    case types.SHOW_NOTIFICATIONS_SETTINGS:
      nextState = {
        ...state,
        notificationsSettingsVisible: true
      };
      break;

    case types.HIDE_NOTIFICATIONS_SETTINGS:
      nextState = {
        ...state,
        notificationsSettingsVisible: false
      };
      break;
  }
  return calculateUnseenCount(nextState);
};

export const notificationsReceived = payload => ({
  type: types.NOTIFICATIONS_RECEIVED,
  payload
});

export const tooltipNotificationReceived = notification => ({
  type: types.TOOLTIP_NOTIFICATION_RECEIVED,
  notification
});

export const upcomingLmsReceived = (userSelector, bookings) => ({
  type: types.UPCOMING_LMS_RECEIVED,
  userSelector,
  bookings
});

export const notificationsPanelClosed = () => ({
  type: types.NOTIFICATIONS_PANEL_CLOSED
});

export const showNotificationsSettings = () => ({
  type: types.SHOW_NOTIFICATIONS_SETTINGS
});

export const hideNotificationsSettings = () => ({
  type: types.HIDE_NOTIFICATIONS_SETTINGS
});

export const markNotificationSeen = (userId, notificationId) => {
  return (dispatch, getState, { api }) => {
    api.notifications.markNotificationSeen(userId, notificationId);
  };
};

export const setNotificationsLastOpenTime = (userId, timestamp) => async (
  dispatch,
  getState,
  { api }
) => {
  await api.user.updateUser(userId, {
    notificationsLastOpenTime: timestamp
  });

  const state = getState();
  const user = getUser(state, userId);
  dispatch({
    type: sclTypes.UPDATE_ENTITY_USERS,
    entityType: 'users',
    id: userId,
    entity: { ...user, notificationsLastOpenTime: timestamp }
  });
};

export const actions = {
  markNotificationSeen,
  setNotificationsLastOpenTime,
  notificationsPanelClosed,
  showNotificationsSettings,
  hideNotificationsSettings
};
