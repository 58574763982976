import { gql } from '@apollo/client';

export const deleteComment = apolloClient => (commentSectionId, commentId) => {
  const mutation = gql`
    mutation deleteComment($input: DeleteCommentInput!) {
      deleteComment(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        commentSectionId,
        commentId
      }
    }
  });
};
