import { actionType } from '../constants';
import { logAndSendError } from 'utils/sentryHelper';

export const resetPassword = email => {
  return (dispatch, getState, { api }) => {
    dispatch({ type: actionType.resetPassword });
    api.user
      .resetPassword(email)
      .then(user =>
        dispatch({ type: actionType.resetPasswordComplete, payload: user })
      )
      .catch(error => {
        logAndSendError(error, 'error resetting password');
        dispatch({ type: actionType.resetPasswordError, payload: error });
      });
  };
};

export const resetPasswordFlow = () => {
  return dispatch => {
    dispatch({ type: actionType.resetPasswordFlow });
  };
};
