import { actions as uploadActions } from '../uploads';
import { logAndSendError } from 'utils/sentryHelper';
import { selectors as sclSelectors } from 'smashcut-client-lib';
import { UPLOAD_TYPE } from 'common/constants';
import { getFileType } from '../../utils/getFileType';
import { map, get, isEmpty, take } from 'lodash';
import { uid } from 'utils/uid';
import { convertToTreeStructure } from 'utils/convertToTreeStructure';
import { findAsset } from '../../common/assetUtils';

export const SORT_ORDER = {
  NEWEST: 'NEWEST',
  OLDEST: 'OLDEST'
};

const PREFIX = 'DASHBOARD/MY_FILES';

export const types = {
  MY_FILE_ADDED: `${PREFIX}/MY_FILE_ADDED`,
  FOLDER_CREATED: `${PREFIX}/FOLDER_CREATED`,
  MY_FILE_DELETED: `${PREFIX}/MY_FILE_DELETED`,
  FILES_MOVED_TO_FOLDER: `${PREFIX}/FILES_MOVED_TO_FOLDER`,
  MY_FILE_CREATOR_CLOSE: `${PREFIX}/MY_FILE_CREATOR_CLOSE`,
  MY_FILE_CREATOR_OPEN: `${PREFIX}/MY_FILE_CREATOR_OPEN`,
  UPLOAD_IMAGE_STACK_DIALOG_CLOSE: `${PREFIX}/UPLOAD_IMAGE_STACK_DIALOG_CLOSE`,
  UPLOAD_IMAGE_STACK_DIALOG_OPEN: `${PREFIX}/UPLOAD_IMAGE_STACK_DIALOG_OPEN`,
  SUBMIT_IMAGES: `${PREFIX}/SUBMIT_IMAGES`,
  CREW_MY_FILE_CREATOR_OPEN: `${PREFIX}/CREW_MY_FILE_CREATOR_OPEN`,
  MOVE_TO_FOLDER_DIALOG_OPEN: `${PREFIX}/MOVE_TO_FOLDER_DIALOG_OPEN`,
  MOVE_TO_FOLDER_DIALOG_CLOSE: `${PREFIX}/MOVE_TO_FOLDER_DIALOG_CLOSE`,
  CREATE_FOLDER_OR_RENAME_DIALOG_OPEN: `${PREFIX}/CREATE_FOLDER_OR_RENAME_DIALOG_OPEN`,
  CREATE_FOLDER_OR_RENAME_DIALOG_CLOSE: `${PREFIX}/CREATE_FOLDER_OR_RENAME_DIALOG_CLOSE`,
  UPLOAD_FILE_DIALOG_OPEN: `${PREFIX}/UPLOAD_FILE_DIALOG_OPEN`,
  UPLOAD_FILE_DIALOG_CLOSE: `${PREFIX}/UPLOAD_FILE_DIALOG_CLOSE`,
  DOWNLOAD_FILE_DIALOG_OPEN: `${PREFIX}/DOWNLOAD_FILE_DIALOG_OPEN`,
  DOWNLOAD_FILE_DIALOG_CLOSE: `${PREFIX}/DOWNLOAD_FILE_DIALOG_CLOSE`,
  FILE_RENAME_FINISHED: `${PREFIX}/FILE_RENAME_FINISHED`,
  SET_SORT_ORDER: `${PREFIX}/SET_SORT_ORDER`,
  OPEN_FOLDER: `${PREFIX}/OPEN_FOLDER`,
  GO_TO_FOLDER: `${PREFIX}/GO_TO_FOLDER`,
  GO_UP_FOLDER: `${PREFIX}/GO_UP_FOLDER`,
  CLEAR_NODE_STACK: `${PREFIX}/CLEAR_NODE_STACK`,
  SET_NODE_STACK: `${PREFIX}/SET_NODE_STACK`
};

const initialState = {
  addedMyFiles: [],
  forceUpdate: 0,
  createdFolder: null,
  fileRenamed: false,
  myFileCreator: {
    open: false,
    isCrewContext: false,
    parentId: null,
    isSharedFile: false,
    projectId: null,
    crewId: null
  },
  moveToFolder: {
    open: false,
    files: null,
    folderList: null
  },
  openCreateFolderOrRenameDialog: {
    open: false,
    event: null,
    fileId: null,
    isSharedFile: false
  },
  uploadFileDialog: {
    open: false
  },
  uploadImageStackDialog: {
    open: false
  },
  downloadFileDialog: {
    open: false,
    files: []
  },
  nodeStack: [],
  sortOrder: SORT_ORDER.NEWEST
};

export const myFilesReducer = (state = initialState, action) => {
  let nextState = state;
  switch (action.type) {
    case types.MY_FILE_ADDED:
      nextState = {
        ...state,
        forceUpdate: state.forceUpdate + 1,
        addedMyFiles: [...state.addedMyFiles, action.payload.myFile]
      };
      break;

    case types.MY_FILE_DELETED:
    case types.FILES_MOVED_TO_FOLDER:
      nextState = {
        ...state,
        forceUpdate: state.forceUpdate + 1
      };
      break;

    case types.FILE_RENAME_FINISHED:
      nextState = {
        ...state,
        fileRenamed: true,
        forceUpdate: state.forceUpdate + 1
      };
      break;

    case types.FOLDER_CREATED:
      nextState = {
        ...state,
        forceUpdate: state.forceUpdate + 1,
        createdFolder: action.payload.folder
      };
      break;

    case types.MY_FILE_CREATOR_OPEN:
      nextState = {
        ...state,
        addedMyFiles: [],
        myFileCreator: {
          ...state.myFileCreator,
          crewId: undefined,
          projectId: undefined,
          parentId: action.parentId,
          isSharedFile: action.isSharedFile,
          open: true
        }
      };
      break;

    case types.UPLOAD_FILE_DIALOG_OPEN:
      nextState = {
        ...state,
        addedMyFiles: [],
        uploadFileDialog: {
          ...state.uploadFileDialog,
          open: true
        }
      };
      break;

    case types.UPLOAD_FILE_DIALOG_CLOSE:
      nextState = {
        ...state,
        addedMyFiles: [],
        uploadFileDialog: {
          ...state.uploadFileDialog,
          open: false
        }
      };
      break;

    case types.UPLOAD_IMAGE_STACK_DIALOG_OPEN:
      nextState = {
        ...state,
        addedMyFiles: [],
        uploadImageStackDialog: {
          ...state.uploadImageStackDialog,
          parentId: action.parentId,
          open: true
        }
      };
      break;

    case types.UPLOAD_IMAGE_STACK_DIALOG_CLOSE:
      nextState = {
        ...state,
        addedMyFiles: [],
        uploadImageStackDialog: {
          ...state.uploadImageStackDialog,
          open: false
        }
      };
      break;

    case types.DOWNLOAD_FILE_DIALOG_OPEN:
      nextState = {
        ...state,
        addedMyFiles: [],
        downloadFileDialog: {
          ...state.downloadFileDialog,
          open: true,
          files: action.files
        }
      };
      break;

    case types.DOWNLOAD_FILE_DIALOG_CLOSE:
      nextState = {
        ...state,
        addedMyFiles: [],
        downloadFileDialog: {
          ...state.downloadFileDialog,
          open: false,
          files: []
        }
      };
      break;

    case types.CREW_MY_FILE_CREATOR_OPEN:
      nextState = {
        ...state,
        addedMyFiles: [],
        myFileCreator: {
          ...state.myFileCreator,
          open: true,
          crewId: action.crewId,
          projectId: action.projectId
        }
      };
      break;

    case types.MY_FILE_CREATOR_CLOSE:
      nextState = {
        ...state,
        addedMyFiles: [],
        myFileCreator: {
          ...state.myFileCreator,
          open: false,
          parentId: null,
          crewId: null,
          projectId: null
        }
      };
      break;

    case types.SET_SORT_ORDER:
      nextState = {
        ...state,
        sortOrder: action.payload.order
      };
      break;

    case types.CREATE_FOLDER_OR_RENAME_DIALOG_OPEN:
      nextState = {
        ...state,
        createdFolder: null,
        fileRenamed: false,
        openCreateFolderOrRenameDialog: {
          ...state.openCreateFolderOrRenameDialog,
          open: true,
          event: action.event,
          file: action.file,
          isSharedFile: action.isSharedFile
        }
      };
      break;

    case types.CREATE_FOLDER_OR_RENAME_DIALOG_CLOSE:
      nextState = {
        ...state,
        createdFolder: null,
        fileRenamed: false,
        openCreateFolderOrRenameDialog: {
          ...state.openCreateFolderOrRenameDialog,
          open: false
        }
      };
      break;

    case types.MOVE_TO_FOLDER_DIALOG_OPEN:
      nextState = {
        ...state,
        moveToFolder: {
          ...state.moveToFolder,
          open: true,
          files: action.files,
          folderList: action.folderList
        }
      };
      break;

    case types.MOVE_TO_FOLDER_DIALOG_CLOSE:
      nextState = {
        ...state,
        moveToFolder: {
          ...state.moveToFolder,
          open: false,
          files: null,
          folderList: null
        }
      };
      break;

    case types.OPEN_FOLDER:
      nextState = {
        ...state,
        nodeStack: [...state.nodeStack, action.file]
      };
      break;

    case types.GO_UP_FOLDER:
      let nodeStack = [...state.nodeStack];
      nodeStack.pop();
      nextState = {
        ...state,
        nodeStack
      };
      break;

    case types.GO_TO_FOLDER:
      let newNodeStack = [...state.nodeStack];
      const index = newNodeStack.findIndex(
        folder => folder.id === action.folder.id
      );
      nextState = {
        ...state,
        nodeStack: take(newNodeStack, index + 1)
      };
      break;

    case types.CLEAR_NODE_STACK:
      nextState = {
        ...state,
        nodeStack: []
      };
      break;

    case types.SET_NODE_STACK:
      nextState = {
        ...state,
        nodeStack: action.nodeStack
      };
      break;
  }

  return nextState;
};

function openFolder(file) {
  return {
    type: types.OPEN_FOLDER,
    file
  };
}

function goUpFolder() {
  return {
    type: types.GO_UP_FOLDER
  };
}

function goToFolder(folder) {
  return {
    type: types.GO_TO_FOLDER,
    folder
  };
}

function clearNodeStack() {
  return {
    type: types.CLEAR_NODE_STACK
  };
}

function setNodeStack(nodeStack) {
  return {
    type: types.SET_NODE_STACK,
    nodeStack
  };
}

function createFolder(parentId, name, isSharedFile) {
  return (dispatch, getState, { api, myFilesApi }) => {
    try {
      const fullState = getState();
      const uploadCreatorId = sclSelectors.getCurrentUserId(fullState);
      return myFilesApi
        .createFolder({
          name,
          ownerId: uploadCreatorId,
          parentId,
          isSharedFile
        })
        .then(folder => {
          console.log('folder', folder);
          dispatch({ type: types.FOLDER_CREATED, payload: { folder } });
        });
    } catch (err) {
      logAndSendError(err, 'error creating folder');
    }
  };
}

function getMaybeCrewProjectIds(documentLocation) {
  const [
    crewPartName,
    crewId,
    projectPartName,
    projectId
  ] = documentLocation.pathname
    .split('/')
    .filter(Boolean)
    .slice(-4);

  const results =
    crewPartName === 'crewHub' && projectPartName === 'projects'
      ? [crewId, projectId]
      : [undefined, undefined];

  console.warn(
    'crewId and projectId arguments are ignored and are pulled ' +
      ' from the current location instead.',
    crewId,
    projectId
  );
  return results;
}

/* The upload/encoding stores all the necessary data in a new record of the
   entities/uploads collection. If you need this data elsewhere, eg. in a
   myFile, you need to copy it over. For myFiles and projects this is done
   in the cf "updateDependentObjectsWhenUploadChanges"
 */
function addMyFile(
  name,
  description,
  file,
  crewId,
  projectId,
  parentId,
  isSharedFile
) {
  // console.info('addMyFile', arguments);
  return async (dispatch, getState, { api, myFilesApi, documentLocation }) => {
    if (file) {
      // when file exists, create or replace a myFile
      const fileType = getFileType(file.name, true);
      const fullState = getState();
      const uploadCreatorId = sclSelectors.getCurrentUserId(fullState);
      await dispatch(
        // TODO can the uploading code be simplified and made testable?
        uploadActions.startUpload({
          sourceFile: file,
          fileType,
          uploadOwnerId: uploadCreatorId,
          uploadType: UPLOAD_TYPE.MY_FILE,
          isSharedFile
        })
      )
        .then(handleMyFileUploaded)
        .catch(failedUploadRecord => {
          logAndSendError(failedUploadRecord, 'error uploading myFile');
          handleMyFileUploaded(failedUploadRecord, true);
        });
    } else {
      // otherwise update the description only
      alert('not implemented');
    }

    // --------------------------------------------

    // add the myFile regardless of error
    // so we can show it as failed
    async function handleMyFileUploaded(uploadRecord, uploadHasFailed) {
      try {
        // console.log('handleMyFileUploaded', uploadRecord);
        if (uploadRecord.status === 'CANCELED') {
          console.log('upload canceled', uploadRecord);
          return;
        }

        const [crewId, projectId] = getMaybeCrewProjectIds(documentLocation);

        const myFile = await myFilesApi.addMyFile(
          {
            ...uploadRecord,
            name,
            description,
            isCrewFile: !!(crewId && projectId),
            status: getMyFileStatus(uploadRecord),
            parentId,
            isSharedFile
          },
          crewId,
          projectId
        );
        console.log('handleMyFileUploaded response', myFile);
        dispatch({ type: types.MY_FILE_ADDED, payload: { myFile } });
      } catch (err) {
        logAndSendError(err, 'error creating myFile');
      }
    }

    function getMyFileStatus(uploadRecord) {
      if (uploadRecord.fileType === 'VIDEO') {
        return uploadRecord.status === 'COMPLETE'
          ? 'Encoding'
          : uploadRecord.status;
      } else {
        return uploadRecord.status === 'COMPLETE'
          ? 'Ready'
          : uploadRecord.status;
      }
    }

    function showUnexpectedFileTypeError(file) {
      // note: the blank lines are part of the text formatting, don't remove
      // them unless you want to change that
      alert(
        `Unexpected File Type!

          ${file.name}

          You can upload images, videos, screenplays in .fountain format and .pdf files only.`
      );
    }

    function shouldForceEncodingError(uploadRecord) {
      return (
        uploadRecord.fileName.toLowerCase().indexOf('force-encoding-error') >= 0
      );
    }
  };
}

function deleteMyFiles(input) {
  return (dispatch, getState, { myFilesApi }) => {
    return myFilesApi.deleteMyFiles(input).then(files => {
      dispatch({ type: types.MY_FILE_DELETED });
    });
  };
}

function setSortOrder(order) {
  return { type: types.SET_SORT_ORDER, payload: { order } };
}

function gotoMyFile(myFile, history) {
  return (dispatch, getState) => {
    return history.push('/project/' + myFile.id);
  };
}

function openMyFileCreator(isCrewContext, parentId, isSharedFile) {
  return {
    type: types.MY_FILE_CREATOR_OPEN,
    isCrewContext,
    parentId,
    isSharedFile
  };
}

function openCrewMyFileCreator(crewId, projectId) {
  return { type: types.CREW_MY_FILE_CREATOR_OPEN, crewId, projectId };
}

function closeMyFileCreator() {
  return { type: types.MY_FILE_CREATOR_CLOSE };
}

function closeMoveToFolderDialog() {
  return { type: types.MOVE_TO_FOLDER_DIALOG_CLOSE };
}

function createFolderOrRenameDialog(event, file, isSharedFile) {
  return {
    type: types.CREATE_FOLDER_OR_RENAME_DIALOG_OPEN,
    event,
    file,
    isSharedFile
  };
}

function openUploadFileDialog(parentId) {
  return {
    type: types.UPLOAD_FILE_DIALOG_OPEN,
    parentId
  };
}

function closeUploadFileDialog() {
  return { type: types.UPLOAD_FILE_DIALOG_CLOSE };
}

function openUploadImageStackDialog(parentId) {
  return {
    type: types.UPLOAD_IMAGE_STACK_DIALOG_OPEN,
    parentId
  };
}

function closeUploadImageStackDialog() {
  return { type: types.UPLOAD_IMAGE_STACK_DIALOG_CLOSE };
}

function openDownloadFileDialog(files) {
  return {
    type: types.DOWNLOAD_FILE_DIALOG_OPEN,
    files
  };
}

function closeDownloadFileDialog() {
  return { type: types.DOWNLOAD_FILE_DIALOG_CLOSE };
}

function submitImages(
  title,
  files,
  crewId,
  projectId,
  parentId,
  isSharedFile,
  uploadUiState
) {
  return (dispatch, getState, { myFilesApi, documentLocation }) => {
    if (files && files.length) {
      return multiUpload(files).then(handleImagesUploaded);
    } else {
      // otherwise update description only
      console.log('no image file selected');
      dispatch(closeUploadImageStackDialog());
    }

    // --------------------------------------------

    async function multiUpload(files) {
      let bytesLoaded = 0;
      const bytesTotal = files.reduce((acc, f) => acc + f.size, 0);
      const onProgress = (loaded, total, uploadTask) => {
        if (uploadUiState.canceled) {
          uploadTask && uploadTask.cancel && uploadTask.cancel();
        }
        return {
          bytesLoaded: bytesLoaded + loaded,
          bytesTotal
        };
      };
      const uploadCreatorId = sclSelectors.getCurrentUserId(getState());

      const uploadRecords = [];
      for (const file of files) {
        if (uploadUiState.canceled) {
          break;
        }
        await dispatch(
          uploadActions.startUpload({
            sourceFile: file,
            fileType: 'image',
            uploadOwnerId: uploadCreatorId,
            uploadType: UPLOAD_TYPE.IMAGE,
            onProgress,
            resetProgress: bytesLoaded === 0
          })
        )
          .then(uploadRecord => {
            uploadRecords.push(uploadRecord);
          })
          .catch(failedUploadRecord => {
            logAndSendError(failedUploadRecord, 'error uploading image');
            uploadRecords.push(failedUploadRecord);
          });
        bytesLoaded += file.size;
      }
      return uploadRecords;
    }

    async function handleImagesUploaded(uploadRecords) {
      console.log('handleImagesUploaded', uploadRecords);
      if (uploadUiState.canceled) {
        return [];
      }
      if (uploadRecords.some(ur => ur.status !== 'COMPLETE')) {
        return uploadRecords;
      }
      try {
        const fullState = getState();
        const ownerId = sclSelectors.getCurrentUserId(fullState);
        const [crewId, projectId] = getMaybeCrewProjectIds(documentLocation);
        const myFile = await myFilesApi.addMyFile(
          {
            id: uid(),
            name: title,
            isCrewFile: !!(crewId && projectId),
            parentId,
            isSharedFile,
            ownerId,
            images: map(uploadRecords, ur => ({
              uploadId: ur.id,
              downloadUrl: ur.downloadUrl
            }))
          },
          crewId,
          projectId
        );
        console.log('handleMyFileUploaded response', myFile);
        dispatch({ type: types.MY_FILE_ADDED, payload: { myFile } });
      } catch (e) {
        logAndSendError(e, 'error adding image');
      }
      dispatch(closeUploadImageStackDialog());
    }
  };
}

// -------------------
function renameFile(fileId, newName) {
  return (dispatch, getState, { myFilesApi }) => {
    return myFilesApi
      .updateMyFile({ id: fileId, name: newName })
      .then(files => {
        dispatch({ type: types.FILE_RENAME_FINISHED });
      });
  };
}

function closeCreateFolderDialog() {
  return { type: types.CREATE_FOLDER_OR_RENAME_DIALOG_CLOSE };
}

function moveFilesToFolder(files, folderId) {
  return (dispatch, getState, { myFilesApi }) => {
    return myFilesApi.moveToFolder(files, folderId).then(files => {
      dispatch({ type: types.FILES_MOVED_TO_FOLDER });
    });
  };
}

function resolvePreloadedFileDownloadUrlNew(myFile) {
  return (dispatch, getState, { api }) => {
    const asset = findAsset(myFile.downloadUrl);
    return api.getContentBucketDownloadUrl(asset);
  };
}

function resolvePreloadedFileDownloadUrl(url) {
  return (dispatch, getState, { api }) => {
    return api.getContentBucketDownloadUrl({ name: url });
  };
}

function moveToFolderDialog(files, folderList) {
  return {
    type: types.MOVE_TO_FOLDER_DIALOG_OPEN,
    files,
    folderList
  };
}

export const actions = {
  addMyFile,
  createFolder,
  closeMyFileCreator,
  deleteMyFiles,
  gotoMyFile,
  openMyFileCreator,
  openCrewMyFileCreator,
  resolvePreloadedFileDownloadUrl,
  resolvePreloadedFileDownloadUrlNew,
  setSortOrder,
  moveToFolderDialog,
  closeMoveToFolderDialog,
  moveFilesToFolder,
  createFolderOrRenameDialog,
  closeCreateFolderDialog,
  renameFile,
  openUploadFileDialog,
  closeUploadFileDialog,
  openUploadImageStackDialog,
  closeUploadImageStackDialog,
  openDownloadFileDialog,
  closeDownloadFileDialog,
  submitImages,
  openFolder,
  goToFolder,
  goUpFolder,
  clearNodeStack,
  setNodeStack
};

const getCurrentFileList = (state, allFiles, rootId) => {
  const treeFiles = convertToTreeStructure(allFiles, rootId);
  const nodeStack = get(state, 'dashboard.myFiles.nodeStack');

  if (!nodeStack || isEmpty(treeFiles)) {
    return [];
  }

  const path = nodeStack
    .map(file => file.id)
    .reduce((acc, id) => {
      const obj = acc === '' ? treeFiles : get(treeFiles, acc);
      const index = obj.findIndex(child => child.id === id);
      acc += `[${index}].children`;
      return acc;
    }, '');

  return isEmpty(nodeStack) ? treeFiles : get(treeFiles, path) || [];
};

export const selectors = {
  getAddedMyFiles: state => state.dashboard.myFiles.addedMyFiles,
  getForceUpdate: state => state.dashboard.myFiles.forceUpdate,
  getMyFileCreator: state => state.dashboard.myFiles.myFileCreator,
  getSortOrder: state => state.dashboard.myFiles.sortOrder,
  getMoveToFolder: state => state.dashboard.myFiles.moveToFolder,
  getOpenCreateFolderOrRenameDialog: state =>
    state.dashboard.myFiles.openCreateFolderOrRenameDialog,
  getUploadFileDialog: state => state.dashboard.myFiles.uploadFileDialog,
  getUploadImageStackDialog: state =>
    state.dashboard.myFiles.uploadImageStackDialog,
  getDownloadFileDialog: state => state.dashboard.myFiles.downloadFileDialog,
  getCreatedFolder: state => state.dashboard.myFiles.createdFolder,
  checkIfRenamed: state => state.dashboard.myFiles.fileRenamed,
  getNodeStack: state => state.dashboard.myFiles.nodeStack,
  getCurrentFileList
};
