/* global Intercom */

import LogRocket from 'logrocket';
import config from 'config';

function startSession(user) {
  if (config.logRocketEnabled) {
    LogRocket.startNewSession();
    identifyUser(user);
  }
}

function stopSession() {
  if (config.logRocketEnabled) {
    LogRocket.startNewSession();
    LogRocket.identify();
  }
}

function identifyUser(user) {
  let userID = user.email ? user.email : user.id;
  LogRocket.identify(userID);

  Intercom('update', {
    logrocketURL:
      'https://app.logrocket.com/' + config.logrocket + '/sessions?u=' + userID
  });

  LogRocket.getSessionURL(function(sessionURL) {
    Intercom('trackEvent', 'LogRocket', { sessionURL: sessionURL });
  });
}

function initTrackingApi() {
  return {
    tracking: {
      startSession,
      stopSession
    }
  };
}

export default initTrackingApi;
