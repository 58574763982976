const smashcutService = (config, apolloClient) => {
  return {
    createProject: async (projectName, projectDescription, url) => {
      // TODO: apolloClient.createProject
      // const idToken = await helper.getCurrentUserToken();
      // const res = await helper.post('/project/create-v2', {
      //   idToken,
      //   name: projectName,
      //   description: projectDescription,
      //   fromURL: url
      // });
      // return res;
    },
    startEncoding: async projectId => {
      // TODO: apolloClient.startEncoding
      // const idToken = await helper.getCurrentUserToken();
      // const res = await helper.post('/project/start-encoding', {
      //   idToken,
      //   projectId
      // });
      // return res;
    },
    startEncodingV2: async uploadId => {
      // TODO: apolloClient.startEncodingV2
      // const idToken = await helper.getCurrentUserToken();
      // const res = await helper.post('/encoding/start-encoding', {
      //   idToken,
      //   uploadId
      // });
      // return res;
    }
  };
};

export default smashcutService;
