import { all, takeEvery, take, call, put, select } from 'redux-saga/effects';
import { types } from '../reducers/accountDetails';
import { has } from 'lodash';
import { loadLoggedInUser } from 'smashcut-client-lib/actions/loadLoggedInUser';
import { actionType } from 'smashcut-client-lib/constants';
import { intercomHelper } from '../utils/intercomHelper';

export function* reauthenticate(mainApi, { password }) {
  try {
    yield call(mainApi.reauthenticate, password);
    yield put({ type: types.REAUTHENTICATE_SUCCESS });
  } catch (e) {
    console.warn('nok', e);
    yield put({ type: types.REAUTHENTICATE_FAILURE, error: e });
  }
}

export function* updateProfileTab(mainApi, intercomHelper, { values }) {
  let userId;
  try {
    const state = yield select();
    userId = state.auth.user.id;

    yield call(mainApi.user.updateSocial, userId, {
      website: values.website
    });

    const res = yield call(mainApi.user.updateUser, userId, {
      avatar: values.avatar,
      fullName: values.fullName,
      email: values.email,
      location: values.location,
      shortBio: values.shortBio
    });

    yield call(intercomHelper().update, values.email);

    yield put({
      type: actionType.updateUser,
      changes: res.data.updateUser
    });

    yield put({
      type: types.UPDATE_PROFILE_TAB_SUCCESS,
      profileTabData: res.data.updateUser
    });
  } catch (e) {
    yield put({
      type: types.UPDATE_PROFILE_TAB_FAILURE,
      error: 'An error occurred while updating, please try again'
    });
  }
}

export function* updateNotificationsTab(mainApi, { values }) {
  try {
    const state = yield select();
    const userId = state.auth.user.id;
    yield call(mainApi.user.updateSettings, userId, {
      smsNotifications: values.smsNotifications,
      emailNotifications: values.emailNotifications
    });
    yield call(mainApi.user.updateUser, userId, {
      smsPhoneNumber: values.smsPhoneNumber
    });
    const user = yield call(mainApi.user.loadUser, userId);
    yield put(loadLoggedInUser(user));
    yield put({
      type: actionType.updateUserSettings,
      settings: values
    });

    yield put({
      type: types.UPDATE_NOTIFICATIONS_TAB_SUCCESS,
      notificationTabData: values
    });
  } catch (e) {
    yield put({
      type: types.UPDATE_NOTIFICATIONS_TAB_FAILURE,
      error: 'An error occurred while updating, please try again'
    });
  }
}

export function* updatePrivacyTab(mainApi, { values }) {
  try {
    const state = yield select();
    const userId = state.auth.user.id;
    yield call(mainApi.user.updateUser, userId, {
      isPrivate: values.isPrivate
    });
    const user = yield call(mainApi.user.loadUser, userId);
    yield put(loadLoggedInUser(user));
    yield put({
      type: types.UPDATE_PRIVACY_TAB_SUCCESS,
      privacyTabData: values
    });
  } catch (e) {
    yield put({
      type: types.UPDATE_PRIVACY_TAB_FAILURE,
      error: 'An error occurred while updating, please try again'
    });
  }
}

export function* updateSocialTab(mainApi, { values }) {
  try {
    const state = yield select();
    const userId = state.auth.user.id;
    yield call(mainApi.user.updateSocial, userId, {
      youtube: values.youtube,
      vimeo: values.vimeo,
      instagram: values.instagram,
      facebook: values.facebook,
      twitter: values.twitter
    });
    const user = yield call(mainApi.user.loadUser, userId);
    yield put(loadLoggedInUser(user));
    yield put({
      type: types.UPDATE_SOCIAL_TAB_SUCCESS,
      socialTabData: values
    });
  } catch (e) {
    yield put({
      type: types.UPDATE_SOCIAL_TAB_FAILURE,
      error: 'An error occurred while updating, please try again'
    });
  }
}

export function* updatePasswordTab(mainApi, { values }) {
  try {
    const state = yield select();
    const userId = state.auth.user.id;
    const isChanged = yield call(
      mainApi.changePassword,
      userId,
      values.password,
      values.newPassword
    );

    if (!isChanged) {
      yield put({
        type: types.UPDATE_PASSWORD_TAB_FAILURE,
        error: 'Incorrect Password'
      });
      return;
    }
    yield put({ type: types.UPDATE_PASSWORD_TAB_SUCCESS });
  } catch (e) {
    yield put({
      type: types.UPDATE_PASSWORD_TAB_FAILURE,
      error: 'An error occurred while updating, please try again'
    });
  }
}

export function* accountDetailsSaga({ apis }) {
  const mainApi = apis.api;
  yield all([
    takeEvery(
      types.UPDATE_PROFILE_TAB_REQUEST,
      updateProfileTab.bind(this, mainApi, intercomHelper)
    ),
    takeEvery(
      types.UPDATE_NOTIFICATIONS_TAB_REQUEST,
      updateNotificationsTab.bind(this, mainApi)
    ),
    takeEvery(
      types.UPDATE_PRIVACY_TAB_REQUEST,
      updatePrivacyTab.bind(this, mainApi)
    ),
    takeEvery(
      types.UPDATE_SOCIAL_TAB_REQUEST,
      updateSocialTab.bind(this, mainApi)
    ),
    takeEvery(
      types.UPDATE_PASSWORD_TAB_REQUEST,
      updatePasswordTab.bind(this, mainApi)
    ),
    takeEvery(types.REAUTHENTICATE_REQUEST, reauthenticate.bind(this, mainApi))
  ]);
}
