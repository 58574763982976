import { addVideoComment } from './addVideoComment';
import { addVideoReply } from './addVideoReply';
import { updateVideoUploadProgress } from './updateVideoUploadProgress';
import {
  getAppSettings,
  getCommentSectionId,
  getCurrentUserId,
  getVideoCommentUploadPath,
  getVideoPlayerCurrentTime,
  getVideoReplyUploadPath
} from '../selectors';
import { logAndSendError } from 'utils/sentryHelper';
import { uid } from 'utils/uid';

// the payload is passed through from opening the video recorder popup
export const saveRecordedVideo = (savedVideo, payload) => {
  return (dispatch, getState, { api }) => {
    let state = getState();
    let author = getCurrentUserId(state);
    let commentSectionId = getCommentSectionId(state);
    let videoMetadata = {
      browser: savedVideo.browser,
      recorderSettings: getAppSettings(state).videoRecorder,
      videoHeight: savedVideo.videoHeight,
      videoWidth: savedVideo.videoWidth
    };

    let onProgress = (bytesTransferred, totalBytes) => {
      var percent = (bytesTransferred * 100) / totalBytes;
      dispatch(updateVideoUploadProgress(percent));
    };

    let onError = err => {
      console.error('error while uploading video', err);
    };

    if (payload.typeOfComment === 'reply') {
      let replyId = uid();
      let path = getVideoReplyUploadPath(commentSectionId, replyId);
      let onComplete = url => {
        dispatch(
          addVideoReply({
            commentSectionId,
            replyId,
            author,
            parentId: payload.parentId,
            url,
            videoMetadata
          })
        );
      };
      return api
        .uploadVideo(path, savedVideo.blob, onProgress)
        .then(onComplete, onError)
        .catch(e => logAndSendError(e, 'error uploading recorded video'));
    } else {
      let commentId = uid();
      let path = getVideoCommentUploadPath(commentSectionId, commentId);
      let time = getVideoPlayerCurrentTime(state);
      let onComplete = url => {
        dispatch(
          addVideoComment({
            commentSectionId,
            commentId,
            author,
            time,
            url,
            videoMetadata
          })
        );
      };
      return api
        .uploadVideo(path, savedVideo.blob, onProgress)
        .then(onComplete, onError)
        .catch(e => logAndSendError(e, 'error uploading video'));
    }
  };
};
