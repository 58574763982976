import Img from 'react-image';
import Logo from './header_logo.png';
import React from 'react';
import styles from './CenteredLogo.scss';

const CenteredLogo = ({ children, testLogo }) => (
  <div className={styles.centeredLogo}>
    <div className={styles.logoBackground}>
      <Img
        src={testLogo || Logo}
        className={styles.logoImage}
        alt="School of Rock Logo"
      />
    </div>
    {children}
  </div>
);

export default CenteredLogo;
