// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NoteMarkerDisplay--title--1biBJCuC{font-weight:bold;font-size:16px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:10px}.NoteMarkerDisplay--title--1biBJCuC .NoteMarkerDisplay--avatar--1vHvkfGG{padding-right:10px}.NoteMarkerDisplay--title--1biBJCuC .NoteMarkerDisplay--titleContent--1OiLjTLL .NoteMarkerDisplay--titleSub--288uez3r{font-size:14px;font-weight:normal;color:#000}\n", ""]);
// Exports
exports.locals = {
	"title": "NoteMarkerDisplay--title--1biBJCuC",
	"avatar": "NoteMarkerDisplay--avatar--1vHvkfGG",
	"titleContent": "NoteMarkerDisplay--titleContent--1OiLjTLL",
	"titleSub": "NoteMarkerDisplay--titleSub--288uez3r"
};
module.exports = exports;
