const PREFIX = 'DASHBOARD_ARCHIVES';

export const types = {
  GET_SIGNED_ARCHIVE_URL_REQUEST: `${PREFIX}/GET_SIGNED_ARCHIVE_URL_REQUEST`,
  GET_SIGNED_ARCHIVE_URL_SUCCESS: `${PREFIX}/GET_SIGNED_ARCHIVE_URL_SUCCESS`,
  GET_SIGNED_ARCHIVE_URL_FAILURE: `${PREFIX}/GET_SIGNED_ARCHIVE_URL_FAILURE`,
  UPDATE_ARCHIVE_VISIBILITY_REQUEST: `${PREFIX}/UPDATE_ARCHIVE_VISIBILITY_REQUEST`,
  UPDATE_ARCHIVE_VISIBILITY_SUCCESS: `${PREFIX}/UPDATE_ARCHIVE_VISIBILITY_SUCCESS`,
  UPDATE_ARCHIVE_VISIBILITY_FAILURE: `${PREFIX}/UPDATE_ARCHIVE_VISIBILITY_FAILURE`,
  UPDATE_ARCHIVE_METADATA_REQUEST: `${PREFIX}/UPDATE_ARCHIVE_METADATA_REQUEST`,
  UPDATE_ARCHIVE_METADATA_SUCCESS: `${PREFIX}/UPDATE_ARCHIVE_METADATA_SUCCESS`,
  UPDATE_ARCHIVE_METADATA_FAILURE: `${PREFIX}/UPDATE_ARCHIVE_METADATA_FAILURE`,
  REMOVE_ARCHIVE_REQUEST: `${PREFIX}/REMOVE_ARCHIVE_REQUEST`,
  REMOVE_ARCHIVE_SUCCESS: `${PREFIX}/REMOVE_ARCHIVE_SUCCESS`,
  REMOVE_ARCHIVE_FAILURE: `${PREFIX}/REMOVE_ARCHIVE_FAILURE`
};

const initialState = {};

export const archivesReducer = (state = initialState, action) => {
  let nextState = state;

  switch (action.type) {
    case types.GET_SIGNED_ARCHIVE_URL_REQUEST:
      nextState = { ...state, signedArchiveUrl: undefined };
      break;

    case types.GET_SIGNED_ARCHIVE_URL_SUCCESS:
      nextState = { ...state, signedArchiveUrl: action.signedUrl };
      break;
  }
  return nextState;
};

export const getSignedArchiveUrl = (archiveUrl, downloadFileName) => ({
  type: types.GET_SIGNED_ARCHIVE_URL_REQUEST,
  archiveUrl,
  downloadFileName
});

const updateArchiveVisibility = (archiveId, visibility) => ({
  type: types.UPDATE_ARCHIVE_VISIBILITY_REQUEST,
  archiveId,
  visibility
});

const updateArchiveMetadata = (archiveId, metadata) => ({
  type: types.UPDATE_ARCHIVE_METADATA_REQUEST,
  archiveId,
  metadata
});

const removeArchive = archiveId => ({
  type: types.REMOVE_ARCHIVE_REQUEST,
  archiveId
});

export const actions = {
  getSignedArchiveUrl,
  updateArchiveVisibility,
  updateArchiveMetadata,
  removeArchive
};

export const selectors = {
  getSignedArchiveUrl: state => state.dashboard.archives.signedArchiveUrl
};
