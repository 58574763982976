import accountDetailsReducer from 'reducers/accountDetails';
import adminReducer from './admin/reducers/adminReducer';
import fileDetailsReducer from 'reducers/fileDetails';
import lessonImageReducer from 'reducers/lessonImage';
import lessonReducer from './reducers/lesson';
import mainReducer from 'smashcut-client-lib/reducers/mainReducer';
import mobileBetaWarning from './reducers/mobileBetaWarning';
import noteDisplayReducer from './reducers/noteDisplay';
import officeHoursReducer from './reducers/officeHoursReducer';
import relatedVideoPlayerReducer from './reducers/relatedVideoPlayer';
import onlyOnComputerWarningReducer from './reducers/onlyOnComputerWarning';
import scheduleAvailabilityReducer from './reducers/scheduleAvailability';
import scheduleBookingsReducer from './reducers/scheduleBookings.js';
import screenplayViewReducer from './reducers/screenplayView';
import shareableFilesReducer from './reducers/shareableFiles';
import { adminActionsReducer } from './admin/reducers/adminActionsReducer';
import { appVersionReducer } from './reducers/appVersion';
import { dashboardReducer } from './reducers/dashboard';
import { lessonTabReducer } from './reducers/lessonTab';
import { notificationsReducer } from './reducers/notifications';
import { popupReducer } from './reducers/popupReducer';
import { reducer as lessonProjectReducer } from './reducers/lessonProject';
import { selectProgramReducer } from './components/selectProgram/selectProgramReducer';
import { uploadsReducer } from './reducers/uploads';
import { videoAppTabReducer } from './reducers/videoAppTabs';
import { videoConferenceReducer } from './reducers/videoConference';
import { hotSpotReducer } from './reducers/hotspot';

export const allReducers = config =>
  mainReducer(config, [
    { selector: 'accountDetails', reducerFunction: accountDetailsReducer },
    { selector: 'admin', reducerFunction: adminReducer },
    { selector: 'adminActions', reducerFunction: adminActionsReducer },
    { selector: 'appVersion', reducerFunction: appVersionReducer },
    { selector: 'availability', reducerFunction: scheduleAvailabilityReducer },
    { selector: 'bookings', reducerFunction: scheduleBookingsReducer },
    { selector: 'dashboard', reducerFunction: dashboardReducer },
    { selector: 'fileDetails', reducerFunction: fileDetailsReducer },
    { selector: 'lesson', reducerFunction: lessonReducer },
    { selector: 'lessonImage', reducerFunction: lessonImageReducer },
    { selector: 'lessonProject', reducerFunction: lessonProjectReducer },
    { selector: 'lessonTab', reducerFunction: lessonTabReducer },
    { selector: 'mobileBetaWarning', reducerFunction: mobileBetaWarning },
    { selector: 'noteDisplay', reducerFunction: noteDisplayReducer },
    { selector: 'notifications', reducerFunction: notificationsReducer },
    { selector: 'officeHours', reducerFunction: officeHoursReducer },
    { selector: 'popup', reducerFunction: popupReducer },
    {
      selector: 'relatedVideoPlayer',
      reducerFunction: relatedVideoPlayerReducer
    },
    {
      selector: 'onlyOnComputerWarning',
      reducerFunction: onlyOnComputerWarningReducer
    },
    { selector: 'screenplayView', reducerFunction: screenplayViewReducer },
    { selector: 'selectProgram', reducerFunction: selectProgramReducer },
    { selector: 'shareableFiles', reducerFunction: shareableFilesReducer },
    { selector: 'uploads', reducerFunction: uploadsReducer },
    { selector: 'videoAppTab', reducerFunction: videoAppTabReducer },
    { selector: 'videoConference', reducerFunction: videoConferenceReducer },
    { selector: 'hotspot', reducerFunction: hotSpotReducer }
  ]);
