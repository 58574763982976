import { extendMoment } from 'moment-range';
import _ from 'lodash';

const Moment = require('moment-timezone');
const moment = extendMoment(Moment);

const BASE = 'SCHEDULE_AVAILABILITY';
export const types = {
  BEGIN_BATCH: `${BASE}/BEGIN_BATCH`,
  END_BATCH: `${BASE}/END_BATCH`,
  BATCH_SUCCESS: `${BASE}/BATCH_SUCCESS`,
  BATCH_FAILURE: `${BASE}/BATCH_FAILURE`,
  GET_ALL_AVAILABILITY_REQUEST: `${BASE}/GET_ALL_AVAILABILITY_REQUEST`,
  GET_ALL_AVAILABILITY_SUCCESS: `${BASE}/GET_ALL_AVAILABILITY_SUCCESS`,
  GET_ALL_AVAILABILITY_FAILURE: `${BASE}/GET_ALL_AVAILABILITY_FAILURE`,
  UPDATE_AVAILABILITY_REQUEST: `${BASE}/UPDATE_AVAILABILITY_REQUEST`,
  UPDATE_AVAILABILITY_SUCCESS: `${BASE}/UPDATE_AVAILABILITY_SUCCESS`,
  UPDATE_AVAILABILITY_FAILURE: `${BASE}/UPDATE_AVAILABILITY_FAILURE`,
  ADD_NEW_AVAILABILITY_REQUEST: `${BASE}/ADD_NEW_AVAILABILITY_REQUEST`,
  ADD_NEW_AVAILABILITY_SUCCESS: `${BASE}/ADD_NEW_AVAILABILITY_SUCCESS`,
  ADD_NEW_AVAILABILITY_FAILURE: `${BASE}/ADD_NEW_AVAILABILITY_FAILURE`,
  DELETE_AVAILABILITY_REQUEST: `${BASE}/DELETE_AVAILABILITY_REQUEST`,
  DELETE_AVAILABILITY_SUCCESS: `${BASE}/DELETE_AVAILABILITY_SUCCESS`,
  DELETE_AVAILABILITY_FAILURE: `${BASE}/DELETE_AVAILABILITY_FAILURE`
};

export const initialState = {
  schedules: [
    {
      //default
      schedule: [[32, 33], [37, 39]]
    }
  ]
};

export default (state = initialState, action) => {
  let batchErrors = state.batchErrors || [];

  switch (action.type) {
    case types.BEGIN_BATCH:
      // console.log('[RED] begin batch');
      return { ...state, batchMode: true, batchErrors: [], batchDone: false };

    case types.END_BATCH:
      // console.log('[RED] end batch');
      return { ...state, batchMode: false };

    case types.BATCH_FAILURE:
    case types.BATCH_SUCCESS:
      // console.log('[RED] batch done. errors: ', state.batchErrors);
      return {
        ...state,
        batchMode: false,
        batchDone: true,
        batchErrors,
        success: action.type == types.BATCH_SUCCESS
      };

    case types.GET_ALL_AVAILABILITY_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case types.GET_ALL_AVAILABILITY_SUCCESS: {
      const availabilities = _.clone(state.availabilities || {});
      const bookings = _.clone(state.bookings || {});
      const schedule = action.schedules.map(s => {
        const range = (() => {
          if (s.from && s.to) {
            return moment.range(
              moment(s.from).startOf('day'),
              moment(s.to).endOf('day')
            );
          }
        })();
        return {
          id: s.id,
          availability: s.ranges,
          range,
          timeZone: s.timeZone,
          isDefault: s.isDefault
        };
      });

      availabilities[action.userId] = schedule;
      bookings[action.userId] = action.bookings;
      return {
        ...state,
        isLoading: false,
        schedules: action.schedules,
        availabilities,
        bookings
      };
    }

    case types.GET_ALL_AVAILABILITY_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case types.UPDATE_AVAILABILITY_REQUEST:
    case types.ADD_NEW_AVAILABILITY_REQUEST:
      return {
        ...state,
        updating: true,
        updated: false,
        error: null
      };

    case types.UPDATE_AVAILABILITY_SUCCESS:
    case types.ADD_NEW_AVAILABILITY_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: true,
        error: null
      };

    case types.UPDATE_AVAILABILITY_FAILURE:
    case types.ADD_NEW_AVAILABILITY_FAILURE:
      batchErrors.push(action.error);
      return {
        ...state,
        updating: false,
        updated: false,
        error: action,
        batchErrors
      };

    case types.DELETE_AVAILABILITY_REQUEST:
      return {
        ...state,
        deleting: action.id,
        deleted: undefined
      };

    case types.DELETE_AVAILABILITY_SUCCESS:
      return {
        ...state,
        deleting: undefined,
        deleted: state.deleting
      };

    case types.DELETE_AVAILABILITY_FAILURE:
      batchErrors.push(action.error);
      return {
        ...state,
        deleting: undefined,
        deleted: undefined,
        error: action.error,
        batchErrors
      };

    default:
      return state;
  }
};

export const actions = {
  beginBatch: () => ({
    type: types.BEGIN_BATCH
  }),
  endBatch: () => ({
    type: types.END_BATCH
  }),
  getAllAvailability: ({ userId }) => ({
    type: types.GET_ALL_AVAILABILITY_REQUEST,
    userId
  }),
  updateAvailability: ({
    id,
    begin,
    end,
    availability,
    isBlocked,
    timezone
  }) => ({
    type: types.UPDATE_AVAILABILITY_REQUEST,
    id,
    begin,
    end,
    availability,
    isBlocked,
    timezone
  }),
  addNewAvailability: ({
    userId,
    begin,
    end,
    availability,
    isBlocked,
    timezone
  }) => ({
    type: types.ADD_NEW_AVAILABILITY_REQUEST,
    userId,
    begin,
    end,
    availability,
    isBlocked,
    timezone
  }),
  deleteAvailability: ({ id }) => ({
    type: types.DELETE_AVAILABILITY_REQUEST,
    id
  })
};
