const assertLoggedIn = () => {
  // if (!firebase.auth().currentUser) {
  //   throw 'no logged in user';
  // }
};

const appService = config => ({
  updateEmail: newEmail => {
    // const currentUser = firebase.auth().currentUser;
    // assertLoggedIn();
    // return currentUser.updateEmail(newEmail);
  },
  updatePassword: newPassword => {
    // const currentUser = firebase.auth().currentUser;
    // assertLoggedIn();
    // return currentUser.updatePassword(newPassword);
  },
  reauthenticate: password => {
    // const currentUser = firebase.auth().currentUser;
    // assertLoggedIn();
    // const credential = firebase.auth.EmailAuthProvider.credential(
    //   currentUser.email,
    //   password
    // );
    //
    // return currentUser.reauthenticateAndRetrieveDataWithCredential(credential);
  }
});

export default appService;
