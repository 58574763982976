import { gql } from '@apollo/client';

export const loadUser = apolloClient => userId => {
  const getUser = gql`
    query getUser($id: String!) {
      user(id: $id) {
        id
        avatar
        currentUserProgramId
        email
        fullName
        firstName
        lastName
        isAdmin
        isMentor
        isPrivate
        isStaff
        isHidden
        isFreeAccount
        lastTimeOnline
        location
        notificationsLastOpenTime
        privacy
        roleLabel
        shortBio
        smsPhoneNumber
        settings {
          handIconIndex
        }
        social {
          facebook
          instagram
          twitter
          vimeo
          website
          youtube
        }
        crews {
          id
          name
          unreadMessageCount
        }
        introVideo {
          id
          created
          downloadUrl
          fileMimeType
          fileName
          fileSize
          fileType
          finished
          isVisible
          ownerId
          started
          status
          uploadDurationMs
          uploadId
          uploadPath
          uploadType
        }
        enrollments {
          id
          applicantDetails {
            audio
            camera
            other
            software
            whyFilmmaker
            workUrls
            howDidYouHearAbout
          }
          application {
            status
            paymentResult
          }
          applicationTimestamp
          isInstructor
          paymentToken
          paymentPlanCode
          program {
            id
            title
          }
          students {
            id
          }
          mentors {
            discipline
          }
          notes {
            userId
            note
          }
          user {
            fullName
          }
          statistics {
            projectCount
            commentCount
            uploadCount
          }
        }
      }
    }
  `;
  return apolloClient
    .query({
      query: getUser,
      variables: {
        id: userId
      }
    })
    .then(res => {
      return res.data.user;
    });
};
