import { actionType } from '../constants';
import { logAndSendError } from 'utils/sentryHelper';

export const sendEmailVerification = user => {
  return (dispatch, getState, { api }) => {
    dispatch({
      type: actionType.createUserSendEmailVerification,
      payload: user
    });
    api.user
      .sendEmailVerification()
      .then(function() {
        dispatch({
          type: actionType.createUserSendEmailVerificationComplete,
          payload: user
        });
      })
      .catch(function(e) {
        dispatch({
          type: actionType.createUserSendEmailVerificationError,
          payload: user
        });
        logAndSendError(e, 'error sending email verification');
      });
  };
};
