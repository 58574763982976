import { actionType } from 'smashcut-client-lib/constants';
import { all, call, takeEvery } from 'redux-saga/effects';
import { types as syllabusTypes } from '../reducers/dashboard/syllabus';
import { types as lessonTypes } from '../reducers/lesson';
import { types as lessonProjectTypes } from '../reducers/lessonProjectTypes';
import { types as videoConferenceTypes } from '../reducers/videoConference';

import { LOAD_LOGGED_IN_USER } from 'smashcut-client-lib/reducers/authReducer';
import * as helper from '../helpers/configSegment';

export function* handleAddComment(_helper) {
  const [analytics] = yield call(_helper.getAnalytics);
  yield call(analytics.track, 'Text Comment Added', {});
}

export function* handleAddreply(_helper, action) {
  const [analytics] = yield call(_helper.getAnalytics);
  yield call(analytics.track, 'Comment Reply Added', {
    parentId: action.parentId
  });
}

export function* handleMarkedAsDone(_helper, action) {
  const [analytics] = yield call(_helper.getAnalytics);
  yield call(analytics.track, 'Assigment Marked as Done', {
    userProgramId: action.userProgramId,
    projectId: action.projectId
  });
}

export function* handleLoadLoggedInUser(_helper, action) {
  const [analytics] = yield call(_helper.getAnalytics);
  const { user } = action.payload;
  yield call(analytics.identify, user.id, {
    name: user.fullName,
    email: user.email
  });
  yield call(analytics.group, _helper.getEnv(), { envName: _helper.getEnv() });
}

export function* handleCreateUserComplete(_helper, action) {
  const [analytics] = yield call(_helper.getAnalytics);
  console.log(JSON.stringify(action, undefined, '  '));
  const { id } = action.payload;
  yield call(analytics.track, 'User Created', {
    id
  });
}

export function* handleLoadLessonSuccess(_helper, action) {
  const [analytics] = yield call(_helper.getAnalytics);
  const { lesson } = action.data;
  yield call(analytics.track, 'Lesson Loaded', {
    lessonId: lesson?.id,
    lessonTitle: lesson?.info?.title
  });
}

export function* handleProjectCreated(_helper, action) {
  const [analytics] = yield call(_helper.getAnalytics);
  const projectId = action.projectId;
  yield call(analytics.track, 'Project Created', {
    projectId
  });
}

export function* handleSessionConnected(_helper) {
  const [analytics] = yield call(_helper.getAnalytics);
  yield call(analytics.track, 'Session Connected', {});
}

export function* segmentAnalyticsSaga() {
  yield all([
    takeEvery(actionType.addTextComment, handleAddComment.bind(this, helper)),
    takeEvery(actionType.addTextReply, handleAddreply.bind(this, helper)),
    takeEvery(
      actionType.createUserComplete,
      handleCreateUserComplete.bind(this, helper)
    ),
    // user clicked done on assigment from sylabus page
    takeEvery(
      syllabusTypes.MARK_AS_DONE_SUCCESS,
      handleMarkedAsDone.bind(this, helper)
    ),
    takeEvery(LOAD_LOGGED_IN_USER, handleLoadLoggedInUser.bind(this, helper)),
    takeEvery(
      lessonTypes.LOAD_LESSON_SUCCESS,
      handleLoadLessonSuccess.bind(this, helper)
    ),
    takeEvery(
      lessonProjectTypes.PROJECT_CREATED,
      handleProjectCreated.bind(this, helper)
    ),
    takeEvery(
      videoConferenceTypes.SESSION_CONNECTED,
      handleSessionConnected.bind(this, helper)
    )
  ]);
}
