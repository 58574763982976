import { gql } from '@apollo/client';

export const updateUserSocial = apolloClient => async (userId, newValues) => {
  const mutation = gql`
    mutation updateUserSocial($input: UpdateUserSocialInput!) {
      updateUserSocial(input: $input) {
        id
        social {
          facebook
          instagram
          twitter
          vimeo
          website
          youtube
        }
      }
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        userId,
        ...newValues
      }
    }
  });
};
