// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SmashcutAvatar--avatarHolder--2Zf6ewfb,.SmashcutAvatar--defaultAvatarHolder--6kGEZrgi{position:relative}.SmashcutAvatar--avatarHolder--2Zf6ewfb .SmashcutAvatar--avatar--27oXRcpo,.SmashcutAvatar--defaultAvatarHolder--6kGEZrgi .SmashcutAvatar--avatar--27oXRcpo{width:42px;height:42px;display:block;border-radius:50%;-webkit-box-shadow:0px 1px 4px 0px rgba(0, 0, 0, .5);box-shadow:0px 1px 4px 0px rgba(0, 0, 0, .5)}.SmashcutAvatar--avatarHolder--2Zf6ewfb .SmashcutAvatar--defaultAvatar--1ybH4AfE,.SmashcutAvatar--defaultAvatarHolder--6kGEZrgi .SmashcutAvatar--defaultAvatar--1ybH4AfE{margin:auto}\n", ""]);
// Exports
exports.locals = {
	"avatarHolder": "SmashcutAvatar--avatarHolder--2Zf6ewfb",
	"defaultAvatarHolder": "SmashcutAvatar--defaultAvatarHolder--6kGEZrgi",
	"avatar": "SmashcutAvatar--avatar--27oXRcpo",
	"defaultAvatar": "SmashcutAvatar--defaultAvatar--1ybH4AfE"
};
module.exports = exports;
