import { gql } from '@apollo/client';

export function updateProgram(apolloClient) {
  const mutation = gql`
    mutation updateProgram($input: ProgramInstanceInput!) {
      updateProgram(input: $input) {
        id
        title
        startDate
      }
    }
  `;

  return async (programInstanceId, updates) => {
    await apolloClient.mutate({
      mutation,
      variables: {
        input: {
          id: programInstanceId,
          ...updates
        }
      },
      refetchQueries: ['getAdminData']
    });
  };
}
