const prefix = 'POPUP';

export const types = {
  SHOW: `${prefix}/SHOW`,
  HIDE: `${prefix}/HIDE`
};

export const initialState = {};

export function popupReducer(state = initialState, action) {
  let nextState = state;

  switch (action.type) {
    case types.SHOW:
      nextState = {
        ...state,
        [action.payload.id]: action.payload.details
      };
      break;

    case types.HIDE:
      nextState = {
        ...state,
        [action.payload.id]: false
      };
      break;
  }
  return nextState;
}

export const actions = {
  show: id => details => ({ type: types.SHOW, payload: { id, details } }),
  hide: id => () => ({ type: types.HIDE, payload: { id } })
};

export const selectors = {
  isPopupOpen: (state, id) => !!state.popup[id],
  getPopupDetails: (state, id) => state.popup[id]
};
