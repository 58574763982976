import { actionType } from '../constants';
import {
  getAddingPin,
  getCommentSectionId,
  getCurrentProject,
  getCurrentUser,
  getCurrentUserId,
  getPin,
  getVideoPlayer,
  getVideoPlayerCurrentTime
} from '../selectors';
import { selectors as hotSpotSelectors } from 'reducers/hotspot';
import { MAIN_AREA_TYPE } from '../utils/gotoLessonHelpers';
import { get, isEmpty } from 'lodash';
import { receiveEntity } from './receiveEntity';
import { uid } from 'utils/uid';
import { selectors as lessonSelectors } from 'reducers/lesson';
import { selectors as imageSelectors } from 'reducers/lessonImage';
import { selectors as myFileImageSelectors } from 'reducers/dashboard/myFileImage';

export const addTextComment = text => {
  return (dispatch, getState, { api }) => {
    let state = getState();
    let author = getCurrentUserId(state);
    let authorUser = getCurrentUser(state);
    let time = getVideoPlayerCurrentTime(state);
    let commentSectionId = getCommentSectionId(state);
    const { inPoint, outPoint } = getVideoPlayer(state);
    const selectedChunkIndex = get(state, 'screenplayView.selectedChunk.index');
    if (state.lesson.lessonSpec.mainAreaType === MAIN_AREA_TYPE.SCREENPLAY) {
      time = Date.now();
    }

    let optionalImageProps = {};
    if (
      lessonSelectors.getCurrentMainAreaType(state) === MAIN_AREA_TYPE.IMAGE
    ) {
      const [
        imageProjectId,
        maybeImageId
      ] = lessonSelectors.getCurrentMainAreaImageIds(state);
      const imageProject = imageSelectors.getImageProject(state);
      if (!isEmpty(imageProject)) {
        const imageId = maybeImageId || imageProject.images[0].uploadId;
        optionalImageProps = {
          imageProjectId,
          imageId,
          imageUrl: imageProject.images.find(i => i.uploadId === imageId)
            .downloadUrl
        };
        if (getAddingPin(state)) {
          optionalImageProps.pin = getPin(state);
        }
      }
    } else if (getCurrentProject(state)) {
      const imageProject = myFileImageSelectors.getImageProject(state);

      if (!isEmpty(imageProject)) {
        const imageId =
          imageProject.imageId || get(imageProject, 'images[0].uploadId');
        // console.log('imageProject', imageProject);

        optionalImageProps = {
          imageProjectId: imageProject.id,
          imageId,
          imageUrl: imageProject.images.find(i => i.uploadId === imageId)
            .downloadUrl
        };
        if (getAddingPin(state)) {
          optionalImageProps.pin = getPin(state);
        }
      }
    }

    const hotPoints = hotSpotSelectors.getHotPoints(state) || {};

    // for closing the editor
    // and optional optimistic insertion
    // we dispatch right away

    dispatch({
      type: actionType.addTextComment,
      author,
      time,
      text
    });

    let commentId = uid();

    console.log(
      `Adding comment commentSectionId: ${commentSectionId}, commentId: ${commentId}, chunkIndex: ${selectedChunkIndex}, inPoint: ${inPoint}, outPoint: ${outPoint} time: ${time}`
    );

    let newComment = {
      id: commentId,
      author,
      msg: text,
      duration: !isNaN(inPoint) && !isNaN(outPoint) && outPoint - inPoint,
      chunkIndex: selectedChunkIndex,
      authorUser,
      created: Date.now(),
      ...optionalImageProps,
      hotPoints
    };
    if (time) {
      newComment.time = (!isNaN(inPoint) && inPoint) || time;
    }

    if (!isNaN(inPoint) && !isNaN(outPoint)) {
      api.addTextComment(
        commentSectionId,
        commentId,
        author,
        inPoint,
        text,
        outPoint - inPoint,
        selectedChunkIndex
      );
    } else {
      api.addTextComment(
        commentSectionId,
        commentId,
        author,
        time,
        text,
        undefined,
        selectedChunkIndex,
        optionalImageProps.imageProjectId,
        optionalImageProps.imageId,
        optionalImageProps.pin,
        hotPoints
      );
    }
    dispatch({ type: actionType.resetPoints });
    dispatch(receiveEntity('comments')(commentId, newComment));
  };
};
