import React, { useRef } from 'react';
import { BrowseButton as ReactToolboxBrowseButton } from 'react-toolbox/lib/button';
import { themr } from 'react-css-themr';
import { BUTTON } from 'react-toolbox/lib/identifiers';
import { toArray } from 'lodash';

const lastUploadIsOldEnough = timeStamp => Date.now() - timeStamp > 5000;
const isDifferentFile = (a, b) =>
  a.name !== b.name || a.lastModified !== b.lastModified;

export const SmashcutBrowseButton = props => {
  const selectedFile = useRef({});
  const timeStamp = useRef(0);

  const handleChange = event => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const files = toArray(event.target.files);

      // reset the chosen file
      // otherwise you cannot select the same file again
      event.target.value = '';

      if (event.target.multiple) {
        props.onChange(files);
        return;
      }

      // for some reason we are getting the change event
      // twice for a single selection
      if (
        lastUploadIsOldEnough(timeStamp.current) ||
        isDifferentFile(file, selectedFile.current)
      ) {
        selectedFile.current = file;
        timeStamp.current = Date.now();
        props.onChange(file);
      }
    }
  };

  return (
    <ReactToolboxBrowseButton {...props} onChange={handleChange}>
      <div className={props.theme.underline} />
    </ReactToolboxBrowseButton>
  );
};

export default themr(BUTTON)(SmashcutBrowseButton);
