import { loadAppSettingsDataComplete } from './loadAppSettingsDataComplete';

export const loadAppSettingsData = () => {
  return (dispatch, getState, { api }) => {
    api.loadAppSettings().then(data => {
      dispatch(loadAppSettingsDataComplete(data));
      return Promise.resolve(data);
    });
  };
};
