import { gql } from '@apollo/client';

export const updateEmail = apolloClient => async (userId, email) => {
  const UPDATE_EMAIL = gql`
    mutation updateEmail($input: UpdateEmailInput!) {
      updateEmail(input: $input) {
        id
        email
      }
    }
  `;
  return await apolloClient.mutate({
    mutation: UPDATE_EMAIL,
    variables: {
      input: {
        userId,
        newEmail: email
      }
    }
  });
};
