import { actionType } from '../constants';

const initialState = { id: 'none' };

const inlineEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.startEditingComment:
      return {
        ...state,
        id: action.id,
        parentId: ''
      };

    case actionType.startEditingReply:
      return {
        ...state,
        id: action.id,
        parentId: action.parentId
      };

    case actionType.cancelEditingComment:
    case actionType.cancelEditingReply:
    case actionType.startReplying:
    case actionType.submitEditedComment:
    case actionType.submitEditedReply:
      return {
        ...state,
        id: 'none',
        parentId: 'none'
      };

    default:
      return state;
  }
};

export default inlineEditorReducer;
