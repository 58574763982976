import { actionType } from '../constants/actionTypes';
import { logAndSendError } from 'utils/sentryHelper';

export const setUserIsFreeAccount = (userId, isFreeAccount) => {
  return (dispatch, getState, { api }) => {
    dispatch({ type: actionType.setUserIsFreeAccount });
    api.user
      .updateUser(userId, {
        isFreeAccount
      })
      .then(() => dispatch({ type: actionType.setUserIsFreeAccountSuccess }))
      .catch(e => logAndSendError(e, 'error setting is free account for user'));
  };
};
