import { gql } from '@apollo/client';

function str2ab(str) {
  if (!str || str.length == 0) {
    return new Uint8Array(0);
  }
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  let i = 0;
  const strLen = str.length;
  for (; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return bufView;
}

export const loadAnnotation = (apolloClient, compressionService) => (
  commentSectionId,
  commentId
) => {
  const query = gql`
    query m($input: LoadAnnotationInput!) {
      annotation(input: $input) {
        data
        height
        width
      }
    }
  `;
  return apolloClient
    .query({
      query,
      variables: {
        input: {
          commentSectionId,
          commentId
        }
      }
    })
    .then(response => {
      let annotation = response.data.annotation;
      if (annotation) {
        let decodedImage = annotation.data;
        let compressedJson = str2ab(decodedImage);
        let jsonString = compressionService.deCompressToString(compressedJson);
        let json = JSON.parse(jsonString);
        return {
          width: annotation.width,
          height: annotation.height,
          data: json
        };
      } else {
        return { data: new Uint8Array(0) };
      }
    });
};
