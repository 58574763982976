import pako from 'pako';

const compressionService = () => ({
  compress: input => {
    return pako.deflate(input);
  },
  deCompressToByteArray: input => {
    return pako.inflate(input);
  },
  deCompressToString: input => {
    return pako.inflate(input, { to: 'string' });
  }
});

export default compressionService;
