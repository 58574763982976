// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VideoMobileControl--mobileControl--5q386MoH{position:absolute;z-index:101;width:100%;height:100%;background:rgba(0, 0, 0, .7);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.VideoMobileControl--mobileControl--5q386MoH.VideoMobileControl--hide--21Ck3P2_{background:transparent}.VideoMobileControl--mobileControl--5q386MoH .VideoMobileControl--closeButton--2z-eF3JK{padding:0 !important;background:transparent !important;position:absolute;top:25px;right:25px}.VideoMobileControl--mobileControl--5q386MoH .VideoMobileControl--playContols--Od7LDuio{display:-webkit-box;display:-ms-flexbox;display:flex}.VideoMobileControl--mobileControl--5q386MoH .VideoMobileControl--playContols--Od7LDuio span{font-size:30px;color:#ffffff;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin:0 18px}.VideoMobileControl--mobileControl--5q386MoH .VideoMobileControl--playContols--Od7LDuio span>i{display:block;margin-bottom:5px}.VideoMobileControl--mobileControl--5q386MoH .VideoMobileControl--playContols--Od7LDuio span .VideoMobileControl--label--3JDw8ZSi{font-size:15px}.VideoMobileControl--mobileControl--5q386MoH .VideoMobileControl--time--3AYDCHB5{color:#ffffff;position:absolute;bottom:8px;left:15px}.VideoMobileControl--mobileControl--5q386MoH .VideoMobileControl--fullScreen--2qx_vKOQ{color:#ffffff;position:absolute;bottom:8px;right:15px}.VideoMobileControl--mobileControl--5q386MoH .VideoMobileControl--volume--ATRiFDHn{color:#ffffff;position:absolute;bottom:8px;right:50px}.VideoMobileControl--mobileControl--5q386MoH .VideoMobileControl--volume--ATRiFDHn.VideoMobileControl--disabled--2PpL6VRY>i{pointer-events:none;color:rgba(255, 255, 255, .4)}\n", ""]);
// Exports
exports.locals = {
	"mobileControl": "VideoMobileControl--mobileControl--5q386MoH",
	"hide": "VideoMobileControl--hide--21Ck3P2_",
	"closeButton": "VideoMobileControl--closeButton--2z-eF3JK",
	"playContols": "VideoMobileControl--playContols--Od7LDuio",
	"label": "VideoMobileControl--label--3JDw8ZSi",
	"time": "VideoMobileControl--time--3AYDCHB5",
	"fullScreen": "VideoMobileControl--fullScreen--2qx_vKOQ",
	"volume": "VideoMobileControl--volume--ATRiFDHn",
	"disabled": "VideoMobileControl--disabled--2PpL6VRY"
};
module.exports = exports;
