import { cancelRecordVideo } from './cancelRecordVideo';
import { startRecordingVideo } from './startRecordingVideo';

export const clickRecordVideo = (typeOfComment, parentId) => {
  return (dispatch, getState) => {
    if (getState().recordVideoPopup.open) {
      dispatch(cancelRecordVideo());
    } else {
      dispatch(startRecordingVideo(typeOfComment, parentId));
    }
  };
};
