import { isEqual } from 'lodash';

const PREFIX = 'APP_VERSION/';

export const types = {
  APP_VERSION_UPDATE: PREFIX + 'APP_VERSION_UPDATE'
};

const initialState = {};

export const appVersionReducer = (state = initialState, action) => {
  let nextState = state;

  switch (action.type) {
    case types.APP_VERSION_UPDATE:
      nextState = {
        ...state,
        oldAppVersion: state.currentAppVersion,
        currentAppVersion: action.appVersion
      };
      break;
  }
  return nextState;
};

export const actions = {
  updateAppVersion: appVersion => ({
    type: types.APP_VERSION_UPDATE,
    appVersion
  })
};

export const selectors = {
  hasAppVersionChanged: state =>
    state.appVersion.oldAppVersion &&
    !isEqual(state.appVersion.oldAppVersion, state.appVersion.currentAppVersion)
};
