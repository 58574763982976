export function getProjectForAssignment(state, assignmentId) {
  let result =
    getLastProject(state.entities.projects, sortBy('date')) ||
    getLastProject(state.entities.screenplays, sortBy('created')) ||
    getLastProject(
      state.entities.nonSupportedFileProjects,
      sortBy('created')
    ) ||
    getLastProject(state.entities.imageProjects, sortBy('created')) ||
    getLastProject(state.entities.object3DProjects, sortBy('created'));
  return result;

  // ------------------------------

  function getLastProject(projectMap, sortFn) {
    return Object.keys(projectMap || {})
      .map(id => projectMap[id])
      .filter(p => p.assignmentId === assignmentId)
      .sort(sortFn)
      .pop();
  }

  function sortBy(property) {
    return function(a, b) {
      if (a[property] < b[property]) return -1;
      if (a[property] > b[property]) return 1;
      return 0;
    };
  }
}
