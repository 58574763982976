export const secsToTimecode = (secs, frameRate = 0) => {
  let fullSecs = Math.floor(secs);
  let minutes = Math.floor(fullSecs / 60);
  let remainingSecs = fullSecs % 60;

  let twoPlaces = x => {
    return x < 10 ? '0' + x : '' + x;
  };

  var result = twoPlaces(minutes) + ':' + twoPlaces(remainingSecs);
  if (frameRate > 0) {
    let frame = Math.floor((secs % 1) * frameRate);
    result += ':' + twoPlaces(frame);
  }

  return result;
};
