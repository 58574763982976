export const simulateClickOnFocusedElement = e => {
  if (e.keyCode == 32 || e.keyCode == 13) {
    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    e.target.dispatchEvent(clickEvent);
  }
};
