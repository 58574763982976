import _, { findKey, findIndex, isEmpty } from 'lodash';

export const DASHBOARD_TAB_INDEX = [
  'ACTIVITIES',
  'PROJECTS_FOR_REVIEW',
  'SYLLABUS',
  'PRE_START',
  'NO_PROGRAM',
  'STUDENTS',
  'PROJECTS',
  'MEETING_ARCHIVES',
  'MY_FILES',
  'UPCOMING_LMS',
  'CREWS',
  'CONFERENCES',
  'SCHEDULE_MEETINGS',
  'CALENDAR',
  'NOTIFICATIONS',
  'COMMUNITY',
  'DISCUSSIONS'
];

export const DASHBOARD_TAB_PATH = {
  ACTIVITIES: 'activities',
  PROJECTS_FOR_REVIEW: 'projects-for-review',
  SYLLABUS: 'syllabus',
  PRE_START: 'pre-start',
  NO_PROGRAM: 'welcome',
  STUDENTS: 'students',
  PROJECTS: 'projects',
  MEETING_ARCHIVES: 'meeting-archives',
  MY_FILES: 'myfiles',
  UPCOMING_LMS: 'upcoming-video-meetings',
  CREWS: 'crews',
  CONFERENCES: 'conferences',
  SCHEDULE_MEETINGS: 'schedule-meetings',
  CALENDAR: 'calendar',
  NOTIFICATIONS: 'notifications',
  COMMUNITY: 'community',
  DISCUSSIONS: 'discussions'
};

const getIndexOrTabPath = (preferTab, hidden, tabIndex = 0) => {
  const preferableTab = preferTab.replace(/\_/g, '');
  const visible = _(hidden)
    .mapValues(val => !val)
    .pickBy()
    .keys()
    .value()
    .map(val => val.toUpperCase().replace('WELCOME', ''));

  if (visible.includes(preferableTab)) {
    return DASHBOARD_TAB_INDEX.indexOf(preferTab);
  }

  if (isEmpty(visible)) {
    return '';
  }

  const firstVisibleTabIndex = findIndex(
    DASHBOARD_TAB_INDEX.map(tabIndex => tabIndex.replace(/\_/g, '')),
    key => key === visible[tabIndex]
  );

  if (firstVisibleTabIndex === -1) {
    return getIndexOrTabPath(preferTab, hidden, tabIndex + 1);
  }

  const firstVisisbleFoundTabPath =
    DASHBOARD_TAB_PATH[DASHBOARD_TAB_INDEX[firstVisibleTabIndex]];

  return firstVisisbleFoundTabPath;
};

export const getLocationForIndex = (
  index,
  shouldShowNoProgram,
  shouldShowPreProgram,
  isMentor
) =>
  '/dashboard/' + DASHBOARD_TAB_PATH[DASHBOARD_TAB_INDEX[index]] ||
  (() => {
    if (shouldShowNoProgram) {
      return DASHBOARD_TAB_PATH.NO_PROGRAM;
    }
    if (isMentor) {
      return DASHBOARD_TAB_PATH.PROJECTS_FOR_REVIEW;
    }
    if (shouldShowPreProgram) {
      return DASHBOARD_TAB_PATH.PRE_START;
    }
    return DASHBOARD_TAB_PATH.SYLLABUS;
  })();

export const getIndexForLocation = (
  location,
  shouldShowNoProgram,
  shouldShowPreProgram,
  isMentor,
  hidden
) => {
  const index = DASHBOARD_TAB_INDEX.indexOf(
    findKey(DASHBOARD_TAB_PATH, key => key === location)
  );

  return index > 0
    ? index
    : (() => {
        if (shouldShowNoProgram) {
          return getIndexOrTabPath('NO_PROGRAM', hidden);
        }
        if (isMentor) {
          return getIndexOrTabPath('PROJECTS_FOR_REVIEW', hidden);
        }
        if (shouldShowPreProgram) {
          return getIndexOrTabPath('PRE_START', hidden);
        }
        return getIndexOrTabPath('SYLLABUS', hidden);
      })();
};
