import { actionType } from '../constants';
import {
  getCommentSection,
  getCurrentUser,
  getCurrentUserId
} from '../selectors';
import { receiveEntity } from './receiveEntity';
import { uid } from 'utils/uid';

export const addTextReply = (parentId, msg) => {
  return (dispatch, getState, { api }) => {
    let state = getState();
    let author = getCurrentUserId(state);
    let commentSectionId = getCommentSection(state).id;
    let authorUser = getCurrentUser(state);

    dispatch({
      type: actionType.addTextReply,
      author,
      parentId,
      msg
    });

    let replyId = uid();
    api.addTextReply(commentSectionId, parentId, replyId, author, msg);

    let newReply = {
      id: replyId,
      parentId,
      author,
      msg,
      authorUser,
      created: Date.now()
    };

    dispatch(receiveEntity('replies')(replyId, newReply));
  };
};
