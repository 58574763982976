import * as userProgramHelper from 'smashcut-client-lib/utils/userProgramHelper';
import { gql } from '@apollo/client';
import moment from 'moment-timezone';
import { actions as lessonActions } from 'reducers/lesson';
import { actions as relatedVideoActions } from 'reducers/relatedVideoPlayer';
import { logAndSendError } from 'utils/sentryHelper';
import { makeDate } from '../../smashcut-client-lib/utils/makeDate';
import { types as lessonProjectTypes } from 'reducers/lessonProjectTypes';
import {
  getCurrentUser,
  getCurrentUserProgram,
  getCurrentUserPrograms
} from 'smashcut-client-lib/selectors';

const PREFIX = 'DASHBOARD_SYLLABUS';

export const types = {
  SELECT_PROGRAM: `${PREFIX}/SELECT_PROGRAM`
};

const initialState = {};

export const selectProgramReducer = (state = initialState, action) => {
  let nextState = state;

  return nextState;
};

function selectProgramId(programOption) {
  return (dispatch, getState, { api }) => {
    const state = getState();
    const user = getCurrentUser(state);
    api.user.setCurrentUserProgramId(user.id, programOption.value);
    dispatch({ type: types.SELECT_PROGRAM });
  };
}

function selectUserProgramFilterId(programOption) {
  return (dispatch, getState, { api }) => {
    const state = getState();
    const user = getCurrentUser(state);
    api.user.updateSettings(user.id, {
      ...user.settings,
      selectedUserProgramFilterId: programOption.value,
      selectedUserProgramInstanceId: programOption.programInstanceId
    });
  };
}

export const actions = {
  selectUserProgramFilterId,
  selectProgramId
};

// ------------------------------------------------

const defaultValueArr = [];
const defaultValueObj = {};

function getSelectableUserPrograms(state) {
  const userPrograms = getCurrentUserPrograms(state);
  // console.log('getSelectableUserPrograms', userPrograms);
  if (!userPrograms || !userPrograms.length) {
    return {
      allPrograms: [],
      currentPrograms: [],
      futurePrograms: [],
      pastPrograms: []
    };
  }

  const programInstanceIds = {};
  const sortedItems = userPrograms
    .filter(up => {
      if (programInstanceIds[up.programInstance.id]) {
        return false;
      }
      programInstanceIds[up.programInstance.id] = true;
      return true;
    })
    .map(p => ({ p, ...getStartAndEndDate(p) }))
    .filter(item => !!item.startDate)
    .sort(sortByDatesAscending);

  const today = new Date();
  const currentPrograms = sortedItems
    .filter(item => item.startDate <= today && item.endDate >= today)
    .map(item => item.p);
  const futurePrograms = sortedItems
    .filter(item => item.startDate > today)
    .map(item => item.p);
  const pastPrograms = sortedItems
    .filter(item => item.endDate < today)
    .map(item => item.p);

  return {
    allPrograms: sortedItems.map(item => item.p),
    currentPrograms,
    futurePrograms,
    pastPrograms
  };

  // --------------------------------------------

  function getStartAndEndDate(userProgram) {
    if (!userProgram) {
      console.warn('syllabus: userProgram is null');
      return null;
    }
    const programInstance = userProgram.programInstance;
    const program = programInstance.program;
    const pace = program.paces[programInstance.pace];
    if (!pace) {
      console.warn('syllabus: Pace not found', programInstance);
      return null;
    }
    const startDate = makeDate(programInstance.startDate, 0);
    if (!startDate) {
      console.warn(
        'syllabus: Error parsing startDate from program instance',
        programInstance
      );
      return null;
    }

    const endDate = makeDate(startDate, pace.weeks.length * 7);
    return { startDate, endDate };
  }

  function sortByDatesAscending(a, b) {
    if (a.startDate < b.startDate) {
      return -1;
    }
    if (a.startDate > b.startDate) {
      return 1;
    }
    if (a.endDate < b.endDate) {
      return -1;
    }
    if (a.endDate > b.endDate) {
      return 1;
    }
    return 0;
  }
}

function getSelectedUserProgramFilterId(state) {
  const user = getCurrentUser(state);
  return user && user.settings && user.settings.selectedUserProgramFilterId;
}

function getCurrentOrSelectedUserProgram(state) {
  const currentUserProgram = getCurrentUserProgram(state);
  const userProgramId = getSelectedUserProgramFilterId(state);
  const id = userProgramId || currentUserProgram.id;

  return id === 'all'
    ? {
        programInstanceId: 'all',
        userPrograms: state.entities.userPrograms
      }
    : state.entities.userPrograms[id];
}

export const selectors = {
  getSelectableUserPrograms,
  getSelectedUserProgramFilterId,
  getCurrentOrSelectedUserProgram
};
