import { actionType } from '../constants';
import {
  findCommentIdByTime,
  getNextCommentId,
  getPreviousCommentId
} from '../selectors';

const currentCommentIdReducer = (state, action, fullState) => {
  let nextState = state;

  switch (action.type) {
    case actionType.gotoComment:
      nextState = action.id;
      break;

    case actionType.gotoNextComment:
      nextState = getNextCommentId(fullState);
      break;

    case actionType.gotoPreviousComment:
      nextState = getPreviousCommentId(fullState);
      break;

    case actionType.updateCurrentTime:
      nextState = findCommentIdByTime(fullState, action.value);
      break;

    case actionType.seekVideoPosition:
      nextState = findCommentIdByTime(fullState, action.position);
      break;
  }

  return nextState;
};

export default currentCommentIdReducer;
