import { actionType } from '../constants';
import { types as activitiesActionType } from 'reducers/dashboard/activities';
import { types as lessonActionType } from 'reducers/lesson';
import { types as officeHourTypes } from 'reducers/officeHoursReducerTypes';
import { types as videoConferenceTypes } from 'reducers/videoConference';
import { LOGOUT } from './authReducer';

const initialState = {
  loaded: false,
  id: null
};

const commentSectionReducer = (state = initialState, action) => {
  let newState = state;
  let entity, nextId;

  switch (action.type) {
    case actionType.UNLOAD_COMMENT_SECTION:
      // When no other action changes nextId,
      // this will result in showing the same comments again,
      // the next time comments are shown.
      // When another action changes nextId,
      // this will result in showing a spinner until the new
      // comments are loaded.
      newState = {
        ...state,
        loaded: false,
        id: state.id,
        nextId: state.id
      };
      break;
    case actionType.COMMENT_SECTION_LOADED:
      newState = {
        ...state,
        nextId: undefined,
        loaded: true
      };
      if (state.nextId) {
        newState.id = state.nextId;
      }
      break;

    case lessonActionType.LOAD_LESSON_SUCCESS:
      entity =
        action.data.video ||
        action.data.screenplay ||
        action.data.image ||
        action.data.object3DFile ||
        action.data.nonSupportedFile;
      nextId = entity && (entity.commentSectionId || entity.id);

      if (nextId) {
        newState = {
          ...state,
          id: undefined,
          nextId,
          loaded: false
        };
      }
      break;

    case activitiesActionType.START_PROJECT_REVIEW:
      entity = action.item.project;
      newState = {
        ...state,
        nextId: entity.id,
        loaded: false
      };
      break;

    case videoConferenceTypes.INITIALIZE_CONFERENCE_SUCCESS:
      newState = {
        ...state,
        nextId: undefined,
        loaded: false
      };
      break;

    case officeHourTypes.SELECT_PROJECT:
    case lessonActionType.SELECT_PROJECT:
    case videoConferenceTypes.SELECT_MEDIA:
      entity = action.project || action.media;
      // console.log('selecting project', entity);

      if (entity) {
        newState = {
          ...state,
          nextId: entity.commentSectionId || entity.id,
          loaded: false
        };
      } else {
        newState = {
          ...state,
          nextId: undefined,
          loaded: false
        };
      }
      break;

    case lessonActionType.LAUNCH_LESSON_VIDEO:
      newState = {
        ...state,
        nextId: action.video.commentSectionId,
        loaded: false
      };
      break;

    case actionType.selectProject:
      newState = {
        ...state,
        nextId: action.id, // old (legacy) project
        loaded: false
      };
      break;

    case LOGOUT:
      newState = { ...initialState };
      break;
  }

  return newState;
};

export default commentSectionReducer;
