import { gql } from '@apollo/client';

export const addReview = apolloClient => (
  project,
  lessonRecord,
  feedback,
  author,
  reviewOrReject
) => {
  const mutation = gql`
    mutation addReview($input: AddReviewInput!) {
      addReview(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        lessonRecordId: lessonRecord.id,
        userProgramId: lessonRecord.userProgramId,
        feedback,
        authorId: author.id,
        reviewOrReject
      }
    }
  });
};
