import { get } from 'lodash';
import { actions as lessonActions } from 'reducers/lesson';
import { selectors as lessonSelectors } from 'reducers/lesson';
import { gql } from '@apollo/client';
import { MAIN_AREA_TYPE } from '../smashcut-client-lib/utils/gotoLessonHelpers';

const BASE = 'LESSON_IMAGE';

export const types = {
  SET_IMAGE_PROJECT: `${BASE}/SET_IMAGE_PROJECT`
};

export const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_IMAGE_PROJECT:
      return {
        ...state,
        imageProject: action.imageProject
      };

    default:
      return state;
  }
};

function showNextImage() {
  console.log('showNextImage');
  return showImageByOffset(1);
}

function showPreviousImage() {
  console.log('showPreviousImage');
  return showImageByOffset(-1);
}

function showImageByOffset(offset) {
  return (dispatch, getState) => {
    const state = getState();

    const [
      imageProjectId,
      imageId
    ] = lessonSelectors.getCurrentMainAreaImageIds(state);
    const imageIndex = Math.max(
      selectors.getImageIds(state).indexOf(imageId),
      0
    );
    const newImageIndex =
      offset > 0
        ? imageIndex
          ? imageIndex + offset
          : 1
        : imageIndex
        ? imageIndex + offset
        : 0;
    const newImageId = selectors.getImageIds(state)[newImageIndex];

    const newPayload = {
      ...lessonSelectors.getLessonSpec(state),
      mainAreaType: MAIN_AREA_TYPE.IMAGE,
      mainAreaId: `${imageProjectId}!${newImageId}`
    };

    return dispatch(lessonActions.gotoLesson(newPayload));
  };
}

function showImage(image) {
  return (dispatch, getState) => {
    const state = getState();
    const imageProject = selectors.getImageProject(state);
    const newPayload = {
      ...lessonSelectors.getLessonSpec(state),
      mainAreaType: MAIN_AREA_TYPE.IMAGE,
      mainAreaId: `${imageProject.id}!${image.uploadId}`
    };
    return dispatch(lessonActions.gotoLesson(newPayload));
  };
}

function moveImageDown(image) {
  return (dispatch, getState, { apolloClient }) => {
    const imageProject = selectors.getImageProject(getState());
    return apolloClient
      .mutate({
        mutation: gql`
          mutation moveImageDown($imageProjectId: ID, $imageId: ID) {
            moveImageDown(imageProjectId: $imageProjectId, imageId: $imageId) {
              id
              images {
                uploadId
                downloadUrl
              }
            }
          }
        `,
        variables: {
          imageProjectId: imageProject.id,
          imageId: image.uploadId
        }
      })
      .then(response => {
        console.log('moveImageDown', response);
      });
  };
}

function moveImageUp(image) {
  return (dispatch, getState, { apolloClient }) => {
    const imageProject = selectors.getImageProject(getState());
    return apolloClient
      .mutate({
        mutation: gql`
          mutation moveImageUp($imageProjectId: ID, $imageId: ID) {
            moveImageUp(imageProjectId: $imageProjectId, imageId: $imageId) {
              id
              images {
                uploadId
                downloadUrl
              }
            }
          }
        `,
        variables: {
          imageProjectId: imageProject.id,
          imageId: image.uploadId
        }
      })
      .then(response => {
        console.log('moveImageUp', response);
      });
  };
}

export const actions = {
  moveImageDown,
  moveImageUp,
  showPreviousImage,
  showNextImage,
  showImage,
  setImageProject: imageProject => ({
    type: types.SET_IMAGE_PROJECT,
    imageProject
  })
};

export const selectors = {
  getImageProject: state => get(state, 'lessonImage.imageProject'),
  getImageIds: state =>
    get(state, 'lessonImage.imageProject.images', []).map(i => i.uploadId)
};
