import Logo from './header_logo.png';
import styles from './HeaderLogo.scss';
import React from 'react';

export const HeaderLogo = ({ testLogo }) => (
  <img
    className={styles.logo}
    src={testLogo || Logo}
    alt="School of Rock Logo"
  />
);
