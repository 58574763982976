import { actionType } from '../constants';

export const SORT_TYPE = {
  TIME: 'TIME',
  NEWEST: 'NEWEST',
  OLDEST: 'OLDEST'
};

const initialState = {
  search: '',
  sort: SORT_TYPE.TIME
};

const commentFilterReducer = (state = initialState, action) => {
  let newState = state;

  switch (action.type) {
    case actionType.SEARCH_COMMENTS:
      newState = {
        ...state,
        search: action.input
      };
      break;
  }

  return newState;
};

export default commentFilterReducer;
