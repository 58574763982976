import React from 'react';
import styles from './SmashcutConfirmDialog.scss';
import { MainButton } from 'components/common/button';
import FocusLock from 'react-focus-lock';
import { simulateClickOnFocusedElement } from 'utils/simulateClickOnFocusedElement';
import { IMAGE_UPLOAD_LIMIT_MBS, VIDEO_UPLOAD_LIMIT_GBS } from './UploadLimits';

export default ({ onOk }) => (
  <FocusLock returnFocus={true}>
    <div className={styles.topContainer}>
      <div className={styles.overlay} />
      <div className={styles.dialog}>
        <div className={styles.header}>
          <div className={styles.label} role="heading" aria-level="2">
            File size over limit
          </div>
          <div
            className={styles.icon}
            onClick={onOk}
            aria-label="Close"
            role="button"
            tabIndex="0"
            onKeyUp={simulateClickOnFocusedElement}
          >
            <i className="fas fa-times" />
          </div>
        </div>
        <div className={styles.body}>
          <div>
            <h2>File size exceeds limit</h2>
            <p>
              Video files must be smaller than {VIDEO_UPLOAD_LIMIT_GBS} GB. We
              recommend compressing your video before uploading.
              <br />
              Need help? Check out this{' '}
              <a
                href="https://intercom.help/smashcut/en/articles/3359441-video-compression-guide"
                target="_blank"
                aria-label="step-by-step tutorial (New Window)"
                role="link"
              >
                step-by-step tutorial on video compression.
              </a>
            </p>
            <p>
              Image files must be smaller than {IMAGE_UPLOAD_LIMIT_MBS} MB. Need
              help? <br />
              Read this{' '}
              <a
                href="https://intercom.help/smashcut/en/articles/3561153-resizing-image-files"
                target="_blank"
                aria-label="step-by-step tutorial (New Window)"
                role="link"
              >
                quick guide for resizing images.
              </a>
            </p>
          </div>
        </div>
        <div className={styles.buttons}>
          <MainButton label="Ok" onClick={onOk} aria-label="Ok" />
        </div>
      </div>
    </div>
  </FocusLock>
);
