import { gql } from '@apollo/client';

const UPDATE_MY_FILE = gql`
  mutation updateMyFileDetails($updates: AddMyFileInput!) {
    updateMyFileDetails(updates: $updates)
  }
`;

export function updateMyFileDetails(apolloClient) {
  return updates => {
    return apolloClient
      .mutate({
        mutation: UPDATE_MY_FILE,
        variables: {
          updates
        }
      })
      .then(response => response.data.updateMyFileDetails);
  };
}
