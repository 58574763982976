import { gql } from '@apollo/client';
import { apolloClient } from 'apolloClient';

export const getUserProfile = userId => {
  return dispatch => {
    dispatch(getUserProfileStart());
    const VIEW_USER = gql`
      query search($userId: String!) {
        user(id: $userId) {
          fullName
          id
          avatar
          email
          isAdmin
          isMentor
          isPrivate
          location
          roleLabel
          shortBio
          social {
            facebook
            instagram
            twitter
            vimeo
            website
            youtube
          }
          myFiles {
            id
            commentCount
            fileType
            name
            status
            downloadUrl
          }
          screenplayAssignmentProjects {
            id
            assignmentId
            commentCount
            name
            responseType
            status
          }
          videoAssignmentProjects {
            id
            assignmentId
            commentCount
            name
            responseType
            status
            courseId
            assignmentId
            lessonId
            userProgramId
          }
          enrollments {
            paymentPlanCode
            program {
              id
              title
            }
            application {
              status
              paymentResult
            }
            applicantDetails {
              audio
              camera
              other
              software
              whyFilmmaker
              workUrls
              howDidYouHearAbout
            }
            mentors {
              discipline
              enrollee {
                id
                user {
                  id
                  fullName
                }
              }
            }
            students {
              user {
                id
                fullName
              }
            }
          }
          introVideo {
            id
            created
            downloadUrl
            fileMimeType
            fileName
            fileSize
            fileType
            finished
            isVisible
            ownerId
            started
            status
            uploadDurationMs
            uploadId
            uploadPath
            uploadType
          }
        }
        allAvailability(userId: $userId) {
          isDefault
        }
      }
    `;

    return apolloClient
      .query({
        query: VIEW_USER,
        fetchPolicy: 'network-only',
        variables: { userId: userId }
      })
      .then(result => {
        dispatch(getUserProfileFinished(result));
      });
  };
};

const BASE = 'USER_PROFILE';

export const types = {
  GET_USER_PROFILE_REQUEST: `${BASE}/GET_USER_PROFILE_REQUEST`,
  GET_USER_PROFILE_SUCCESS: `${BASE}/GET_USER_PROFILE_SUCCESS`,
  GET_USER_PROFILE_FAILURE: `${BASE}/GET_USER_PROFILE_FAILURE`
};

export const initialState = {};

export const viewUserProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        viewUserProfileLookup: true,
        viewUserProfileSuccess: undefined
      };

    case types.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        viewUserProfileLookup: false,
        viewUserProfileSuccess: true,
        viewUserProfileData: action.data
      };

    case types.GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        viewUserProfileLookup: false,
        viewUserProfileSuccess: false
      };

    default:
      return state;
  }
};

export const getUserProfileStart = () => ({
  type: types.GET_USER_PROFILE_REQUEST
});

export const getUserProfileFinished = data => ({
  type: types.GET_USER_PROFILE_SUCCESS,
  data: data
});
export const actions = {
  getUserProfile
};
