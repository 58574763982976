import { isMobile } from 'smashcut-client-lib/utils/isMobile';
import { isTest } from 'common/isTest';

const COLOR_CODE = {
  WARN: 2,
  MESSAGE: 3
};

const NOTIFICATION_TYPE = {
  GENERAL_ERROR: 'generalError',
  MOBILE_WARNING: 'mobileWarning',
  PROJECT_REMOVED: 'projectRemoved'
};

const PREFIX = 'NOTIFICATIONS';

export const types = {
  SHOW_NOTIFICATIONS_MESSAGE: `${PREFIX}/SHOW_NOTIFICATIONS_MESSAGE`,
  HIDE_NOTIFICATIONS_MESSAGE: `${PREFIX}/HIDE_NOTIFICATIONS_MESSAGE`
};

const initialState = {
  body: null,
  colorCode: 0,
  title: null,
  type: NOTIFICATION_TYPE.GENERAL_ERROR, // this is needed to initially hide the view
  visible: false
};

export const notificationsReducer = (state = initialState, action) => {
  let nextState = state;
  let info;

  switch (action.type) {
    case types.SHOW_NOTIFICATIONS_MESSAGE:
      info = getNotificationInfo(action.info);
      nextState = {
        ...info,
        visible: true
      };
      break;

    case types.HIDE_NOTIFICATIONS_MESSAGE:
      nextState = {
        ...state,
        visible: false
      };
      break;
  }

  return nextState;
};

const hideNotificationsMessage = () => ({
  type: types.HIDE_NOTIFICATIONS_MESSAGE
});

const showNotificationsMessage = info => ({
  type: types.SHOW_NOTIFICATIONS_MESSAGE,
  info
});

const showMobileWarning = () => {
  return dispatch => {
    if (isMobile() || isTest('mobileWarning')) {
      return dispatch(
        showNotificationsMessage({ type: NOTIFICATION_TYPE.MOBILE_WARNING })
      );
    }
  };
};

const showProjectRemovedMessage = () => {
  return dispatch => {
    return dispatch(
      showNotificationsMessage({ type: NOTIFICATION_TYPE.PROJECT_REMOVED })
    );
  };
};

export const actions = {
  hideNotificationsMessage,
  showMobileWarning,
  showNotificationsMessage,
  showProjectRemovedMessage
};

function getNotificationInfo(info) {
  let content;

  switch (info.type) {
    case NOTIFICATION_TYPE.GENERAL_ERROR:
      content = {
        type: info.type,
        title: 'Sorry - We found a bug !',
        body: `Our developers have been alerted with detailed a bug report about the specific problem you encountered, and will get right on it.
        Please chat with our support team if you have any questions or concerns.`,
        colorCode: COLOR_CODE.WARN,
        autoCloseAfter: 10000
      };
      break;

    case NOTIFICATION_TYPE.MOBILE_WARNING:
      content = {
        type: info.type,
        title: 'Mobile devices not supported!',
        body: `For the best Smashcut experience we strongly recommend using the Chrome web browser on your desktop or laptop. 
          Please let us know if you have questions by emailing <a href="mailto:help@smashcut.com">help@smashcut.com</a>`,
        colorCode: COLOR_CODE.WARN,
        autoCloseAfter: 0
      };
      break;

    case NOTIFICATION_TYPE.PROJECT_REMOVED:
      content = {
        type: info.type,
        title: 'Your project is being removed.',
        body: '',
        colorCode: COLOR_CODE.MESSAGE,
        autoCloseAfter: 5000
      };
      break;

    default:
      content = info;
  }

  return content;
}

export function getNotificationsState(state) {
  return state.notifications;
}

function getNotificationMessageStatus(state) {
  const notificationsState = getNotificationsState(state);
  return notificationsState.visible;
}

export const selectors = {
  getNotificationsState,
  getNotificationMessageStatus
};
