import adminApi from './admin/api';
import lessonApi from './services/lesson';
import crewsApi from './services/crews';
import mainApi from 'smashcut-client-lib/api';
import myFilesApi from './services/myFiles';
import schedulingApi from 'services/schedule-service';
import { apolloClient } from './apolloClient';
import { officeHoursApi } from './services/officeHours';
import { auth0Lock } from './login/components/Auth0Lock';

export const allApis = config => ({
  adminApi: adminApi(config),
  api: mainApi(config),
  apolloClient,
  auth0Lock,
  crewsApi: crewsApi(config),
  lessonApi: lessonApi(config),
  documentLocation: document.location,
  myFilesApi: myFilesApi(config),
  officeHoursApi: officeHoursApi(config),
  schedulingApi: schedulingApi(config)
});
