export const ACTION_ID = {
  NONE: '',
  OPEN_UPLOAD_FORM: 'OPEN_UPLOAD_FORM',
  SHOW_SUB_ASSIGNMENT: 'SHOW_SUB_ASSIGNMENT',
  SCROLL_TO_ASSIGNMENT: 'SCROLL_TO_ASSIGNMENT'
};

export function openUploadFormLessonOnLoadAction(
  itemId,
  isSubAssignment = false
) {
  return {
    actionId: ACTION_ID.OPEN_UPLOAD_FORM,
    itemId,
    isSubAssignment
  };
}

export function showSubAssignmentLessonOnLoadAction(itemId) {
  return {
    actionId: ACTION_ID.SHOW_SUB_ASSIGNMENT,
    itemId
  };
}

export function scrollToAssignmentLessonOnLoadAction(itemId) {
  return {
    actionId: ACTION_ID.SCROLL_TO_ASSIGNMENT,
    itemId
  };
}
