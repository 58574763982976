// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CenteredLogo--centeredLogo--3xaHiEWM{position:absolute;top:0;left:0;right:0;bottom:0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.CenteredLogo--logoBackground--dovoQNKd{padding-top:4px;padding-left:5px;padding-right:5px}.CenteredLogo--logoImage--uht0T2uD{width:200px}\n", ""]);
// Exports
exports.locals = {
	"centeredLogo": "CenteredLogo--centeredLogo--3xaHiEWM",
	"logoBackground": "CenteredLogo--logoBackground--dovoQNKd",
	"logoImage": "CenteredLogo--logoImage--uht0T2uD"
};
module.exports = exports;
