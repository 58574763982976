const PREFIX = 'DASHBOARD_PROJECTS_FOR_REVIEW';

export const types = {
  START_PROJECT_REVIEW: `${PREFIX}/START_PROJECT_REVIEW`
};

const initialState = {
  reviewItem: null
};

export const projectsForReviewReducer = (state = initialState, action) => {
  let nextState = state;

  switch (action.type) {
    case types.START_PROJECT_REVIEW:
      nextState = {
        ...state,
        reviewItem: action.item
      };
      break;
  }
  return nextState;
};

function startProjectReview(item) {
  return { type: types.START_PROJECT_REVIEW, item };
  // see projectsForReviewSagas
}

export const actions = {
  startProjectReview
};
