import { SecondaryButton } from 'components/common/button';
import React from 'react';
import styles from './Header.scss';
import { simulateClickOnFocusedElement } from 'utils/simulateClickOnFocusedElement';

const focusFirstNodeAfterNav = () => {
  const dashboardTabs = document.querySelectorAll("[data-react-toolbox='tab']");
  const lastDashboardTab = dashboardTabs[dashboardTabs.length - 1];
  const allFocusable = document.querySelectorAll(
    "[tabindex='0'], button, input"
  );
  for (let i = 0; i < allFocusable.length; i++) {
    if (allFocusable[i] === lastDashboardTab) {
      allFocusable[i + 1] && allFocusable[i + 1].focus();
      break;
    }
  }
};

const SkipNavigationButton = () => (
  <div
    className={styles.skipNavButtonHolder}
    tabIndex="0"
    onClick={focusFirstNodeAfterNav}
    onKeyUp={simulateClickOnFocusedElement}
  >
    <SecondaryButton
      label="SKIP NAVIGATION"
      size="big"
      className={styles.skipNavButton}
      tabIndex="-1"
    />
  </div>
);

export default SkipNavigationButton;
