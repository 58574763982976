import { actionType } from '../constants/actionTypes';
import { logAndSendError } from 'utils/sentryHelper';

export const removeTrialUser = userProgram => {
  return (dispatch, getState, { adminApi }) => {
    dispatch({ type: actionType.removeTrialUser });
    adminApi
      .removeTrialUser(userProgram)
      .then(() => dispatch({ type: actionType.removeTrialUserSuccess }))
      .catch(e => logAndSendError(e, 'error removing user'));
  };
};
