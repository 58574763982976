import config from 'config';
import { resolveAssetIdToStoragePath } from './assetUtils';

export function resolveContentImage(url, maybeWidth) {
  if (!url) {
    return '';
  }
  if (url.indexOf('http') === 0) {
    return url;
  }

  const storagePath = resolveAssetIdToStoragePath(url);
  const host = config.smashcutApiUrl;
  const widthParam = maybeWidth ? `width=${maybeWidth}&` : '';

  return `${host}/imageService?${widthParam}source=${encodeURIComponent(
    storagePath
  )}`;
}
