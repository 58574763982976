import PropTypes from 'prop-types';
import React from 'react';
import styles from './MarkDownDisplay.scss';
import { markDownToDangerousHtml } from './markDownToDangerousHtml';

const MarkDownDisplay = ({ markDown, className }) => (
  <div
    className={`${styles.markDownDisplay} ${className}`}
    dangerouslySetInnerHTML={markDownToDangerousHtml(markDown)}
  />
);

MarkDownDisplay.propTypes = {
  markDown: PropTypes.string
};

export default MarkDownDisplay;
