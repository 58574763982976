// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dropdown--values--1ht0t0TK{background-color:#666666 !important}.dropdown--selected--kv4Ms7Pl{color:#ffffff !important;font-weight:bold}.dropdown--value--2yXv4hkw::after{border-top-color:#ccc !important}\n", ""]);
// Exports
exports.locals = {
	"values": "dropdown--values--1ht0t0TK",
	"selected": "dropdown--selected--kv4Ms7Pl",
	"value": "dropdown--value--2yXv4hkw"
};
module.exports = exports;
