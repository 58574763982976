import * as sclTypes from 'smashcut-client-lib/constants/actionType';

const prefix = 'LESSON_NOTES';

export const types = {
  SHOW_NOTE_DISPLAY: `${prefix}/SHOW_NOTE_DISPLAY`,
  HIDE_NOTE_DISPLAY: `${prefix}/HIDE_NOTE_DISPLAY`
};

export const initialState = {
  visible: false
};

export default (state = initialState, action) => {
  let nextState = state;

  switch (action.type) {
    case sclTypes.MARKER_CLICK:
      if (action.marker.markerType === 'note') {
        nextState = toggleDisplay(action.marker);
      } else {
        nextState = hideDisplay();
      }
      break;

    case types.SHOW_NOTE_DISPLAY:
      nextState = showDisplay(action.note);
      break;

    case sclTypes.LOCATION_CHANGE:
    case sclTypes.playerIsPlaying:
    case sclTypes.SEEK_START:
    case types.HIDE_NOTE_DISPLAY:
      nextState = hideDisplay();
      break;
  }

  return nextState;

  // ---------------------

  function isSameMarker(a, b) {
    return a && b && a.time === b.time;
  }

  function hideDisplay() {
    return {
      ...state,
      visible: false,
      note: undefined
    };
  }

  function showDisplay(note) {
    return {
      ...state,
      visible: true,
      note
    };
  }

  function toggleDisplay(note) {
    if (isSameMarker(state.note, note)) {
      return hideDisplay();
    } else {
      return showDisplay(note);
    }
  }
};

const showNoteDisplay = note => ({
  type: types.SHOW_NOTE_DISPLAY,
  note
});

const hideNoteDisplay = () => ({
  type: types.HIDE_NOTE_DISPLAY
});

export const actions = {
  showNoteDisplay,
  hideNoteDisplay
};

const getNoteDisplay = state => {
  return state.noteDisplay;
};

export const selectors = {
  getNoteDisplay
};
