import React from 'react';
import styles from './SmashcutPhoneInput.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactPhoneInput from 'react-phone-input-2';

const SmashcutPhoneInput = ({ error, className, onChange, ...rest }) => {
  return (
    <div className={className}>
      <ReactPhoneInput
        containerClass={classnames(
          'react-tel-input',
          styles.phoneInputContainer
        )}
        inputClass={classnames(styles.phoneInput, {
          [styles.phoneError]: error
        })}
        countryCodeEditable={true}
        defaultCountry={'us'}
        onChange={onChange}
        {...rest}
      />
      {error && <div className={styles.phoneErrorMessage}>{error}</div>}
    </div>
  );
};

SmashcutPhoneInput.propTypes = {
  error: PropTypes.string
};

export default SmashcutPhoneInput;
