import config from 'config';
import { gql } from '@apollo/client';

let intervalHandle = 0;
let lastActivity = 1;

const activityEvents = [
  'keydown',
  'mousedown',
  'mousemove',
  'scroll',
  'touchstart'
];

function updateLastActivity() {
  lastActivity = 0;
}

function addActivityListeners(document) {
  activityEvents.forEach(eventName => {
    document.addEventListener(eventName, updateLastActivity, true);
  });
}

function removeActivityListeners(document) {
  activityEvents.forEach(eventName => {
    document.removeEventListener(eventName, updateLastActivity, true);
  });
}

export const stopUpdatingOnlineStatus = document => () => {
  if (intervalHandle) {
    clearInterval(intervalHandle);
    intervalHandle = undefined;
  }
  removeActivityListeners(document);
};

export const startUpdatingOnlineStatus = (apolloClient, document) => userId => {
  if (intervalHandle) {
    clearInterval(intervalHandle);
  }
  updateLastTimeOnline();
  intervalHandle = setInterval(
    checkActivity,
    config.onlineStatusUpdateInterval
  );
  addActivityListeners(document);
  return intervalHandle;

  // ----------------------------------

  function checkActivity() {
    // when a listener set it to 0 during the last onlineStatusUpdateInterval
    if (!lastActivity) {
      updateLastTimeOnline();
    }
    lastActivity++;
  }

  function updateLastTimeOnline() {
    const mutation = gql`
      mutation updateUserLastTimeOnline(
        $input: UpdateUserLastTimeOnlineInput!
      ) {
        updateUserLastTimeOnline(input: $input) {
          id
          lastTimeOnline
        }
      }
    `;
    return apolloClient.mutate({
      mutation,
      variables: {
        input: {
          userId
        }
      }
    });
  }
};
