import { actions } from 'reducers/dashboard/createProject';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import React from 'react';
import SmashcutBrowseButton from 'components/common/SmashcutBrowseButton';
import SmashcutButton from 'components/common/SmashcutButton';
import styles from './CreateProjectForm.scss';
import { connect } from 'react-redux';
import { Input } from 'react-toolbox/lib/input';
import PropTypes from 'prop-types';
import { selectors as sclSelectors } from 'smashcut-client-lib';

export class CreateProjectForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projectDescription: props.description || '',
      projectName: props.name || '',
      projectNameError: '',
      selectedFile: null,
      selectedFileError: '',
      selectedFileName: '',
      projectStatus: '',
      uploadTask: null
    };
  }

  validateInputs() {
    let hasErrors = false;

    let newState = {
      projectNameError: '',
      selectedFileError: ''
    };

    if (!this.state.projectName) {
      newState.projectNameError = 'Video title is required';
      hasErrors = true;
    }

    if (!this.state.selectedFile) {
      newState.selectedFileError = 'File is required';
      hasErrors = true;
    }

    return !hasErrors;
  }

  create = () => {
    if (this.validateInputs()) {
      // console.log('Creating', this.state.projectName);
      this.props.createProject(
        this.state.projectName,
        this.state.projectDescription,
        this.state.selectedFile
      );
    }
  };

  cancel = () => {
    this.setState({
      projectDescription: '',
      projectName: '',
      projectNameError: '',
      selectedFile: null,
      selectedFileError: '',
      selectedFileName: '',
      uploadTask: null
    });

    if (this.state.uploadTask && this.state.uploadTask.state === 'running') {
      this.state.uploadTask.cancel();
    }

    this.props.onClose();
  };

  fileChosen = file => {
    let newState = {
      selectedFile: file,
      selectedFileName: file.name,
      selectedFileError: ''
    };
    if (!this.state.projectName) {
      newState.projectName = file.name;
      newState.projectNameError = '';
    }
    this.setState(newState);
  };

  handleChange = name => value => {
    this.setState({ [name]: value, [name + 'Error']: '' });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { createProjectData, project } = nextProps;

    if (createProjectData && createProjectData.uploadTask) {
      this.setState({
        uploadTask: createProjectData.uploadTask
      });
    }

    if (!createProjectData.uploading) {
      this.setState({
        uploadTask: null
      });
    }

    if (project && project.status.toLowerCase() === 'ready') {
      this.cancel();
    }

    if (project && project.status.toLowerCase() === 'error') {
      alert(
        'Upload Failed\n' +
          'This can happen if your network is unstable or you lose connection mid-upload. Please check your network and try your upload again. '
      );
      this.cancel();
    }
  }

  render() {
    const { project } = this.props;
    let { progress, uploading, creatingProject } = this.props.createProjectData;
    let {
      projectNameError,
      selectedFileError,
      projectDescription,
      projectName,
      selectedFileName
    } = this.state;

    let uploadingBlock = (
      <div className={styles.uploadingBlock}>
        <div className={styles.uploadStatus}>
          UPLOADING VIDEO{' '}
          <span>
            ({Math.floor(progress)}
            %)
          </span>
        </div>
        <br />
        <div className={styles.progressContainer}>
          <ProgressBar type="linear" mode="determinate" value={progress} />
        </div>
      </div>
    );

    let spinnerBlock = (
      <div className={styles.spinnerBlock}>
        <i className="fa fa-spinner" />
        <span>
          {(creatingProject || project) &&
            (creatingProject || project.status.toLowerCase() === 'creating'
              ? 'CREATING PROJECT'
              : 'ENCODING VIDEO')}
        </span>
      </div>
    );

    let projectNameBlock = (
      <div className={styles.videoTitleContainer}>
        <Input
          placeholder="Video title *"
          aria-label="Video title *"
          type="text"
          error={projectNameError}
          theme={styles}
          floating={false}
          value={projectName}
          required={true}
          onChange={this.handleChange('projectName')}
          disabled={uploading}
        />
      </div>
    );

    let projectDescriptionBlock = (
      <div className={styles.descriptionInput}>
        <Input
          className={styles.textareaContainer}
          placeholder="Project Description"
          aria-label="Project Description"
          type="text"
          multiline
          theme={styles}
          floating={true}
          value={projectDescription}
          onChange={this.handleChange('projectDescription')}
          disabled={uploading}
        />
      </div>
    );

    let selectedFileBlock = (
      <div className={styles.selectedFileBlock}>
        <SmashcutBrowseButton
          label="Choose File"
          primary
          className={styles.selectedFileButton}
          onChange={this.fileChosen}
          data-cy="fileSelectorButton"
        />
        {selectedFileName !== '' && (
          <Input
            type="text"
            aria-label="Selected File"
            error={selectedFileError}
            theme={styles}
            className={styles.selectedFileInput}
            floating={true}
            required={true}
            value={selectedFileName}
            disabled={true}
          />
        )}
      </div>
    );

    const uploadDisabled =
      !this.validateInputs() || selectedFileName === '' || uploading;

    let createCancelButtonsBlock = (
      <div className={styles.createCancelButtonsBlock}>
        <SmashcutButton
          primary
          disabled={uploadDisabled}
          className={styles.createButton}
          onClick={this.create}
          label="Upload"
        />
        <SmashcutButton primary onClick={this.cancel} label="Cancel" />
      </div>
    );

    return (
      <div className={styles.topContainer}>
        <div className={styles.formContainer}>
          {projectNameBlock}
          {projectDescriptionBlock}
          <div className={styles.buttonsContainer}>
            {creatingProject || project
              ? spinnerBlock
              : uploading
              ? uploadingBlock
              : selectedFileBlock}
            {createCancelButtonsBlock}
          </div>
        </div>
      </div>
    );
  }
}

CreateProjectForm.propTypes = {
  onClose: PropTypes.func,
  createProjectData: PropTypes.shape({
    uploading: PropTypes.bool,
    progress: PropTypes.number
  }),
  project: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string
  })
};

export function mapStateToProps(state) {
  const createProjectData = state.dashboard.createProject;
  let project;

  if (createProjectData.hasOwnProperty('projectId')) {
    project = sclSelectors.getProjectEntity(state, createProjectData.projectId);
  }

  return {
    createProjectData,
    project
  };
}

export const mapDispatchToProps = dispatch => {
  return {
    createProject: (name, description, file) =>
      dispatch(actions.createProject(name, description, file))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProjectForm);
