import {
  getComment,
  getCommentSection,
  getVideoCommentUploadPath,
  getVideoReplyUploadPath,
  getListOfReplies,
  getReply
} from '../selectors';

import { receiveEntity } from './receiveEntity';

export const deleteComment = id => {
  return (dispatch, getState, { api }) => {
    let state = getState();
    let commentSectionId = getCommentSection(state).id;
    let comment = getComment(state, id);

    if (comment.videoUrl) {
      let path = getVideoCommentUploadPath(commentSectionId, id);
      api.deleteVideo(path);
    }

    let replyIds = getListOfReplies(state, id) || [];
    for (let replyId of replyIds) {
      let reply = getReply(state, replyId);
      if (reply.videoUrl) {
        let path = getVideoReplyUploadPath(commentSectionId, replyId);
        api.deleteVideo(path);
      }
      api.deleteReply(commentSectionId, replyId);
    }

    api.deleteComment(commentSectionId, id);

    dispatch(receiveEntity('comments')(id, null));
  };
};
