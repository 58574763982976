import { withEllipsis } from 'smashcut-client-lib/utils/withEllipsis';

export function makeVideoMarker(comment, author, isCurrentUser, number) {
  let newMarker = {
    markerType: 1,
    time: 0,
    title: '',
    comment: '',
    avatar: '',
    number: ''
  };

  newMarker.commentId = comment.id;
  newMarker.markerType = 'student';

  if (author.isMentor) {
    newMarker.markerType = 'mentor';
  }

  if (author.isStaff) {
    newMarker.markerType = 'staff';
  }

  if (isCurrentUser) {
    delete newMarker.markerType;
  }

  newMarker.time = comment.time;
  newMarker.title = author.fullName;
  if (comment.duration) {
    newMarker.duration = comment.duration;
  }
  newMarker.comment = withEllipsis(comment.msg, 120);
  newMarker.avatar = `<img src="${author.avatar}" style="border-radius: 50%; width: 24px; height: 24px;">`;
  newMarker.number = number ? number : '';
  newMarker.roleLabel = author.roleLabel;

  return newMarker;
}
