import { gql } from '@apollo/client';

export const setIsStaff = apolloClient => async (userId, isStaff) => {
  const mutation = gql`
    mutation setIsStaff($input: SetIsStaffInput!) {
      setIsStaff(input: $input) {
        id
        isStaff
      }
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        userId,
        isStaff
      }
    }
  });
};
