import { gql } from '@apollo/client';

export const add3D = apolloClient => async (
  item,
  description,
  uploadRecord
) => {
  const asmt = item.assignment;

  const mutation = gql`
    mutation add3DProject($input: Add3DInput!) {
      add3D(input: $input) {
        object3DProject {
          id
          assignmentId
          commentCount
          courseId
          created
          downloadUrl
          date
          description
          fileName
          fileSize
          lessonId
          name
          owner {
            id
          }
          projectType
          responseType
          status
          thumbnailUrl
          type
          userProgramId
        }
        lessonRecord {
          id
          userProgramId
          lessonId
          courseId
          assignmentId
          created
          status
          assignmentResponse {
            type
            projectId
          }
        }
      }
    }
  `;

  return apolloClient
    .mutate({
      mutation,
      variables: {
        input: {
          userProgramId: asmt.userProgramId,
          courseId: asmt.courseId,
          lessonId: asmt.lessonId,
          assignmentId: asmt.id,
          title: asmt.title,
          description,
          uploadRecord: {
            id: uploadRecord.id,
            ownerId: uploadRecord.ownerId,
            downloadUrl: uploadRecord.downloadUrl,
            status: uploadRecord.status,
            uploadPath: uploadRecord.uploadPath
          }
        }
      },
      refetchQueries: ['lessonUpdate']
    })
    .then(response => response.data.add3D);
};
