import { gql } from '@apollo/client';

const SET_MY_FILE_STATUS = gql`
  mutation setMyFileStatus($myFileId: ID!, $status: String!, $error: String) {
    setMyFileStatus(myFileId: $myFileId, status: $status, error: $error)
  }
`;

export function handleMyFileEncodingError(apolloClient) {
  return myFileId => {
    console.log('handleMyFileEncodingError', myFileId);
    return apolloClient
      .mutate({
        mutation: SET_MY_FILE_STATUS,
        variables: {
          myFileId,
          status: 'Error',
          error: 'encoding error'
        }
      })
      .then(response => response.data.setMyFileStatus);
  };
}
