import { archivesReducer } from './archives';
import { activitiesReducer } from './activities';
import { createProjectReducer } from './createProject';
import { crewsReducer } from './crews';
import { discussionsReducer } from './discussions';
import { myFilesReducer } from './myFiles';
import { myFileImageReducer } from './myFileImage';
import { notificationsReducer } from './notifications';
import { preStartReducer } from './preStart';
import { videoMessageReducer } from './videoMessage';
import { projectsForReviewReducer } from './projectsForReview';
import { selectProgramReducer } from './selectProgram';
import { studentNotesReducer } from './studentNotes';
import { syllabusReducer } from './syllabus';
import { upcomingLmsReducer } from './upcomingLms';
import { userProjectsReducer } from './userProjects';
import { viewUserProfileReducer } from './viewUserProfile';

export const dashboardReducer = (state = {}, action, fullState) => {
  let activities = activitiesReducer(state.activities, action, fullState);
  let archives = archivesReducer(state.archives, action, fullState);
  let createProject = createProjectReducer(
    state.createProject,
    action,
    fullState
  );
  let crews = crewsReducer(state.crews, action, fullState);
  let discussions = discussionsReducer(state.discussions, action, fullState);
  let myFiles = myFilesReducer(state.myFiles, action, fullState);
  let myFileImage = myFileImageReducer(state.myFileImage, action, fullState);
  let notifications = notificationsReducer(
    state.notifications,
    action,
    fullState
  );
  let preStart = preStartReducer(state.preStart, action, fullState);
  let projectsForReview = projectsForReviewReducer(
    state.projectsForReview,
    action,
    fullState
  );
  let selectProgram = selectProgramReducer(
    state.selectProgram,
    action,
    fullState
  );
  let studentNotes = studentNotesReducer(state.studentNotes, action, fullState);
  let syllabus = syllabusReducer(state.syllabus, action, fullState);
  let userProjects = userProjectsReducer(state.userProjects, action, fullState);
  let upcomingLms = upcomingLmsReducer(state.upcomingLms, action, fullState);
  let viewUserProfile = viewUserProfileReducer(
    state.viewUserProfile,
    action,
    fullState
  );
  let videoMessage = videoMessageReducer(state.videoMessage, action, fullState);

  return activities !== state.activities ||
    archives !== state.archives ||
    createProject !== state.createProject ||
    crews !== state.crews ||
    discussions !== state.discussions ||
    myFiles !== state.myFiles ||
    myFileImage !== state.myFileImage ||
    notifications !== state.notifications ||
    preStart !== state.preStart ||
    videoMessage !== state.videoMessage ||
    projectsForReview !== state.projectsForReview ||
    selectProgram !== state.selectProgram ||
    studentNotes !== state.studentNotes ||
    syllabus !== state.syllabus ||
    upcomingLms !== state.upcomingLms ||
    userProjects !== state.userProjects ||
    viewUserProfile !== state.viewUserProfile
    ? {
        ...state,
        activities,
        archives,
        createProject,
        crews,
        discussions,
        myFiles,
        myFileImage,
        notifications,
        preStart,
        videoMessage,
        projectsForReview,
        selectProgram,
        studentNotes,
        syllabus,
        upcomingLms,
        userProjects,
        viewUserProfile
      }
    : state;
};
