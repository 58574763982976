import initCommentsApi from './commentsApi';
import initDiscussionApi from './discussionApi';
import initNotificationApi from './notificationApi';
import initTrackingApi from './trackingApi';
import initUserApi from './userApi';
import { initAppVersionApi } from './appVersionApi';

import { CommentSection } from './CommentSection';
import { createProject } from './createProject';
import { deleteVideo } from './deleteVideo';
import { getContentBucketDownloadUrl } from './getContentBucketDownloadUrl';
import { getToken } from './getToken';
import { initMessaging } from './initMessaging';
import { loadAnnotation } from './loadAnnotation';
import { loadAppSettings } from './loadAppSettings';
import { loadMyFile } from './loadMyFile';
import { loadUserData } from './loadUserData';
import { reauthenticate } from './reauthenticate';
import { saveAnnotation } from './saveAnnotation';
import { signOut } from './signOut';
import { signUp } from './signUp';
import { login } from './login';
import { startEncoding } from './startEncoding';
import { startEncodingV2 } from './startEncodingV2';
import { updateEmail } from './updateEmail';
import { changePassword } from './changePassword';
import { updateUserProgramDetails } from './updateUserProgramDetails';
import { uploadFile } from './uploadFile';
import { uploadVideo } from './uploadVideo';
import {
  apolloClient,
  appService,
  authService,
  smashcutService,
  storageService,
  compressionService
} from '../services';

const api = config => {
  let appSrvc = appService(config);
  let authSrvc = authService(config, apolloClient);
  let compressionSrvc = compressionService(config);
  let smashcutSrvc = smashcutService(config, apolloClient);
  let storageSrvc = storageService(config, apolloClient);

  return Object.assign(
    {
      commentSection: new CommentSection(apolloClient),
      createProject: createProject(smashcutSrvc),
      deleteVideo: deleteVideo(storageSrvc),
      getContentBucketDownloadUrl: getContentBucketDownloadUrl(
        storageSrvc,
        config.appEnv === 'prod'
      ),
      getToken: getToken(authSrvc),
      initMessaging: initMessaging(apolloClient, config),
      loadAnnotation: loadAnnotation(apolloClient, compressionSrvc),
      loadAppSettings: loadAppSettings(apolloClient),
      loadMyFile: loadMyFile(apolloClient),
      loadUserData: loadUserData(apolloClient),
      reauthenticate: reauthenticate(appSrvc),
      saveAnnotation: saveAnnotation(apolloClient, compressionSrvc),
      signOut: signOut(authSrvc),
      signUp: signUp(apolloClient),
      login: login(apolloClient),
      updateEmail: updateEmail(appSrvc),
      changePassword: changePassword(apolloClient),
      startEncoding: startEncoding(smashcutSrvc),
      startEncodingV2: startEncodingV2(smashcutSrvc),
      updateUserProgramDetails: updateUserProgramDetails(apolloClient),
      uploadFile: uploadFile(apolloClient, storageSrvc),
      uploadVideo: uploadVideo(storageSrvc)
    },
    initAppVersionApi(apolloClient),
    initCommentsApi(apolloClient),
    initDiscussionApi(apolloClient),
    initNotificationApi(apolloClient),
    initTrackingApi(),
    initUserApi(authSrvc, apolloClient, storageSrvc)
  );
};

export default api;
