import * as Sentry from '@sentry/react';

export const logAndSendError = (e, message) => {
  window.console &&
    window.console.error &&
    window.console.error(message || '', e);
  Sentry.withScope(scope => {
    scope.setExtra('type', 'handled');
    scope.setExtra('message', message);
    Sentry.captureException(e);
  });
};
