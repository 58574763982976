import { gql } from '@apollo/client';

export const addTextComment = apolloClient => async (
  commentSectionId,
  commentId,
  author,
  time,
  msg,
  duration,
  chunkIndex,
  imageProjectId,
  imageId,
  pin,
  hotPoints
) => {
  const mutation = gql`
    mutation addTextComment($input: AddTextCommentInput!) {
      addTextComment(input: $input)
    }
  `;
  const input = {
    commentSectionId,
    commentId,
    author,
    msg,
    duration,
    chunkIndex,
    imageProjectId,
    imageId,
    pin,
    hotPoints
  };

  // reviews do not have a time
  if (!isNaN(time) && time >= 0) {
    input.time = time;
  }
  return apolloClient.mutate({
    mutation,
    variables: {
      input
    }
  });
};
