import { actionType } from '../constants/actionTypes';
import { logAndSendError } from 'utils/sentryHelper';

export const addEnrollee = input => {
  return (dispatch, getState, { adminApi }) => {
    dispatch({ type: actionType.addEnrollee });
    return adminApi
      .addEnrollee(input)
      .then(() => dispatch({ type: actionType.addEnrolleeSuccess }))
      .catch(e => logAndSendError(e, 'error adding enrollee'));
  };
};

export const addEnrolleeNoAuth = input => {
  return (dispatch, getState, { adminApi }) => {
    dispatch({ type: actionType.addEnrollee });
    return adminApi
      .addEnrolleeNoAuth(input)
      .then(() => dispatch({ type: actionType.addEnrolleeSuccess }));
  };
};
