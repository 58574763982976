import { actionType } from '../constants';

const videoAnnotatorReducer = (state, action) => {
  let nextState = state || {
    selectedTool: 'select',
    color: { hex: '#ffffff' }
  };

  switch (action.type) {
    case actionType.startAnnotating:
      nextState = {
        ...state,
        annotating: true
      };
      break;

    case actionType.stopAnnotating:
      nextState = {
        ...state,
        annotating: false
      };
      break;

    case actionType.toggleAnnotating:
      nextState = {
        ...state,
        annotating: !state.annotating
      };
      break;

    case actionType.setAnnotationColor:
      nextState = {
        ...state,
        color: action.color
      };
      break;

    case actionType.loadAnnotationComplete:
      nextState = {
        ...state,
        annotation: action.annotation
      };
      break;

    case actionType.clearAnnotation:
      nextState = {
        ...state,
        annotation: { ...state.annotation, data: [] }
      };
      break;

    case actionType.selectAnnotationTool:
      nextState = {
        ...state,
        selectedTool: action.tool
      };
      break;

    case actionType.deleteSelectedAnnotation:
      nextState = {
        ...state,
        shouldDeleteSelectedAnnotation: {}
      };
      break;
  }

  return nextState;
};

export default videoAnnotatorReducer;
