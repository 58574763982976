import { actionType } from '../constants';
import { getComment, getReply } from '../selectors';

const videoCommentPlayerReducer = (state, action, fullState) => {
  let item,
    nextState = state;

  switch (action.type) {
    case actionType.playVideoComment:
    case actionType.playVideoReply:
      item =
        action.type == actionType.playVideoComment
          ? getComment(fullState, action.id)
          : getReply(fullState, action.id);

      nextState = {
        ...state,
        isPlaying: {}, //using an object to enforce an update
        item
      };
      break;
  }
  return nextState;
};

export default videoCommentPlayerReducer;
