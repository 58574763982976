import * as partnerConfig from 'themes/partnerConfig';
import React from 'react';
import { connect } from 'react-redux';
import {
  getCurrentUser,
  getCurrentUserProgramHasEnded,
  getCurrentUserProgram,
  getCurrentUserPrograms
} from 'smashcut-client-lib/selectors';
import _, { get, filter, head, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { isTest } from '../../common/isTest';
import { calculateDashboardTabsVisibility } from 'utils/calculateDashboardTabsVisibility';
import { videoMeetingArchivingEnabled } from '../../assets/themes/unreal/partnerConfig';

const mapStateToProps = state => {
  const userPrograms = getCurrentUserPrograms(state);
  const currentUserProgram = getCurrentUserProgram(state);
  const user = getCurrentUser(state);
  const isAdmin = get(user, 'isAdmin');
  const isMentor = get(user, 'isMentor');
  const isInstructor = get(currentUserProgram, 'isInstructor');
  const programHasEnded = getCurrentUserProgramHasEnded(state);
  const acceptedOrEnrolledPrograms = _(userPrograms)
    .filter(
      up =>
        up.application &&
        (up.application.status == 'ACCEPTED' ||
          up.application.status == 'ENROLLED')
    )
    .map(up => ({
      ...up,
      startTimeStamp: isTest('pre-program')
        ? moment()
            .add(-1, 'minute')
            .unix()
        : (moment(up.programInstance.availabilityDate).isValid()
            ? moment(up.programInstance.availabilityDate)
            : moment(up.programInstance.startDate)
          ).unix()
    }))
    .sortBy('startTimeStamp')
    .value();

  const currentDate = moment();
  const currentTimeStamp = currentDate.unix();
  const programsStartingAfter = filter(
    acceptedOrEnrolledPrograms,
    p => p.startTimeStamp > currentTimeStamp && p.id == currentUserProgram.id
  );
  const upcomingProgram = head(programsStartingAfter);
  const shouldShowPreProgram =
    !isMentor &&
    !isAdmin &&
    !isEmpty(upcomingProgram) &&
    upcomingProgram.id == currentUserProgram.id;
  const shouldShowNoProgram = isEmpty(userPrograms);
  const shouldShowLms = partnerConfig.showLms;
  const shouldShowMyRecordings = partnerConfig.videoMeetingArchivingEnabled;
  const shouldHideLmsFromStudents = partnerConfig.shouldHideLmsFromStudents;

  return {
    currentUserProgram,
    isInstructor,
    isMentor,
    programHasEnded,
    user,
    shouldHideLmsFromStudents,
    shouldShowLms,
    shouldShowPreProgram,
    shouldShowNoProgram,
    shouldShowMyRecordings,
    upcomingProgram
  };
};

const mapDispatchToProps = {};
const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const withHiddenTabs = Component =>
  withRedux(props => {
    const tabsToHide = get(
      props,
      'currentUserProgram.programInstance.disabledDashboardTabs',
      []
    ).map(tab => tab.replace(/\_/g, '').toLowerCase());

    const visible = calculateDashboardTabsVisibility(props);
    const hidden = _(visible)
      .keys()
      .reduce(
        (acc, tab) => ({
          ...acc,
          [tab]: !!tabsToHide.includes(tab.toLowerCase()) || !visible[tab]
        }),
        {}
      );

    return <Component {...props} hidden={hidden} />;
  });
