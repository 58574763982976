import { gql } from '@apollo/client';
import { call } from 'redux-saga/effects';
import { put, takeEvery } from 'redux-saga/effects';
import { types } from 'reducers/dashboard/studentNotes';

const UPDATE_OR_CREATE_NOTE = gql`
  mutation updateOrCreateNote($enrolleeId: ID!, $userId: ID!, $note: String!) {
    updateOrCreateNote(enrolleeId: $enrolleeId, userId: $userId, note: $note)
  }
`;

export function* updateNote(apolloClient, action) {
  try {
    const res = yield call(apolloClient.mutate, {
      mutation: UPDATE_OR_CREATE_NOTE,
      variables: action,
      refetchQueries: ['studentListUsers']
    });
    const note = res.data.updateOrCreateNote;
    yield put({
      type: types.NOTE_UPDATE_SUCCESS,
      payload: {
        ...action,
        note
      }
    });
  } catch (e) {
    console.error(e);
    yield put({ type: types.NOTE_UPDATE_FAILURE, error: e });
  }
}

export function* watchUpdateOrCreateNote({ apis }) {
  yield takeEvery(types.NOTE_UPDATE, updateNote.bind(this, apis.apolloClient));
}

export const notesSaga = [watchUpdateOrCreateNote];
