import { gql } from '@apollo/client';

export function uploadProgramBackground(apolloClient) {
  const mutation = gql`
    mutation upload($input: UploadProgramBackgroundInput!) {
      uploadProgramBackground(input: $input)
    }
  `;

  return (programInstanceId, source) =>
    apolloClient.mutate({
      mutation,
      variables: {
        input: {
          programInstanceId,
          source: source.slice(22)
        }
      },
      refetchQueries: ['getProgramsForEnrollment']
    });
}
