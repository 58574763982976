import { gql } from '@apollo/client';
import { omit, map } from 'lodash';

export const sendMessage = apolloClient => (
  crewId,
  userId,
  chatId,
  message,
  file,
  video,
  privateRecipientId
) => {
  console.log('sending crew message', {
    crewId,
    userId,
    chatId,
    message,
    file,
    video,
    privateRecipientId
  });

  const maybeFile = file && {
    file: {
      ...omit(file, '__typename'),
      images: (file.images || []).map(i => omit(i, '__typename'))
    }
  };
  const maybeVideo = video && { video: omit(video, '__typename') };

  const mutation = gql`
    mutation sendCrewMessage($input: SendCrewMessageInput!) {
      sendCrewMessage(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        crewId,
        userId,
        chatId,
        message,
        ...maybeFile,
        ...maybeVideo,
        privateRecipientId
      }
    }
  });
};
