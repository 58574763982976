export const footerLinks = {
  copyrightOwner: 'School of Rock',
  facebookUrl: 'https://www.facebook.com/SchoolofRockUSA/',
  flickrUrl: '',
  instagramUrl: 'https://www.instagram.com/schoolofrock/',
  mediumUrl: '',
  privacyPolicyUrl: 'https://www.smashcut.com/privacy',
  termsOfServiceUrl: 'https://www.smashcut.com/terms',
  twitterUrl: 'https://twitter.com/SchoolOfRockUSA',
  vimeoUrl: '',
  youtubeUrl: 'https://www.youtube.com/@SchoolofRockUSA'
};

export const getInTouch = `If you have questions, you can get in touch
      with a student advisor by emailing help@smashcut.com or by clicking the
      "Help Chat" button in the header of any Smashcut webpage.`;

export const helpCenterUrl = 'https://intercom.help/smashcut';

export const highlightColor = '#D9505D';

export const auth0Logo = '/assets/themes/schoolofrock/logo.png';

export const showDisciplines = false;

export const showProgramStartDate = true;

export const useScreenplayForPdfUploads = false;

export const hideCrews = false;

export const showLms = true;

export const blockMobileAccess = false;

export const topNavLinks = {
  theme: 'schoolofrock',
  blog: {
    blogComponent: true,
    blogUrl: 'https://medium.com/smashcut-film'
  },
  helpCenter: {
    helpCenterKeyMentor: 'mentor handbook',
    helpCenterTitleMentor: 'Mentor Handbook',
    helpCenterUrlMentor: 'https://pasteapp.com/p/oz2WQBAG2D8/s/crf0i4rz',
    helpCenterKey: 'help center',
    helpCenterTitle: 'Help Center',
    helpCenterUrl: 'https://intercom.help/smashcut/smashcut-student-handbook'
  },
  availability: {
    // why does smashcut show availability to everyone instead of just mentors?
    availabilityComponent: true
  }
};

export const requiredAccountDetailsFields = {};

export const preStartModules = {};

export const climbcreditEnabled = false;

export const videoMeetingArchivingEnabled = true;

export const breakoutRoomsEnabled = true;
export const fastUserInit = () => false;
