import React from 'react';

class PromiseImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { resolvedUrl: undefined };

    // there is an issue with setState when a PromiseImage is removed
    // maybe we should move the resolving code out of the component?
    this.canSetState = false;
  }

  resolveImage(props) {
    if (props.src) {
      if (props.src.then) {
        props.src.then(url => {
          this.canSetState && this.setState({ resolvedUrl: url });
        });
      } else {
        this.setState({ resolvedUrl: props.src });
      }
    }
  }

  componentWillUnmount() {
    this.canSetState = false;
  }

  componentDidMount() {
    this.canSetState = true;
    this.resolveImage(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.src && this.props.src != props.src) {
      this.resolveImage(props);
    }
  }

  render() {
    let { className, title } = this.props;
    let { resolvedUrl } = this.state;

    return <img className={className} src={resolvedUrl} alt={title} />;
  }
}

export default PromiseImage;
