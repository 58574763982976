import { getCurrentUser } from './getCurrentUser';
import { getCurrentUserPrograms } from './getCurrentUserPrograms';
import { isEqual, find } from 'lodash';

const defaultReturnValue = undefined;

let cache;
function cached(o) {
  if (!isEqual(cache, o)) {
    cache = o;
  }
  return cache;
}

export function getCurrentUserProgram(state) {
  let userPrograms = getCurrentUserPrograms(state);

  if (!userPrograms) {
    return defaultReturnValue;
  }

  const user = getCurrentUser(state);
  if (
    user.currentUserProgramId &&
    find(userPrograms, up => up.id === user.currentUserProgramId)
  ) {
    const up = userPrograms.find(up => up.id === user.currentUserProgramId);
    if (up) {
      return cached(up);
    }
    console.warn('Current userProgram not found', user);
  }
  if (userPrograms.length) {
    return cached(userPrograms[userPrograms.length - 1]);
  }

  // console.warn('No enrollment found for user', user);
}
