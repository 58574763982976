import React from 'react';
import config from 'config';
import VideoPlayer from 'smashcut-player-lib/VideoPlayer';
import { NoteDisplay } from '../noteDisplay/NoteDisplay';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as sclActions } from 'smashcut-client-lib';

let counter = 1000;

class VideoPlayerWithNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = { markerItem: {} };
    this.videoHolderId = 'cmsVideoHolder-' + counter++;
  }

  render() {
    return (
      <VideoPlayer
        appConfig={config}
        video={this.props.video}
        markers={this.props.markers}
        actions={this.props.actions}
        videoHolderId={this.videoHolderId}
        closeDisabled={true}
        shouldTogglePlay={this.props.shouldTogglePlay}
      >
        <NoteDisplay
          item={this.state.markerItem}
          hideNoteDisplay={() =>
            this.setState({
              markerItem: { ...this.state.markerItem, visible: false }
            })
          }
        />
      </VideoPlayer>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...sclActions }, dispatch)
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

export default withStore(VideoPlayerWithNotes);
