import { gql } from '@apollo/client';

export function impersonate(apolloClient, authSrvc) {
  const QUERY = gql`
    mutation impersonate($userId: String!) {
      impersonate(userId: $userId) {
        token
        user {
          id
          email
          fullName
          avatar
        }
      }
    }
  `;

  return async userId => {
    const response = await apolloClient.mutate({
      mutation: QUERY,
      variables: {
        userId
      }
    });
    const customToken = response.data.impersonate.token;
    const user = response.data.impersonate.user;
    const authResponse = await authSrvc.signInWithCustomToken(
      user,
      customToken
    );

    return 'ok';
  };
}
