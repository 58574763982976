import { gql } from '@apollo/client';
import { clone, get } from 'lodash';

const GET_CREW_LAST_SEENS = gql`
  query lastSeens($crewId: String!, $userId: String!) {
    crewLastSeens(crewId: $crewId, userId: $userId) {
      id
      userId
      chatId
      time
    }
  }
`;

export const getLastSeens = apolloClient => async (
  crewId,
  userId,
  callback
) => {
  const result = await apolloClient.query({
    query: GET_CREW_LAST_SEENS,
    fetchPolicy: 'network-only',
    variables: {
      crewId,
      userId
    }
  });
  const lastSeens = get(result, 'data.crewLastSeens', []);
  callback(clone(lastSeens));
};
