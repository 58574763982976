import { selectors as sclSelectors } from 'smashcut-client-lib';
import { LOGOUT } from '../../smashcut-client-lib/reducers/authReducer';
import {
  discussionMessageReceived,
  discussionInsertLastRead
} from 'smashcut-client-lib/actions/discussionsActions';
import moment from 'moment-timezone';
import { types } from './discussionTypes';
import { getCurrentUserProgram } from 'smashcut-client-lib/selectors';
import { get } from 'lodash';

const initialState = {
  forceMessagesScrollDown: {},
  channels: [],
  selectedChannelId: null,
  memberMap: {}
};

export const discussionsReducer = (state = initialState, action) => {
  let nextState = state;

  switch (action.type) {
    case types.ADD_MESSAGE_RESULT:
      nextState = { ...state, forceMessagesScrollDown: {} };
      break;
    case types.DELETE_MESSAGE_RESULT:
      break;
    case types.LOAD_CHANNEL_GROUPS_SUCCESS:
      nextState = {
        ...state,
        channels: action.channels,
        memberMap: action.memberMap
      };
      break;
    case types.SELECT_CHANNEL:
      nextState = {
        ...state,
        selectedChannelId: action.userId,
        forceMessagesScrollDown: {}
      };
      break;

    case types.CLEAR_DISCUSSION:
      nextState = {
        ...state,
        selectedChannelId: null
      };
      break;

    case types.CLEAR_ALL:
      nextState = initialState;
      break;

    case LOGOUT:
      nextState = Object.assign({}, initialState);
      break;
  }
  return nextState;
};

export const updateLastRead = (receiverId, userId) => (
  dispatch,
  getState,
  { api }
) => {
  const state = getState();
  const up = getCurrentUserProgram(state);
  const programInstanceId = up.programInstanceId;

  api.discussions
    .updateLastRead(receiverId, userId, programInstanceId)
    .then(lastRead => {
      lastRead && discussionInsertLastRead(dispatch, lastRead);
    });
};

const selectDefaultChannel = channelId => (dispatch, getState, { api }) => {
  const state = getState();
  const channels = getChannels(state);

  if (!channels) {
    return;
  }
  const channel =
    (channelId && channels.find(ch => ch.id === channelId)) ||
    channels.find(ch => ch.isGroupChat);

  if (channel) {
    dispatch(selectChannel(channel));
  }
};

const selectChannel = channel => (dispatch, getState, { api }) => {
  const state = getState();
  const currentUser = sclSelectors.getCurrentUser(state);
  dispatch(updateLastRead(channel.id, currentUser.id));
  dispatch({ type: types.SELECT_CHANNEL, userId: channel.id });
};

const unsetDiscussions = () => ({
  type: types.CLEAR_DISCUSSION
});

const openShareMediaModal = () => ({
  type: types.OPEN_SHARE_MEDIA_MODAL
});

const addMessage = (receiverId, userId, text, file, video) => (
  dispatch,
  getState,
  { api }
) => {
  const state = getState();
  const isGroupMessage =
    !!state.entities.programInstances[
      state.dashboard.discussions.selectedChannelId
    ] || false;

  const up = getCurrentUserProgram(state);
  const programInstanceId = up.programInstanceId;

  return api.discussions
    .addMessage(
      receiverId,
      userId,
      text,
      file,
      video,
      isGroupMessage,
      programInstanceId
    )
    .then(message => {
      const currentUser = sclSelectors.getCurrentUser(state);
      discussionMessageReceived(dispatch, api, getState)(message);
      dispatch({
        type: types.ADD_MESSAGE_RESULT,
        result: { success: true }
      });

      if (message.isGroupMessage) {
        dispatch(updateLastRead(message.receiverId, currentUser.id));
      }
    });
};

const updateMessage = (id, message) => (dispatch, getState, { api }) => {
  const state = getState();
  const up = getCurrentUserProgram(state);
  const programInstanceId = up.programInstanceId;
  const userId = get(sclSelectors.getCurrentUser(state), 'id');

  return api.discussions
    .updateMessage(id, message, programInstanceId, userId)
    .then(message => {
      discussionMessageReceived(dispatch, api, getState)(message);
    });
};

const shareComment = payload => (dispatch, getState, { api }) => {
  const state = getState();
  const programInstanceId = sclSelectors.getCurrentUserProgram(state)
    .programInstanceId;
  const authorId = sclSelectors.getCurrentUser(state).id;
  const sharedCommentAuthorId = payload.author.id;

  const comment = { ...payload.comment };
  delete comment.authorUser;
  delete comment.__typename;

  if (comment.duration === false) {
    comment.duration = 0;
  }

  const sharedComment = {
    authorId: sharedCommentAuthorId,
    comment: comment
  };

  const callApi = (recipientId, isGroup) =>
    api.discussions.addMessage(
      recipientId,
      authorId,
      payload.message,
      undefined,
      undefined,
      isGroup,
      programInstanceId,
      sharedComment
    );

  payload.userIds?.forEach(recipientId => callApi(recipientId, false));

  if (payload.groupId) {
    callApi(payload.groupId, true);
  }
};

const shareMedia = (
  userId,
  userChannelIds,
  groupChannelId,
  text,
  files,
  programInstanceId,
  shouldNotify
) => (dispatch, getState, { api }) => {
  const state = getState();
  const up = getCurrentUserProgram(state);
  const programInstanceId = up.programInstanceId;

  return api.discussions
    .shareMedia(
      userId,
      userChannelIds,
      groupChannelId,
      text,
      files,
      programInstanceId,
      shouldNotify
    )
    .then(message => {
      dispatch({
        type: types.SHARE_MEDIA_SUCCESS,
        message
      });
      console.log('Sharing media result', message);
    })
    .catch(error => {
      dispatch({
        type: types.SHARE_MEDIA_FAILURE,
        error
      });
    });
};

const deleteMessage = message => (dispatch, getState, { api }) => {
  const state = getState();
  const up = getCurrentUserProgram(state);
  const programInstanceId = up.programInstanceId;
  return api.discussions
    .deleteMessage(message.authorId, message.id, programInstanceId)
    .then(() => {
      discussionMessageReceived(dispatch, api, getState, true)(message);
      dispatch({
        type: types.DELETE_MESSAGE_RESULT,
        result: { success: true }
      });
    });
};

const previewFile = file => () => {
  if (file.projectLink) {
    window.open(file.projectLink, '_blank', 'noopener');
  } else {
    window.open(`/project/${file.id}`, '_blank', 'noopener');
  }
};

export const actions = {
  addMessage,
  updateMessage,
  deleteMessage,
  selectDefaultChannel,
  previewFile,
  selectChannel,
  unsetDiscussions,
  openShareMediaModal,
  shareMedia,
  shareComment
};

const getselectedChannelId = state =>
  state.dashboard.discussions.selectedChannelId;
const getMessages = state => state.entities.messages;
const getAllMembers = state => state.dashboard.discussions.memberMap;
const getChannels = state => state.dashboard.discussions.channels;

export const selectors = {
  getChannels,
  getMessages,
  getselectedChannelId,
  getAllMembers,
  getForceMessagesScrollDown: state =>
    state.dashboard.discussions.forceMessagesScrollDown,
  getLastReads: state => state.entities.lastReads,
  getSelectedChannel: state => {
    const channels = state.dashboard.discussions.channels;
    const channelId = state.dashboard.discussions.selectedChannelId;
    return channels.find(ch => ch.id === channelId);
  },
  getMessagesForUser: state => channelId => {
    const programInstances = sclSelectors.getProgramInstances(state);
    const messageMap = getMessages(state);

    const selectedGroupChat =
      programInstances &&
      Object.keys(programInstances).find(pri => pri === channelId);

    const messages =
      messageMap &&
      Object.keys(messageMap)
        .map(messageId => ({ ...messageMap[messageId] }))
        .filter(message => {
          if (selectedGroupChat) {
            return (
              message.isGroupMessage && message.receiverId === selectedGroupChat
            );
          }
          return (
            !message.isGroupMessage &&
            (message.authorId === channelId || message.receiverId === channelId)
          );
        })
        .map(m => ({ ...m, content: m.text, sender: m.authorId }))
        .sort((a, b) => {
          if (moment(a.created).isBefore(b.created)) {
            return -1;
          }
          if (moment(a.created).isAfter(b.created)) {
            return 1;
          }
          return 0;
        });
    return messages;
  }
};
