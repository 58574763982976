import CreateProjectForm from './CreateProjectForm';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { selectors as userProjectsSelectors } from 'reducers/dashboard/userProjects';
import { actions as userProjectsActions } from 'reducers/dashboard/userProjects';
import { PopupDialog } from 'components/common/PopupDialog';

export class CreateProjectPopup extends React.Component {
  render() {
    let { open, onClose } = this.props;

    return (
      <PopupDialog open={open} onClose={onClose} title="Upload">
        <CreateProjectForm onClose={onClose} />
      </PopupDialog>
    );
  }
}

CreateProjectPopup.propTypes = {
  open: PropTypes.bool
};

export const mapStateToProps = state => {
  let projectEditorData = userProjectsSelectors.getProjectCreator(state);

  return {
    open: projectEditorData.open
  };
};

export const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(userProjectsActions.closeProjectCreator())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProjectPopup);
