import { actionType } from '../constants/actionTypes';
import { logAndSendError } from 'utils/sentryHelper';

export const makeUserInstructor = (userProgramId, isInstructor) => {
  return (dispatch, getState, { adminApi }) => {
    dispatch({ type: actionType.makeUserInstructor });
    return adminApi
      .makeUserInstructor(userProgramId, isInstructor)
      .then(() => dispatch({ type: actionType.makeUserInstructorSuccess }))
      .catch(e => logAndSendError(e, 'error making user instructor'));
  };
};
