// The basic lesson url looks like this
// program/{pid}/course/{cid}/lesson/{lid}/{mainAreaType}/{mainAreaId}
//
// This loads a program, creates the lesson structure
// and loads the lesson view with the default tab info open
// The main area type can be 'video', 'project' or 'screenplay'
// The main area id is the id of the item to be shown in the main area
//
// option 1: the tab name can be appended to open a specific tab
// without selecting an item in the tab, eg. info, comments, assignments
// program/{pid}/course/{cid}/lesson/{lid}/{mat}/{mid}/comments
//
// option 2: an item type plus itemId can be appended, which will
// open the correct tab for the item and maybe highlight it or filter out other items
// program/{pid}/course/{cid}/lesson/{lid}/{mat}/{mid}/assignment/{asid}

export const MAIN_AREA_TYPE = {
  VIDEO: 'video',
  PROJECT: 'project',
  SCREENPLAY: 'screenplay',
  IMAGE: 'image',
  NON_SUPPORTED_FORMAT: 'nsf',
  OBJECT3D: 'object3d',
  PDF: 'pdf'
};

export function gotoLessonPayloadToUrl(payload) {
  const m = payload.mainAreaId;
  const mainAreaId =
    payload.mainAreaType === MAIN_AREA_TYPE.IMAGE && m.indexOf('!') < 0
      ? `${m}!${m}` // first image has same id as project
      : m;
  let parts = [
    'program',
    payload.programId,
    'course',
    payload.courseId,
    'lesson',
    payload.lessonId,
    payload.mainAreaType,
    mainAreaId
  ];
  if (payload.tabName) {
    parts.push(payload.tabName);
  } else if (payload.itemType) {
    parts.push(payload.itemType, payload.itemId);
  }
  const url = '/' + parts.join('/');
  return url;
}

export function makeGotoLessonPayload(
  programId,
  courseId,
  lessonId,
  mainAreaType,
  mainAreaId,
  tabNameOrItemType,
  itemId
) {
  let result = {
    programId,
    courseId,
    lessonId,
    mainAreaType,
    mainAreaId
  };
  if (tabNameOrItemType && itemId) {
    result.itemType = tabNameOrItemType;
    result.itemId = itemId;
  } else if (tabNameOrItemType) {
    result.tabName = tabNameOrItemType;
  }
  return result;
}

export const getMainAreaType = responseType => {
  switch (responseType) {
    case 'VIDEO':
      return MAIN_AREA_TYPE.PROJECT;
    case 'IMAGE':
      return MAIN_AREA_TYPE.IMAGE;
    case 'SCREENPLAY':
      return MAIN_AREA_TYPE.SCREENPLAY;
    case 'NONSUPPORTED':
      return MAIN_AREA_TYPE.NON_SUPPORTED_FORMAT;
    case 'OBJECT3D':
      return MAIN_AREA_TYPE.OBJECT3D;
    default:
      console.error('Unexpected responseType', responseType);
  }
};

export function responseTypeToMainAreaType(responseType) {
  const result = getMainAreaType(responseType);
  return result || MAIN_AREA_TYPE.PROJECT;
}
