import { gql } from '@apollo/client';

export function testDbStructure(apolloClient) {
  const query = gql`
    query testDbStructure {
      testDbStructure {
        id
        msg
        fix {
          method
          targetObjId
          details
        }
        details {
          id
          fullName
          currentUserProgramId
          email
          user
          programInstance
          programs
          mentors
          students
          mentorUpId
          studentUpId
        }
      }
    }
  `;

  return async () => {
    await apolloClient.query({
      query
    });
  };
}
