import React from 'react';
import styles from './SmashcutAvatar.scss';
import DefaultRed from 'themes/default_avatar.svg?url';
import classnames from 'classnames';
import PropTypes from 'prop-types';

class SmashcutAvatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false
    };
    this.clearListeners = null;
  }

  cleanup = () => {
    if (this.clearListeners) {
      this.clearListeners();
      this.clearListeners = null;
    }
  };

  tryToLoadImage = src => {
    this.cleanup();

    let img = new Image();
    this.clearListeners = () => {
      img.onload = null;
      img.onerror = null;
    };
    img.onerror = () => {
      this.cleanup();
      this.setState({ error: true });
    };
    img.onload = () => {
      this.cleanup();
      this.setState({ error: false });
    };
    img.src = src;
  };

  componentDidMount() {
    this.tryToLoadImage(this.props.src);
  }

  componentWillUnmount() {
    this.cleanup();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.tryToLoadImage(newProps.src);
  }

  render() {
    let {
      width,
      height,
      title,
      className,
      holderClassName,
      children,
      onClick,
      alt,
      ...rest
    } = this.props;
    let style = { width, height };

    return this.state.error ? (
      <div
        onClick={onClick}
        title={title}
        alt={title}
        data-cy="avatarImageDefault"
        className={classnames(styles.defaultAvatarHolder, holderClassName)}
      >
        {
          <img
            src={DefaultRed}
            className={classnames(
              styles.avatar,
              styles.defaultAvatar,
              className
            )}
            style={style}
          />
        }
        {children}
      </div>
    ) : (
      <div
        onClick={onClick}
        className={classnames(styles.avatarHolder, holderClassName)}
        {...rest}
      >
        <img
          src={this.props.src}
          style={style}
          className={classnames(styles.avatar, className)}
          data-cy="avatarImage"
          title={title}
          alt={alt}
        />
        {children}
      </div>
    );
  }
}

SmashcutAvatar.propTypes = {
  className: PropTypes.string,
  holderClassName: PropTypes.string,
  height: PropTypes.number,
  src: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number
};

export default SmashcutAvatar;
