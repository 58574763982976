import { logAndSendError } from '../utils/sentryHelper';

const handleFetchError = promise =>
  new Promise((resolve, reject) => {
    promise
      .then(result => result.json())
      .then(result => {
        if (result.error) {
          reject(result.error);
        }
        resolve(result);
      })
      .catch(reject);
  });

export default function(config) {
  // console.log('sch api', config);
  const serviceUrl = config.smashcutApiUrl + '/schedule';

  const getAllAvailability = userId => {
    // console.log('service.getAllAvail');
    return fetch(`${serviceUrl}/get-all-availability/${userId}`)
      .then(r => r.json())
      .catch(e =>
        logAndSendError(e, `error getting availability for ${userId}`)
      );
  };

  const addNewAvailability = (
    userId,
    begin,
    end,
    availability,
    isBlocked,
    timezone
  ) =>
    handleFetchError(
      fetch(`${serviceUrl}/add-availability/${userId}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
          begin: begin,
          end: end,
          availability,
          isBlocked,
          timezone: timezone || 'UTC'
        })
      })
    );

  const updateAvailability = (id, begin, end, availability, timezone) => {
    // console.log('service.updateAvalability', timezone);
    return handleFetchError(
      fetch(`${serviceUrl}/update-availability/${id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
          begin: begin,
          end: end,
          availability,
          timezone
        })
      })
    );
  };

  const deleteAvailability = id =>
    handleFetchError(
      fetch(`${serviceUrl}/delete-availability/${id}`, {
        method: 'DELETE'
      })
    );

  const bookSchedule = (firstUserId, secondUserId, begin, end) => {
    // console.log('service.bookSchedule');
    return handleFetchError(
      fetch(`${serviceUrl}/book-session`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
          begin: begin,
          end: end,
          user1: firstUserId,
          user2: secondUserId
        })
      })
    );
  };

  return {
    getAllAvailability,
    addNewAvailability,
    updateAvailability,
    deleteAvailability,
    bookSchedule
  };
}
