import React from 'react';
import { Button as ReactToolboxButton } from 'react-toolbox/lib/button';
import styles from './DefaultButton.scss';
import classnames from 'classnames';
import { isString } from 'lodash';

export const DefaultButton = ({
  label,
  size,
  type,
  icon,
  className,
  iconPosition,
  isLocked,
  isActive,
  ...rest
}) => {
  const typeStyle = type ? styles[type] : styles.main;
  const sizeClass = size ? styles[size] : '';
  const ariaLabel = rest['aria-label'] || label;
  let iconPos = iconPosition || 'left';
  let iconNode = isString(icon) ? <i className={icon} /> : icon;

  if (isLocked) {
    iconNode = <i className="fas fa-lock" />;
    iconPos = 'left';
    rest.title = 'Program has ended, function disabled.';
    rest.onClick = () => {};
    rest.disabled = false;
  }
  return (
    <ReactToolboxButton
      className={classnames(
        styles.button,
        typeStyle,
        sizeClass,
        className,
        isLocked && styles.locked,
        isActive && styles.active
      )}
      {...rest}
      aria-label={ariaLabel}
    >
      {iconPos === 'left' && iconNode}
      <span className={styles.buttonText}>{label}</span>
      {iconPos === 'right' && iconNode}
    </ReactToolboxButton>
  );
};
