import { gql } from '@apollo/client';

const signUpMutation = gql`
  mutation signup($fullName: String!, $password: String!, $email: String!) {
    signup(fullName: $fullName, password: $password, email: $email) {
      status
      message
    }
  }
`;

export const signUp = apolloClient => (email, password, fullName) =>
  apolloClient
    .mutate({
      mutation: signUpMutation,
      input: { email, fullName, password }
    })
    .then(response => response.data.signUp);
