const BASE = 'ACCOUNT_DETAILS';

export const types = {
  UPDATE_PROFILE_TAB_REQUEST: `${BASE}/UPDATE_PROFILE_TAB_REQUEST`,
  UPDATE_PROFILE_TAB_SUCCESS: `${BASE}/UPDATE_PROFILE_TAB_SUCCESS`,
  UPDATE_PROFILE_TAB_FAILURE: `${BASE}/UPDATE_PROFILE_TAB_FAILURE`,

  UPDATE_NOTIFICATIONS_TAB_REQUEST: `${BASE}/UPDATE_NOTIFICATIONS_TAB_REQUEST`,
  UPDATE_NOTIFICATIONS_TAB_SUCCESS: `${BASE}/UPDATE_NOTIFICATIONS_TAB_SUCCESS`,
  UPDATE_NOTIFICATIONS_TAB_FAILURE: `${BASE}/UPDATE_NOTIFICATIONS_TAB_FAILURE`,

  UPDATE_PRIVACY_TAB_REQUEST: `${BASE}/UPDATE_PRIVACY_TAB_REQUEST`,
  UPDATE_PRIVACY_TAB_SUCCESS: `${BASE}/UPDATE_PRIVACY_TAB_SUCCESS`,
  UPDATE_PRIVACY_TAB_FAILURE: `${BASE}/UPDATE_PRIVACY_TAB_FAILURE`,

  UPDATE_SOCIAL_TAB_REQUEST: `${BASE}/UPDATE_SOCIAL_TAB_REQUEST`,
  UPDATE_SOCIAL_TAB_SUCCESS: `${BASE}/UPDATE_SOCIAL_TAB_SUCCESS`,
  UPDATE_SOCIAL_TAB_FAILURE: `${BASE}/UPDATE_SOCIAL_TAB_FAILURE`,

  UPDATE_PASSWORD_TAB_REQUEST: `${BASE}/UPDATE_PASSWORD_TAB_REQUEST`,
  UPDATE_PASSWORD_TAB_SUCCESS: `${BASE}/UPDATE_PASSWORD_TAB_SUCCESS`,
  UPDATE_PASSWORD_TAB_FAILURE: `${BASE}/UPDATE_PASSWORD_TAB_FAILURE`,

  REAUTHENTICATE_REQUEST: `${BASE}/REAUTHENTICATE_REQUEST`,
  REAUTHENTICATE_SUCCESS: `${BASE}/REAUTHENTICATE_SUCCESS`,
  REAUTHENTICATE_FAILURE: `${BASE}/REAUTHENTICATE_FAILURE`,

  REAUTHENTICATION_REQUIRED: `${BASE}/REAUTHENTICATION_REQUIRED`,
  CANCEL_REAUTHENTICATION: `${BASE}/CANCEL_REAUTHENTICATION`,
  CONFIRM_SUCCESS: `${BASE}/CONFIRM_SUCCESS`,
  CLEAR_ERRORS: `${BASE}/CLEAR_ERRORS`
};

export const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_PROFILE_TAB_REQUEST:
      return {
        ...state,
        updatingProfileData: true,
        profileDataChangedSuccess: undefined
      };
    case types.UPDATE_PROFILE_TAB_SUCCESS:
      return {
        ...state,
        updatingProfileData: false,
        profileDataChangedSuccess: true
      };
    case types.UPDATE_PROFILE_TAB_FAILURE:
      return {
        ...state,
        updatingProfileData: false,
        profileDataChangedSuccess: false
      };
    case types.UPDATE_NOTIFICATIONS_TAB_REQUEST:
      return {
        ...state,
        updatingNotificationsData: true,
        notificationsDataChangedSuccess: undefined
      };
    case types.UPDATE_NOTIFICATIONS_TAB_SUCCESS:
      return {
        ...state,
        updatingNotificationsData: false,
        notificationsDataChangedSuccess: true
      };
    case types.UPDATE_NOTIFICATIONS_TAB_FAILURE:
      return {
        ...state,
        updatingNotificationsData: false,
        notificationsDataChangedSuccess: false
      };
    case types.UPDATE_PRIVACY_TAB_REQUEST:
      return {
        ...state,
        updatingPrivacyData: true,
        privacyDataChangedSuccess: undefined
      };
    case types.UPDATE_PRIVACY_TAB_SUCCESS:
      return {
        ...state,
        updatingPrivacyData: false,
        privacyDataChangedSuccess: true
      };
    case types.UPDATE_PRIVACY_TAB_FAILURE:
      return {
        ...state,
        updatingPrivacyData: false,
        privacyDataChangedSuccess: false
      };
    case types.UPDATE_SOCIAL_TAB_REQUEST:
      return {
        ...state,
        updatingSocialData: true,
        socialDataChangedSuccess: undefined
      };
    case types.UPDATE_SOCIAL_TAB_SUCCESS:
      return {
        ...state,
        updatingSocialData: false,
        socialDataChangedSuccess: true
      };
    case types.UPDATE_SOCIAL_TAB_FAILURE:
      return {
        ...state,
        updatingSocialData: false,
        socialDataChangedSuccess: false
      };
    case types.UPDATE_PASSWORD_TAB_REQUEST:
      return {
        ...state,
        updatingPasswordData: true,
        passwordDataChangedSuccess: undefined
      };
    case types.UPDATE_PASSWORD_TAB_SUCCESS:
      return {
        ...state,
        updatingPasswordData: false,
        passwordDataChangedSuccess: true
      };
    case types.UPDATE_PASSWORD_TAB_FAILURE:
      return {
        ...state,
        updatingPasswordData: false,
        errors: { password: action.error },
        passwordDataChangedSuccess: false
      };
    case types.REAUTHENTICATION_REQUIRED:
      return { ...state, reauthenticating: true };

    case types.REAUTHENTICATE_REQUEST:
      return {
        ...state,
        reauthenticatingSuccess: false,
        reauthenticationError: undefined
      };

    case types.REAUTHENTICATE_SUCCESS:
      return {
        ...state,
        reauthenticatingSuccess: true,
        reauthenticating: false
      };

    case types.REAUTHENTICATE_FAILURE:
      return {
        ...state,
        reauthenticatingSuccess: false,
        reauthenticationError: 'not good reauth'
      };

    case types.CANCEL_REAUTHENTICATION:
      return { ...state, reauthenticating: false };

    case types.CONFIRM_SUCCESS:
      return {
        ...state,
        profileDataChangedSuccess: undefined,
        notificationsDataChangedSuccess: undefined,
        socialDataChangedSuccess: undefined,
        privacyDataChangedSuccess: undefined,
        passwordDataChangedSuccess: undefined,
        reauthenticatingSuccess: undefined
      };

    case types.CLEAR_ERRORS:
      return {
        ...state,
        errors: { [action.fieldName]: undefined }
      };

    default:
      return state;
  }
};

export const actions = {
  updateProfileTab: values => {
    return (dispatch, getState) => {
      if (values.website === 'https://' || values.website === 'http://') {
        values.website = '';
      }
      dispatch({
        type: types.UPDATE_PROFILE_TAB_REQUEST,
        values
      });
    };
  },
  updateNotificationTab: values => ({
    type: types.UPDATE_NOTIFICATIONS_TAB_REQUEST,
    values
  }),
  updatePrivacyTab: values => ({
    type: types.UPDATE_PRIVACY_TAB_REQUEST,
    values
  }),
  updateSocialTab: values => {
    return (dispatch, getState) => {
      const filteredValues = Object.keys(values).reduce((acc, key) => {
        if (values[key] !== 'https://' && values[key] !== 'http://') {
          acc[key] = values[key];
        } else {
          acc[key] = '';
        }
        return acc;
      }, {});
      dispatch({
        type: types.UPDATE_SOCIAL_TAB_REQUEST,
        values: filteredValues
      });
    };
  },
  updatePasswordTab: values => ({
    type: types.UPDATE_PASSWORD_TAB_REQUEST,
    values
  }),
  reauthenticate: password => ({
    type: types.REAUTHENTICATE_REQUEST,
    password
  }),
  cancelReauthentication: () => ({
    type: types.CANCEL_REAUTHENTICATION
  }),
  confirmSuccess: () => ({
    type: types.CONFIRM_SUCCESS
  }),
  clearErrorsForField: fieldName => ({
    type: types.CLEAR_ERRORS,
    fieldName
  })
};
