import { gql } from '@apollo/client';

export function enrollTrialUser(apolloClient) {
  const ENROLL_TRIAL_USER = gql`
    mutation enrollTrialUser(
      $enrolleeId: ID!
      $programInstanceId: ID!
      $trialProgramInstanceId: ID!
    ) {
      enrollTrialUser(
        enrolleeId: $enrolleeId
        programInstanceId: $programInstanceId
        trialProgramInstanceId: $trialProgramInstanceId
      )
    }
  `;

  return async (userProgram, programInstance) => {
    await apolloClient.mutate({
      mutation: ENROLL_TRIAL_USER,
      variables: {
        enrolleeId: userProgram.id,
        programInstanceId: programInstance.id,
        trialProgramInstanceId: userProgram.programInstance
      },
      refetchQueries: ['getAdminData']
    });
  };
}
