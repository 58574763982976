import styles from './SmashcutIframe.scss';
import React from 'react';
import { Rnd } from 'react-rnd';
import Iframe from 'react-iframe';
import {
  actions as popupActions,
  selectors as popupSelectors
} from '../../reducers/popupReducer';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { SecondaryButton } from './button';
import MarkDownDisplay from './MarkDownDisplay';

export const SmashcutIframeComponent = ({
  superTitle,
  title,
  description,
  isOpen,
  src,
  onClose,
  hMargin = 50,
  vMargin = 50
}) => {
  return (
    <div className={styles.topContainer}>
      {isOpen ? (
        <>
          <div
            className={styles.modalOverlay}
            onClick={e => e.stopPropagation()}
          />
          <Rnd
            default={{
              x: hMargin,
              y: vMargin,
              width: window.innerWidth - 2 * hMargin,
              height: window.innerHeight - 2 * vMargin
            }}
            className={styles.rnd}
            minWidth={1000}
            minHeight={100}
          >
            <div className={styles.contentContainer}>
              <Iframe url={src} width="100%" minWidth="700px" height="100%" />
              <div className={styles.sidePanel}>
                <div className={styles.header}>
                  <div className={styles.superTitle}>{superTitle}</div>
                  <SecondaryButton
                    size="small"
                    label="Close"
                    aria-label="Close"
                    onClick={onClose}
                  />
                </div>
                <div className={styles.content}>
                  <MarkDownDisplay markDown={title} className={styles.title} />
                  <MarkDownDisplay
                    markDown={description}
                    className={styles.description}
                  />
                </div>
              </div>
            </div>
          </Rnd>
        </>
      ) : null}
    </div>
  );
};

export const POPUP_ID = 'Iframe';
export const showIframe = popupActions.show.call(popupActions, POPUP_ID);
export const hideIframe = popupActions.hide.call(popupActions, POPUP_ID);

export const mapStateToProps = state => {
  const isOpen = popupSelectors.isPopupOpen(state, POPUP_ID);
  const details = popupSelectors.getPopupDetails(state, POPUP_ID) || {};
  return {
    ...details,
    hMargin: details.hMargin || 150,
    vMargin: details.vMargin || 150,
    isOpen
  };
};

const mapDispatchToProps = {
  onClose: hideIframe
};

export const SmashcutIframe = hot(
  connect(mapStateToProps, mapDispatchToProps)(SmashcutIframeComponent)
);
