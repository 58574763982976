import { signUrl as signUrlDefault } from './signUrl';
import videoJsDefault from 'video.js';
import { invoke } from 'lodash';

let videoJs = videoJsDefault;
let signUrl = signUrlDefault;

export function setVideoJs(value) {
  videoJs = value;
}

export function setSignUrl(value) {
  signUrl = value;
}

const videoJsPlayerWrapperFactory = (config, videoHolderDomId) => {
  videoJs = videoJs || videoJsDefault;
  signUrl = signUrl || signUrlDefault;

  class VideoJsPlayerWrapper {
    constructor(config, videoHolderDomId) {
      this.config = config;
      this.videoHolderDomId = videoHolderDomId;
    }

    addEventHandler(eventName, handler) {
      return this.player && this.player.on(eventName, handler);
    }

    addEventHandlers(handlers) {
      this.addEventHandler(
        'ended',
        handlers.handlePlaybackFinished.bind(handlers)
      );
      this.addEventHandler('pause', handlers.handlePaused.bind(handlers));
      this.addEventHandler('play', handlers.handlePlay.bind(handlers));
      this.addEventHandler('seeked', handlers.handleSeeked.bind(handlers));
      this.addEventHandler('share', handlers.handleShare.bind(handlers));
      this.addEventHandler(
        'timeupdate',
        handlers.handleTimeChanged.bind(handlers)
      );
      this.addEventHandler(
        'playerresize',
        handlers.handlePlayerResize.bind(handlers)
      );
      this.addEventHandler(
        'fullscreenchange',
        handlers.handleFullScreenChange.bind(handlers)
      );
      this.addEventHandler(
        'userinactive',
        handlers.handleHideControls.bind(handlers)
      );
      this.addEventHandler(
        'useractive',
        handlers.handleShowControls.bind(handlers)
      );
      this.addEventHandler(
        'durationchange',
        handlers.handleDurationChange.bind(handlers)
      );

      this.addEventHandler(
        'smashcutplayerui',
        handlers.handleSmashcutPlayerUiEvent.bind(handlers)
      );
      this.addEventHandler(
        'loadeddata',
        handlers.handleLoadedData.bind(handlers)
      );
    }

    play() {
      return this.player && this.player.play();
    }

    pause() {
      return this.player && this.player.pause();
    }

    seek(position) {
      if (!this.player) {
        return;
      }
      this.player.currentTime(position);
    }

    clearPosterImage() {
      this.player.hasStarted(true);
    }

    getCurrentTime() {
      return this.player ? this.player.currentTime() : 0;
    }

    getDuration() {
      return this.player ? this.player.duration() : 0;
    }

    getPercentagePosition(percentage) {
      return this.player ? percentage * this.player.duration() : 0;
    }

    isPlaying() {
      return this.player ? !this.player.paused() : false;
    }

    isPaused() {
      return this.player ? this.player.paused() : true;
    }

    volume(val) {
      return this.player ? this.player.volume(val) : false;
    }

    getVolume() {
      return this.player ? this.player.volume() : false;
    }

    isFullScreen() {
      return this.player ? this.player.isFullscreen() : false;
    }

    destroy() {
      try {
        if (this.player) {
          this.player.dispose();
          this.player = null;
        }
      } catch (err) {
        console.warn('Catched unexpected error:', err);
      }
    }

    initPlayer(video, lessonNav) {
      return this.setup(this.makeConf(video, lessonNav));
    }

    makeConf(video, lessonNav) {
      function addBaseUrl(baseUrl, url) {
        if (
          !url ||
          url.indexOf('http') === 0 ||
          url.indexOf('/imageService') === 0
        ) {
          return url;
        }
        return baseUrl + url;
      }
      const baseUrl = video.baseUrl;
      const urls = {
        dashUrl: addBaseUrl(baseUrl, video.dashUrl),
        hlsUrl: addBaseUrl(baseUrl, video.hlsUrl),
        spriteUrl: addBaseUrl(baseUrl, video.spriteUrl),
        thumbnailUrl: addBaseUrl(baseUrl, video.thumbnailUrl),
        vttUrl: addBaseUrl(baseUrl, video.vttUrl)
      };

      const conf = {
        source: {
          controls: true,
          responsive: true,
          fill: true,

          sources: [
            {
              src: urls.dashUrl,
              type: 'application/dash+xml'
            },
            {
              src: urls.hlsUrl,
              type: 'application/x-mpegURL'
            },
            {
              // For preloaded my file videos baseUrl has the full url for the video
              src: baseUrl
            }
          ],
          tracks: (video.subTitles || []).map(st => ({
            kind: 'subtitles',
            label: st.label,
            language: st.lang,
            srclang: st.lang,
            id: st.id,
            src: st.url
          })),
          poster: video.thumbnailUrl ? urls.thumbnailUrl : undefined,
          endOfVideoNav: {
            again: 'available',
            next:
              lessonNav && lessonNav.showNavAtTheEndOfVideo
                ? lessonNav.nextLessonLocked
                  ? 'locked'
                  : lessonNav.nextLesson
                  ? 'available'
                  : 'not-available'
                : 'not-available',
            previous:
              lessonNav && lessonNav.showNavAtTheEndOfVideo
                ? lessonNav.previousLessonLocked
                  ? 'locked'
                  : lessonNav.previousLesson
                  ? 'available'
                  : 'not-available'
                : 'not-available'
          }
        },
        timelineThumbnails: urls.vttUrl
      };
      conf.autoplay = video.autoPlay;
      return conf;
    }

    attachShareMediaButton = () => {
      const Button = videoJs.getComponent('Button');
      const button = new Button(this.player, {
        clickHandler: event => button.player_.trigger('share')
      });

      button.controlText('Share');
      const myButtonDom = button.el();
      myButtonDom.classList.add('videoJsShareButton');

      this.player.on(this.player, 'fullscreenchange', () => {
        setTimeout(() => {
          const isFullscreen = this.player.isFullscreen();
          if (isFullscreen) {
            myButtonDom.classList.add('hiddenButton');
          } else {
            myButtonDom.classList.remove('hiddenButton');
          }
        }, 10);
      });

      this.player.controlBar.addChild(button);

      this.player.controlBar.controlBarBottomRightEl.insertBefore(
        myButtonDom,
        this.player.controlBar.controlBarBottomRightEl.firstChild
      );
    };

    setup(conf) {
      console.log('setup', conf);

      invoke(this.player, 'endOfVideoNav.hideEndOfVideoNav');

      // NOTE:
      // When you see 403s when playing a video and this function is not
      // called, run "yarn upgrade:player"
      // The strange path makes the fn available in
      // the videoJs http-streaming module
      /*let preprocessHttpRequestHasBeenCalled = false;
      videoJs.Vhs.xhr.preprocessHttpRequest = async url => {
        console.log('preprocessHttpRequest', url);
        preprocessHttpRequestHasBeenCalled = true;
        const lowUrl = url.toLowerCase();
        if (lowUrl.indexOf('.mpd') > 0 || lowUrl.indexOf('.m3u8') > 0) {
          const signedUrl = await signUrl(decodeURI(url));
          // console.log('signUrl_', url, signedUrl);
          return signedUrl;
        } else {
          return url;
        }
      };*/

      return new Promise(resolve => {
        const IS_NEW_PLAYER = true;
        if (this.player) {
          this.player.src(conf.source.sources);
          // setting poster explicitly bc. otherwise it does not change
          this.player.poster(conf.source.poster);
          this.player.ready(() => {
            this.player.vttThumbnails.src(conf.timelineThumbnails);
            resolve(!IS_NEW_PLAYER);
          });
        } else {
          this.player = videoJs(this.videoHolderDomId, conf.source);
          // see ch16955
          // this.attachShareMediaButton();
          this.player.ready(() => {
            this.player.seekButtons({
              forward: 10,
              back: 10
            });
            this.player.vttThumbnails({
              src: conf.timelineThumbnails
            });

            resolve(IS_NEW_PLAYER);
          });
        }
      });
    }

    setSkipSpeed(speed) {
      if (!this.player) {
        return;
      }
      if (speed > 0) {
        this.player.playbackRate(speed);
        if (this.player.paused()) {
          this.player.play();
        }
      } else if (speed === 0) {
        this.player.playbackRate(1);
        this.stopRewind();
      } else {
        this.startRewind(speed);
      }
    }

    stopRewind() {
      this.player.off('seeked', this.rewindHandler);
    }

    rewindHandler = () => {
      const frameRate = 2; // todo get real frame rate?
      const currentTime = this.player.currentTime();
      if (currentTime === 0) {
        return;
      }
      const nextPosition = currentTime + this.rewindSpeed / frameRate;
      this.player.currentTime(Math.max(0, nextPosition));
    };

    startRewind(speed) {
      this.player.pause();
      this.rewindSpeed = speed;
      this.player.off('seeked', this.rewindHandler);
      this.player.on('seeked', this.rewindHandler);
      this.rewindHandler();
    }
  }

  return new VideoJsPlayerWrapper(config, videoHolderDomId);
};

export default videoJsPlayerWrapperFactory;
