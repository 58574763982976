import { findAsset } from '../../../common/assetUtils';

/**
 * @deprecated
 */
export function makeResolveAssetFn(userProgram, api) {
  return makeResolveAssetFnFromAssets(
    userProgram.programInstance.program.assets,
    api
  );
}

export function makeResolveAssetFnFromAssets(assets, api) {
  return async function resolveAsset(fileName) {
    // console.log('resolveAsset', fileName);
    if (fileName && fileName.indexOf('http') === 0) {
      return fileName;
    }

    let asset = findAsset(fileName);
    if (asset) {
      let downloadUrl = await api.getContentBucketDownloadUrl(asset);
      return downloadUrl;
    }

    console.warn('asset not found:', fileName);
    return undefined;
  };
}

export async function resolveAsset(asset, api) {
  let downloadUrl = await api.getContentBucketDownloadUrl(asset);
  return downloadUrl;
}
