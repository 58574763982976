// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AppUpdateDialog--rnd--2Gkcl5vi{z-index:300}.AppUpdateDialog--topContainer--22Rlygj-{padding:20px;border-radius:4px;background-color:#363636;-webkit-box-shadow:0 8px 10px 0 rgba(0, 0, 0, .24);box-shadow:0 8px 10px 0 rgba(0, 0, 0, .24);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.AppUpdateDialog--topContainer--22Rlygj-.AppUpdateDialog--color0--3jtueZXa{border-left:10px solid #595959}.AppUpdateDialog--topContainer--22Rlygj-.AppUpdateDialog--color1--3RZGU6JI{border-left:10px solid #43a047}.AppUpdateDialog--topContainer--22Rlygj-.AppUpdateDialog--color2--3haTp1sV{border-left:10px solid #e53935}.AppUpdateDialog--topContainer--22Rlygj-.AppUpdateDialog--color3--3VRwG6Xp{border-left:10px solid #2196f3}.AppUpdateDialog--topContainer--22Rlygj-.AppUpdateDialog--color4--1UtZmg1N{border-left:10px solid #e9a300}.AppUpdateDialog--topContainer--22Rlygj- .AppUpdateDialog--textBlock--39M4r1bu{padding-left:20px;padding-right:20px;font-size:14px;font-weight:bold;color:#ffffff}.AppUpdateDialog--topContainer--22Rlygj- .AppUpdateDialog--reloadButton--3c0-GjoV{width:220px}\n", ""]);
// Exports
exports.locals = {
	"rnd": "AppUpdateDialog--rnd--2Gkcl5vi",
	"topContainer": "AppUpdateDialog--topContainer--22Rlygj-",
	"color0": "AppUpdateDialog--color0--3jtueZXa",
	"color1": "AppUpdateDialog--color1--3RZGU6JI",
	"color2": "AppUpdateDialog--color2--3haTp1sV",
	"color3": "AppUpdateDialog--color3--3VRwG6Xp",
	"color4": "AppUpdateDialog--color4--1UtZmg1N",
	"textBlock": "AppUpdateDialog--textBlock--39M4r1bu",
	"reloadButton": "AppUpdateDialog--reloadButton--3c0-GjoV"
};
module.exports = exports;
