import { actionType } from '../constants';
import { replayAction } from './replayAction';
import { getReplay } from '../selectors';

export const connectionOpened = (connectionId, opentokSession) => {
  return (dispatch, getState, { api }) => {
    // console.log('connectionOpened', connectionId, opentokSession, getState());
    opentokSession.on('signal:action', event => {
      const replay = getReplay(getState());
      const replayItem = JSON.parse(event.data);
      // console.log(
      //   'onAction',
      //   replayItem.actionOriginId,
      //   replay.actionOriginId,
      //   replayItem,
      //   replay
      // );
      if (replay.actionOriginId !== replayItem.actionOriginId) {
        dispatch(replayAction(replayItem));
      }
    });

    dispatch({
      type: actionType.connectionOpened,
      connectionId,
      opentokSession
    });
  };
};
