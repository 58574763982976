import { gql } from '@apollo/client';
import { isFunction } from 'lodash';

export const initMessaging = (apolloClient, config) => (
  userId,
  onNewMessage
) => {
  let context = {};
  return checkBrowserSupport()
    .then(registerServiceWorker)
    .then(askPermission)
    .then(manageSubscription)
    .then(addServiceWorkerMessageHandler);

  // ----------------------------------------------------

  function checkBrowserSupport() {
    if (!('serviceWorker' in navigator)) {
      // Service Worker isn't supported on this browser, disable or hide UI.
      return Promise.reject(
        new Error('Service Worker is not supported on this browser')
      );
    }

    if (!('PushManager' in window)) {
      // Push isn't supported on this browser, disable or hide UI.
      return Promise.reject(
        new Error('PushManager is not supported on this browser')
      );
    }
    return Promise.resolve();
  }

  function registerServiceWorker() {
    return navigator.serviceWorker
      .register('/service-worker.js')
      .then(function(registration) {
        console.log('Service worker successfully registered.');
        context.registration = registration;
        return registration;
      })
      .catch(function(err) {
        console.error('Unable to register service worker.', err);
      });
  }

  function askPermission() {
    return new Promise(function(resolve, reject) {
      const permissionResult = Notification.requestPermission(function(result) {
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then(function(permissionResult) {
      context.permissionResult = permissionResult;
      if (permissionResult !== 'granted') {
        console.warn("We weren't granted permission.");
      }
    });
  }

  function manageSubscription() {
    return context.registration.pushManager
      .getSubscription()
      .then(pushSubscription => {
        if (pushSubscription) {
          console.log('User subscribed to push messages already.');
          // make sure the subscription is saved
          return saveSubscription(pushSubscription).then(() => 'ok');
        } else {
          return subscribeUserToPush()
            .then(saveSubscription)
            .then(() => 'ok');
        }
      });
  }

  function addServiceWorkerMessageHandler() {
    if (isFunction(navigator.serviceWorker.addEventListener)) {
      navigator.serviceWorker.addEventListener('message', event => {
        onNewMessage && onNewMessage(event.data);
      });
      return Promise.resolve();
    } else {
      return Promise.reject(new Error('navigator.addEventListener missing'));
    }
  }

  function subscribeUserToPush() {
    console.log('Subscribe user to push messages.');
    const subscribeOptions = {
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(
        config.pushMessagingPublicVapidKey
      )
    };

    return context.registration.pushManager.subscribe(subscribeOptions);
  }

  function saveSubscription(pushSubscription) {
    const ps = JSON.parse(JSON.stringify(pushSubscription));
    console.log('saveSubscription', userId, ps);
    const mutation = gql`
      mutation savePushSubscription($input: SavePushSubscriptionInput!) {
        savePushSubscription(input: $input)
      }
    `;

    return apolloClient
      .mutate({
        mutation,
        variables: {
          input: {
            userId,
            endpoint: ps.endpoint,
            p256dh: ps.keys.p256dh,
            auth: ps.keys.auth
          }
        }
      })
      .then(response => {
        console.log('saveSubscription success', response);
        return response;
      });
  }

  // urlBase64ToUint8Array will encode the base64 public
  // key to Array buffer, which is needed by the subscription option
  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
};
