import { isString } from 'lodash';

export const objectsToMap = (arr, value) =>
  arr &&
  arr.reduce((acc, item) => {
    let val = null;
    if (isString(value)) {
      val = item[value];
    } else {
      val = value || item;
    }
    acc[item.id] = val || {};
    return acc;
  }, {});
