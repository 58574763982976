import React from 'react';
import { connect } from 'react-redux';
import styles from './MobileMarkerModal.scss';
import { actions, selectors } from 'reducers/noteDisplay';
import MarkDownDisplay from '../common/MarkDownDisplay';
import { withDeviceDetector } from 'components/common/withDeviceDetector';

export const MobileMarkerModal = ({ item, hideNoteDisplay, isMobile }) => {
  if (!isMobile) {
    return null;
  }
  const { note, visible } = item;
  const { text = '', title = '' } = note ? note : {};

  return visible ? (
    <div className={styles.overLay} onClick={hideNoteDisplay}>
      <div className={styles.topContainer}>
        <div className={styles.header} onClick={hideNoteDisplay}>
          {title}
          <i className="fas fa-chevron-down" />
        </div>
        <div className={styles.body}>
          <MarkDownDisplay markDown={text} />
        </div>
      </div>
    </div>
  ) : (
    ''
  );
};

export const mapStateToProps = state => ({
  item: selectors.getNoteDisplay(state)
});

export const mapDispatchToProps = {
  hideNoteDisplay: actions.hideNoteDisplay
};

export default withDeviceDetector(
  connect(mapStateToProps, mapDispatchToProps)(MobileMarkerModal)
);
