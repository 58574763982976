import React from 'react';
import { use100vh } from 'react-div-100vh';
import { ChemistryIcon } from 'assets/chemistry-d-svg';
import styles from './MobileBetaWarning.scss';
import { withDeviceDetector } from 'components/common/withDeviceDetector';
import { connect } from 'react-redux';
import {
  actions as mobileBetaWarningActions,
  selectors as mobileBetaWarningSelectors
} from 'reducers/mobileBetaWarning';
import { useToggleBodyScroll } from 'utils/useToggleBodyScroll';
import { MainButton } from 'components/common/button';
import classnames from 'classnames';

const MobileBetaWarning = ({ isOpen, closeMobileBetaWarning }) => {
  const height = use100vh();
  useToggleBodyScroll(isOpen);

  return (
    <div
      className={classnames(styles.overLay, {
        [styles.open]: isOpen
      })}
      style={{ height }}
    >
      <div className={styles.topContainer} style={{ height }}>
        <div className={styles.icon}>
          <ChemistryIcon fillColor={styles.fillColor} />
        </div>
        <div className={styles.title}>Welcome to Mobile Beta</div>
        <div className={styles.subTitle}>
          This version of our mobile site has limited functionality.
        </div>
        <div className={styles.notSupport}>Mobile Beta does not support:</div>
        <ul className={styles.list}>
          <li>
            <i className="fas fa-video-slash" />
            Live video meetings
          </li>
          <li>
            <i className="fas fa-comment-slash" />
            Discussions or Crews
          </li>
          <li>
            <i className="fas fa-ban" />
            Assignment submission
          </li>
        </ul>
        <div className={styles.toDesktopMessage}>
          Please switch to a laptop or desktop to use these features.
        </div>
        <MainButton onClick={closeMobileBetaWarning} label="Okay, got it!" />
      </div>
    </div>
  );
};

export const mapStateToProps = state => ({
  isOpen: mobileBetaWarningSelectors.isMobileBetaWarningOpen(state)
});

export const mapDispatchToProps = {
  openMobileBetaWarning: mobileBetaWarningActions.showWarning,
  closeMobileBetaWarning: mobileBetaWarningActions.hideWarning
};

export default withDeviceDetector(
  connect(mapStateToProps, mapDispatchToProps)(MobileBetaWarning)
);
