import { gql } from '@apollo/client';

export const setupOfficeHours = apolloClient => async ({
  firstUserId,
  secondUserId,
  startTime,
  endTime
}) => {
  const mutation = gql`
    mutation setupOfficeHours($input: SetupOfficeHoursInput!) {
      setupOfficeHours(input: $input) {
        id
        firstUserId
        secondUserId
        startTime
        endTime
        sessionId
        token
      }
    }
  `;
  return apolloClient
    .mutate({
      mutation,
      variables: {
        input: {
          firstUserId,
          secondUserId,
          startTime,
          endTime
        }
      }
    })
    .then(response => response.data.setupOfficeHours);
};
