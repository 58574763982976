import gql from 'graphql-tag';

export function updateRole(apolloClient) {
  const mutation = gql`
    mutation updateRole($input: UpdateRoleInput!) {
      updateRole(input: $input) {
        id
        roleLabel
      }
    }
  `;

  return (userId, newRole) => {
    return apolloClient.mutate({
      mutation,
      variables: {
        input: { userId, newRole }
      },
      refetchQueries: ['getAdminData']
    });
  };
}
