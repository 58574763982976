import { apolloClient } from '../apolloClient';
import { gql } from '@apollo/client';

export function signUrl(url) {
  const query = gql`
    query signUrl($url: String) {
      signedUrl: signUrl(url: $url)
    }
  `;

  return apolloClient
    .query({ query, variables: { url }, fetchPolicy: 'network-only' })
    .then(response => response.data.signedUrl);
}
