const PREFIX = 'DISCUSSIONS/';

export const types = {
  ADD_MESSAGE_RESULT: `${PREFIX}ADD_MESSAGE_RESULT`,
  DELETE_MESSAGE_RESULT: `${PREFIX}DELETE_MESSAGE_RESULT`,
  LOAD_CHANNEL_GROUPS_REQUEST: `${PREFIX}LOAD_CHANNEL_GROUPS_REQUEST`,
  LOAD_CHANNEL_GROUPS_SUCCESS: `${PREFIX}LOAD_CHANNEL_GROUPS_SUCCESS`,
  SELECT_CHANNEL: `${PREFIX}SELECT_CHANNEL`,
  CLEAR_DISCUSSION: `${PREFIX}CLEAR_DISCUSSION`,
  CLEAR_ALL: `${PREFIX}CLEAR_ALL`,
  OPEN_SHARE_MEDIA_MODAL: `${PREFIX}OPEN_SHARE_MEDIA_MODAL`,
  CLOSE_SHARE_MEDIA_MODAL: `${PREFIX}CLOSE_SHARE_MEDIA_MODAL`,
  SHARE_MEDIA_SUCCESS: `${PREFIX}SHARE_MEDIA_SUCCESS`,
  SHARE_MEDIA_FAILURE: `${PREFIX}SHARE_MEDIA_FAILURE`
};
