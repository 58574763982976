import { gql } from '@apollo/client';

const DELETE_MY_FILES = gql`
  mutation deleteMyFiles($input: [MyFileInput]) {
    deleteMyFiles(input: $input)
  }
`;

export const deleteMyFiles = apolloClient => input =>
  apolloClient
    .mutate({
      mutation: DELETE_MY_FILES,
      variables: { input }
    })
    .then(response => response.data.deleteMyFiles);
