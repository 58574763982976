const PREFIX = 'OFFICE_HOURS';

export const types = {
  CLEAR_OFFICE_HOUR: `${PREFIX}/CLEAR_OFFICE_HOUR`,
  CLOSE_PROJECT: `${PREFIX}/CLOSE_PROJECT`,
  LOAD_OFFICE_HOURS: `${PREFIX}/LOAD_OFFICE_HOURS`,
  LOAD_OFFICE_HOURS_COMPLETE: `${PREFIX}/LOAD_OFFICE_HOURS_COMPLETE`,
  LOAD_OFFICE_HOURS_ERROR: `${PREFIX}/LOAD_OFFICE_HOURS_ERROR`,
  SELECT_PROJECT: `${PREFIX}/SELECT_PROJECT`,
  SETUP_OFFICE_HOURS: `${PREFIX}/SETUP_OFFICE_HOURS`,
  SETUP_OFFICE_HOURS_COMPLETE: `${PREFIX}/SETUP_OFFICE_HOURS_COMPLETE`,
  SETUP_OFFICE_HOURS_ERROR: `${PREFIX}/SETUP_OFFICE_HOURS_ERROR`,
  TOGGLE_CHAT_VISIBLE: `${PREFIX}/TOGGLE_CHAT_VISIBLE`,
  TOGGLE_COMMENTS_VISIBLE: `${PREFIX}/TOGGLE_COMMENTS_VISIBLE`,
  UPDATE_LIVE_MEETING_DATA: `${PREFIX}/UPDATE_LIVE_MEETING_DATA`
};
