import { actionType } from '../constants';
import { logAndSendError } from 'utils/sentryHelper';

export const addVideoReply = payload => {
  return (dispatch, getState, { api }) => {
    return api
      .addVideoReply(payload)
      .then(() => {
        return dispatch({
          type: actionType.addVideoReply
        });
      })
      .catch(e => logAndSendError(e, 'error adding video reply'));
  };
};
