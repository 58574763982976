import { gql } from '@apollo/client';

export const updateProject = apolloClient => (projectId, name, description) => {
  const mutation = gql`
    mutation updateProject($input: UpdateProjectInput!) {
      updateProject(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: { projectId, name, description }
    }
  });
};
