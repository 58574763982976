import { actionType } from '../constants/actionTypes';
import { logAndSendError } from 'utils/sentryHelper';

export const loadAllUsers = () => {
  return (dispatch, getState, { adminApi }) => {
    adminApi
      .loadAllUsers()
      .then(users => dispatch({ type: actionType.loadAllUserSuccess, users }))
      .catch(e => logAndSendError(e, 'error while downloading users'));
  };
};
