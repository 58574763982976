import { get, findIndex } from 'lodash';
import { actionType } from 'smashcut-client-lib/constants';

const BASE = 'MYFILE_IMAGE';

export const types = {
  SET_IMAGE_PROJECT: `${BASE}/SET_IMAGE_PROJECT`,
  SHOW_IMAGE: `${BASE}/SHOW_IMAGE`
};

export const initialState = {};

export const myFileImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.selectProject:
      if (!action.project) return;
      if (action.project.fileType === 'MULTI_IMAGE') {
        return {
          ...state,
          ...action.project,
          imageId: get(action, 'project.images.0.uploadId')
        };
      }
      if (action.project.fileType === 'IMAGE') {
        return {
          ...state,
          ...action.project,
          imageId: get(action, 'project.id'),
          images: [
            {
              downloadUrl: get(action, 'project.downloadUrl'),
              uploadId: get(action, 'project.id')
            }
          ]
        };
      }
      break;

    case types.SHOW_IMAGE:
      return {
        ...state,
        imageId: action.image.uploadId
      };

    default:
      return state;
  }
};

function showNextImage() {
  return showImageByOffset(1);
}

function showPreviousImage() {
  return showImageByOffset(-1);
}

function showImageByOffset(offset) {
  return (dispatch, getState) => {
    const state = getState();

    const imageId = get(state, 'dashboard.myFileImage.imageId');
    const imageIndex = Math.max(
      findIndex(selectors.getImages(state), img => img.uploadId === imageId),
      0
    );
    const newImageIndex =
      offset > 0
        ? imageIndex
          ? imageIndex + offset
          : 1
        : imageIndex
        ? imageIndex + offset
        : 0;

    const newImage = selectors.getImages(state)[newImageIndex];
    dispatch({ type: types.SHOW_IMAGE, image: newImage });
  };
}

function showImage(image) {
  return (dispatch, getState) => {
    dispatch({ type: types.SHOW_IMAGE, image });
  };
}

function moveImageDown(image) {
  return (dispatch, getState, { apolloClient }) => {
    // TODO: needs to be implemented for MyFile if we want
  };
}

function moveImageUp(image) {
  return (dispatch, getState, { apolloClient }) => {
    // TODO: needs to be implemented for MyFile if we want
  };
}

export const actions = {
  moveImageDown,
  moveImageUp,
  showPreviousImage,
  showNextImage,
  showImage
};

export const selectors = {
  getImageProject: state => get(state, 'dashboard.myFileImage'),
  getImages: state => get(state, 'dashboard.myFileImage.images', []),
  getImageId: state => get(state, 'dashboard.myFileImage.imageId')
};
