import React from 'react';
import { connect } from 'react-redux';
import styles from './HelpModal.scss';
import { getCurrentUser } from 'smashcut-client-lib/selectors/index';
import { actions, selectors } from 'reducers/dashboard/syllabus';
import MarkDownDisplay from 'components/common/MarkDownDisplay';
import { simulateClickOnFocusedElement } from 'utils/simulateClickOnFocusedElement';
import FocusLock from 'react-focus-lock';
class HelpModal extends React.Component {
  render() {
    const { helpModal, toggleHelpModal } = this.props;

    if (!helpModal.opened) {
      return null;
    }

    return (
      <FocusLock returnFocus={true}>
        <div className={styles.topContainer}>
          <div className={styles.overlay} />
          <div className={styles.popup}>
            <div className={styles.header}>
              <div className={styles.title} role="heading" aria-level="2">
                Help
              </div>
              <div
                className={styles.closeButton}
                onClick={toggleHelpModal}
                role="button"
                aria-label="Close Modal"
                onKeyUp={simulateClickOnFocusedElement}
                tabIndex="0"
              >
                <i className="fa fa-times" />
              </div>
            </div>
            <div className={styles.content}>
              <MarkDownDisplay
                className={styles.markDownDisplay}
                markDown={helpModal.text}
              />
            </div>
          </div>
        </div>
      </FocusLock>
    );
  }
}

export const mapStateToProps = state => {
  const user = getCurrentUser(state);
  const helpModal = selectors.getHelpModalStatus(state);

  return {
    user,
    helpModal,
    ...state.accountDetails
  };
};

export const mapDispatchToProps = {
  toggleHelpModal: actions.toggleHelpModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HelpModal);
