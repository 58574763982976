import { selectors as sclSelectors } from 'smashcut-client-lib';
import { find, get } from 'lodash';
import { actions as relatedVideoActions } from 'reducers/relatedVideoPlayer';
import { actions as uploadActions } from 'reducers/uploads';
import { logAndSendError } from '../utils/sentryHelper';
import { types as syllabusTypes } from 'reducers/dashboard/syllabus';
import { types } from './lessonProjectTypes';
import { UPLOAD_TYPE } from 'common/constants';
import {
  actions as lessonActions,
  selectors as lessonSelectors,
  types as lessonTypes
} from 'reducers/lesson';
import { actions as notificationActions } from 'reducers/notifications';
import {
  getMainAreaType,
  makeGotoLessonPayload
} from '../smashcut-client-lib/utils/gotoLessonHelpers';
import { actionType } from '../smashcut-client-lib/constants';
import { showIframe as showIframeAction } from '../components/common/SmashcutIframe';
import { gql } from '@apollo/client';

const initialState = {
  fromUrl: '',
  projectEditor: {
    open: false
  },
  reviewEditor: {
    open: false
  },
  reviewViewer: {
    open: false
  },
  screenplayEditor: {
    open: false
  },
  imageEditor: {
    open: false
  },
  object3DEditor: {
    open: false
  },
  nonSupportedFileEditor: {
    open: false
  }
};

export function reducer(state = initialState, action) {
  let nextState = state;

  switch (action.type) {
    case syllabusTypes.MARK_AS_DONE_SUCCESS:
    case syllabusTypes.REMOVE_MARK_AS_DONE_SUCCESS:
    case types.MARK_AS_DONE_SUCCESS:
    case types.PROJECT_CREATED:
    case types.REVIEW_ADDED:
    case types.REVIEW_REMOVED:
    case types.SCREENPLAY_CREATED:
    case types.SCREENPLAY_UPDATED:
    case types.IMAGE_PROJECT_CREATED:
    case types.OBJECT_3D_PROJECT_CREATED:
    case types.REMOVE_PROJECT:
      nextState = { ...state, updateRequired: {} }; // change the object to enforce an update
      break;

    case types.PROJECT_EDITOR_OPEN:
      nextState = { ...state };
      nextState.projectEditor = {};
      nextState.projectEditor.open = true;
      nextState.projectEditor.item = action.item;
      nextState.projectEditor.submitAction = action.submitAction;
      break;

    case types.PROJECT_EDITOR_CLOSE:
      nextState = { ...state };
      nextState.projectEditor = { ...state.projectEditor };
      nextState.projectEditor.open = false;
      break;

    case types.REVIEW_EDITOR_OPEN:
      nextState = { ...state };
      nextState.reviewEditor = {};
      nextState.reviewEditor.open = true;
      nextState.reviewEditor.lessonRecord = action.lessonRecord;
      nextState.reviewEditor.project = action.project;
      break;

    case types.REVIEW_EDITOR_CLOSE:
      nextState = { ...state };
      nextState.reviewEditor = { ...state.reviewEditor };
      nextState.reviewEditor.open = false;
      break;

    case types.REVIEW_VIEWER_OPEN:
      nextState = { ...state };
      nextState.reviewViewer = {};
      nextState.reviewViewer.open = true;
      nextState.reviewViewer.lessonRecord = action.lessonRecord;
      nextState.reviewViewer.project = action.project;
      nextState.reviewViewer.mentorId = action.mentorId;
      break;

    case types.REVIEW_VIEWER_CLOSE:
      nextState = { ...state };
      nextState.reviewViewer = { ...state.reviewViewer };
      nextState.reviewViewer.open = false;
      break;

    case types.SCREENPLAY_EDITOR_OPEN:
      nextState = { ...state };
      nextState.screenplayEditor = {};
      nextState.screenplayEditor.open = true;
      nextState.screenplayEditor.item = action.item;
      nextState.screenplayEditor.submitAction = action.submitAction;
      break;

    case types.SCREENPLAY_EDITOR_CLOSE:
      nextState = { ...state };
      nextState.screenplayEditor = { ...state.screenplayEditor };
      nextState.screenplayEditor.open = false;
      break;

    case types.IMAGE_EDITOR_OPEN:
      nextState = { ...state };
      nextState.imageEditor = {};
      nextState.imageEditor.open = true;
      nextState.imageEditor.item = action.item;
      nextState.imageEditor.submitAction = action.submitAction;
      break;

    case types.IMAGE_EDITOR_CLOSE:
      nextState = { ...state };
      nextState.imageEditor = { ...state.imageEditor };
      nextState.imageEditor.open = false;
      break;

    case types.OBJECT_3D_EDITOR_OPEN:
      nextState = { ...state };
      nextState.object3DEditor = {};
      nextState.object3DEditor.open = true;
      nextState.object3DEditor.item = action.item;
      nextState.object3DEditor.submitAction = action.submitAction;
      break;

    case types.OBJECT_3D_EDITOR_CLOSE:
      nextState = { ...state };
      nextState.object3DEditor = { ...state.object3DEditor };
      nextState.object3DEditor.open = false;
      break;

    case types.NON_SUPPORTED_FILE_EDITOR_OPEN:
      nextState = { ...state };
      nextState.nonSupportedFileEditor = {};
      nextState.nonSupportedFileEditor.open = true;
      nextState.nonSupportedFileEditor.item = action.item;
      nextState.nonSupportedFileEditor.submitAction = action.submitAction;
      break;

    case types.NON_SUPPORTED_FILE_EDITOR_CLOSE:
      nextState = { ...state };
      nextState.nonSupportedFileEditor = { ...state.nonSupportedFileEditor };
      nextState.nonSupportedFileEditor.open = false;
      break;

    case actionType.LOCATION_CHANGE:
      const path = action.location.pathname;
      const fromUrl =
        (path === '/dashboard/syllabus' ||
          path === '/dashboard/myfiles' ||
          path === '/dashboard/projects' ||
          path === '/dashboard/projects-for-review') &&
        path;
      nextState = {
        ...state,
        fromUrl: fromUrl || state.fromUrl,
        projectEditor: {
          open: false
        },
        reviewEditor: {
          open: false
        },
        reviewViewer: {
          open: false
        },
        screenplayEditor: {
          open: false
        }
      };
      break;
  }

  return nextState;
}

function markAsDone({ project }) {
  // console.log('markAsDone', project);
  return {
    type: types.MARK_AS_DONE,
    enrolleeId: project.userProgramId,
    projectId: project.id
  };
}

function cancelEncoding({ project }) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: types.CANCEL_ENCODING,
      enrolleeId: project.userProgramId,
      assignmentId: project.assignmentId,
      projectId: project.id
    });
  };
}

function removeResponse({ project }) {
  return (dispatch, getState) => {
    const state = getState();
    const lessonInfo = lessonSelectors.getLessonInfo(state);
    dispatch(
      lessonActions.selectAssignment(lessonInfo.video, project.assignmentId)
    );
    dispatch(notificationActions.showProjectRemovedMessage());
    dispatch({
      type: types.REMOVE_PROJECT,
      enrolleeId: project.userProgramId,
      assignmentId: project.assignmentId
    });
  };
}

function createResponse(item) {
  switch (item.assignment.responseType) {
    case 'VIDEO':
      return openProjectEditor(item, 'Create');
    case 'SCREENPLAY':
      return openScreenplayEditor(item, 'Create');
    case 'IMAGE':
      console.log('uploading image');
      return openImageEditor(item, 'Create');
    case 'NONSUPPORTED':
      return openNonSupportedFileEditor(item, 'Create');
    case 'OBJECT3D':
      return openObject3DEditor(item, 'Create');
    default:
      throw new Error(
        'Unexpected response type: ' + item.assignment.responseType
      );
  }
}

function replaceResponse(item) {
  switch (item.assignment.responseType) {
    case 'VIDEO':
      return openProjectEditor(item, 'Replace');
    case 'SCREENPLAY':
      return openScreenplayEditor(item, 'Replace');
    case 'IMAGE':
      return openImageEditor(item, 'Replace');
    case 'NONSUPPORTED':
      return openNonSupportedFileEditor(item, 'Replace');
    case 'OBJECT3D':
      return openObject3DEditor(item, 'Replace');
    default:
      throw new Error(
        'Unexpected response type: ' + item.assignment.responseType
      );
  }
}

function openProjectEditor(item, submitAction) {
  return {
    type: types.PROJECT_EDITOR_OPEN,
    item,
    submitAction
  };
}

function openReviewViewer({ project, lessonRecord }, mentorId) {
  return {
    type: types.REVIEW_VIEWER_OPEN,
    project,
    lessonRecord,
    mentorId
  };
}

function closeReviewViewer() {
  return { type: types.REVIEW_VIEWER_CLOSE };
}

function closeProjectEditor() {
  return { type: types.PROJECT_EDITOR_CLOSE };
}

function openReviewEditor({ project, lessonRecord }) {
  return {
    type: types.REVIEW_EDITOR_OPEN,
    project,
    lessonRecord
  };
}

function closeReviewEditor() {
  return { type: types.REVIEW_EDITOR_CLOSE };
}

function openScreenplayEditor(item, submitAction) {
  return { type: types.SCREENPLAY_EDITOR_OPEN, item, submitAction };
}

function closeScreenplayEditor() {
  return { type: types.SCREENPLAY_EDITOR_CLOSE };
}

function openImageEditor(item, submitAction) {
  return { type: types.IMAGE_EDITOR_OPEN, item, submitAction };
}

function closeImageEditor() {
  return { type: types.IMAGE_EDITOR_CLOSE };
}

function openObject3DEditor(item, submitAction) {
  return { type: types.OBJECT_3D_EDITOR_OPEN, item, submitAction };
}

function closeObject3DEditor() {
  return { type: types.OBJECT_3D_EDITOR_CLOSE };
}

function openNonSupportedFileEditor(item, submitAction) {
  return { type: types.NON_SUPPORTED_FILE_EDITOR_OPEN, item, submitAction };
}

function closeNonSupportedFileEditor() {
  return { type: types.NON_SUPPORTED_FILE_EDITOR_CLOSE };
}

function editReview(project, lessonRecord, reviewOrReject) {
  return (dispatch, getState) => {
    dispatch(closeReviewViewer());
    dispatch(openReviewEditor({ project, lessonRecord }, reviewOrReject, true));
  };
}

function gotoYourProject() {
  return (dispatch, getState) => {
    const state = getState();
    const lessonSpec = lessonSelectors.getLessonSpec(state);
    const lessonInfo = lessonSelectors.getLessonInfo(state);
    const currentUserProgramId = get(
      sclSelectors.getCurrentUserProgram(state),
      'id'
    );
    dispatch(
      lessonActions.selectAssignment(
        lessonInfo.video,
        lessonSpec.itemId,
        currentUserProgramId
      )
    );
  };
}

function selectAssignmentProject(item) {
  return (dispatch, getState) => {
    console.log('selectAssignmentProject', item);

    const project = item.project;

    if (project) {
      const state = getState();

      if (project.id === lessonSelectors.getCurrentMainAreaProjectId(state)) {
        // return;
      }

      const projectType = getMainAreaType(
        item.lessonRecord.assignmentResponse.type
      );

      dispatch({
        type: lessonTypes.SELECT_PROJECT,
        project,
        projectType
      });

      const payload = makeGotoLessonPayload(
        project.userProgramId,
        project.courseId,
        project.lessonId,
        projectType,
        project.id,
        item.parentItem ? 'subAssignment' : 'assignment',
        project.assignmentId
      );
      dispatch(lessonActions.gotoLesson(payload));
    } else if (item.assignment.assignmentType === 'watch') {
      const a = item.assignment;
      const isModalVideo =
        a.resolvedVideo && a.resolvedVideo.id !== 'dummyVideo';

      if (isModalVideo) {
        dispatch(relatedVideoActions.showPlayer(a.title, a));
      } else {
        window.open(a.videoUrl);
      }
    } else if (
      item.assignment.assignmentType === 'read' ||
      item.assignment.assignmentType === 'listen'
    ) {
      const url =
        item.assignment.externalUrl || item.assignment.instructionsPdfUrl;
      const resolveAsset = lessonSelectors.getLessonData(getState())
        .resolveAsset;
      resolveAsset(url, 'pdf')
        .then(url => {
          const win = window.open(url, '_blank');
          win.focus();
        })
        .catch(logAndSendError);
    } else if (item.assignment.assignmentType === 'iframe') {
      openIFrame(item.assignment);
    }

    //-----------------------------------

    function openIFrame(assignment) {
      let url = assignment.externalUrl;
      if (url && url.indexOf('http') === 0) {
        showIframe(assignment.externalUrl, assignment);
        return;
      }
      url = assignment.instructionsPdfUrl;
      if (url) {
        const state = getState();
        const resolveAsset = lessonSelectors.getLessonData(getState())
          .resolveAsset;
        resolveAsset(url).then(resolvedUrl => {
          if (resolvedUrl) {
            showIframe(resolvedUrl, assignment);
          }
        });
        return;
      }
      throw new Error('iframe assignment is missing url');
    }

    function showIframe(url, assignment) {
      dispatch(
        showIframeAction({
          src: url,
          superTitle: 'Assignment',
          title: assignment.title,
          description: assignment.description
        })
      );
    }
  };
}

function goToAssignmentProject(payload) {
  return async (dispatch, getState, { apolloClient }) => {
    const { gotoLessonPayload: gtlpAssignment } = payload;

    const lessonUpdate = await apolloClient.query({
      query: gql`
        query lessonUpdateGoToAssignment($input: LessonInput) {
          lessonUpdate(input: $input) {
            lessonRecords {
              id
              assignmentId
              courseId
              userProgramId
              lessonId
              assignmentResponse {
                projectId
                type
              }
            }
          }
        }
      `,
      variables: {
        input: {
          courseId: payload.gotoLessonPayload.courseId,
          enrolleeId: payload.gotoLessonPayload.programId,
          lessonId: payload.gotoLessonPayload.lessonId
        }
      }
    });

    const { lessonRecords } = lessonUpdate.data.lessonUpdate;

    const lessonRecord = find(
      lessonRecords,
      record => record.assignmentId === gtlpAssignment.itemId
    );
    const newPayload = {
      programId: lessonRecord.userProgramId,
      courseId: lessonRecord.courseId,
      lessonId: lessonRecord.lessonId,
      mainAreaType: getMainAreaType(payload.responseType),
      mainAreaId: lessonRecord.assignmentResponse.projectId,
      itemType: 'assignment',
      tabName: 'comments',
      itemId: gtlpAssignment.itemId
    };

    return dispatch(lessonActions.gotoLesson(newPayload));
  };
}

function uploadFromSyllabus(data) {
  return (dispatch, getState) => {
    const assignment = { ...data };
    assignment.lessonId = data.gotoLessonPayload.lessonId;
    assignment.courseId = data.gotoLessonPayload.courseId;
    assignment.userProgramId = data.gotoLessonPayload.programId;
    const state = getState();
    const uploadCreatorId = sclSelectors.getCurrentUser(state).id;
    if (assignment.responseType === 'SCREENPLAY') {
      dispatch(openScreenplayEditor({ assignment, uploadCreatorId }, 'Create'));
    } else {
      dispatch(openProjectEditor({ assignment, uploadCreatorId }, 'Create'));
    }
  };
}

function submitReview(reviewOrReject, feedback, maybeItem) {
  return (dispatch, getState, { lessonApi }) => {
    const state = getState();
    const reviewAuthor = sclSelectors.getCurrentUser(state);
    const { project, lessonRecord } = maybeItem || getReviewEditor(state);
    lessonApi
      .addReview(project, lessonRecord, feedback, reviewAuthor, reviewOrReject)
      .then(() => {
        dispatch({ type: types.REVIEW_ADDED });
      })
      .catch(e => logAndSendError(e, 'error adding review'));

    dispatch(closeReviewEditor());
  };
}

function unreviewProject({ project, lessonRecord }) {
  // console.log('unreviewProject')
  return (dispatch, getState, { lessonApi }) => {
    lessonApi
      .removeReview(project, lessonRecord)
      .then(() => {
        dispatch({ type: types.REVIEW_REMOVED });
      })
      .catch(e => logAndSendError(e, 'error removing review'));
  };
}

function submitProject(item, description, file) {
  // console.info('submitProject', arguments);
  return (dispatch, getState, { api, lessonApi }) => {
    if (file) {
      // create or replace a project
      dispatch(
        // TODO can the uploading code be simplified and made testable?
        uploadActions.startUpload({
          sourceFile: file,
          fileType: 'VIDEO',
          uploadOwnerId: item.uploadCreatorId,
          uploadType: UPLOAD_TYPE.VIDEO
        })
      )
        .then(handleProjectUploaded)
        .catch(failedUploadRecord => {
          logAndSendError(failedUploadRecord, 'error uploading project');
          handleProjectUploaded(failedUploadRecord, true);
        });
    } else {
      // otherwise update description only
      lessonApi.updateProject(item.project.id, description);
      dispatch({ type: types.PROJECT_UPDATED });
    }

    // --------------------------------------------

    // add the project regardless of error
    // so we can show it as failed
    async function handleProjectUploaded(uploadRecord, uploadHasFailed) {
      try {
        console.log('handleProjectUploaded', uploadRecord);
        if (uploadRecord.status === 'CANCELED') {
          console.log('upload canceled', uploadRecord);
          return;
        }

        const lessonRecordId = await lessonApi.addProject(
          item,
          description,
          uploadRecord
        );
        dispatch({ type: types.PROJECT_CREATED });

        try {
          if (uploadHasFailed) {
            throw new Error('Upload has failed for ' + uploadRecord.fileName);
          }
          if (shouldForceEncodingError(uploadRecord)) {
            throw new Error(
              'The filename forces an encoding error: ' + uploadRecord.fileName
            );
          }
        } catch (e) {
          logAndSendError(e, 'error starting encoding');
          lessonApi.handleEncodingError(item, uploadRecord.id, lessonRecordId);
        }
      } catch (e) {
        logAndSendError(e, 'error adding project');
        // TODO show notification
      }
      dispatch(closeProjectEditor());
    }

    //-----------------------------------

    function shouldForceEncodingError(uploadRecord) {
      return (
        uploadRecord.fileName.toLowerCase().indexOf('force-encoding-error') >=
          0 ||
        // - are removed by addProject atm
        uploadRecord.fileName.toLowerCase().indexOf('forceencodingerror') >= 0
      );
    }
  };
}

function submitScreenplay(item, description, file) {
  // console.info('submitScreenplay', arguments);
  return (dispatch, getState, { lessonApi }) => {
    if (file) {
      // create or replace a screenplay
      dispatch(
        // TODO can the uploading code be simplified and made testable?
        uploadActions.startUpload({
          sourceFile: file,
          fileType: 'screenplay',
          uploadOwnerId: item.uploadCreatorId,
          uploadType: UPLOAD_TYPE.SCREENPLAY
        })
      )
        .then(handleScreenplayUploaded)
        .catch(failedUploadRecord => {
          logAndSendError(failedUploadRecord, 'error uploading screenplay');
          handleScreenplayUploaded(failedUploadRecord);
        });
    } else {
      // otherwise update description only
      lessonApi.updateScreenplay(item.project.id, description);
      dispatch({ type: types.SCREENPLAY_UPDATED });
      dispatch(closeScreenplayEditor());
    }

    // --------------------------------------------

    // add the screenplay regardless of error
    async function handleScreenplayUploaded(uploadRecord) {
      // console.log('handleScreenplayUploaded', uploadRecord);
      try {
        if (uploadRecord.status === 'CANCELED') {
          console.log('upload canceled', uploadRecord);
          return;
        }

        const response = await lessonApi.addScreenplay(
          item,
          description,
          uploadRecord
        );
        dispatch({ type: types.SCREENPLAY_CREATED });

        // auto load new screenplay
        const newItem = {
          ...item,
          lessonRecord: response.lessonRecord,
          project: response.screenplay,
          assignment: {
            ...item.assignment,
            screenplays: item.assignment.screenplays || []
          }
        };
        newItem.assignment.screenplays.concat(response.screenplay);
        dispatch(selectAssignmentProject(newItem));
      } catch (e) {
        logAndSendError(e, 'error adding screenplay');
        // TODO show notification
      }
      dispatch(closeScreenplayEditor());
    }
  };
}

function submitImages(item, description, files, uploadUiState) {
  console.info('submitImages', arguments);
  return (dispatch, getState, { lessonApi }) => {
    if (files && files.length) {
      // create or replace an image
      return multiUpload(files).then(handleImagesUploaded);
    } else {
      // otherwise update description only
      console.log('no image file selected');
      dispatch(closeImageEditor());
    }

    // --------------------------------------------

    async function multiUpload(files) {
      let bytesLoaded = 0;
      const bytesTotal = files.reduce((acc, f) => acc + f.size, 0);
      const onProgress = (loaded, total, uploadTask) => {
        if (uploadUiState.canceled) {
          uploadTask && uploadTask.cancel && uploadTask.cancel();
        }
        return {
          bytesLoaded: bytesLoaded + loaded,
          bytesTotal
        };
      };
      const uploadRecords = [];
      for (const file of files) {
        if (uploadUiState.canceled) {
          break;
        }
        await dispatch(
          uploadActions.startUpload({
            sourceFile: file,
            fileType: 'image',
            uploadOwnerId: item.uploadCreatorId,
            uploadType: UPLOAD_TYPE.IMAGE,
            onProgress,
            resetProgress: bytesLoaded === 0
          })
        )
          .then(uploadRecord => {
            uploadRecords.push(uploadRecord);
          })
          .catch(failedUploadRecord => {
            logAndSendError(failedUploadRecord, 'error uploading image');
            uploadRecords.push(failedUploadRecord);
          });
        bytesLoaded += file.size;
      }
      return uploadRecords;
    }

    async function handleImagesUploaded(uploadRecords) {
      // console.log('handleImagesUploaded', uploadRecords);
      if (uploadUiState.canceled) {
        return [];
      }
      if (uploadRecords.some(ur => ur.status !== 'COMPLETE')) {
        return uploadRecords;
      }
      try {
        const response = await lessonApi.addImageProject(
          item,
          description,
          uploadRecords
        );
        dispatch({ type: types.IMAGE_PROJECT_CREATED });

        // auto load new image
        const newItem = {
          ...item,
          lessonRecord: response.lessonRecord,
          project: response.imageProject,
          assignment: { ...item.assignment }
        };
        newItem.assignment.imageProjects =
          newItem.assignment.imageProjects || [];
        newItem.assignment.imageProjects.push(response.imageProject);

        dispatch(selectAssignmentProject(newItem));
      } catch (e) {
        logAndSendError(e, 'error adding image');
        // TODO show notification
      }
      dispatch(closeImageEditor());
    }
  };
}

function submitNonSupported(item, description, file) {
  console.info('submitNonSupported', arguments);
  return (dispatch, getState, { lessonApi }) => {
    if (file) {
      // create or replace a screenplay
      dispatch(
        uploadActions.startUpload({
          sourceFile: file,
          fileType: 'non',
          uploadOwnerId: item.uploadCreatorId,
          uploadType: UPLOAD_TYPE.UNKNOWN
        })
      )
        .then(handleNonSupportedUploaded)
        .catch(failedUploadRecord => {
          logAndSendError(failedUploadRecord, 'error uploading screenplay');
          handleNonSupportedUploaded(failedUploadRecord);
        });
    }

    // --------------------------------------------

    // add the project regardless of error
    async function handleNonSupportedUploaded(uploadRecord) {
      console.log('handleNonSupportedUploaded', uploadRecord);
      try {
        if (uploadRecord.status === 'CANCELED') {
          console.log('upload canceled', uploadRecord);
          return;
        }

        const response = await lessonApi.addNonSupportedFile(
          item,
          description,
          uploadRecord
        );

        // auto load new non supported file
        const newItem = {
          ...item,
          lessonRecord: response.lessonRecord,
          project: response.nonSupportedFileProject,
          assignment: {
            ...item.assignment,
            nonSupportedFileProjects:
              item.assignment.nonSupportedFileProjects || []
          }
        };
        newItem.assignment.nonSupportedFileProjects.concat(
          response.nonSupportedFileProject
        );
        dispatch(selectAssignmentProject(newItem));
      } catch (e) {
        logAndSendError(e, 'error adding non supported file');
      }
      dispatch(closeNonSupportedFileEditor());
    }
  };
}

function submit3d(item, description, file) {
  return (dispatch, getState, { lessonApi }) => {
    if (file) {
      // create or replace a screenplay
      dispatch(
        uploadActions.startUpload({
          sourceFile: file,
          fileType: 'OBJECT3D',
          uploadOwnerId: item.uploadCreatorId,
          uploadType: UPLOAD_TYPE.OBJECT3D
        })
      )
        .then(handle3dUploaded)
        .catch(failedUploadRecord => {
          logAndSendError(failedUploadRecord, 'error uploading screenplay');
          handle3dUploaded(failedUploadRecord);
        });
    }

    // --------------------------------------------

    // add the project regardless of error
    async function handle3dUploaded(uploadRecord) {
      try {
        if (uploadRecord.status === 'CANCELED') {
          console.log('upload canceled', uploadRecord);
          return;
        }

        const response = await lessonApi.add3D(item, description, uploadRecord);

        const newItem = {
          ...item,
          lessonRecord: response.lessonRecord,
          project: response.object3DProject,
          assignment: {
            ...item.assignment,
            object3DProjects: item.assignment.object3DProjects || []
          }
        };

        newItem.assignment.object3DProjects.concat(response.object3DProject);

        dispatch(selectAssignmentProject(newItem));
      } catch (e) {
        logAndSendError(e, 'error adding non supported file');
      }
      dispatch(closeObject3DEditor());
    }
  };
}

export const actions = {
  cancelEncoding,
  closeProjectEditor,
  closeReviewEditor,
  closeScreenplayEditor,
  closeImageEditor,
  closeNonSupportedFileEditor,
  createResponse,
  goToAssignmentProject,
  markAsDone,
  removeResponse,
  openProjectEditor,
  openReviewEditor,
  openReviewViewer,
  editReview,
  closeReviewViewer,
  submitScreenplay,
  submitImages,
  submitNonSupported,
  submit3d,
  openScreenplayEditor,
  openImageEditor,
  replaceResponse,
  selectAssignmentProject,
  submitProject,
  submitReview,
  unreviewProject,
  uploadFromSyllabus,
  gotoYourProject,
  openObject3DEditor,
  closeObject3DEditor
};

function getProjectEditor(fullState) {
  return fullState.lessonProject.projectEditor;
}

function getReviewEditor(fullState) {
  return fullState.lessonProject.reviewEditor;
}

function getReviewViewer(fullState) {
  return fullState.lessonProject.reviewViewer;
}

function getScreenplayEditor(fullState) {
  return fullState.lessonProject.screenplayEditor;
}

function getImageEditor(fullState) {
  return fullState.lessonProject.imageEditor;
}

function getObject3DEditor(fullState) {
  return fullState.lessonProject.object3DEditor;
}

function getNonSupportedFileEditor(fullState) {
  return fullState.lessonProject.nonSupportedFileEditor;
}

function getUpdateRequired(fullState) {
  return fullState.lessonProject.updateRequired;
}

function getFromUrl(fullState) {
  return fullState.lessonProject.fromUrl;
}

export const selectors = {
  getProjectEditor,
  getReviewEditor,
  getReviewViewer,
  getScreenplayEditor,
  getImageEditor,
  getNonSupportedFileEditor,
  getUpdateRequired,
  getFromUrl,
  getObject3DEditor
};
