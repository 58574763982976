import classnames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import styles from './StickyImpersonateWarning.scss';
import { connect } from 'react-redux';
import { MainButton } from '../../components/common/button';
import { Rnd } from 'react-rnd';
import { impersonateExit } from '../actions';
import { withRouter } from 'react-router-dom';

function StickyImpersonateWarning({ user, impersonateExit, history }) {
  return user
    ? ReactDOM.createPortal(
        <Rnd
          default={{ x: 100, y: 100, width: 650, height: 200 }}
          enableResizing={false}
          className={styles.rnd}
        >
          <div className={classnames(styles.topContainer, styles.color4)}>
            <div className={styles.textBlock}>
              <p>
                <i>With great power comes great responsibility!</i>
              </p>
              <p>You are now logged in as {user.email}.</p>
              <p>
                Be careful not to change anything for the user. <br />
                Log out when you are finished with looking around.
              </p>
            </div>
            <MainButton
              icon="fas fa-redo-alt"
              label="Log Out Now"
              aria-label="Log Out Now"
              onClick={() => impersonateExit(history.replace)}
              className={styles.reloadButton}
            />
          </div>
        </Rnd>,
        document.body
      )
    : null;
}

const mapStateToProps = state => {
  const user = state.admin.impersonatingUser;

  return {
    user
  };
};

const mapDispatchToProps = {
  impersonateExit
};

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(withRedux(StickyImpersonateWarning));
