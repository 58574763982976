import { gql } from '@apollo/client';

export const updateUserCrewLastSeen = apolloClient => async (
  userId,
  chatId,
  lastSeen
) => {
  const mutation = gql`
    mutation updateUserLastSeen($input: UpdateUserLastSeenInput!) {
      updateUserLastSeen(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        userId,
        chatId,
        lastSeen
      }
    }
  });
};
