import { gql } from '@apollo/client';

export function removeTrialUser(apolloClient) {
  const REMOVE_TRIAL_USER = gql`
    mutation removeTrialUser(
      $userId: ID!
      $enrolleeId: ID!
      $programInstanceId: ID!
    ) {
      removeTrialUser(
        userId: $userId
        enrolleeId: $enrolleeId
        programInstanceId: $programInstanceId
      )
    }
  `;

  return async userProgram => {
    await apolloClient.mutate({
      mutation: REMOVE_TRIAL_USER,
      variables: {
        userId: userProgram.user,
        enrolleeId: userProgram.id,
        programInstanceId: userProgram.programInstance
      },
      refetchQueries: ['getAdminData']
    });
  };
}
