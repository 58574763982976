import styles from './updateBodyScrollClass.css';

export const addBodyScrollClass = () => {
  document.documentElement.classList.add(styles.noScroll);
  document.body.classList.add(styles.noScroll);
};
export const removeBodyScrollClass = () => {
  document.documentElement.classList.remove(styles.noScroll);
  document.body.classList.remove(styles.noScroll);
};
