import { gql } from '@apollo/client';
import { omit } from 'lodash';

export function repairDb(apolloClient) {
  const mutation = gql`
    mutation repairDb($input: DbStructureErrorFixInput!) {
      repairDb(input: $input)
    }
  `;

  return async error => {
    await apolloClient.mutate({
      mutation,
      variables: {
        input: omit(error.fix, '__typename')
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: error.id,
          __typename: 'DbStructureError'
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    });
  };
}
