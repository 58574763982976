'use strict';

import baseConfig from './base';

const awsApi = 'https://demo.api.smashcut.com';
const awsWss = 'wss://2g21o8z93l.execute-api.eu-west-1.amazonaws.com/demo';

let config = {
  apolloUri: `${awsApi}/graphql`,
  appEnv: 'demo',
  baseUrl: 'https://demo.smashcut.com',
  camSharingEnabled: true,
  logRocketEnabled: true,
  logrocket: 'px1cem/smashcut-web-demo',
  opentokApiKey: '47015524',
  pushMessagingPublicVapidKey:
    'BFN-lMZAU3ZLv1m6qI5iYIubiDzpxT9mnW15HENebnRFcv5IjpD_Dt_r5jtYR4v7LZ1VLeSPL4p0WlPotFnMIiY',
  sentry:
    'https://f671c0560cbe4ff0a7195f3a33396f72@o82327.ingest.sentry.io/179874',
  sentryEnabled: true,
  smashcutApiUrl: awsApi,
  wssUrl: awsWss
};

let mergedConfig = Object.freeze(Object.assign({}, baseConfig, config));
console.log('Using config: ', mergedConfig);
export default mergedConfig;
