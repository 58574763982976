import { get, isEmpty } from 'lodash';

const addChildren = (currentFile, files, resultTreeFiles) => {
  const currentFolderChildren = files.filter(
    file => file.parentId === get(currentFile, 'id', null)
  );

  if (isEmpty(currentFolderChildren)) {
    return resultTreeFiles;
  }

  return {
    ...resultTreeFiles,
    children: currentFolderChildren.map(child => ({
      ...child,
      ...addChildren(child, files, resultTreeFiles)
    }))
  };
};

export const convertToTreeStructure = (files, rootId = null) => {
  const treeFiles = addChildren({ id: rootId }, files, []);
  return get(treeFiles, 'children', []);
};
