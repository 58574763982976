// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RangeMarkerGroup--rangeCommentGroup--1AgTiKqH{position:absolute;-webkit-transition:opacity 0.25s;transition:opacity 0.25s;top:-4px}.RangeMarkerGroup--rangeCommentGroup--1AgTiKqH .RangeMarkerGroup--rangeLine--1xcM8AtW{position:absolute;top:3px;border:none;background-color:#3a8c3e;height:10px;opacity:0}.RangeMarkerGroup--rangeCommentGroup--1AgTiKqH .RangeMarkerGroup--rangeLine--1xcM8AtW.RangeMarkerGroup--rangeIsPlaying--1x4vDgsa{opacity:0.3}.RangeMarkerGroup--rangeCommentGroup--1AgTiKqH .RangeMarkerGroup--rangeLine--1xcM8AtW.RangeMarkerGroup--provisional--MN9awXsx{top:3px;border:none;background-color:#ffffff;height:10px;opacity:0.3;pointer-events:none}.RangeMarkerGroup--rangeCommentGroup--1AgTiKqH:hover .RangeMarkerGroup--rangeLine--1xcM8AtW{opacity:0.3}\n", ""]);
// Exports
exports.locals = {
	"rangeCommentGroup": "RangeMarkerGroup--rangeCommentGroup--1AgTiKqH",
	"rangeLine": "RangeMarkerGroup--rangeLine--1xcM8AtW",
	"rangeIsPlaying": "RangeMarkerGroup--rangeIsPlaying--1x4vDgsa",
	"provisional": "RangeMarkerGroup--provisional--MN9awXsx"
};
module.exports = exports;
