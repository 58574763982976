import { all, take, takeEvery, call, put, race } from 'redux-saga/effects';
import { actions, types } from '../reducers/deleteUserReducer';

export function* deleteUser(userApi, { payload }) {
  const title = `Deleting User ${payload.user.fullName}`;
  try {
    const isEnrolled = yield call(userApi.isEnrolled, payload.user.id);
    if (isEnrolled) {
      yield put(
        actions.deleteUserAlert(
          `User cannot be deleted because they are enrolled in a program.`,
          title
        )
      );
      return;
    }

    const hasUploads = yield call(userApi.hasUploads, payload.user.id);
    const message = hasUploads
      ? `This user has uploaded My Files and/or Projects. Are you sure you want to delete this user and all of their data?`
      : `Are you sure you want to delete this user?`;
    yield put(actions.deleteUserConfirm(message, title));

    const { ok, cancel } = yield race({
      ok: take(types.DELETE_USER_CONFIRM_OK),
      cancel: take(types.DELETE_USER_CONFIRM_CANCEL)
    });

    if (ok) {
      console.log(`delete confirmed`);
      yield put(actions.deleteUserRequest(payload.user));
      yield call(userApi.deleteUser, payload.user.id);
      yield put(
        actions.deleteUserSuccess(`Success! The user has been deleted`, title)
      );
    }
    console.log(`delete exit`);
  } catch (e) {
    console.warn(`nok`, e);
    yield put(
      actions.deleteUserFailure(
        `An error occurred while deleting, please try again`,
        title
      )
    );
  }
}

export function* deleteUserSaga({ apis }) {
  const userApi = apis.api.user;
  yield all([takeEvery(types.DELETE_USER, deleteUser.bind(this, userApi))]);
}
