// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MobileMarkerModal--overLay--2XMlnTZj{position:absolute;top:0;bottom:0;right:0;left:0;background:rgba(0, 0, 0, .7);z-index:88888}.MobileMarkerModal--topContainer--XpN5dDdL{position:absolute;bottom:0;right:0;left:0;background:#242424;z-index:2222;border-radius:8px 8px 0px 0px;max-height:90%;overflow-y:scroll}.MobileMarkerModal--topContainer--XpN5dDdL .MobileMarkerModal--header--TH2UuPKc{background:#2d2d2d;border-radius:8px 8px 0px 0px;padding:20px;font-size:16px;line-height:18px;color:rgba(255, 255, 255, .7);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.MobileMarkerModal--topContainer--XpN5dDdL .MobileMarkerModal--body--bRsilxb8{padding:15px 20px;font-size:14px;line-height:21px;color:#ffffff}\n", ""]);
// Exports
exports.locals = {
	"overLay": "MobileMarkerModal--overLay--2XMlnTZj",
	"topContainer": "MobileMarkerModal--topContainer--XpN5dDdL",
	"header": "MobileMarkerModal--header--TH2UuPKc",
	"body": "MobileMarkerModal--body--bRsilxb8"
};
module.exports = exports;
