const PREFIX = 'STUDENT_NOTES/';

export const types = {
  NOTE_UPDATE: PREFIX + 'NOTE_UPDATE',
  NOTE_UPDATE_FAILURE: PREFIX + 'NOTE_UPDATED_FAILURE',
  NOTE_UPDATE_SUCCESS: PREFIX + 'NOTE_UPDATED_SUCCESS'
};

const initialState = {};

export const studentNotesReducer = (state = initialState, action) => {
  let nextState = state;

  switch (action.type) {
    case types.NOTE_UPDATE:
      nextState = {
        ...state,
        updatingNote: true
      };
      break;
    case types.NOTE_UPDATE_SUCCESS:
      nextState = {
        ...state,
        updateRequired: {},
        updatingNote: false
      };
      break;
  }
  return nextState;
};

export const actions = {
  noteUpdateOrCreate: (enrolleeId, userId, note) => ({
    type: types.NOTE_UPDATE,
    enrolleeId,
    userId,
    note
  })
};

export const selectors = {
  getUpdatingNote: state => state.dashboard.studentNotes.updatingNote
};
