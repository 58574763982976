import React from 'react';
import styles from './Header.scss';
import { getString } from '../../common/getString';
import classnames from 'classnames';
import SmashcutAvatar from './SmashcutAvatar';
import { SecondaryButton } from 'components/common/button';
import SelectProgramMobileButton from '../selectProgram/SelectProgramMobileButton';
import onClickOutside from 'react-onclickoutside';
import { useToggleBodyScroll } from 'utils/useToggleBodyScroll';
import { getPageFromLocation } from 'utils/getPageFromLocation';

const MenuItem = ({ hidden, label, icon, onClick, className }) =>
  !hidden ? (
    <li onClick={onClick} className={className}>
      {icon && <i className={icon} />}
      {label}
    </li>
  ) : (
    <li />
  );

const DrawerMenu = ({
  menuOpen,
  location,
  setMenuOpen,
  handleDrawerMenuClick,
  handleSelect,
  closeMenu,
  user,
  title,
  openMobileBetaWarning,
  hidden
}) => {
  const { pathname } = location;
  const currentPage = getPageFromLocation(location);
  const isHome = pathname === '/';
  DrawerMenu.handleClickOutside = () => closeMenu();
  useToggleBodyScroll(menuOpen);

  return (
    <div
      className={classnames(styles.overlay, {
        [styles.open]: menuOpen
      })}
      onClick={closeMenu}
    >
      <div
        className={classnames(styles.drawerMenu, {
          [styles.open]: menuOpen
        })}
      >
        <div className={styles.menuHeader}>
          <div className={styles.leftSide}>
            <SmashcutAvatar
              className={styles.avatar}
              src={user.avatar}
              title={title}
              alt={`${user.fullName} Avatar`}
            />
            <div className={styles.name}>
              <span>{user.fullName}</span>
              <span>{getString(user.roleLabel)}</span>
            </div>
          </div>
          <SecondaryButton
            size="small"
            className={styles.close}
            icon="fas fa-times"
            onClick={() => setMenuOpen(!menuOpen)}
          />
        </div>
        <SelectProgramMobileButton onClick={closeMenu} />
        <div className={styles.dashboardLabel}>Dashboard</div>
        <ul className={styles.menu}>
          {[
            {
              id: 'syllabus',
              label: 'Syllabus',
              match: !user.isMentor && isHome,
              hidden: hidden.syllabus
            },
            {
              id: 'projects-for-review',
              label: 'Projects For Review',
              match: user.isMentor && isHome,
              hidden: hidden.projectsForReview
            },
            {
              id: 'projects',
              label: 'Projects',
              hidden: hidden.projects
            },
            {
              id: 'myfiles',
              label: 'My Files',
              hidden: hidden.myFiles
            },
            {
              id: 'upcoming-video-meetings',
              label: 'Upcoming Video Meetings',
              hidden: hidden.upcomingVideoMeetings
            }
          ].map(menu => (
            <MenuItem
              key={menu.id}
              hidden={menu.hidden}
              className={
                menu.id === currentPage || menu.match ? styles.active : ''
              }
              icon="fas fa-dot-circle"
              onClick={() => handleDrawerMenuClick(menu.id, true)}
              {...menu}
            />
          ))}
        </ul>
        <div className={styles.dashboardLabel}>Settings</div>
        <ul className={styles.menu}>
          <MenuItem
            label="Account Details"
            onClick={() => handleDrawerMenuClick('account-details')}
          />
          <MenuItem
            label="Browse Courses"
            onClick={() => handleDrawerMenuClick('courses')}
          />
          <MenuItem label="About Mobile Beta" onClick={openMobileBetaWarning} />
          <MenuItem label="Help Center" className="launch-help-chat" />
          <li className={styles.logOut} onClick={() => handleSelect('logout')}>
            Log out
            <i className="fas fa-sign-out-alt" />
          </li>
        </ul>
      </div>
    </div>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => DrawerMenu.handleClickOutside
};

export default onClickOutside(DrawerMenu, clickOutsideConfig);
