import React from 'react';
import styles from './OnlyOnComputerAlert.scss';
import { SecondaryButton } from 'components/common/button';

export const OnlyOnComputerAlert = ({ onClick, visible, message }) =>
  visible && (
    <div className={styles.onlyOnComputerDialogOverlay}>
      <div className={styles.onlyOnComputerDialog}>
        {message}
        <SecondaryButton
          size="small"
          icon="fas fa-times"
          className={styles.closeButton}
          onClick={onClick}
        />
      </div>
    </div>
  );
