import React from 'react';
import styles from './ToastNotification.scss';
import classnames from 'classnames';

export default ({ style, type, visible, notification, onClose, onClick }) => {
  notification = notification || {};

  const { title, body, colorCode, icon } = notification;

  return (
    <div
      style={style}
      className={classnames(
        styles.toastNotification,
        styles[`color${colorCode}`],
        {
          [styles[type]]: type,
          [styles.visible]: visible
        }
      )}
      onClick={() => onClick && onClick(notification)}
    >
      <div className={classnames(styles.icon, styles[`color${colorCode}`])}>
        <i className={icon} />
      </div>
      <div className={styles.textBlock}>
        <div className={styles.titleBlock}>{title}</div>
        <div className={styles.bodyBlock}>{body}</div>
      </div>
      <div className={styles.closeBlock}>
        <i className="fas fa-times" onClick={onClose} />
      </div>
    </div>
  );
};
