import { gql } from '@apollo/client';

export function setProgramIsTrash(apolloClient) {
  const mutation = gql`
    mutation setProgramIsTrash($input: ProgramInstanceInput!) {
      setProgramIsTrash(input: $input) {
        id
        isTrash
      }
    }
  `;

  return async (programInstanceId, isTrash) => {
    await apolloClient.mutate({
      mutation,
      variables: {
        input: {
          id: programInstanceId,
          isTrash
        }
      },
      refetchQueries: ['getAdminData']
    });
  };
}
