import { hot } from 'react-hot-loader/root';
import React from 'react';
import Preloader from 'components/common/Preloader';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Router, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { initApp, initStore } from 'smashcut-client-lib';
import config from 'config';
import { allApis } from './allApis';
import { allReducers } from './allReducers';
import { allSagas } from './allSagas';
import { auth0SetStore } from './login/components/Auth0Lock';
import {
  configSentry,
  setStore as sentrySetStore
} from './helpers/configSentry.js';
import { logout } from './smashcut-client-lib/actions/userActions';

// called by head in index.html, when all scripts are loaded
window.smashcutHeadOnLoad = () => {
  document.body.addEventListener('mousedown', function() {
    document.body.classList.add('using-mouse');
  });
  document.body.addEventListener('keydown', function() {
    document.body.classList.remove('using-mouse');
  });
};

class StoreProvider extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  };
  constructor(props, context) {
    super(props, context);

    const storeInitializer = {
      config: config,
      reducer: allReducers(config),
      apis: allApis(config),
      rootSaga: allSagas(config),
      history: props.history
    };
    this.store = initStore(storeInitializer);

    auth0SetStore(this.store);
    sentrySetStore(this.store);

    this.store.dispatch(
      window.location.href.indexOf('/logout') > 0 ? logout() : initApp()
    );
  }
  render() {
    return <Provider store={this.store}>{this.props.children}</Provider>;
  }
}
const StoreProviderWithRouter = withRouter(StoreProvider);

const history = createBrowserHistory();
configSentry({ history });

const App = ({ apolloClient, MainRouter }) => (
  <ApolloProvider client={apolloClient}>
    <Router history={history}>
      <StoreProviderWithRouter>
        <Preloader MainRouter={MainRouter} />
      </StoreProviderWithRouter>
    </Router>
  </ApolloProvider>
);
export default hot(App);
