import React from 'react';
import classnames from 'classnames';
import styles from './RangeMarkerGroup.scss';
import { Marker, DraggableMarker } from './VideoMarker';
import { VideoMarkersContext } from './VideoMarkersContext';

export class RangeMarkerGroup extends React.Component {
  handleMarkerDragStop = (context, side) => delta => {
    const { videoLength, containerWidth } = context;
    const { marker, onMarkerChange } = this.props;
    const timePlusDuration = marker.time + marker.duration;

    if (side === 'left') {
      const x = (marker.time * containerWidth) / videoLength + 3;
      const newX = x + delta;
      const newTime = ((newX - 3) * videoLength) / containerWidth;
      const newDuration = Math.max(
        marker.duration + (marker.time - newTime),
        0.1
      );
      onMarkerChange &&
        onMarkerChange({ time: newTime, duration: newDuration });
    } else {
      const x = (timePlusDuration * containerWidth) / videoLength + 3;
      const newX = x + delta;
      const newTime = ((newX - 3) * videoLength) / containerWidth;
      const newDuration = Math.max(
        marker.duration + (newTime - timePlusDuration),
        0.1
      );
      onMarkerChange && onMarkerChange({ duration: newDuration });
    }
  };

  render() {
    const renderFn = contextValues => {
      const { containerWidth, videoLength } = contextValues;
      const { marker, editable } = this.props;
      const startX = (marker.time * containerWidth) / videoLength + 3;
      const endX =
        ((marker.time + marker.duration) * containerWidth) / videoLength + 3;

      const MarkerClass = editable ? DraggableMarker : Marker;
      const firstMarker = (
        <MarkerClass
          marker={marker}
          onDragStop={this.handleMarkerDragStop(contextValues, 'left')}
          onClick={this.props.onClick}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
        />
      );
      const rangeLine = (
        <div
          className={classnames(styles.rangeLine, {
            [styles[marker.markerType]]: true,
            [styles.rangeIsPlaying]: this.props.isPlaying
          })}
          onClick={this.props.onClick}
          onMouseEnter={() =>
            this.props.onMouseEnter && this.props.onMouseEnter(marker)
          }
          onMouseLeave={() =>
            this.props.onMouseLeave && this.props.onMouseLeave(marker)
          }
          style={{ left: `${startX + 2}px`, width: `${endX - startX + 3}px` }}
        />
      );
      const secondMarker = (
        <MarkerClass
          marker={marker}
          side="right"
          onDragStop={this.handleMarkerDragStop(contextValues, 'right')}
          onClick={this.props.onClick}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
        />
      );
      return (
        <div className={styles.rangeCommentGroup}>
          {marker.duration > 0.1 && rangeLine}
          {firstMarker}
          {marker.duration > 0.1 && secondMarker}
        </div>
      );
    };
    return (
      <VideoMarkersContext.Consumer>
        {contextValues => renderFn(contextValues)}
      </VideoMarkersContext.Consumer>
    );
  }
}
