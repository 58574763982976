import { gql } from '@apollo/client';

export const addVideoReply = apolloClient => ({
  commentSectionId,
  parentId,
  replyId,
  author,
  url,
  videoMetadata
}) => {
  const mutation = gql`
    mutation addVideoReply($input: AddVideoReplyInput!) {
      addVideoReply(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        commentSectionId,
        parentId,
        replyId,
        author,
        url,
        videoMetadata: {
          videoHeight: videoMetadata.videoHeight,
          videoWidth: videoMetadata.videoWidth
        }
      }
    }
  });
};
