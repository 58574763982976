import { isEqual } from 'lodash';

export const receiveEntity = entityType => (id, entity) => {
  return (dispatch, getState) => {
    if (!entity) {
      dispatch({
        type: 'REMOVE_ENTITY_' + entityType.toUpperCase(),
        entityType,
        entity,
        id
      });
      return;
    }
    let entities = getState().entities[entityType];
    let oldItem = entities && entities[id];
    let newItem = entity;
    if (!oldItem) {
      dispatch({
        type: 'INSERT_ENTITY_' + entityType.toUpperCase(),
        entityType,
        entity: newItem,
        id
      });
      return;
    }
    if (!isEqual(oldItem, newItem)) {
      dispatch({
        type: 'UPDATE_ENTITY_' + entityType.toUpperCase(),
        entityType,
        entity: newItem,
        id
      });
    } else {
      //++countUpdate
      // console.log('Duplicate update', countUpdate, entityType, id)
    }
  };
};
