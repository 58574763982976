import { gql } from '@apollo/client';
import { get, keys } from 'lodash';

const ALLUPS = gql`
  query getAllUserPrograms {
    allUserPrograms {
      id
      programInstance
      isInstructor
      students {
        id
      }
      mentors {
        id
        discipline
      }
      userId
      application {
        status
      }
    }
  }
`;

const mapUp = up => ({
  ...up,
  students: keys(up.students).map(s => ({ id: s })),
  mentors: keys(up.mentors).map(m => ({
    id: up.id + '/' + m,
    discipline: up.mentors[m]
  })),
  userId: up.user
});

export function getAllUserPrograms(apolloClient) {
  return () => {
    apolloClient
      .query({ query: ALLUPS })
      .then(ups => get(ups, 'data.allUserPrograms', []).map(mapUp));
  };
}
