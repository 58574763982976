import {
  getReply,
  getCommentSection,
  getVideoReplyUploadPath
} from '../selectors';
import { receiveEntity } from './receiveEntity';

export const deleteReply = id => {
  return (dispatch, getState, { api }) => {
    let state = getState();
    let reply = getReply(state, id);
    let commentSectionId = getCommentSection(state).id;

    if (reply.videoUrl) {
      let path = getVideoReplyUploadPath(commentSectionId, id);
      api.deleteVideo(path);
    }

    api.deleteReply(commentSectionId, id);
    dispatch(receiveEntity('replies')(id, null));
  };
};
