const appState = {
  appSettings: {
    heroBackgroundUrl: '',
    shouldClientDoCleanup: true, //client or cloud functions?
    videoRecorder: {
      mimeTypesSortedByPreference: [
        'video/webm;codecs=vp8', //compatible with firefox & chrome
        'video/webm;codecs=vp9',
        'video/webm;codecs=h264'
      ],
      bandWidth: {
        audioBitsPerSecond: 64 * 1000,
        videoBitsPerSecond: 1250 * 1000,
        bitsPerSecond: (1250 + 64) * 1000
      },
      width: 320,
      height: 240
    }
  },
  auth: {},
  commentSection: {},
  currentCommentId: '',
  currentProjectId: '',
  currentUserId: '',
  dashboard: {
    upcomingLms: {}
  },
  embeddedVideo: {
    id: null,
    loaded: false,
    data: {}
  },
  inlineEditor: {
    id: 'none'
  },
  lesson: {
    lessonSpec: {},
    loading: false,
    data: {}
  },
  listOfComments: [],
  listOfReplies: {},
  messages: [],
  newReplyEditor: {
    parentId: ''
  },
  pushPermissionRequired: true,
  recordVideoPopup: {
    open: false
  },
  replay: {
    active: false,
    connectionId: null
  },
  videoCommentPlayer: {
    isPlaying: null,
    item: null
  },
  videoPlayer: {
    currentTime: 0,
    duration: 0,
    frameRate: 0,
    gotoTime: 0,
    skipSpeed: 0,
    isPlaying: false,
    wasPlayingBeforePause: false
  },
  entities: {
    comments: {},
    counts: {},
    lessons: {},
    programInstances: {},
    programs: {},
    projects: {},
    replies: {},
    screenplays: {},
    userPrograms: {},
    users: {}
  }
};

export default appState;
