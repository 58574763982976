import { actionType } from '../constants';
import { LOGOUT } from './authReducer';

export default (state = {}, action) => {
  let nextState = state;

  switch (action.type) {
    case LOGOUT:
      nextState = { ...state, userProgramLoaded: false, programs: {} };
      break;

    case actionType.USER_PROGRAM_LOADED:
      nextState = { ...state, userProgramLoaded: true };
      if (action.key) {
        let programs = { ...state.programs };
        programs[action.key] = action.userProgram;
        nextState.programs = programs;
      }
      break;
  }

  return nextState;
};
