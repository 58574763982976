import * as userProgramHelper from 'smashcut-client-lib/utils/userProgramHelper';
import moment from 'moment';
import { actions as lessonActions } from 'reducers/lesson';
import { actions as relatedVideoActions } from 'reducers/relatedVideoPlayer';
import { getCurrentUserProgram } from 'smashcut-client-lib/selectors';
import { logAndSendError } from 'utils/sentryHelper';
import { types as lessonProjectTypes } from 'reducers/lessonProjectTypes';
import { types as selectProgramTypes } from 'components/selectProgram/selectProgramReducer';
import { LOGOUT } from '../../smashcut-client-lib/reducers/authReducer';
import { showIframe as showIframeAction } from '../../components/common/SmashcutIframe';
import { getString } from '../../common/getString';
import { cloneDeep, flatten, isEmpty } from 'lodash';
import {
  SYLLABUS_ASSIGNMENT_FRAGMENT,
  SYLLABUS_QUERY,
  SYLLABUS_SUB_ASSIGNMENT_FRAGMENT
} from './SyllabusQuery';
import { openUploadFormLessonOnLoadAction } from '../lessonOnLoadActions';

const PREFIX = 'DASHBOARD_SYLLABUS';

export const types = {
  HELP_MODAL_TOGGLE: `${PREFIX}/HELP_MODAL_TOGGLE`,
  LOAD_SYLLABUS_FAILURE: `${PREFIX}/LOAD_SYLLABUS_FAILURE`,
  LOAD_SYLLABUS_START: `${PREFIX}/LOAD_SYLLABUS_START`,
  LOAD_SYLLABUS_SUCCESS: `${PREFIX}/LOAD_SYLLABUS_SUCCESS`,
  MARK_AS_DONE_SUCCESS: `${PREFIX}/MARK_AS_DONE_SUCCESS`,
  OPEN_THIS_WEEK: `${PREFIX}/OPEN_THIS_WEEK`,
  PREVIEW_SYLLABUS: `${PREFIX}/PREVIEW_SYLLABUS`,
  REMOVE_MARK_AS_DONE_SUCCESS: `${PREFIX}/REMOVE_MARK_AS_DONE_SUCCESS`,
  SUBMIT_PROJECT_MODAL_TOGGLE: `${PREFIX}/SUBMIT_PROJECT_MODAL_TOGGLE`
};

const initialState = {
  openedWeek: 0,
  loading: false,
  helpModal: {
    opened: false
  }
};

export const syllabusReducer = (state = initialState, action) => {
  let nextState = state;

  switch (action.type) {
    case types.LOAD_SYLLABUS_START:
      nextState = {
        ...state,
        loading: true
      };
      break;

    case types.PREVIEW_SYLLABUS:
      nextState = {
        isPreview: true,
        contentId: action.contentId,
        programId: action.programId
      };
      break;

    case types.LOAD_SYLLABUS_SUCCESS:
      nextState = {
        ...state,
        data: action.data,
        resolveAsset: action.resolveAsset,
        loading: false
      };
      break;

    case types.LOAD_SYLLABUS_FAILURE:
      nextState = {
        ...state,
        error: action.error,
        loading: false
      };
      break;

    case types.OPEN_THIS_WEEK:
      nextState = { ...state, openedWeek: action.weekIndex };
      break;

    case types.HELP_MODAL_TOGGLE:
      nextState = {
        ...state,
        helpModal: {
          text: action.text,
          opened: !state.helpModal.opened
        }
      };
      break;

    case types.SUBMIT_PROJECT_MODAL_TOGGLE:
      nextState = {
        ...state,
        submitProjectOpened: state.submitProjectOpened
          ? undefined
          : {
              item: action.item,
              type: action.itemType
            }
      };
      break;

    case lessonProjectTypes.MARK_AS_DONE_SUCCESS:
    case types.MARK_AS_DONE_SUCCESS:
      nextState = {
        ...state,
        submitProjectOpened: undefined
      };
      break;

    case LOGOUT:
    case selectProgramTypes.SELECT_USER_PROGRAM:
      nextState = {
        ...state,
        openedWeek: 0
      };
      break;
  }

  return nextState;
};

// Note: When you are missing data from an assignment, check getItem below
export const loadSyllabus = maybeId => {
  return (dispatch, getState, { api, apolloClient }) => {
    const state = getState();
    const userProgram = getCurrentUserProgram(state);
    const id = maybeId || (userProgram && userProgram.id);
    if (id) {
      return loadSyllabusForUserProgram(id);
    }
    // ---------------------------------
    // only functions below
    // ---------------------------------

    function loadSyllabusForUserProgram(userProgramId) {
      dispatch(loadSyllabusStart());

      return apolloClient
        .query({
          query: SYLLABUS_QUERY,
          variables: { enrolleeId: userProgramId },
          fetchPolicy: 'network-only' // disable cache to force updates
          // TODO when we change the ids of the syllabus parts to be unique, we can remove the network-only fetchPolicy
        })
        .then(result => {
          const syllabus = result.data.syllabus;
          const assets = result.data.syllabus.assets;
          const resolveAsset = userProgramHelper.makeResolveAssetFnFromAssets(
            assets,
            api
          );
          console.log('syllabus', syllabus);
          dispatch(loadSyllabusSuccess(syllabus, resolveAsset, !!maybeId));
        })
        .catch(e => {
          dispatch(
            loadSyllabusFailure(`error loading syllabus for ${userProgramId}`)
          );
          logAndSendError(e, `error loading syllabus for ${userProgramId}`);
        });
    }
  };
};

export const loadSyllabusFailure = error => ({
  type: types.LOAD_SYLLABUS_FAILURE,
  error
});

export const loadSyllabusStart = () => ({
  type: types.LOAD_SYLLABUS_START
});

export const loadSyllabusSuccess = (data, resolveAsset, idProvided) => ({
  type: types.LOAD_SYLLABUS_SUCCESS,
  data,
  resolveAsset,
  idProvided
});

function findWeekForDate(syllabus, date) {
  let d = date.toISOString();

  for (let id in syllabus.weeks) {
    let week = syllabus.weeks[id];

    if (week) {
      if (dayStart(week.startDate) <= d && d <= dayEnd(week.endDate)) {
        return week;
      }
    }
  }

  console.warn('findWeekForDate: no week found');
  return null;

  // ---------------------

  function dayStart(d) {
    return moment(d)
      .startOf('day')
      .toISOString();
  }

  function dayEnd(d) {
    return moment(d)
      .endOf('day')
      .toISOString();
  }
}

export const markAsDone = (item, type) => {
  return (dispatch, getState, { lessonApi }) => {
    return lessonApi
      .addConfirm(item, type)
      .then(() => dispatch({ type: types.MARK_AS_DONE_SUCCESS }))
      .catch(e => logAndSendError(e, 'error adding confirm'));
  };
};

export const removeMarkAsDone = (item, type) => {
  return (dispatch, getState, { lessonApi }) => {
    return lessonApi
      .removeConfirm(item, type)
      .then(() => dispatch({ type: types.REMOVE_MARK_AS_DONE_SUCCESS }))
      .catch(e => logAndSendError(e, 'error removing confirm'));
  };
};

// make it a factory, so we can configure the function to open a window
export const navigateFactory = openInWindowFn => payload => {
  // console.log('navigateFactory', payload);
  return (dispatch, getState, { apolloClient }) => {
    if (isAssignmentItem(payload)) {
      const item = getItemFromApolloCache(apolloClient, payload);
      if (!item) {
        console.warn('Item not found: ', payload);
      } else {
        if (item.assignmentType === 'watch') {
          if (item.resolvedVideo) {
            openInModal(item);
          } else {
            // legacy programs had their external urls in videoUrl
            // cms programs can use videoUrl for assets only
            // and externalUrl for youtube, ..
            openNewBrowser(item.videoUrl || item.externalUrl);
          }

          // don't navigate to lesson view
          return;
        }

        if (
          item.assignmentType === 'read' ||
          item.assignmentType === 'listen'
        ) {
          openNewBrowser(item.externalUrl);
          return;
        }

        if (item.assignmentType === 'iframe') {
          openIFrame(item);
          return;
        }
      }
    }
    dispatch(lessonActions.gotoLesson(payload));

    // -------------------------------------

    function openInModal(item) {
      const state = getState();
      const resolveAsset = getSyllabusResolveAssetFn(state);
      resolveSubTitles(resolveAsset, item).then(item => {
        dispatch(relatedVideoActions.showPlayer(item.title, item));
      });
    }

    async function resolveSubTitles(resolveAssetFn, item) {
      if (isEmpty(item.subTitles)) return item;

      const writeableItem = cloneDeep(item);
      writeableItem.subTitles = await Promise.all(
        writeableItem.subTitles.map(async s => {
          const subTitle = { ...s };
          subTitle.originalName = s.url;
          subTitle.url = await resolveAssetFn(s.url);
          console.log('resolved subtitle', subTitle.url);
          return subTitle;
        })
      );
      return writeableItem;
    }

    function isAssignmentItem(payload) {
      return (
        payload &&
        ['assignment', 'subAssignment'].indexOf(payload.itemType) >= 0
      );
    }

    function openNewBrowser(url) {
      if (url && url.indexOf('http') === 0) {
        openInWindowFn(url);
      } else {
        console.warn('ignoring local videoUrl: ', url);
      }
    }

    function openIFrame(assignment) {
      let url = assignment.externalUrl;
      if (url && url.indexOf('http') === 0) {
        showIframe(assignment.externalUrl, assignment);
        return;
      }
      url = assignment.instructionsPdfUrl;
      if (url) {
        const state = getState();
        const resolveAsset = getSyllabusResolveAssetFn(state);
        resolveAsset(url).then(resolvedUrl => {
          if (resolvedUrl) {
            showIframe(resolvedUrl, assignment);
          }
        });
        return;
      }
      throw new Error('iframe assignment is missing url');

      function showIframe(url, assignment) {
        const singleAssignmentLabel = getAssignmentLabel(
          getState(),
          assignment
        );

        dispatch(
          showIframeAction({
            src: url,
            superTitle: singleAssignmentLabel,
            title: assignment.title,
            description: assignment.description
          })
        );
      }
    }

    function getItemFromApolloCache(apolloClient, payload) {
      // Note that reading a fragment returns null when the
      // query contains fields, which cannot fulfilled
      if (payload.itemType === 'subAssignment') {
        return retrieveFragment(
          payload.itemId,
          SYLLABUS_SUB_ASSIGNMENT_FRAGMENT
        );
      }
      return retrieveFragment(payload.itemId, SYLLABUS_ASSIGNMENT_FRAGMENT);

      // only functions below

      function retrieveFragment(id, fragment) {
        return apolloClient.readFragment({
          id: 'SyllabusAssignment:' + id,
          fragment
        });
      }
    }
  };
};

export const openThisWeek = () => {
  return openWeekContainingDate(new Date());
};

export const openWeekContainingDate = date => {
  return (dispatch, getState) => {
    let state = getState();
    let syllabus = getSyllabusData(state);
    let week = findWeekForDate(syllabus, date);
    if (week) {
      dispatch({ type: types.OPEN_THIS_WEEK, weekIndex: week.index });
    }
  };
};

export const openWeek = weekIndex => ({
  type: types.OPEN_THIS_WEEK,
  weekIndex
});

export const toggleHelpModal = text => ({
  type: types.HELP_MODAL_TOGGLE,
  text
});

export const toggleSubmitProject = (item, itemType) => ({
  type: types.SUBMIT_PROJECT_MODAL_TOGGLE,
  item,
  itemType
});

function goToAssignmentWithUpload(data) {
  return dispatch => {
    dispatch(
      lessonActions.gotoLesson(
        data.gotoLessonPayload,
        openUploadFormLessonOnLoadAction(data.gotoLessonPayload.itemId)
      )
    );
  };
}

function goToSubAssignmentWithUpload(data) {
  console.log('goToAssignmentWithUpload', data);
  return dispatch => {
    dispatch(
      lessonActions.gotoLesson(
        data.gotoLessonPayload,
        openUploadFormLessonOnLoadAction(data.gotoLessonPayload.itemId, true)
      )
    );
  };
}

export const actions = {
  goToAssignmentWithUpload,
  goToSubAssignmentWithUpload,
  loadSyllabus,
  loadSyllabusFailure,
  loadSyllabusStart,
  loadSyllabusSuccess,
  markAsDone,
  navigate: navigateFactory(window.open),
  openThisWeek,
  openWeek,
  removeMarkAsDone,
  toggleHelpModal,
  toggleSubmitProject
};

// ------------------------------------------------

const defaultValueArr = [];
const defaultValueObj = {};

function getSyllabus(state) {
  return state.dashboard.syllabus;
}

function getSyllabusData(state) {
  let syllabus = getSyllabus(state);
  return (syllabus && syllabus.data) || defaultValueObj;
}

function resolveToUndefined(/*name*/) {
  console.warn(
    'resolveAsset not set (yet), this function resolves to undefined'
  );
  // whatever is passed in as name, we will always return undefined
  return Promise.resolve(undefined);
}

function getSyllabusResolveAssetFn(state) {
  let syllabus = getSyllabus(state);
  if (syllabus && syllabus.resolveAsset) return syllabus.resolveAsset;
  return resolveToUndefined;
}

function getSyllabusSpec(state) {
  return state.dashboard.syllabusSpec;
}

function getAssignmentLabel(state, assignment) {
  const weeks = getSyllabusWeeks(state);
  const lessons = flatten(weeks.map(w => w.lessons));
  const parentLessons = lessons.filter(l =>
    l.assignments.find(a => a.id === assignment.id)
  );
  if (parentLessons.length === 0) {
    console.error('parent lesson not found');
    return getString('Assignments');
  }
  if (parentLessons.length > 1) {
    console.warn('more than one parent lesson found', parentLessons);
  }
  const lesson = parentLessons.shift();
  const assignmentsLabel = lesson.assignmentsLabel || getString('Assignments');

  const singleAssignmentLabel = assignmentsLabel.replace(/s$/, '');
  return singleAssignmentLabel;
}

function getSyllabusWeeks(state) {
  let syllabus = getSyllabusData(state);
  return syllabus.weeks || defaultValueArr;
}

function getSyllabusWeek(state, weekIndex) {
  let weeks = getSyllabusWeeks(state);
  return weeks[weekIndex];
}

function getSyllabusOpenedWeekIndex(state) {
  let syllabus = getSyllabus(state);

  return syllabus.openedWeek;
}

function getSyllabusOpenedWeek(state) {
  let syllabus = getSyllabus(state);

  return syllabus.weeks[syllabus.openedWeek] || defaultValueObj;
}

function getSubmitProjectModalStatus(state) {
  const syllabus = getSyllabus(state);

  return syllabus.submitProjectOpened;
}

function getHelpModalStatus(state) {
  const syllabus = getSyllabus(state);

  return syllabus.helpModal;
}

function getSyllabusWeekIsOpen(state, weekIndex) {
  let syllabus = getSyllabus(state);

  return syllabus.openedWeek === weekIndex;
}

function getSyllabusWeekLessons(state, weekIndex) {
  let week = getSyllabusWeek(state, weekIndex);
  return (week && week.lessons) || defaultValueArr;
}

function getSyllabusWeekLesson(state, weekIndex, lessonId) {
  let lessons = getSyllabusWeekLessons(state, weekIndex);
  return lessons[lessonId] || defaultValueObj;
}

export const selectors = {
  getAssignmentLabel,
  getSyllabus,
  getSyllabusData,
  getSyllabusResolveAssetFn,
  getSyllabusSpec,
  getSyllabusWeek,
  getSyllabusWeeks,
  getSyllabusWeekIsOpen,
  getSyllabusOpenedWeekIndex,
  getSyllabusOpenedWeek,
  getSyllabusWeekLesson,
  getSyllabusWeekLessons,
  getSubmitProjectModalStatus,
  getHelpModalStatus
};
