import { gql } from '@apollo/client';

export const updateTextComment = apolloClient => (
  commentSectionId,
  item,
  msg
) => {
  const mutation = gql`
    mutation updateTextComment($input: UpdateTextCommentInput!) {
      updateTextComment(input: $input)
    }
  `;

  const maybePin = item.pin ? { pin: item.pin } : {};
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        commentSectionId,
        commentId: item.id,
        msg,
        ...maybePin
      }
    }
  });
};
