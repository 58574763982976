import { gql } from '@apollo/client';
import { get } from 'lodash';

export const deleteUser = apolloClient => async userId => {
  const mutation = gql`
    mutation deleteUser($userId: ID!) {
      deleteUser(userId: $userId)
    }
  `;
  const response = await apolloClient.mutate({
    mutation,
    variables: {
      userId
    },
    update(cache) {
      const normalizedId = cache.identify({
        id: userId,
        __typename: 'ExtendedUserInfo'
      });
      cache.evict({ id: normalizedId });
      cache.gc();
    }
  });
  if (get(response, 'data.deleteUser') !== userId) {
    console.error('deleteUser failed', response);
    throw new Error('Unexpected Error');
  }
  return userId;
};
