import { gql } from '@apollo/client';

export const updateIntercom = apolloClient => async userId => {
  console.log('updating intercom for', userId);
  const UPDATE_INTERCOM = gql`
    mutation updateIntercom($userId: ID!) {
      updateIntercom(userId: $userId)
    }
  `;
  await apolloClient.mutate({
    mutation: UPDATE_INTERCOM,
    variables: {
      userId
    }
  });
};
