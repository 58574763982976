import { actionType } from '../constants';

const pushPermissionRequiredReducer = (state, action) => {
  let nextState = state;

  switch (action.type) {
    case actionType.updatePushPermissionRequired:
      nextState = action.required;
      break;
  }

  return nextState;
};

export default pushPermissionRequiredReducer;
