import CreateMyFileForm from './CreateMyFileForm';
import React from 'react';
import { connect } from 'react-redux';
import { selectors as myFilesSelectors } from 'reducers/dashboard/myFiles';
import { actions as myFilesActions } from 'reducers/dashboard/myFiles';
import { PopupDialog } from 'components/common/PopupDialog';

export const CreateMyFilePopup = ({ open, crewId, projectId, onClose }) => (
  <PopupDialog open={open} title="Choose a file to upload">
    <CreateMyFileForm onClose={onClose} crewId={crewId} projectId={projectId} />
  </PopupDialog>
);

export default connect(
  state => myFilesSelectors.getMyFileCreator(state),
  dispatch => ({
    onClose: () => dispatch(myFilesActions.closeMyFileCreator())
  })
)(CreateMyFilePopup);
