import { gql } from '@apollo/client';

export const addProject = apolloClient => async (
  item,
  description,
  uploadRecord
) => {
  const asmt = item.assignment;

  const mutation = gql`
    mutation addProject($input: AddProjectInput!) {
      addProject(input: $input)
    }
  `;

  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        userProgramId: asmt.userProgramId,
        courseId: asmt.courseId,
        lessonId: asmt.lessonId,
        assignmentId: asmt.id,
        title: asmt.title,
        description,
        uploadRecord: {
          id: uploadRecord.id,
          ownerId: uploadRecord.ownerId,
          downloadUrl: uploadRecord.downloadUrl,
          status: uploadRecord.status,
          uploadPath: uploadRecord.uploadPath
        }
      }
    }
  });
};
