import { gql } from '@apollo/client';

export const setIntroVideoEncodingError = apolloClient => async uploadRecordId => {
  const mutation = gql`
    mutation setIntroVideoEncodingError(
      $input: SetIntroVideoEncodingErrorInput!
    ) {
      setIntroVideoEncodingError(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        uploadRecordId
      }
    }
  });
};
