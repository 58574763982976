import config from 'config';

export const intercomHelper = () => ({
  stopSession: () => {
    if (config.intercomEnabled) {
      window.Intercom('shutdown');
    }
  },
  boot: () => {
    if (config.intercomEnabled) {
      window.Intercom('boot', {
        app_id: config.intercomAppId
      });
    }
  },
  update: email => {
    if (config.intercomEnabled) {
      window.Intercom('update', {
        email: email,
        app_id: config.intercomAppId,
        hide_default_launcher: true,
        custom_launcher_selector: '.launch-help-chat'
      });
    }
  }
});
