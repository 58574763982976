import moment from 'moment-timezone';
import _, { map } from 'lodash';
import { gql } from '@apollo/client';
import { apolloClient } from 'apolloClient';
import { saveAs } from 'file-saver';

const GET_PROGRAMS = gql`
  query getAdminData {
    programs {
      id
      title
      availabilityDate
      startDate
      weekStartDay
      isTrash
      isTrial
      hasEnded
      isUsingCrewFilesInsteadOfAssets
      programContent {
        version
        id
        title
        paces {
          id
        }
        pace {
          weeks {
            id
          }
        }
      }
    }
    allUserPrograms {
      id
      programInstance
      isInstructor
      students {
        id
      }
      mentors {
        id
        discipline
      }
      userId
      application {
        status
      }
    }
  }
`;

const valuesNoTypename = obj => _.values(obj).map(i => _.omit(i, '__typename'));

const isTrialId = (maybeTrialId, programInstanceId) =>
  maybeTrialId.indexOf(programInstanceId) === 0 &&
  maybeTrialId.indexOf('-trial-') > 0;

export const downloadCsv = async users => {
  const data = (
    await apolloClient.query({
      query: GET_PROGRAMS
    })
  ).data;

  const getUser = userId => {
    const formattedUsers = valuesNoTypename(users);
    const user = userId && _.find(formattedUsers, u => u.id === userId);
    return user || {};
  };

  const getUsers = (pi, type) => {
    const userPrograms = valuesNoTypename(data.allUserPrograms);

    const isStudent = up => {
      const user = getUser(up.userId);
      return user && !user.isMentor;
    };

    const isMentor = up => {
      const user = getUser(up.userId);
      return user && user.isMentor;
    };

    const isMemberOfProgram = up =>
      up.programInstance &&
      (up.programInstance === pi.id || isTrialId(up.programInstance, pi.id));
    const isType = type == 'student' ? isStudent : isMentor;
    const users = userPrograms.reduce(
      (acc, up) =>
        isMemberOfProgram(up) && isType(up)
          ? _.concat(acc, [getUser(up.userId)])
          : acc,
      []
    );
    return users;
  };

  const headerFields = [
    'Start Date',
    'Title',
    'Students',
    'Mentors',
    'Instance ID',
    'Program ID',
    'Open status'
  ];
  let csv = [
    headerFields.join(','),
    ...map(data.programs, program => {
      const students = (
        _.map(getUsers(program, 'student'), u => `${u.fullName}|${u.email}`) ||
        []
      ).join(',');
      const mentors = (
        _.map(getUsers(program, 'mentor'), u => `${u.fullName}|${u.email}`) ||
        []
      ).join(',');
      const openClosed = program.hasEnded ? 'Open' : 'Closed';

      return `${moment(program.startDate).format('D MMMM YYYY')},"${
        program.title
      }","${students}","${mentors}",${program.id},"${
        program.programContent.id
      }",${openClosed}`;
    })
  ];
  const blob = new Blob([csv.join('\n')], {
    type: 'text/plain;charset=utf-8'
  });
  const now = moment();
  saveAs(blob, `programs-${now.format()}.csv`);

  return 'ok';
};
