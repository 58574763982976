import { actionType } from '../constants';
import { receiveEntity } from './receiveEntity';
import { getCommentSection } from '../selectors';

export const loadComments = () => {
  return (dispatch, getState, { api }) => {
    let state = getState();
    let commentSection = getCommentSection(state);

    // console.log('loading cs', commentSection);
    if (!commentSection) {
      return;
    }

    if (commentSection.loaded) {
      return;
    }

    if (commentSection.id === commentSection.nextId) {
      dispatch({ type: actionType.COMMENT_SECTION_LOADED });
      return;
    }

    if (commentSection.id) {
      api.commentSection.disconnect();
    }

    if (commentSection.nextId) {
      let makeHandler = entityType => sn =>
        dispatch(receiveEntity(entityType)(sn.key, sn.value));

      dispatch({ type: actionType.COMMENT_SECTION_START_LOADING });

      let onCompleteCalled = false;
      api.commentSection.connect({
        commentSectionId: commentSection.nextId,
        onCommentValue: makeHandler('comments'),
        onCountsValue: makeHandler('counts'),
        onReplyValue: makeHandler('replies'),
        onUserValue: makeHandler('users'),
        onComplete: () => {
          if (!onCompleteCalled) {
            onCompleteCalled = true;
            dispatch({ type: actionType.COMMENT_SECTION_LOADED });
          }
        }
      });
    }
  };
};
