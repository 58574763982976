import { actionType } from '../constants';
import { selectors as sclSelectors } from '../index';

const initialState = {
  addingComment: false,
  addingPin: false,
  pin: { x: 0, y: 0, color: 'white' }
};

const addCommentReducer = (state = initialState, action, fullState) => {
  switch (action.type) {
    case actionType.startEditingComment:
      const comment = sclSelectors.getComment(fullState, action.id);
      return {
        addingPin: comment && comment.pin
      };

    case actionType.startAddingComment:
      return {
        addingComment: !state.addingComment,
        addingPin: false
      };

    case actionType.clickAddPin:
      return {
        ...state,
        addingPin: !state.addingPin
      };

    case actionType.updatePin:
      return {
        ...state,
        pin: action.pin
      };

    case actionType.addTextComment:
    case actionType.addVideoComment:
    case actionType.cancelAddingComment:
    case actionType.cancelEditingComment:
    case actionType.submitEditedComment:
    case actionType.LOCATION_CHANGE:
      return {
        addingComment: false,
        addingPin: false
      };

    default:
      return state;
  }
};

export default addCommentReducer;
