import React from 'react';
import Select, { components } from 'react-select';
import { selectCustomStyles } from './SmashcutDropdownSelectStyles';
import ReactTooltip from 'react-tooltip';
import { useRef } from 'react';
import MarkDownDisplay from './MarkDownDisplay';

const MarkDownOption = props => {
  // console.log(props);
  const myprops = {
    ...props,
    children: <MarkDownDisplay markDown={props.label}></MarkDownDisplay>
  };
  return <components.Option {...myprops}></components.Option>;
};

const MarkDownSingleValue = props => {
  // console.log(props);
  const myprops = {
    ...props,
    children: <MarkDownDisplay markDown={props.children}></MarkDownDisplay>
  };
  return <components.SingleValue {...myprops}></components.SingleValue>;
};

const DropdownIndicator = props => {
  // console.log(props);
  return (
    <components.DropdownIndicator {...props}>
      {props.isDisabled && <i className="fas fa-lock"></i>}
      {props.selectProps.menuIsOpen && <i className="fas fa-chevron-up"></i>}
      {!props.selectProps.menuIsOpen && <i className="fas fa-chevron-down"></i>}
    </components.DropdownIndicator>
  );
};

const ClearIndicator = props => {
  return (
    <components.ClearIndicator {...props}>
      <i className="fas fa-times-circle" data-tip="clear"></i>
      <ReactTooltip effect="solid" place="top" />
    </components.ClearIndicator>
  );
};

function findOptionAndIndex(options, selectedItem) {
  if (!options) {
    return;
  }
  let optionIndex = -1;
  const recurse = options => {
    for (const opt of options) {
      optionIndex++;
      if (opt.options) {
        // nested options? weird
        const found = recurse(opt.options);
        if (found) {
          return found;
        }
        // allow selectItem to be a value or an option
      } else if (
        opt.value === selectedItem ||
        (selectedItem && opt.value === selectedItem.value)
      ) {
        return { option: opt, optionIndex };
      }
    }
  };
  return recurse(options);
}

function findOption(options, selectedItem) {
  const result = findOptionAndIndex(options, selectedItem);
  return result && result.option ? result.option : null;
}

// preferredValue
// When value is not set, and you open the dropdown, the first few options are
// shown by default.
// By setting preferredValue you can change at which position the dropdown opens
// So the user has less to scroll

export const SmashcutDropdown = ({
  ariaLabel,
  classNamePrefix,
  debug,
  isScrollToViewNotNeed,
  noResultsText,
  onMenuOpen,
  options,
  preferredValue,
  scrollIntoViewOptions,
  useMarkDown,
  value,
  ...props
}) => {
  const selectRef = useRef();
  if (classNamePrefix || onMenuOpen) {
    throw new Error(
      'These properties are ignored because they are used already. See below.'
    );
  }
  if (debug)
    console.log(
      'SmashcutDropdown in debug mode',
      value,
      preferredValue,
      options
    );

  const scrollToSelectedOption = () => {
    setTimeout(() => {
      const result =
        findOptionAndIndex(options, value) ||
        findOptionAndIndex(options, preferredValue);

      if (!result) {
        if (debug)
          console.log(
            'findOptionAndIndex option not found',
            value,
            preferredValue
          );
        return;
      }

      const optionElementId =
        selectRef.current.select.instancePrefix +
        '-option-' +
        result.optionIndex;
      const optionElement = document.getElementById(optionElementId);
      if (!optionElement) {
        if (debug) console.log('optionElement not found', optionElementId);
        return;
      }

      const listElement = optionElement.closest('.smashcut__menu-list');
      if (!listElement) {
        if (debug) console.log('listElement not found', 'smashcut__menu-list');
        return;
      }

      if (
        listElement.scrollHeight < listElement.clientHeight &&
        listElement.scrollWidth < listElement.clientWidth
      ) {
        if (debug)
          console.log(
            'no need to scroll into view because all options are visible'
          );
        return;
      }

      if (debug) console.log('scrolling into view', optionElement);
      optionElement.scrollIntoView({
        behavior: 'instant',
        block: 'nearest',
        inline: 'start',
        ...scrollIntoViewOptions
      });
    }, 1);
  };

  const customComponents = { ClearIndicator, DropdownIndicator };
  if (useMarkDown) {
    customComponents.Option = MarkDownOption;
    customComponents.SingleValue = MarkDownSingleValue;
  }

  return (
    <Select
      ref={selectRef}
      styles={selectCustomStyles}
      components={customComponents}
      options={options}
      value={findOption(options, value)}
      aria-label={ariaLabel || ''}
      noOptionsMessage={() => noResultsText || 'No results found'}
      placeholder="Select"
      onMenuOpen={isScrollToViewNotNeed ? () => {} : scrollToSelectedOption}
      {...props}
      classNamePrefix="smashcut"
      tabIndex="0"
    />
  );
};
