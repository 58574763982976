import { gql } from '@apollo/client';

export const finalizeIntroVideo = apolloClient => async input => {
  const mutation = gql`
    mutation finalizeIntroVideo($input: FinalizeIntroVideoInput!) {
      finalizeIntroVideo(input: $input) {
        id
        created
        downloadUrl
        fileMimeType
        fileName
        fileSize
        fileType
        finished
        isVisible
        ownerId
        started
        status
        uploadDurationMs
        uploadId
        uploadPath
        uploadType
      }
    }
  `;

  const result = await apolloClient.mutate({
    mutation,
    variables: { input }
  });
  return result.data.finalizeIntroVideo;
};
