import { actionType } from '../constants';

export const saveAnnotation = (
  projectId,
  commentId,
  width,
  height,
  annotationData
) => {
  return (dispatch, getState, { api }) => {
    dispatch({
      type: actionType.saveAnnotation,
      commentId,
      width,
      height,
      annotationData
    });

    api.saveAnnotation(projectId, commentId, width, height, annotationData);
  };
};
