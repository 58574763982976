import * as partnerConfig from 'themes/partnerConfig';

export const selectCustomStyles = {
  control: (provided, { isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: '#242424',
    border: 'solid 1px ' + (isFocused ? partnerConfig.highlightColor : '#000'),
    boxShadow: null,
    color: 'white',
    fontSize: '12px',
    height: '32px',
    borderRadius: '2px',
    minHeight: '32px',
    ':hover': {
      border: 'solid 1px #8900e1'
    }
  }),
  clearIndicator: (provided, state) => {
    return {
      ...provided,
      // padding: '4px 8px',
      color: '#aaa',
      ':hover': {
        color: '#fff'
      }
    };
  },
  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      padding: '4px 8px',
      color: '#aaa',
      ':hover': {
        color: '#fff'
      }
    };
  },
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: state.hasValue ? '#444' : 'transparent'
  }),
  input: (provided, state) => ({
    ...provided,
    color: 'white'
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: '#3d3d3d',
    color: 'white',
    marginTop: 2,
    zIndex: 999
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 999
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: isSelected
      ? partnerConfig.highlightColor
      : isFocused
      ? '#303030'
      : 'transparent',
    borderBottom: '1px solid #212020',
    fontSize: '11px',
    color: 'white'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: 'white'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'white'
  })
};
