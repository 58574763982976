import { actionType } from '../constants';
import { getCurrentUserId } from '../selectors';

export const updateUserSettings = settings => {
  return (dispatch, getState, { api }) => {
    let state = getState();
    let currentUserId = getCurrentUserId(state);
    api.user.updateSettings(currentUserId, settings).then(() => {
      dispatch({
        type: actionType.updateUserSettings,
        settings
      });
    });
  };
};
