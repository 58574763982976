import { gql } from '@apollo/client';

export const restoreIntroVideo = apolloClient => async userId => {
  const mutation = gql`
    mutation restoreIntroVideo($input: RestoreIntroVideoInput!) {
      restoreIntroVideo(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        userId
      }
    }
  });
};
