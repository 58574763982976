import { all, call, put, takeEvery } from 'redux-saga/effects';
import { types } from 'reducers/shareableFiles';
import { gql } from '@apollo/client';

const mutations = {
  SET_DETAILS: gql`
    mutation setShareableFileDetails($input: ShareableFileUpdateInput!) {
      setShareableFileDetails(input: $input) {
        id
        description
        title
      }
    }
  `,
  SET_IS_VISIBLE: gql`
    mutation setShareableFileIsVisible($input: ShareableFileUpdateInput!) {
      setShareableFileIsVisible(input: $input) {
        id
        isVisible
      }
    }
  `
};

export function* updateDetails(
  apolloClient,
  { updates: { file, description, name } }
) {
  try {
    yield call(apolloClient.mutate, {
      mutation: mutations.SET_DETAILS,
      variables: {
        input: {
          id: file.id,
          uploadType: file.uploadType,
          description,
          title: name
        }
      }
    });
    yield put({
      type: types.UPDATE_DETAILS_SUCCESS,
      newFile: {
        id: file.id,
        description,
        name
      }
    });
  } catch (e) {
    console.warn('nok', e);
    yield put({
      type: types.UPDATE_DETAILS_FAILURE,
      error: 'An error occurred while updating, please try again'
    });
  }
}

export function* updateIsVisible(apolloClient, { updates: { file, value } }) {
  try {
    yield call(apolloClient.mutate, {
      mutation: mutations.SET_IS_VISIBLE,
      variables: {
        input: {
          id: file.id,
          uploadType: file.uploadType,
          isVisible: value
        }
      }
    });
    yield put({
      type: types.UPDATE_ISVISIBLE_SUCCESS,
      id: file.id,
      newIsVisible: value
    });
  } catch (e) {
    console.warn('nok', e);
    yield put({
      type: types.UPDATE_ISVISIBLE_FAILURE,
      error: 'An error occurred while updating, please try again'
    });
  }
}

export function* shareableFilesSaga({ apis }) {
  yield all([
    takeEvery(
      types.UPDATE_DETAILS_REQUEST,
      updateDetails.bind(this, apis.apolloClient)
    ),
    takeEvery(
      types.UPDATE_ISVISIBLE_REQUEST,
      updateIsVisible.bind(this, apis.apolloClient)
    )
  ]);
}
