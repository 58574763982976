import { actionType } from '../constants';

const currentProjectReducer = (state, action /*, fullState*/) => {
  let newState = state;

  switch (action.type) {
    case actionType.selectProject:
      // console.log(action.type, action);
      newState = action.project;
      break;

    case actionType.LOCATION_CHANGE:
      if (!(action.location.pathname || '').startsWith('/project')) {
        newState = null;
      }
      break;
  }

  return newState;
};

export default currentProjectReducer;
