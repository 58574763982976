import _ from 'lodash';

const BASE = 'SCHEDULE_BOOKINGS';

export const types = {
  BOOK_SCHEDULE_REQUEST: `${BASE}/BOOK_SCHEDULE_REQUEST`,
  BOOK_SCHEDULE_SUCCESS: `${BASE}/BOOK_SCHEDULE_SUCCESS`,
  BOOK_SCHEDULE_FAILURE: `${BASE}/BOOK_SCHEDULE_FAILURE`,
  SCHEDULE_MEETING_REQUEST: `${BASE}/SCHEDULE_MEETING_REQUEST`,
  SCHEDULE_MEETING_SUCCESS: `${BASE}/SCHEDULE_MEETING_SUCCESS`,
  SCHEDULE_MEETING_FAILURE: `${BASE}/SCHEDULE_MEETING_FAILURE`,
  DELETE_BOOKING_REQUEST: `${BASE}/DELETE_BOOKING_REQUEST`,
  DELETE_BOOKING_SUCCESS: `${BASE}/DELETE_BOOKING_SUCCESS`,
  DELETE_BOOKING_FAILURE: `${BASE}/DELETE_BOOKING_FAILURE`,
  LOAD_USER_DATA_REQUEST: `${BASE}/LOAD_USER_DATA_REQUEST`,
  LOAD_USER_DATA_SUCCESS: `${BASE}/LOAD_USER_DATA_SUCCESS`,
  LOAD_USER_DATA_FAILURE: `${BASE}/LOAD_USER_DATA_FAILURE`,
  LOAD_USERS_AVAILABILITY_AND_BOOKINGS_REQUEST: `${BASE}/LOAD_USERS_AVAILABILITY_AND_BOOKINGS_REQUEST`,
  LOAD_USERS_AVAILABILITY_AND_BOOKINGS_SUCCESS: `${BASE}/LOAD_USERS_AVAILABILITY_AND_BOOKINGS_SUCCESS`,
  LOAD_USERS_AVAILABILITY_AND_BOOKINGS_FAILURE: `${BASE}/LOAD_USERS_AVAILABILITY_AND_BOOKINGS_FAILURE`
};

export const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.BOOK_SCHEDULE_REQUEST:
    case types.SCHEDULE_MEETING_REQUEST:
      return {
        ...state,
        bookingError: undefined,
        bookingInProgress: true,
        bookingSuccess: undefined,
        shouldRefetch: false
      };

    case types.BOOK_SCHEDULE_SUCCESS:
    case types.SCHEDULE_MEETING_SUCCESS:
      // console.log('Booking Success', action.result);
      return {
        ...state,
        bookingError: undefined,
        bookingInProgress: false,
        bookingSuccess: true,
        shouldRefetch: true
      };

    case types.BOOK_SCHEDULE_FAILURE:
    case types.SCHEDULE_MEETING_FAILURE:
      // console.log('Booking Error', action);
      return { ...state, bookingInProgress: false, bookingError: action.error };

    case types.DELETE_BOOKING_REQUEST:
      return {
        ...state,
        deletingInProgress: true,
        shouldRefetch: false,
        deletingError: undefined
      };

    case types.DELETE_BOOKING_SUCCESS:
      return {
        ...state,
        deletingInProgress: false,
        shouldRefetch: true,
        deletingError: undefined
      };

    case types.DELETE_BOOKING_FAILURE:
      return {
        ...state,
        deletingInProgress: false,
        deletingError: action.error
      };

    case types.LOAD_USER_DATA_REQUEST:
      return { ...state, loadingUsersData: true };

    case types.LOAD_USER_DATA_SUCCESS:
      let userDataMap = state.userDataMap ? _.cloneDeep(state.userDataMap) : {};
      userDataMap[action.userId] = action.data.user;
      return { ...state, loadingUsersData: false, userDataMap };

    case types.LOAD_USER_DATA_FAILURE:
      return { ...state, loadingUsersData: false };

    case types.LOAD_USERS_AVAILABILITY_AND_BOOKINGS_REQUEST:
      return {
        ...state,
        user1Availability: undefined,
        user2Availability: undefined,
        user1Bookings: undefined,
        user2Bookings: undefined,
        loadingUsersAvailabilityAndBookings: true
      };

    case types.LOAD_USERS_AVAILABILITY_AND_BOOKINGS_SUCCESS:
      return {
        ...state,
        loadingUsersAvailabilityAndBookings: false,
        ...action.data
      };

    case types.LOAD_USERS_AVAILABILITY_AND_BOOKINGS_FAILURE:
      return { ...state, loadingUsersAvailabilityAndBookings: false };

    default:
      return state;
  }
};

export const actions = {
  scheduleMeeting: input => ({
    type: types.SCHEDULE_MEETING_REQUEST,
    input
  }),
  bookSchedule: input => ({
    type: types.BOOK_SCHEDULE_REQUEST,
    input
  }),
  deleteBooking: bookingId => ({
    type: types.DELETE_BOOKING_REQUEST,
    bookingId
  }),
  loadUsersAvailabilityAndBookings: (firstUserId, secondUserId) => ({
    type: types.LOAD_USERS_AVAILABILITY_AND_BOOKINGS_REQUEST,
    firstUserId,
    secondUserId
  }),
  loadUserData: userId => ({
    type: types.LOAD_USER_DATA_REQUEST,
    userId
  })
};
