import { isEqual } from 'lodash';

const cache = {};

const defaultReturnValue = undefined;

const logCache = {};

export function getFullUserProgram(state, id) {
  let up = state.entities.userPrograms[id] || {};
  let pi = state.entities.programInstances[up.programInstance] || {};
  let p = state.entities.programs[pi.program];

  if (!p) {
    // console.warn('getFullUserProgram incomplete', p, pi, up);
    return defaultReturnValue;
  }
  if (!p.versions) {
    if (!logCache[id]) {
      logCache[id] = true;
      console.warn(
        'Unexpected program format: Missing versions property.',
        id,
        p,
        pi,
        up
      );
    }
    return defaultReturnValue;
  }
  let args = [up, pi, p];
  let cachedResults = cache[id];
  if (cachedResults && isEqual(cachedResults.args, args)) {
    return cachedResults.result;
  }
  let resolvedProgram = {
    ...p.versions[pi.programVersion],
    id: pi.program,
    version: pi.programVersion
  };
  cachedResults = {
    args,
    result: {
      ...up,
      id,
      programInstanceId: up.programInstance,
      programInstance: {
        ...pi,
        id: up.programInstance,
        program: resolvedProgram,
        programId: p.id
      }
    }
  };
  cache[id] = cachedResults;
  return cachedResults.result;
}
