import addCommentReducer from './addCommentReducer';
import appSettingsReducer from './appSettingsReducer';
import authReducer from './authReducer';
import commentFilterReducer from './commentFilterReducer';
import commentSectionReducer from './commentSectionReducer';
import currentCommentIdReducer from './currentCommentIdReducer';
import currentProjectIdReducer from './currentProjectIdReducer';
import currentProjectReducer from './currentProjectReducer';
import entitiesReducer from './entitiesReducer';
import inlineEditorReducer from './inlineEditorReducer';
import listOfCommentsReducer from './listOfCommentsReducer';
import listOfRepliesReducer from './listOfRepliesReducer';
import messagesReducer from './messagesReducer';
import newReplyEditorReducer from './newReplyEditorReducer';
import pushPermissionRequiredReducer from '../reducers/pushPermissionRequiredReducer';
import recordVideoPopupReducer from './recordVideoPopupReducer';
import coBrowsingReducer from './coBrowsingReducer';
import userProgramsReducer from './userProgramsReducer';
import videoAnnotatorReducer from './videoAnnotatorReducer';
import videoCommentPlayerReducer from './videoCommentPlayerReducer';
import videoPlayerReducer from './videoPlayerReducer';
import { cloneDeep, isEqual } from 'lodash';

/*
 @additionalReducers Format: [{selector: '', reducerFunction: ()=>{}}, ...]
 */
const mainReducer = (config, additionalReducers) => (state, action) => {
  let saveState;
  let debugStateChanges = config && config.debugStateChanges;

  // Note: slow!
  if (debugStateChanges) {
    saveState = cloneDeep(state);
  }

  let nextState = { ...state };

  nextState.addComment = addCommentReducer(state.addComment, action, state);
  nextState.appSettings = appSettingsReducer(state.appSettings, action);
  nextState.auth = authReducer(state.auth, action);
  nextState.commentFilter = commentFilterReducer(
    state.commentFilter,
    action,
    state
  );
  nextState.commentSection = commentSectionReducer(
    state.commentSection,
    action,
    state
  );
  nextState.currentCommentId = currentCommentIdReducer(
    state.currentCommentId,
    action,
    state
  );
  nextState.currentProject = currentProjectReducer(
    state.currentProject,
    action,
    state
  );
  nextState.currentProjectId = currentProjectIdReducer(
    state.currentProjectId,
    action,
    state
  );
  nextState.entities = entitiesReducer(state.entities, action, state);
  nextState.inlineEditor = inlineEditorReducer(
    state.inlineEditor,
    action,
    state
  );
  nextState.listOfComments = listOfCommentsReducer(
    state.listOfComments,
    action,
    state
  );
  nextState.listOfReplies = listOfRepliesReducer(
    state.listOfReplies,
    action,
    state
  );
  nextState.messages = messagesReducer(state.messages, action, state);
  nextState.newReplyEditor = newReplyEditorReducer(
    state.newReplyEditor,
    action,
    state
  );
  nextState.pushPermissionRequired = pushPermissionRequiredReducer(
    state.pushPermissionRequired,
    action,
    state
  );
  nextState.recordVideoPopup = recordVideoPopupReducer(
    state.recordVideoPopup,
    action,
    state
  );
  nextState.replay = coBrowsingReducer(state.replay, action, state);
  nextState.userPrograms = userProgramsReducer(
    state.userPrograms,
    action,
    state
  );
  nextState.videoAnnotator = videoAnnotatorReducer(
    state.videoAnnotator,
    action,
    state
  );
  nextState.videoCommentPlayer = videoCommentPlayerReducer(
    state.videoCommentPlayer,
    action,
    state
  );
  nextState.videoPlayer = videoPlayerReducer(state.videoPlayer, action, state);

  if (additionalReducers) {
    additionalReducers.forEach(r => {
      nextState[r.selector] = r.reducerFunction(
        state[r.selector],
        action,
        state
      );
    });
  }

  if (debugStateChanges) {
    if (!isEqual(saveState, state)) {
      console.error('State should not be changed');
    }
  }

  return nextState;
};

export default mainReducer;
