import { actionType } from '../constants/actionTypes';

const initialState = {
  impersonatingUser: false,
  data: {},
  users: []
};

const adminReducer = (state = initialState, action) => {
  let nextState = state;
  switch (action.type) {
    case actionType.adminDataUpdated:
      // console.log('Admin data updated', action);
      const newObj = { ...action.data };
      nextState = { ...state, data: newObj };
      break;

    case actionType.impersonateSuccess:
      nextState = { ...state, impersonatingUser: action.user };
      break;

    case actionType.impersonateExit:
      nextState = { ...state, impersonatingUser: false };
      break;

    case actionType.addEnrolleeSuccess:
      nextState = { ...state, enrollSuccessful: true };
      break;

    case actionType.loadAllUserSuccess:
      nextState = { ...state, users: action.users };
      break;
  }
  return nextState;
};

export default adminReducer;
