import { actionType } from 'smashcut-client-lib';
import { types as screenplayTypes } from './screenplayView';

const PREFIX = 'VIDEOAPP_TAB';

const types = {
  GOTO_TAB: `${PREFIX}/GOTO_TAB`
};

const INFO = 0;
const COMMENTS = 1;

const initialState = {
  tabIndex: INFO
};

export const videoAppTabReducer = (state = initialState, action) => {
  let nextState = state;

  switch (action.type) {
    case actionType.gotoComment:
    case actionType.addTextComment:
    case actionType.addVideoComment:
    case screenplayTypes.SELECT_CHUNK:
    case screenplayTypes.SELECT_NEXT_CHUNK:
    case screenplayTypes.SELECT_PREV_CHUNK:
      if (state.tabIndex != COMMENTS) {
        nextState = { ...state, tabIndex: COMMENTS };
      }
      break;

    case types.GOTO_TAB:
      nextState = { ...state, tabIndex: action.tabIndex };
      break;
  }

  return nextState;
};

function setTabIndex(tabIndex) {
  return {
    type: types.GOTO_TAB,
    tabIndex
  };
}

export const actions = {
  setTabIndex
};

function getVideoAppTab(state) {
  return state.videoAppTab;
}

export const selectors = {
  getVideoAppTab
};
