import moment from 'moment-timezone';

// ms => 1d 5h 34m
const formatDuration = ms => {
  const dur = moment.duration(ms);
  const d = dur.days();
  const h = dur.hours();
  let m = dur.minutes();
  if (m + h + d === 0) {
    m = 1;
  }
  return (d ? `${d}d ` : '') + (h ? `${h}h ` : '') + `${m}m`;
};

export default formatDuration;
