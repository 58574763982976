import { actions as lessonActions } from 'reducers/lesson';

const PREFIX = 'DASHBOARD_ACTIVITIES';

export const types = {
  START_PROJECT_REVIEW: `${PREFIX}/START_PROJECT_REVIEW`
};

const initialState = {};

export const activitiesReducer = (state = initialState, action) => {
  let nextState = state;

  switch (action.type) {
    case types.START_PROJECT_REVIEW:
      nextState = { ...state };
      nextState.reviewItem = action.item;
      break;
  }
  return nextState;
};

export const startProjectReview = item => {
  return dispatch => {
    dispatch(
      lessonActions.gotoLesson({
        programId: item.project.userProgramId,
        courseId: item.project.courseId,
        lessonId: item.project.lessonId,
        itemId: item.project.assignmentId,
        itemType: 'assignment',
        videoId: item.project.id
      })
    );
    dispatch({ type: types.START_PROJECT_REVIEW, item });
  };
};

export const actions = {
  startProjectReview
};

const defaultValueArr = [];

function getDashboardActivityItems(/*state*/) {
  return defaultValueArr;
}

export const selectors = {
  getDashboardActivityItems
};
