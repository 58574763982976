import { logAndSendError } from 'utils/sentryHelper';

export const toggleIsUsingCrewFiles = programInstanceId => (
  dispatch,
  getState,
  { adminApi }
) => {
  adminApi
    .toggleIsUsingCrewFiles(programInstanceId)
    .catch(e => logAndSendError(e, 'error toggling crew files '));
};
