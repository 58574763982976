import * as authUtils from 'common/authUtils';
import { gql } from '@apollo/client';

const authService = (config, apolloClient) => ({
  getToken: () => authUtils.getToken(),

  resetPassword: async email => {
    const mutation = gql`
      mutation requestPasswordReset($input: RequestPasswordResetInput!) {
        requestPasswordReset(input: $input)
      }
    `;
    const resetResult = await apolloClient.mutate({
      mutation,
      variables: {
        input: {
          email
        }
      }
    });
  },

  confirmPasswordReset: async (token, newPassword) => {
    const mutation = gql`
      mutation confirmPasswordReset($input: ConfirmPasswordResetInput!) {
        confirmPasswordReset(input: $input)
      }
    `;
    await apolloClient.mutate({
      mutation,
      variables: {
        input: {
          token,
          newPassword
        }
      }
    });
  },

  signInWithCustomToken: async (user, token) => {
    authUtils.signIn(user, token);
  },

  sendEmailVerification: async () => {
    // TODO
  },

  signOut: async () => {
    authUtils.signOut();
  }
});

export default authService;
