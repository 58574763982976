import { gql } from '@apollo/client';

export function updateProgramForEnrollment(apolloClient) {
  const mutation = gql`
    mutation updateProgramForEnrollment(
      $input: UpdateProgramForEnrollmentInput!
    ) {
      updateProgramForEnrollment(input: $input)
    }
  `;

  return async (programInstanceId, updates) => {
    await apolloClient.mutate({
      mutation,
      variables: {
        input: {
          programInstanceId,
          ...updates
        }
      },
      refetchQueries: ['getProgramsForEnrollment']
    });
  };
}
