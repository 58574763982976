'use strict';

// Settings configured here will be merged into the final config object.

export const awsApi = 'https://dev.api.smashcut.com';

export const awsWss =
  'wss://r1jf4tuu7g.execute-api.eu-west-1.amazonaws.com/dev';

let o = {
  apolloUri: `${awsApi}/graphql`,
  apolloCmsUriEnroll:
    'https://api-us-east-1.graphcms.com/v2/ck5rwi62z3lvv01fggazkhq4v/master',
  apolloCmsUriRegister:
    'https://api-us-east-1.graphcms.com/v2/ck5qqyhk801ql01flhy9l0a29/master',
  auth0Enabled: false,
  auth0Audience: 'smashcut-api',
  auth0ClientId: 'Ht4BXYhgKOhu6e1m49ZvqXCSHJbz50kI',
  auth0Domain: 'dev-smashcut.eu.auth0.com',
  baseUrl: 'https://dev.smashcut.com',
  basePath: '/main',
  camSharingEnabled: false,
  chromeDownloadUrl: 'https://www.google.com/chrome/browser/desktop/index.html',
  createAccountEnabled: false,
  env: 'dev',
  googleGtmId: 'GTM-K9ZWP8W',
  intercomAppId: 'pvzh8im2',
  intercomEnabled: true,
  logGraphql: false,
  logrocket: 'px1cem/smashcut-web-dev',
  logRocketEnabled: false,
  onlineStatusUpdateInterval: 60 * 1000,
  opentokApiKey: '47015434',
  platform: 'web',
  publicSiteUrl: 'https://staging.marketing.smashcut.com/',
  pushMessagingPublicVapidKey:
    'BGJ9RM516CY0zmwPZuCW79M04e-3VqMEnvSyioJkK0BhX4cuNRlDyaXVnA6xGdrMkDbQb-tooW7fx-H6AarWlhQ',
  recurlyPublicKey: 'ewr1-Odobpq1StZc7gVFL3tCZud',
  registerAndEnrollAccountEnabled: true,
  reduxLoggerEnabled: false,
  sentry:
    'https://d527facc0c9f424fad539428fa66ccae@o82327.ingest.sentry.io/179641',
  sentryEnabled: false,
  smashcutApiUrl: awsApi,
  wssUrl: awsWss,
  segmentWriteKey: '8Dvs9RxnKzsY3RpSjI6Y5BA4HDAY8gyM'
};

export default o;
