import * as authUtils from 'common/authUtils';
import * as partnerConfig from 'themes/partnerConfig';
import Auth0Lock from 'auth0-lock';
import './Auth0Lock.scss';
import config from 'config';
import { verifyAccount } from '../../smashcut-client-lib/actions/userActions';

const domain = config.auth0Domain;
const cid = config.auth0ClientId;
const options = {
  // configurationBaseUrl: 'https://cdn.eu.auth0.com',
  oidcConformant: true,
  //allowedConnections: ['Username-Password-Authentication','dev-columbia-saml'],
  //allowedConnections: ['dev-columbia-saml'],
  allowShowPassword: true,
  allowSignUp: false,
  usernameStyle: 'email',
  rememberLastLogin: false,
  //defaultDatabaseConnection: 'acme',
  prefill: {
    //email: 'johnfoo@gmail.com'
  },
  closable: false,
  //defaultDatabaseConnection: 'Username-Password-Authentication',
  //defaultEnterpriseConnection: 'dev-columbia-saml',
  auth: {
    redirectUrl: window.location.origin, // If not specified, defaults to the current page
    responseType: 'token id_token',
    audience: config.auth0Audience,
    params: {
      scope: 'openid profile email https://dev.smashcut.com/' // Learn about scopes: https://auth0.com/docs/scopes
    }
  },
  languageDictionary: {
    title: ''
  },
  theme: {
    logo: window.location.origin + partnerConfig.auth0Logo,
    primaryColor: partnerConfig.highlightColor
  }
};

let store = {
  dispatch: () => {}
};

export const auth0SetStore = initializedStore => {
  store = initializedStore;
};

const auth0LockInit = () => {
  const auth0Lock = new Auth0Lock(cid, domain, options);

  auth0Lock.on('signin submit', function() {
    console.log('signin submit');
  });

  auth0Lock.on('authenticated', function(authResult) {
    console.log('auth0Lock success', authResult, window.location);

    auth0Lock.getUserInfo(authResult.accessToken, function(error, profile) {
      console.log(error, profile);

      store
        .dispatch(verifyAccount(profile, authResult.accessToken))
        .catch(e => {
          alert(e);
          window.location.reload();
        });
    });
  });

  auth0Lock.on('authorization_error', function(error) {
    console.log('authorization_error', error);
  });

  return auth0Lock;
};

export const auth0Lock = config.auth0Enabled ? auth0LockInit() : null;
