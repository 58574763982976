import { gql } from '@apollo/client';

const GET_APP_VERSION = gql`
  query getAppVersion {
    appVersion {
      frontendVersion
      backendVersion
    }
  }
`;

const ON_APP_VERSION = gql`
  subscription onAppVersionUpdate {
    onAppVersionUpdate {
      frontendVersion
      backendVersion
    }
  }
`;

let subscription;

const subscribeToAppVersion = apolloClient => async callback => {
  const result = await apolloClient.query({
    query: GET_APP_VERSION,
    fetchPolicy: 'network-only'
  });

  let appVersion = result.data.appVersion;
  // console.log('appVersion', result);
  callback(appVersion);

  subscription = apolloClient
    .subscribe({
      query: ON_APP_VERSION
    })
    .subscribe({
      next(response) {
        // console.log('appVersion sub', response.data);
        let appVersion = result.data.onAppVersionUpdate;
        callback(appVersion);
      },
      error(err) {
        console.error('err', err);
      }
    });
  return subscription;
};

const unsubscribeFromAppVersion = apolloClient => () => {
  subscription && subscription.unsubscribe();
  subscription = null;
};

export function initAppVersionApi(apolloClient) {
  return {
    appVersion: {
      subscribe: subscribeToAppVersion(apolloClient),
      unsubscribe: unsubscribeFromAppVersion(apolloClient)
    }
  };
}
