const prefix = 'MOBILE_WARNING';

export const types = {
  SHOW_WARNING: `${prefix}/SHOW_WARNING`,
  HIDE_WARNING: `${prefix}/HIDE_WARNING`
};

export const initialState = {
  open: false
};

export default function mobileBetaWarning(state = initialState, action) {
  let nextState = state;

  switch (action.type) {
    case types.SHOW_WARNING:
      nextState = {
        ...state,
        open: true
      };
      break;

    case types.HIDE_WARNING:
      nextState = {
        ...state,
        open: false
      };
      break;
  }
  return nextState;
}

export const actions = {
  showWarning: () => ({ type: types.SHOW_WARNING }),
  hideWarning: () => ({ type: types.HIDE_WARNING })
};

export const selectors = {
  isMobileBetaWarningOpen: state => state.mobileBetaWarning.open
};
