// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Main--topContainer--3d330BVN{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:100%;height:100vh}header:not(:empty) ~ main.Main--topContainer--3d330BVN{min-height:calc(100vh - 48px);height:calc(100vh - 48px)}.Main--redirectMessageHolder--3fJ6M-ZU{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;height:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-ms-flex-pack:distribute;justify-content:space-around}.Main--loadingOverlay--1jLMYWnB{position:fixed;top:0;bottom:0;left:0;right:0;background:rgba(0, 0, 0, .8);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;z-index:333333}\n", ""]);
// Exports
exports.locals = {
	"topContainer": "Main--topContainer--3d330BVN",
	"redirectMessageHolder": "Main--redirectMessageHolder--3fJ6M-ZU",
	"loadingOverlay": "Main--loadingOverlay--1jLMYWnB"
};
module.exports = exports;
