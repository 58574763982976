import config from 'config';

// This utility allows for manual testing of difficult to create
// states of the program
// isTest('xyz') becomes true when you add ?test=xyz to the url
//
export function isTest(expectedContent) {
  try {
    const url = new URL(window.location.href);
    const testQueryContent = url.searchParams.get('test');
    return testQueryContent === expectedContent;
  } catch (e) {
    // ignore
  }
  return false;
}
