import moment from 'moment-timezone';
import _, { omit } from 'lodash';
import { gql } from '@apollo/client';

let pollIvHandle,
  firstUpdaterUnsubscribe,
  secondUpdaterUnsubscribe,
  tooltipUnsubscribe,
  badgeUnsubscribe;

let cache = {
  badge: [],
  tooltip: []
};

let subscription = {
  badge: undefined,
  tooltip: undefined
};

let callbacks = {
  badge: undefined,
  tooltip: undefined
};

const NOTIFICATION_FRAGMENT = `
  id
  created
  type
  link
  channels {
    web
    badge
    tooltip
  }
  contents {
    web {
      title
      message
    }
  }
  bigImage {
    reference
    type
  }
  icon
  seen
  data
`;

const onNotificationSubscription = gql`
  subscription onNewNotification($userId: String) {
    onNewNotification(userId: $userId) {
      id
      type
    }
  }
`;

const convertDataToJSON = n => {
  const newN = { ...n };
  if (n.data) {
    try {
      newN.data = JSON.parse(n.data);
    } catch (_) {}
  }
  return newN;
};

const listen = async (apolloClient, userId, channel, callback) => {
  const query = gql`
    query getUserNotifications($userId: String!) {
      user(id: $userId) {
        id
        notifications {
          ${NOTIFICATION_FRAGMENT}
        }
      }
    }
  `;
  const result = await apolloClient.query({
    query,
    variables: {
      userId
    }
  });

  const { notifications } = result.data.user || {};
  cache[channel] = _(notifications)
    .filter(n => n.channels && n.channels.badge)
    .map(convertDataToJSON)
    .value();

  callback && callback(cache[channel]);

  callbacks[channel] = callback;

  subscription[channel] = apolloClient
    .subscribe({
      query: onNotificationSubscription,
      variables: {
        userId
      }
    })
    .subscribe({
      next(data) {
        const notification = data.data.onNewNotification;
        if (notification.channels[channel]) {
          console.log('New Notification for channel', channel, notification);
          cache[channel] = [
            omit(notification, '__typename'),
            ...cache[channel]
          ];

          callback && callback(cache[channel]);
        }
      },
      error(err) {
        console.error('Error subscribing to notifications: ', err);
      }
    });
};

const listenToBadgeNotifications = apolloClient => (userId, callback) =>
  listen(apolloClient, userId, 'badge', callback);

const listenToTooltipNotifications = apolloClient => (userId, callback) =>
  listen(apolloClient, userId, 'tooltip', callback);

const listenToUpcomingLMSNotifications = apolloClient => (
  userId,
  callback,
  pollInterval
) => {
  // TODO
  // const fs = dbService.getFirestore();
  //
  // const callUpdate = selector => snapshot => {
  //   let bookings = [];
  //   snapshot.forEach(function(doc) {
  //     const data = doc.data();
  //     bookings.push({
  //       id: doc.id,
  //       ...data,
  //       from: moment.utc(data.from.seconds * 1000),
  //       to: moment.utc(data.to.seconds * 1000)
  //     });
  //   });
  //   callback && callback(selector, bookings);
  // };
  //
  // const beforeNow = moment()
  //   .subtract(0.5, 'hours')
  //   .toDate();
  //
  // const afterNow = moment()
  //   .add(2, 'hours')
  //   .toDate();
  //
  // const query = (userSelector, before, after) =>
  //   fs
  //     .collection('lmsBookings')
  //     .where(userSelector, '==', userId)
  //     .where('from', '>', before)
  //     .where('from', '<', after)
  //     .orderBy('from');
  //
  // firstUpdaterUnsubscribe = query(
  //   'firstUserId',
  //   beforeNow,
  //   afterNow
  // ).onSnapshot(callUpdate('first'));
  // secondUpdaterUnsubscribe = query(
  //   'secondUserId',
  //   beforeNow,
  //   afterNow
  // ).onSnapshot(callUpdate('second'));
  //
  // if (pollInterval && !pollIvHandle) {
  //   pollIvHandle = setInterval(() => {
  //     const beforeNow = moment()
  //       .subtract(0.5, 'hours')
  //       .toDate();
  //
  //     const afterNow = moment()
  //       .add(2, 'hours')
  //       .toDate();
  //
  //     query('firstUserId', beforeNow, afterNow)
  //       .get()
  //       .then(s => callUpdate('first')(s));
  //     query('secondUserId', beforeNow, afterNow)
  //       .get()
  //       .then(s => callUpdate('second')(s));
  //   }, pollInterval);
  // }
};

const markNotificationSeen = apolloClient => async (userId, notificationId) => {
  const mutation = gql`
    mutation markNotificationSeen($userId: ID!, $notificationId: ID!) {
      markNotificationSeen(userId: $userId, notificationId: $notificationId)
    }
  `;

  try {
    await apolloClient.mutate({
      mutation,
      variables: {
        userId,
        notificationId
      }
    });

    const notification = cache.badge.find(n => n.id === notificationId);
    notification.seen = true;

    if (callbacks.badge) {
      callbacks.badge(cache.badge);
    }
  } catch (e) {
    console.log('Notification Error', e);
  }
};

const unsubscribe = () => {
  // if (pollIvHandle) {
  //   clearInterval(pollIvHandle);
  // }
  // if (firstUpdaterUnsubscribe) {
  //   firstUpdaterUnsubscribe();
  // }
  // if (secondUpdaterUnsubscribe) {
  //   secondUpdaterUnsubscribe();
  // }
  // if (tooltipUnsubscribe) {
  //   tooltipUnsubscribe();
  // }
  // if (badgeUnsubscribe) {
  //   badgeUnsubscribe();
  // }
  for (const key of Object.keys(subscription)) {
    subscription[key] && subscription[key].unsubscribe();
    delete subscription[key];
    delete cache[key];
    delete callbacks[key];
  }
};

const initNotificationApi = apolloClient => ({
  notifications: {
    unsubscribe,
    listenToBadgeNotifications: listenToBadgeNotifications(apolloClient),
    listenToTooltipNotifications: listenToTooltipNotifications(apolloClient),
    listenToUpcomingLMSNotifications: listenToUpcomingLMSNotifications(
      apolloClient
    ),
    markNotificationSeen: markNotificationSeen(apolloClient)
  }
});

export default initNotificationApi;
