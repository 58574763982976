import { getLastSeens } from './getLastSeens';
import { subscribeToMessages } from './subscribeToMessages';
import { sendMessage } from './sendMessage';
import { removeCrewMessage } from './removeCrewMessage';
import { apolloClient } from '../../apolloClient';

const api = config => {
  return {
    getLastSeens: getLastSeens(apolloClient),
    subscribeToMessages: subscribeToMessages(apolloClient),
    sendMessage: sendMessage(apolloClient),
    removeCrewMessage: removeCrewMessage(apolloClient)
  };
};

export default api;
