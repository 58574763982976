import { gql } from '@apollo/client';

export const updateAvatar = apolloClient => (userId, avatarBase64) => {
  console.log('updateAvatar', userId);
  const UPLOAD = gql`
    mutation($userId: String!, $source: GraphQLUpload!) {
      uploadBase64Avatar(userId: $userId, source: $source) {
        avatar
      }
    }
  `;
  return apolloClient
    .mutate({
      mutation: UPLOAD,
      variables: {
        userId,
        // Removing the data:image/jpeg;base64 from the base64
        source: avatarBase64.slice(22)
      }
    })
    .then(res => res.data.uploadBase64Avatar)
    .catch(err => {
      console.error('updateAvatar: error', err);
      throw err;
    });
};
