import * as userActions from 'smashcut-client-lib/actions/userActions';
import ButtonAndLink from './ButtonAndLink';
import config from 'config';
import Input from 'react-toolbox/lib/input';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import theme from './LoginForm.scss';
import { connect } from 'react-redux';
import { getBrowser } from 'smashcut-client-lib/utils/getBrowser';
import { getCurrentUser } from 'smashcut-client-lib/selectors';
import { withRouter } from 'react-router-dom';
import { userDisabledErrorMessage } from 'themes/userDisabledErrorMessage';
import { withDeviceDetector } from 'components/common/withDeviceDetector';
import { auth0Lock } from './Auth0Lock';
import {
  clearRedirect,
  getRedirect,
  setRedirect
} from '../../common/authUtils';
import { actions as mobileBetaWarningActions } from 'reducers/mobileBetaWarning';

class LoginForm extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      email: props.email || '',
      password: '',
      focused: false
    };
  }

  handleKeyDown = e => {
    switch (e.code) {
      case 'Enter':
        this.state.focused && this.onClickLogin();
        break;
    }
  };

  addKeyboardHandler() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  removeKeyboardHandler() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  onLogin() {
    const {
      history: { replace },
      redirect,
      shouldShowProgramSelector,
      isRegistering,
      isMobile,
      openMobileBetaWarning
    } = this.props;

    if (isRegistering) {
      replace(redirect);
    } else if (shouldShowProgramSelector) {
      replace('/select-program?redirect=' + redirect);
    } else {
      replace(redirect);
      isMobile && openMobileBetaWarning();
    }
    clearRedirect();
  }

  UNSAFE_componentWillMount() {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      this.onLogin();
    } else {
      this.addKeyboardHandler();
    }
  }

  componentDidMount() {
    if (config.auth0Enabled) {
      console.log('login show', window.location);
      auth0Lock.show();
      setRedirect();
    }
  }

  componentWillUnmount() {
    if (config.auth0Enabled) {
      console.log('login hide', window.location);
      auth0Lock.hide();
    }
    this.removeKeyboardHandler();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isAuthenticated, isLoginFailed } = nextProps;
    const { isAuthenticated: wasAuthenticated } = this.props;

    this.setState({ isLoginFailed: isLoginFailed });
    if (!wasAuthenticated && isAuthenticated) {
      this.onLogin();
    }
  }

  onClickLogin = () => {
    this.props.login({
      email: this.state.email,
      password: this.state.password
    });
  };

  onClickCreate = () => {
    const {
      redirect,
      history: { push }
    } = this.props;
    push('/create-account?redirect=' + redirect);
  };

  onClickReset = () => {
    const {
      redirect,
      history: { push }
    } = this.props;
    push('/reset-password?redirect=' + redirect);
  };

  handleChange = (name, value) => {
    this.setState({ ...this.state, [name]: value });
  };

  maybeErrorMessage = loginError => {
    let errorMessage = null;
    switch (loginError && loginError.code) {
      case 'auth/user-not-found':
        errorMessage =
          'Error: User not found. Please contact support if you need help logging in.';
        break;
      case 'auth/wrong-password':
        errorMessage = 'Error: The password you entered does not match.';
        break;
      case 'auth/invalid-email':
        errorMessage =
          "Error: Please double-check you've entered your email properly.";
        break;
      case 'auth/user-disabled':
        errorMessage = 'Error: ' + userDisabledErrorMessage;
        break;
    }
    return errorMessage;
  };

  render() {
    const { loginError, isChrome, isMobile } = this.props;
    const errorMessage = loginError;
    if (config.auth0Enabled) {
      console.log('render login');
      return <div>Login</div>;
    }
    return (
      <div className={theme.loginForm} role="form">
        <h1 className={theme.header}>Login</h1>
        {!isChrome && (
          <div className={theme.optimizedForChrome}>
            Before logging in, please switch to the{' '}
            <a
              aria-label="Download Chrome (New Window)"
              href={config.chromeDownloadUrl}
              target="_blank"
            >
              Chrome web browser.
            </a>
          </div>
        )}
        {isMobile &&
          loginError &&
          loginError.code !== 'auth/wrong-password' &&
          errorMessage && (
            <div className={theme.loginError}>{errorMessage}</div>
          )}
        {!isMobile && errorMessage && (
          <div className={theme.loginError}>{errorMessage}</div>
        )}
        <section>
          <Input
            type="text"
            className={theme.loginInput}
            theme={theme}
            name="email"
            label="Email Address"
            aria-label="Email Address"
            value={this.state.email}
            onChange={this.handleChange.bind(this, 'email')}
            id="emailInput"
            onFocus={() => this.setState({ focused: true })}
            onBlur={() => this.setState({ focused: false })}
            autoFocus
          />
          {isMobile &&
            loginError &&
            loginError.code === 'auth/wrong-password' &&
            errorMessage && (
              <div className={theme.loginError}>{errorMessage}</div>
            )}
          <Input
            type="password"
            className={theme.loginInput}
            theme={theme}
            name="password"
            label="Password"
            aria-label="Password"
            value={this.state.password}
            onChange={this.handleChange.bind(this, 'password')}
            id="passwordInput"
            onFocus={() => this.setState({ focused: true })}
            onBlur={() => this.setState({ focused: false })}
          />
        </section>
        {config.createAccountEnabled ? (
          <ButtonAndLink
            buttonLabel="Login"
            onClickButton={this.onClickLogin}
            questionLabel="Don't have an account?"
            linkLabel="Sign Up"
            onClickLink={this.onClickCreate}
            onClickReset={this.onClickReset}
            isLoginPage={true}
          />
        ) : (
          <ButtonAndLink
            buttonLabel="Login"
            onClickButton={this.onClickLogin}
            onClickReset={this.onClickReset}
            isLoginPage={true}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const user = getCurrentUser(state);
  const hasMultiplePrograms =
    Object.keys((user && user.programs) || {}).length > 1;
  const isAuthenticated = user || false;
  const isLoginFailed = state.auth.loginFailed;
  const shouldShowProgramSelector =
    state.auth.isManualLogin && hasMultiplePrograms;
  const isRegistering =
    (ownProps.redirect && ownProps.redirect.startsWith('register')) || false;
  const redirect = config.auth0Enabled
    ? getRedirect()
    : ownProps.redirect || '/';
  const email = ownProps.email || '';
  const isChrome = getBrowser() === 'Chrome';
  return {
    email,
    isAuthenticated,
    isChrome,
    isLoginFailed,
    isRegistering,
    loginError: state.auth.loginError,
    redirect,
    shouldShowProgramSelector
  };
}

const mapDispatchToProps = {
  login: userActions.login,
  logout: userActions.logout,
  openMobileBetaWarning: mobileBetaWarningActions.showWarning
};

export default withRouter(
  withDeviceDetector(connect(mapStateToProps, mapDispatchToProps)(LoginForm))
);
