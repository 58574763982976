import { gql } from '@apollo/client';
import { get } from 'lodash';

export const isEnrolled = apolloClient => async userId => {
  const query = gql`
    query isEnrolled($userId: ID!) {
      isEnrolled(userId: $userId)
    }
  `;
  const response = await apolloClient.query({
    query,
    variables: {
      userId
    }
  });
  return get(response, 'data.isEnrolled', false);
};
