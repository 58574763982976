import { actionType } from '../constants';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const LOAD_LOGGED_IN_USER = 'LOAD_LOGGED_IN_USER';
export const LOGOUT = 'LOGOUT';

export default function authReducer(state = {}, { type, payload }) {
  switch (type) {
    case actionType.firebaseUp:
      return {
        ...state,
        firebaseUp: true,
        firebaseUser: payload
      };

    case REQUEST_LOGIN:
      const maybeIsManualLogin = payload.isManualLogin
        ? { isManualLogin: true }
        : {};
      return {
        ...state,
        loginError: undefined,
        requestedEmail: payload.email,
        ...maybeIsManualLogin
      };

    case LOAD_LOGGED_IN_USER:
      if (payload && payload.user && payload.user.error) {
        return {
          ...state,
          loginFailed: true,
          loginError: payload.user.error
        };
      }
      return {
        ...state,
        requestedEmail: undefined,
        user: payload.user,
        receivedAt: payload.receivedAt
      };

    case LOGIN_ERROR:
      return {
        ...state,
        loginFailed: true,
        loginError: payload
      };

    case LOGOUT:
      return {
        firebaseUp: true
      };

    case actionType.createUser:
      return {
        ...state,
        creatingUser: true,
        createUserComplete: false,
        createUserError: undefined
      };

    case actionType.createUserComplete:
      return {
        ...state,
        creatingUser: false,
        createUserComplete: true,
        createdUser: payload
      };

    case actionType.createUserError:
      return {
        ...state,
        creatingUser: false,
        createUserError: payload
      };

    case actionType.createUserSendEmailVerification:
      return {
        ...state,
        createUserSendEmailVerification: true,
        createUserSendEmailVerificationError: false,
        createUserSendEmailVerificationComplete: false
      };

    case actionType.createUserSendEmailVerificationError:
      return {
        ...state,
        createUserSendEmailVerification: false,
        createUserSendEmailVerificationError: payload
      };

    case actionType.createUserSendEmailVerificationComplete:
      return {
        ...state,
        createUserSendEmailVerification: false,
        createUserSendEmailVerificationComplete: true
      };

    case actionType.resetPassword:
      return {
        ...state,
        resetPassword: true,
        resetPasswordComplete: false,
        resetPasswordError: undefined
      };

    case actionType.resetPasswordError:
      return {
        ...state,
        resetPassword: false,
        resetPasswordError: payload
      };

    case actionType.resetPasswordComplete:
      return {
        ...state,
        resetPassword: false,
        resetPasswordComplete: true
      };

    case actionType.resetPasswordFlow:
      return {
        ...state,
        resetPasswordFlow: false,
        resetPasswordComplete: false
      };

    case actionType.dismissEmailVerificationWarning:
      return {
        ...state,
        emailVerificationDismissed: true
      };
  }
  return state;
}
