export const signIn = (user, token, refreshToken) => {
  window.localStorage.setItem('token', token);
  if (refreshToken) {
    window.localStorage.setItem('refresh_token', refreshToken);
  }
  if (user.sub) {
    if (user.sub.startsWith('auth0')) {
      const parts = user.sub.split('|');
      user.id = parts.slice(parts.length > 2 ? 2 : 1).join('|');
    } else {
      user.id = user.sub;
    }
  }
  window.localStorage.setItem('user', JSON.stringify(user));
};

export const signOut = () => {
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('refresh_token');
  window.localStorage.removeItem('user');
};

export const updateTokens = (token, refreshToken) => {
  window.localStorage.setItem('token', token);
  window.localStorage.setItem('refresh_token', refreshToken);
};

export const setToken = token => {
  window.localStorage.setItem('token', token);
};

export const setRedirect = () => {
  window.localStorage.setItem(
    'redirect',
    new URL(location.href).searchParams.get('redirect') || '/'
  );
};

export const getRedirect = () => {
  const r = window.localStorage.getItem('redirect');
  return r;
};

export const clearRedirect = () => {
  window.localStorage.removeItem('redirect');
};

export const getUser = () =>
  JSON.parse(window.localStorage.getItem('user') || 'null');

export const getToken = () => window.localStorage.getItem('token');

export const getRefreshToken = () =>
  window.localStorage.getItem('refresh_token');

export const isSsoUser = () => {
  let user = getUser();
  return user && user.id.startsWith('samlp');
};
