import { addTextComment } from './addTextComment';
import { addTextReply } from './addTextReply';
import { addVideoComment } from './addVideoComment';
import { addVideoReply } from './addVideoReply';
import { deleteComment } from './deleteComment';
import { deleteReply } from './deleteReply';
import { updateComment } from './updateComment';
import { updateTextComment } from './updateTextComment';
import { updateTextReply } from './updateTextReply';

function initCommentsApi(apolloClient) {
  return {
    addTextComment: addTextComment(apolloClient),
    addTextReply: addTextReply(apolloClient),
    addVideoComment: addVideoComment(apolloClient),
    addVideoReply: addVideoReply(apolloClient),
    deleteComment: deleteComment(apolloClient),
    deleteReply: deleteReply(apolloClient),
    updateComment: updateComment(apolloClient),
    updateTextComment: updateTextComment(apolloClient),
    updateTextReply: updateTextReply(apolloClient)
  };
}

export default initCommentsApi;
