import { objectsToMap } from 'common/objectsToMap';
export const LOAD_LOGGED_IN_USER = 'LOAD_LOGGED_IN_USER';

export const loadLoggedInUser = user => {
  if (user) {
    user = {
      ...user,
      programs: objectsToMap(user.enrollments, true)
    };
  }
  return {
    type: LOAD_LOGGED_IN_USER,
    payload: {
      user: user,
      receivedAt: Date.now()
    }
  };
};
