import * as userProgramHelper from '../smashcut-client-lib/utils/userProgramHelper';
import { gql } from '@apollo/client';
import {
  gotoLessonPayloadToUrl,
  MAIN_AREA_TYPE
} from '../smashcut-client-lib/utils/gotoLessonHelpers';
import { put, select, takeEvery, call } from 'redux-saga/effects';
import { selectors as lessonSelectors } from 'reducers/lesson';
import { types as lessonTypes } from 'reducers/lesson';
import { types as lessonProjectTypes } from 'reducers/lessonProjectTypes';
import { actionType } from '../smashcut-client-lib/constants';
import { selectors as sclSelectors } from 'smashcut-client-lib';
import { isArray, get, flatMap } from 'lodash';

// -------------------------------------------------------

// this is the meat of the saga, the side effects are taking place here
export function* gotoLessonSaga(history, action) {
  yield call(history.push, gotoLessonPayloadToUrl(action.payload));
}

// the watcher ties the action type to the saga
export function* watchGotoLesson({ history }) {
  yield takeEvery(lessonTypes.GOTO_LESSON, gotoLessonSaga.bind(this, history));
}

// -------------------------------------------------------

// this is the meat of the saga, the side effects are taking place here
export function* gotoCommentSaga(history, action) {
  const comment = yield select(sclSelectors.getComment, action.id);
  // only image comments  are using this saga
  if (!comment || !comment.imageId) {
    return;
  }

  const payload = yield select(lessonSelectors.getLessonSpec);
  if (!payload) {
    throw new Error('goto lesson payload not set');
  }
  const newPayload = {
    ...payload,
    mainAreaType: MAIN_AREA_TYPE.IMAGE,
    mainAreaId: `${comment.imageProjectId}!${comment.imageId}`,
    itemType: 'comment',
    itemId: comment.id
  };

  console.log('gotoCommentSaga', newPayload);

  yield call(history.push, gotoLessonPayloadToUrl(newPayload));
}

// the watcher ties the action type to the saga
export function* watchGotoComment({ history }) {
  yield takeEvery(actionType.gotoComment, gotoCommentSaga.bind(this, history));
}

// -------------------------------------------------------

const SUBMIT_PROJECT = gql`
  mutation submitProject($enrolleeId: ID!, $projectId: ID!) {
    submitProject(enrolleeId: $enrolleeId, projectId: $projectId) {
      id
      status
    }
  }
`;

// this is the meat of the saga, the side effects are taking place here
export function* markAsDoneSaga(apolloClient, action) {
  try {
    const result = yield call(apolloClient.mutate, {
      mutation: SUBMIT_PROJECT,
      variables: action
    });
    yield put({
      type: lessonProjectTypes.MARK_AS_DONE_SUCCESS,
      result,
      userProgramId: action.enrolleeId,
      projectId: action.projectId
    });
  } catch (e) {
    console.error(e);
    yield put({ type: lessonProjectTypes.MARK_AS_DONE_FAILURE, error: e });
  }
}

// the watcher ties the action type to the saga
export function* watchMarkAsDone({ apis }) {
  yield takeEvery(
    lessonProjectTypes.MARK_AS_DONE,
    markAsDoneSaga.bind(this, apis.apolloClient)
  );
}

// -------------------------------------------------------

const CANCEL_ENCODING = gql`
  mutation cancelEncoding(
    $enrolleeId: ID!
    $assignmentId: ID!
    $projectId: ID!
  ) {
    removeAssignmentLessonRecords(
      enrolleeId: $enrolleeId
      assignmentId: $assignmentId
    )
    cancelEncoding(input: { projectId: $projectId })
  }
`;

// this is the meat of the saga, the side effects are taking place here
export function* cancelEncodingSaga(apolloClient, action) {
  try {
    const result = yield call(apolloClient.mutate, {
      mutation: CANCEL_ENCODING,
      variables: action
    });
    yield put({
      type: lessonProjectTypes.CANCEL_ENCODING_SUCCESS,
      result,
      enrolleeId: action.enrolleeId,
      assignmentId: action.assignmentId,
      projectId: action.projectId
    });
  } catch (e) {
    console.error(e);
    yield put({ type: lessonProjectTypes.CANCEL_ENCODING_FAILURE, error: e });
  }
}

// the watcher ties the action type to the saga
export function* watchCancelEncoding({ apis }) {
  yield takeEvery(
    lessonProjectTypes.CANCEL_ENCODING,
    cancelEncodingSaga.bind(this, apis.apolloClient)
  );
}

// -------------------------------------------------------

const REMOVE_PROJECT = gql`
  mutation removeAssignmentLessonRecords($enrolleeId: ID!, $assignmentId: ID!) {
    removeAssignmentLessonRecords(
      enrolleeId: $enrolleeId
      assignmentId: $assignmentId
    )
  }
`;

// this is the meat of the saga, the side effects are taking place here
export function* removeProjectSaga(apolloClient, action) {
  try {
    const result = yield call(apolloClient.mutate, {
      mutation: REMOVE_PROJECT,
      variables: action
    });
    yield put({
      type: lessonProjectTypes.REMOVE_PROJECT_SUCCESS,
      result,
      enrolleeId: action.enrolleeId,
      assignmentId: action.assignmentId
    });
  } catch (e) {
    console.error(e);
    yield put({ type: lessonProjectTypes.REMOVE_PROJECT_FAILURE, error: e });
  }
}

// the watcher ties the action type to the saga
export function* watchRemoveProject({ apis }) {
  yield takeEvery(
    lessonProjectTypes.REMOVE_PROJECT,
    removeProjectSaga.bind(this, apis.apolloClient)
  );
}

// -------------------------------------------------------

const RESOLVED_VIDEO_FRAGMENT = `
  resolvedVideo {
    id
    baseUrl
    dashUrl
    description
    duration
    hlsUrl
    spriteUrl
    thumbnailUrl
    title
    type
    url
    videoUrl
    vttUrl
  }
`;

const SUB_TITLES_FRAGMENT = `
  subTitles {
    id
    label
    lang
    url
  }
`;

const ANNOTATION_FRAGMENT = `
  id
  relatedVideos {
    id
    title
    videoUrl
    ${RESOLVED_VIDEO_FRAGMENT}
  }
  teaser
  text
  time
  title
`;

const VIDEO_FRAGMENT = `
  id
  videoUrl
  pdfUrl
  object3DUrl
  images {
    assetId
    url
  }
  thumbnailUrl
  url
  type
  commentSectionId
  annotations {
    ${ANNOTATION_FRAGMENT}
  }
  ${SUB_TITLES_FRAGMENT}
  ${RESOLVED_VIDEO_FRAGMENT}
`;

const COMMON_ASSIGNMENT_FIELDS_FRAGMENT = `
  id
  parentId
  parentType
  annotations {
    ${ANNOTATION_FRAGMENT}
  }
  assignmentType
  assignmentTypeLabel
  courseId
  description
  dueDate
  help
  instructions
  instructionsPdfUrl
  isCrewAssignment
  lessonId
  responseType
  allowedFileFormats
  title
  userProgramId
  externalUrl
  videoUrl
  ${RESOLVED_VIDEO_FRAGMENT}
  reviewGuide
  reviewGuidePdfUrl
  reviewDueDate
  status
  subtitle
  ${SUB_TITLES_FRAGMENT}
  title
  transscriptUrl
  thumbnailUrl
  crewProject {
    id
    crewId
    assignmentId
  }
  projects {
    id
    baseUrl
    courseId
    dashUrl
    date
    description
    duration
    hlsUrl
    lessonId
    name
    spriteUrl
    status
    thumbnailUrl
    type
    userProgramId
    vttUrl
  }
  screenplays {
    id
    downloadUrl
    fileName
    created
  }
  imageProjects {
    id
    created
    images {
      uploadId
      downloadUrl
    }
  }
  nonSupportedFileProjects {
    id
    description
    userProgramId
    courseId
    lessonId
    assignmentId
    created
    downloadUrl
    fileSize
    status
  }
  object3DProjects {
    id
    description
    userProgramId
    courseId
    lessonId
    assignmentId
    created
    downloadUrl
    fileSize
    status
  }

`;

const LESSON_NAV_FIELDS_FRAGMENT = `
  id
  title
  gotoLessonPayload {
    programId
    courseId
    lessonId
    mainAreaType
    mainAreaId
    tabName
    itemType
    itemId
  }
`;

const LESSON_QUERY = gql`
  query lesson($input: LessonInput) {
    lesson(input: $input) {
      id
      info {
        assignmentsLabel
        title
        description
        lessonDate
        transscriptUrl
        numberOfAssignments
        course {
          id
          name
        }
        video {
          ${VIDEO_FRAGMENT}
        }
        videoDuration
      }
      assets {
        id
        generation
        name
        originalFileName
        storagePath
      }
      assignments {
        ${COMMON_ASSIGNMENT_FIELDS_FRAGMENT}
        subAssignmentsLabel
        subAssignments {
          ${COMMON_ASSIGNMENT_FIELDS_FRAGMENT}
        }
      }
      films {
        id
        duration
        externalUrl
        fullStreamAvailable
        genre
        publishingYear
        thumbnailUrl
        title
      }
      noAssignmentsMessage {
        title
        description
        suggestions {
          ${COMMON_ASSIGNMENT_FIELDS_FRAGMENT}
        }
      }
      videos {
        ${VIDEO_FRAGMENT}
      }
      previousLesson {
        ${LESSON_NAV_FIELDS_FRAGMENT}
      }
      nextLesson {
        ${LESSON_NAV_FIELDS_FRAGMENT}
      }
    }
  }
  `;

export function* loadLessonSaga(apolloClient, api, hasLessonChanged, action) {
  const lessonSpec = action.lessonSpec;
  try {
    let lesson, resolveAsset;
    if (hasLessonChanged) {
      const result = yield call(apolloClient.query, {
        query: LESSON_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            enrolleeId: lessonSpec.programId,
            courseId: lessonSpec.courseId,
            lessonId: lessonSpec.lessonId
          }
        }
      });
      lesson = result.data.lesson;

      const assets = result.data.lesson.assets || [];
      resolveAsset = userProgramHelper.makeResolveAssetFnFromAssets(
        assets,
        api
      );

      const videoResolvedSubTitles = yield call(
        resolveSubTitles,
        resolveAsset,
        lesson.videos
      );

      const resolvedLessonMedia = yield call(
        resolveLessonMedia,
        resolveAsset,
        videoResolvedSubTitles
      );

      const assignmentResolvedSubtitles = yield call(
        resolveSubTitles,
        resolveAsset,
        lesson.assignments
      );

      lesson = {
        ...lesson,
        videos: resolvedLessonMedia,
        assignments: assignmentResolvedSubtitles
      };
    } else {
      const data = yield select(lessonSelectors.getLessonData);
      lesson = data.lesson;
      resolveAsset = data.resolveAsset;
    }
    const assignments = (lesson.assignments || []).concat(
      flatMap(lesson.assignments, 'subAssignments').filter(Boolean)
    );
    const video = findProjectOrLessonVideo(lesson, lessonSpec, assignments);
    const image = findImage(lesson, lessonSpec, assignments);
    const nonSupportedFile = findNonSupported(lesson, lessonSpec, assignments);
    const object3DFile = findObject3D(lesson, lessonSpec, assignments);
    const screenplay = findScreenplay(lesson, lessonSpec, assignments);

    console.log('loadLessonSaga success', {
      lesson,
      resolveAsset,
      video,
      screenplay,
      image,
      nonSupportedFile,
      object3DFile
    });

    yield put({
      type: lessonTypes.LOAD_LESSON_SUCCESS,
      data: {
        lesson,
        resolveAsset,
        video,
        screenplay,
        image,
        nonSupportedFile,
        object3DFile
      }
    });
  } catch (err) {
    let error = new Error(
      'lesson not found for params: ' + JSON.stringify(lessonSpec)
    );

    console.error(error, err);

    yield put({ type: lessonTypes.LOAD_LESSON_FAILURE, error, cause: err });
  }
}

async function resolveLessonMedia(resolveAssetFn, medias) {
  return await Promise.all(
    (medias || []).map(async media => {
      const url = await resolveAssetFn(media.url);
      return {
        ...media,
        images:
          media.images &&
          isArray(media.images) &&
          (await Promise.all(
            media.images.map(async image => ({
              uploadId: image.assetId,
              downloadUrl: await resolveAssetFn(image.url)
            }))
          )),
        originalName: isArray(media.url) ? media.url[0] : media.url,
        url
      };
    })
  );
}

async function resolveSubTitles(resolveAssetFn, videos) {
  const resolvedSubtitles = await Promise.all(
    (videos || []).map(async video =>
      video.subTitles
        ? {
            ...video,
            subTitles: await Promise.all(
              video.subTitles.map(async s => ({
                ...s,
                originalName: s.url,
                url: await resolveAssetFn(s.url)
              }))
            )
          }
        : video
    )
  );
  return resolvedSubtitles;
}

function findScreenplay(lesson, params, assignments) {
  if (
    params.mainAreaType != MAIN_AREA_TYPE.SCREENPLAY &&
    params.mainAreaType != MAIN_AREA_TYPE.PDF
  ) {
    return;
  }

  let screenplays = [];
  lesson.assignments?.forEach(assignment => {
    screenplays = screenplays.concat(assignment.screenplays || []);
  });
  const screenplay = screenplays.find(
    screenplay => screenplay.id === params.mainAreaId
  );

  if (screenplay) {
    return screenplay;
  }

  const pdf = lesson.videos[0];

  if (!pdf) {
    console.error('No screenplay found for id:', params.mainAreaId);
    return undefined;
  }

  return {
    id: pdf.id,
    type: pdf.type,
    url: pdf.url,
    downloadUrl: pdf.url
  };
}

function findProjectOrLessonVideo(lesson, params, assignments) {
  if (params.mainAreaType === MAIN_AREA_TYPE.PROJECT) {
    for (let assignment of assignments) {
      const project = (assignment.projects || []).find(
        p => p.id === params.mainAreaId
      );

      if (project) {
        return project;
      }
    }

    console.error('No project found for id:', params.mainAreaId);
  }

  if (params.mainAreaType === MAIN_AREA_TYPE.VIDEO) {
    let video = (lesson.videos || []).find(v => v.id === params.mainAreaId);

    if (!video) {
      console.error('No video found for id:', params.videoId);
    } else {
      return video;
    }
  }

  return undefined;
}

function findImage(lesson, params, assignments) {
  if (params.mainAreaType === MAIN_AREA_TYPE.IMAGE) {
    const mainAreaId = params.mainAreaId.split('!').shift();

    for (let assignment of assignments) {
      const project = (assignment.imageProjects || []).find(
        p => p.id === mainAreaId
      );

      if (project) {
        return project;
      }
    }

    const lessonMediaImages = get(lesson, 'videos[0].images') || [];
    const lessonMediaImage = lessonMediaImages.find(
      p => p.uploadId === mainAreaId
    );

    if (lessonMediaImage) {
      return {
        id: lessonMediaImage.uploadId,
        images: lessonMediaImages.map(i => ({
          id: i.uploadId,
          ...i
        }))
      };
    }
    console.error('No project found for id:', mainAreaId);
  }

  return undefined;
}

function findNonSupported(lesson, params, assignments) {
  if (params.mainAreaType === MAIN_AREA_TYPE.NON_SUPPORTED_FORMAT) {
    const mainAreaId = params.mainAreaId;
    for (let assignment of assignments) {
      const project = (assignment.nonSupportedFileProjects || []).find(
        p => p.id === mainAreaId
      );

      if (project) {
        return project;
      }
    }

    console.error('No non supported project found for id:', mainAreaId);
  }

  return undefined;
}

function findObject3D(lesson, params, assignments) {
  if (params.mainAreaType === MAIN_AREA_TYPE.OBJECT3D) {
    const mainAreaId = params.mainAreaId;
    for (let assignment of assignments) {
      const project = (assignment.object3DProjects || []).find(
        p => p.id === mainAreaId
      );

      if (project) {
        return project;
      }
    }

    const file = get(lesson, 'videos[0]', {});
    const object3dUrl = get(file, 'url');

    if (object3dUrl) {
      return {
        id: file.id,
        type: file.type,
        downloadUrl: object3dUrl,
        url: object3dUrl
      };
    }

    console.error('No project found for id:', mainAreaId);
  }

  return undefined;
}

// the watcher ties the action type to the saga
export function* watchLoadLessonRequest({ apis }) {
  yield takeEvery(
    lessonTypes.LOAD_LESSON_REQUEST,
    loadLessonSaga.bind(this, apis.apolloClient, apis.api, true)
  );
}

export function* watchUpdateReviewItem({ apis }) {
  yield takeEvery(
    lessonTypes.UPDATE_REVIEW_ITEM,
    loadLessonSaga.bind(this, apis.apolloClient, apis.api, true)
  );
}

// -------------------------------------------------------

// collection of all watchers
// it is used to launch all watchers at once
// it is named after the file name
export const lessonSagas = [
  watchCancelEncoding,
  watchGotoComment,
  watchGotoLesson,
  watchLoadLessonRequest,
  watchMarkAsDone,
  watchRemoveProject,
  watchUpdateReviewItem
];
