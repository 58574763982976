import { actionType } from '../constants';

const coBrowsingReducer = (state, action) => {
  let nextState = state;

  switch (action.type) {
    case actionType.connectionOpened:
      // console.log(action.type, action);
      nextState = { ...state };
      nextState.active = true;
      nextState.connectionId = action.connectionId;
      nextState.opentokSession = action.opentokSession;
      // actionOriginId identifies the origin of an action
      // bc. the originator should not replay it
      nextState.actionOriginId = Math.random();
      break;

    case actionType.connectionClosed:
      console.log(action.type, action);
      nextState = { ...state };
      nextState.active = false;
      nextState.connectionId = null;
      nextState.opentokSession = null;
      break;
  }

  return nextState;
};

export default coBrowsingReducer;
