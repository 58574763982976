import classnames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import styles from './AppUpdateDialog.scss';
import { connect } from 'react-redux';
import { MainButton } from './button';
import { Rnd } from 'react-rnd';
import { selectors as appVersionSelectors } from 'reducers/appVersion';
import { isTest } from 'common/isTest';

function handleConfirm() {
  const isForcedReload = true;
  window.location.reload(isForcedReload);
}

function AppUpdateDialog({ show }) {
  return show
    ? ReactDOM.createPortal(
        <Rnd
          default={{ x: 100, y: 100, width: 550, height: 300 }}
          enableResizing={false}
          className={styles.rnd}
        >
          <div className={classnames(styles.topContainer, styles.color4)}>
            <div className={styles.textBlock}>
              <p>
                <i>
                  Deja Vu is usually a glitch in the Matrix. It happens when
                  they change something.
                </i>
              </p>
              <p>
                We’ve made some improvements to the website! Please reload your
                browser for the latest version.
              </p>
            </div>
            <MainButton
              icon="fas fa-redo-alt"
              label="Reload Now"
              aria-label="Reload Now"
              onClick={handleConfirm}
              className={styles.reloadButton}
            />
          </div>
        </Rnd>,
        document.body
      )
    : null;
}

const mapStateToProps = state => {
  const updateRequired = appVersionSelectors.hasAppVersionChanged(state);

  return {
    show: updateRequired || isTest('app-update')
  };
};

const mapDispatchToProps = {};

const withRedux = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withRedux(AppUpdateDialog);
