import { types } from 'reducers/officeHoursReducerTypes';

const initialState = {};

export const upcomingLmsReducer = (state = initialState, action) => {
  let nextState = state;

  switch (action.type) {
    case types.SETUP_OFFICE_HOURS:
      nextState = {
        ...state,
        settingUpOfficeHours: true,
        officeHoursSetupComplete: false,
        officeHoursError: undefined
      };
      break;

    case types.SETUP_OFFICE_HOURS_COMPLETE:
      nextState = {
        ...state,
        officeHoursSetupComplete: true,
        settingUpOfficeHours: false,
        officeHoursId: action.data.id
      };
      break;

    case types.SETUP_OFFICE_HOURS_ERROR:
      nextState = {
        ...state,
        officeHoursSetupComplete: false,
        settingUpOfficeHours: false,
        officeHoursError: action.error
      };
      break;
  }

  return nextState;
};

function get(state) {
  return state.dashboard.upcomingLms;
}

export const selectors = {
  get
};
