import keyboardHandlerManager from 'common/KeyboardHandlerManager';
import PromiseImage from '../common/PromiseImage';
import React from 'react';
import styles from './NoteDisplay.scss';
import { actions as relatedVideoPlayerActions } from 'reducers/relatedVideoPlayer';
import { actions, selectors } from 'reducers/noteDisplay';
import { connect } from 'react-redux';
import { selectors as lessonSelectors } from 'reducers/lesson';
import MarkDownDisplay from '../common/MarkDownDisplay';

export class NoteDisplay extends React.Component {
  onKeyUp = e => {
    switch (e.key) {
      case 'x':
      case 'X':
      case 'Escape':
        this.props.hideNoteDisplay();
        break;
    }
    return false;
  };

  render() {
    let {
      item,
      hideNoteDisplay,
      launchRelatedVideo,
      resolveAsset
    } = this.props;

    if (!item) {
      return null;
    }

    let active = false;
    let relatedVideos = [];
    let text = '';
    let title = '';

    if (item.visible) {
      active = true;

      //TODO Cleanup the data structure
      let note = item.note.originalData;
      // console.log('item', item)
      relatedVideos = []; //note.relatedVideos || []
      title = note.title || '';
      text = note.text || '';

      keyboardHandlerManager.addKeyboardHandler(this.onKeyUp);
    } else {
      keyboardHandlerManager.removeKeyboardHandler(this.onKeyUp);
    }

    let onClickClose = () => {
      return hideNoteDisplay();
    };

    let onClickVideo = relatedVideo => () => {
      return launchRelatedVideo(
        relatedVideo.title,
        relatedVideo.resolvedVideo || relatedVideo.video
      );
    };

    return (
      <div className={styles.topContainer}>
        <div className={styles.drawer} style={active ? { right: 0 } : {}}>
          <div className={styles.noteTitleContainer}>
            <div className={styles.noteTitle}>{title || 'Notes'}</div>
            <div className={styles.noteCloseButton} onClick={onClickClose}>
              <i className="fa fa-times" />
            </div>
          </div>
          <div className={styles.noteContentContainer}>
            {relatedVideos.length > 0 && (
              <React.Fragment>
                <div className={styles.relatedVideosContainer}>
                  {relatedVideos.map(rv => (
                    <div
                      key={rv.id}
                      className={styles.relatedVideo}
                      onClick={onClickVideo(rv)}
                    >
                      <div className={styles.relatedVideoThumbnail}>
                        <i className={`fa fa-play ${styles.circle}`} />
                        <PromiseImage
                          className={styles.relatedVideoThumbnailImage}
                          src={resolveAsset(rv.thumbnailUrl)}
                          title={rv.title}
                        />
                      </div>
                      <div className={styles.relatedVideoTitle}>{rv.title}</div>
                    </div>
                  ))}
                </div>
                <hr />
              </React.Fragment>
            )}
            <div className={styles.noteContentText}>
              <div className={styles.noteContentTitle}>{title}</div>
              <MarkDownDisplay markDown={text} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = state => {
  return {
    item: selectors.getNoteDisplay(state),
    resolveAsset: lessonSelectors.getLessonData(state).resolveAsset
  };
};

export const mapDispatchToProps = {
  hideNoteDisplay: actions.hideNoteDisplay,
  launchRelatedVideo: relatedVideoPlayerActions.showPlayer
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteDisplay);
