import { objectsToMap } from 'common/objectsToMap';

export default class UserDataFormatter {
  transformPayload(payload) {
    const formattedPrograms = payload.userData.programs.map(pr => ({
      ...pr,
      encodedVideos: objectsToMap(pr.encodedVideos),
      courses: objectsToMap(pr.courses),
      paces: objectsToMap(pr.paces)
    }));

    const formattedUserPrograms = payload.userData.userPrograms.map(up => ({
      ...up,
      projects: objectsToMap(up.projects, true),
      students: objectsToMap(up.students, true),
      mentors: objectsToMap(up.mentors, 'discipline'),
      lessonRecords: objectsToMap(up.lessonRecords),
      notes:
        up.notes &&
        up.notes.reduce((acc, item) => {
          acc[item.userId] = item.note;
          return acc;
        }, {})
    }));

    return {
      ...payload.userData,
      programs: formattedPrograms,
      userPrograms: formattedUserPrograms
    };
  }
}
