import { isArray } from 'lodash';

export const getFilterType = filter => {
  const dictionary = {
    all: 'ALL',
    uploaded: 'UPLOADED',
    submitted: 'NOT REVIEWED',
    'ready for review': 'NOT REVIEWED',
    undone: 'UNDONE',
    reviewed: 'REVIEWED',
    rejected: 'REVISION REQUESTS',
    videos: 'videos',
    images: 'images',
    screenplays: 'screenplays',
    pdfs: 'pdfs',
    cinematography: 'Cinematography',
    screenwriting: 'Screenwriting',
    directing: 'Directing',
    editing: 'Editing',
    filmmaking: 'Filmmaking',
    photography: 'Photography',
    animation: 'Animation',
    others: 'Others',
    folders: 'Folders',
    today: 'Today',
    future: 'Future',
    past: 'Past'
  };

  return dictionary[isArray(filter) ? filter[0] : filter];
};
