const prefix = 'RELATED_VIDEO_PLAYER';

export const types = {
  SHOW_PLAYER: `${prefix}/SHOW_PLAYER`,
  HIDE_PLAYER: `${prefix}/HIDE_PLAYER`
};

export const initialState = {
  title: '',
  item: undefined,
  visible: false
};

export default (state = initialState, action) => {
  let nextState = state;

  switch (action.type) {
    case types.SHOW_PLAYER:
      nextState = {
        ...state,
        visible: true,
        title: action.title,
        item: action.item
      };
      break;

    case types.HIDE_PLAYER:
      nextState = {
        ...state,
        visible: false,
        title: undefined,
        item: undefined
      };
      break;
  }

  return nextState;
};

const showPlayer = (title, item) => ({
  type: types.SHOW_PLAYER,
  title,
  item
});

const hidePlayer = () => ({
  type: types.HIDE_PLAYER
});

export const actions = {
  showPlayer,
  hidePlayer
};

const getRelatedVideoPlayer = state => {
  return state.relatedVideoPlayer;
};

export const selectors = {
  getRelatedVideoPlayer
};
