import * as partnerConfig from 'themes/partnerConfig';
import classnames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import React from 'react';
import SmashcutAvatar from './SmashcutAvatar';
import styles from './TopNav.scss';
import { connect } from 'react-redux';
import { getCurrentUser, isFreeAccount } from 'smashcut-client-lib/selectors';
import { simulateClickOnFocusedElement } from 'utils/simulateClickOnFocusedElement';
import { topNavLinks } from 'themes/partnerConfig';
import { isEmpty, trim } from 'lodash';
import { withRouter } from 'react-router-dom';

export const openInBrowser = (url, target) =>
  window.open(url, target || '_blank');

const TopNav = ({
  setMenuOpen,
  user,
  title,
  showLms,
  isFreeAccount,
  isNoProgramsAccount,
  menuOpen,
  closeMenu,
  handleSelect
}) => {
  TopNav.handleClickOutside = () => closeMenu();

  return (
    <div data-cy="topNav">
      <div
        className={styles.dropButton}
        onClick={() => setMenuOpen(!menuOpen)}
        onKeyUp={simulateClickOnFocusedElement}
        role="button"
        aria-label="CrewHub Menu"
        tabIndex="0"
        aria-expanded={menuOpen}
      >
        <SmashcutAvatar
          className={styles.avatar}
          src={user.avatar}
          title={title}
          alt={`${user.fullName} Avatar`}
        />
        {menuOpen ? (
          <i className={classnames(styles.menuArrow, 'fas fa-angle-up')} />
        ) : (
          <i className={classnames(styles.menuArrow, 'fas fa-angle-down')} />
        )}
      </div>

      <div
        className={classnames(styles.dropContent, {
          [styles.open]: menuOpen
        })}
      >
        <ul>
          <li
            key="accountDetails"
            onClick={() => handleSelect('accountDetails')}
            onKeyUp={simulateClickOnFocusedElement}
            tabIndex="0"
          >
            Account Details
          </li>
          {!isFreeAccount && !isNoProgramsAccount && (
            <li
              key="profile"
              onClick={() => handleSelect('profile')}
              onKeyUp={simulateClickOnFocusedElement}
              tabIndex="0"
            >
              Profile
            </li>
          )}
          {topNavLinks.browseCourses && (
            <li
              key="browseCourses"
              onClick={() => handleSelect('browseCourses')}
              onKeyUp={simulateClickOnFocusedElement}
              tabIndex="0"
            >
              Browse Courses
            </li>
          )}
          {(topNavLinks.availability.availabilityComponent ||
            (showLms && user.isMentor)) &&
            !isFreeAccount && (
              <li
                key="availability"
                onClick={() => handleSelect('availability')}
                onKeyUp={simulateClickOnFocusedElement}
                tabIndex="0"
              >
                Availability
              </li>
            )}
          {!user.isMentor && !isFreeAccount && (
            <li
              key={topNavLinks.helpCenter.helpCenterKey}
              onClick={() =>
                openInBrowser(
                  topNavLinks.helpCenter.helpCenterUrl,
                  'smashcutHelpCenter'
                )
              }
              onKeyUp={simulateClickOnFocusedElement}
              tabIndex="0"
            >
              {topNavLinks.helpCenter.helpCenterTitle}
            </li>
          )}
          {topNavLinks.blog.blogComponent && (
            <li
              key="blog"
              onClick={() => handleSelect('blog')}
              onKeyUp={simulateClickOnFocusedElement}
              tabIndex="0"
            >
              Blog
            </li>
          )}
          <li
            key="logout"
            className={styles.logOut}
            onClick={() => handleSelect('logout')}
            onKeyUp={simulateClickOnFocusedElement}
            tabIndex="0"
          >
            Log Out <i className="fas fa-sign-out-alt" />
          </li>
        </ul>
      </div>
    </div>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => TopNav.handleClickOutside
};

const TopNavWithOutsideClick = onClickOutside(TopNav, clickOutsideConfig);

export const mapStateToProps = state => {
  const user = getCurrentUser(state) || {};
  return {
    user,
    showLms: partnerConfig.showLms,
    isFreeAccount: isFreeAccount(state),
    isNoProgramsAccount: isEmpty(user.programs)
  };
};

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TopNavWithOutsideClick)
);
