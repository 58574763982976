const KB = 1024;
const MB = 1024 * KB;
const GB = 1024 * MB;

export const IMAGE_UPLOAD_LIMIT_BYTES = 50 * MB;
export const IMAGE_UPLOAD_LIMIT_MBS = IMAGE_UPLOAD_LIMIT_BYTES / MB;

export const VIDE0_UPLOAD_LIMIT_BYTES = 3 * GB;
export const VIDE0_UPLOAD_LIMIT_MBS = VIDE0_UPLOAD_LIMIT_BYTES / MB;
export const VIDEO_UPLOAD_LIMIT_GBS = VIDE0_UPLOAD_LIMIT_BYTES / GB;

export const ANY_FILE_UPLOAD_LIMIT_BYTES = 10 * GB;
