import { actionType } from '../actions/actionType';
import { getReplay } from '../selectors';
import { types as officeHourTypes } from 'reducers/officeHoursReducerTypes';
import { types as screenplayTypes } from 'reducers/screenplayView';
import { types as videoConfTypes } from 'reducers/videoConference';

function coBrowsingMiddleware() {
  return ({ /*dispatch,*/ getState }) => next => action => {
    // console.log('coBrowsingMiddleware', arguments)
    if (shouldShare(action)) {
      let replay = getReplay(getState());
      if (replay.active) {
        // console.log('sending action', action);

        let timestamp = Date.now();
        let wrappedAction = {
          action,
          timestamp,
          actionOriginId: replay.actionOriginId
        };
        replay.opentokSession.signal({
          type: 'action',
          data: JSON.stringify(wrappedAction)
        });
      }
    }
    return next(action);
  };
}

function shouldShare(action) {
  if (typeof action === 'function') {
    return false;
  }
  if (action.isReplay) {
    return false;
  }
  switch (action.type) {
    case actionType.playerIsPlaying:
    case actionType.playerIsPaused:
    case actionType.seekVideoPosition:
      return action.causedByUser;

    case actionType.gotoComment:
    case actionType.gotoNextComment:
    case actionType.gotoPreviousComment:
    case actionType.gotoTimecode:
    case actionType.playVideoComment:
    case actionType.playVideoReply:
    case actionType.playCommentRange:
    case actionType.MARKER_CLICK:
    case officeHourTypes.CLOSE_PROJECT:
    case officeHourTypes.SELECT_PROJECT:
    case screenplayTypes.SELECT_CHUNK:
    case screenplayTypes.SELECT_NEXT_CHUNK:
    case screenplayTypes.SELECT_PREV_CHUNK:
    case videoConfTypes.ADD_OR_REMOVE_CO_HOST:
    case videoConfTypes.GOTO_PDF_PAGE:
    case videoConfTypes.RAISE_HAND:
    case videoConfTypes.LOWER_HAND:
    case videoConfTypes.SELECT_IMAGE:
    case videoConfTypes.CHANGE_HAND_ICON:
    case videoConfTypes.CLEAR_RAISED_HANDS:
    case videoConfTypes.CLAIM_HOST_SUCCESS:
    case videoConfTypes.ACCEPT_CLAIM_SUCCESS:
    case videoConfTypes.DECLINE_CLAIM_SUCCESS:
    case videoConfTypes.CANCEL_CLAIM_SUCCESS:
    case videoConfTypes.HANDOFF_HOST_SUCCESS:
    case videoConfTypes.ACCEPT_HANDOFF_SUCCESS:
    case videoConfTypes.DECLINE_HANDOFF_SUCCESS:
    case videoConfTypes.CANCEL_HANDOFF_SUCCESS:
      return true;
  }
  return false;
}

export default coBrowsingMiddleware;
