import { actionType } from '../constants';
import { getComment, getCommentSection } from '../selectors';

export const updateComment = (id, updates) => {
  return (dispatch, getState, { api }) => {
    let state = getState();
    let commentSectionId = getCommentSection(state).id;
    let item = getComment(state, id);
    dispatch({
      type: actionType.updateComment,
      item,
      updates
    });
    api.updateComment(commentSectionId, item, updates);
  };
};
