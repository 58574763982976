import { gql } from '@apollo/client';

export function setProjectStatus(apolloClient) {
  const mutation = gql`
    mutation setProjectStatus($input: ShareableFileUpdateInput!) {
      setShareableFileStatus(input: $input) {
        id
        status
      }
    }
  `;

  return (id, uploadType, status) => {
    return apolloClient.mutate({
      mutation,
      variables: {
        input: {
          id,
          uploadType,
          status
        }
      }
    });
  };
}
