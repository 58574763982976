import React from 'react';
import styles from './ErrorFallbackComponent.scss';
import { HelpChatButton } from './HelpChatButton';

export const ErrorFallbackComponent = () => (
  <div className={styles.error}>
    <h3>
      Something went wrong! Please contact the support team for assistance.
    </h3>
    <div className={styles.buttons}>
      <HelpChatButton />
    </div>
  </div>
);
