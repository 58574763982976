// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MarkDownDisplay--markDownDisplay--1nMQzHZG p{-webkit-margin-before:0;-webkit-margin-after:0}.MarkDownDisplay--markDownContainer--Xb4QtmHw{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.MarkDownDisplay--instructions--33MaJnoJ{font-size:14px;line-height:21px;letter-spacing:0;text-align:left;color:#ffffff;margin-top:0;margin-bottom:20px}.MarkDownDisplay--instructions--33MaJnoJ ul{list-style-type:disc;list-style-type:initial}.MarkDownDisplay--instructions--33MaJnoJ p{-webkit-margin-before:0;-webkit-margin-after:0;margin-bottom:10px}.MarkDownDisplay--moreButton--2uxxUm-g{margin-top:0;margin-bottom:20px;width:100%;height:36px;background-color:#424242;color:#fff;font-weight:bold;border-radius:4px}.MarkDownDisplay--moreButton--2uxxUm-g span{margin-right:13px !important}\n", ""]);
// Exports
exports.locals = {
	"markDownDisplay": "MarkDownDisplay--markDownDisplay--1nMQzHZG",
	"markDownContainer": "MarkDownDisplay--markDownContainer--Xb4QtmHw",
	"instructions": "MarkDownDisplay--instructions--33MaJnoJ",
	"moreButton": "MarkDownDisplay--moreButton--2uxxUm-g"
};
module.exports = exports;
