import { actionType } from '../constants';
import { getRelease } from '../selectors';

export const upsertRelease = release => {
  return (dispatch, getState) => {
    let oldItem = getRelease(getState(), release.id);
    if (!oldItem || !oldItem.id) {
      dispatch({
        type: actionType.insertRelease,
        release
      });
    } else {
      dispatch({
        type: actionType.updateRelease,
        release
      });
    }
  };
};
