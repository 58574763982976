import { gql } from '@apollo/client';

export const removeReview = apolloClient => (project, lessonRecord) => {
  const mutation = gql`
    mutation removeReview($input: RemoveReviewInput!) {
      removeReview(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        lessonRecordId: lessonRecord.id,
        userProgramId: lessonRecord.userProgramId
      }
    }
  });
};
