import { gql } from '@apollo/client';

export const createUser = apolloClient => async input => {
  const mutation = gql`
    mutation createUser($input: SignupInput) {
      signup(input: $input) {
        status
        token
        refreshToken
        user {
          id
        }
      }
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input
    }
  });
};
