import { gql } from '@apollo/client';

export const addIntroVideo = apolloClient => async input => {
  const mutation = gql`
    mutation addIntroVideo($input: AddIntroVideoInput!) {
      addIntroVideo(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input
    }
  });
};
