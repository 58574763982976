import { all, call, put, takeEvery } from 'redux-saga/effects';
import { types } from '../reducers/dashboard/archives';
import { gql } from '@apollo/client';

export const GET_ARCHIVE_URL = gql`
  query getSignedArchiveUrl($input: SignUrlWithContentDispositionInput) {
    signUrlWithContentDisposition(input: $input)
  }
`;

export const UPDATE_ARCHIVE_VISIBILITY = gql`
  mutation updateArchiveVisibility($input: UpdateArchiveVisibilityInput!) {
    updateArchiveVisibility(input: $input)
  }
`;

export const UPDATE_ARCHIVE_METADATA = gql`
  mutation updateArchiveVisibility($input: UpdateArchiveMetadataInput!) {
    updateArchiveMetadata(input: $input)
  }
`;

export const REMOVE_ARCHIVE = gql`
  mutation removeArchvie($input: RemoveArchiveInput!) {
    removeArchive(input: $input)
  }
`;

function* getSignedArchiveUrl(apolloClient, { archiveUrl, downloadFileName }) {
  console.log('Getting archive url for ', {
    archiveUrl,
    downloadFileName
  });
  try {
    const result = yield call(apolloClient.query, {
      query: GET_ARCHIVE_URL,
      fetchPolicy: 'network-only',
      variables: {
        input: {
          url: archiveUrl,
          attachmentFileName: downloadFileName,
          contentDisposition: 'attachment'
        }
      }
    });

    let signedUrl = result.data.signUrlWithContentDisposition;
    yield put({
      type: types.GET_SIGNED_ARCHIVE_URL_SUCCESS,
      signedUrl
    });
  } catch (e) {
    console.warn('nok', e);
    yield put({
      type: types.GET_SIGNED_ARCHIVE_URL_FAILURE,
      error: e
    });
  }
}

export function* updateArchiveVisibility(
  apolloClient,
  { archiveId, visibility }
) {
  try {
    const result = yield call(apolloClient.mutate, {
      mutation: UPDATE_ARCHIVE_VISIBILITY,
      fetchPolicy: 'network-only',
      variables: {
        input: {
          archiveId,
          visibility
        }
      },
      refetchQueries: ['getArchives']
    });

    yield put({
      type: types.UPDATE_ARCHIVE_VISIBILITY_SUCCESS
    });
  } catch (e) {
    yield put({
      type: types.UPDATE_ARCHIVE_VISIBILITY_FAILURE,
      error: e
    });
    console.warn('error updating archive visibility', e);
  }
}

export function* updateArchiveMetadata(apolloClient, { archiveId, metadata }) {
  try {
    const result = yield call(apolloClient.mutate, {
      mutation: UPDATE_ARCHIVE_METADATA,
      fetchPolicy: 'network-only',
      variables: {
        input: {
          archiveId,
          ...metadata
        }
      },
      refetchQueries: ['getArchives']
    });

    yield put({
      type: types.UPDATE_ARCHIVE_METADATA_SUCCESS
    });
  } catch (e) {
    yield put({
      type: types.UPDATE_ARCHIVE_METADATA_FAILURE,
      error: e
    });
    console.warn('error updating archive metadata', e);
  }
}

export function* removeArchive(apolloClient, { archiveId }) {
  try {
    const result = yield call(apolloClient.mutate, {
      mutation: REMOVE_ARCHIVE,
      fetchPolicy: 'network-only',
      variables: {
        input: {
          archiveId
        }
      },
      refetchQueries: ['getArchives']
    });

    yield put({
      type: types.REMOVE_ARCHIVE_SUCCESS
    });
  } catch (e) {
    yield put({
      type: types.REMOVE_ARCHIVE_FAILURE,
      error: e
    });
    console.warn('error removing archive', e);
  }
}

export function* archivesSaga({ apis }) {
  yield all([
    takeEvery(
      types.GET_SIGNED_ARCHIVE_URL_REQUEST,
      getSignedArchiveUrl.bind(this, apis.apolloClient)
    ),
    takeEvery(
      types.UPDATE_ARCHIVE_VISIBILITY_REQUEST,
      updateArchiveVisibility.bind(this, apis.apolloClient)
    ),
    takeEvery(
      types.UPDATE_ARCHIVE_METADATA_REQUEST,
      updateArchiveMetadata.bind(this, apis.apolloClient)
    ),
    takeEvery(
      types.REMOVE_ARCHIVE_REQUEST,
      removeArchive.bind(this, apis.apolloClient)
    )
  ]);
}
