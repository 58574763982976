import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import styles from './VideoMarkers.scss';
import { Marker } from './VideoMarker';
import { NoteMarkerDisplay } from './NoteMarkerDisplay';
import { RangeMarkerGroup } from './RangeMarkerGroup';
import { VideoMarkersContext } from './VideoMarkersContext';

export default class VideoMarkers extends React.Component {
  state = {};
  noteRef = React.createRef();
  containerRef = React.createRef();

  handleMouseEnter = marker => {
    let { videoLength } = this.props;
    videoLength = videoLength || 1;
    const containerWidth = this.calculateContainerWidth();
    // d is the offset to position in the middle of the marker duration
    const d = marker.duration ? marker.duration / 2 : 0;
    const noteX = ((marker.time + d) * containerWidth) / videoLength + 'px';
    this.setState({
      noteVisible: true,
      noteMarker: marker,
      noteX
    });

    setTimeout(this.repositionNote, 10);
  };

  handleMouseLeave = () => {
    this.hideNote();
  };

  handleMarkerClick = marker => {
    if (marker.markerType === 'note') {
      this.hideNote();
    }
    this.props.onMarkerClick(marker);
  };

  hideNote() {
    this.setState({ noteVisible: false });
  }

  handleMarkerChange = (marker, updates) => {
    this.props.onMarkerChange && this.props.onMarkerChange(marker, updates);
  };

  calculateContainerWidth = () =>
    this.containerRef.current && this.containerRef.current.offsetWidth - 13;

  repositionNote = () => {
    const containerWidth = this.calculateContainerWidth();
    const marker = this.state.noteMarker;
    let { videoLength } = this.props;
    videoLength = videoLength || 1;
    let noteX = (marker.time * containerWidth) / videoLength;
    const noteWidth = this.noteRef.current.offsetWidth;
    if (noteX + noteWidth > containerWidth) {
      noteX = containerWidth - noteWidth;
      this.setState({ noteX: `${noteX}px` });
    }
  };

  render() {
    let {
      markers,
      videoLength,
      inPoint,
      outPoint,
      playingComment,
      onMarkerClick
    } = this.props;

    const { noteMarker } = this.state;
    videoLength = videoLength || 1;
    const containerWidth = this.calculateContainerWidth();

    const markerElements = (markers || []).map((marker, i) =>
      marker.duration ? (
        <RangeMarkerGroup
          key={i}
          editable={false}
          marker={marker}
          isPlaying={playingComment == marker.commentId}
          onClick={() => onMarkerClick(marker)}
          onMouseEnter={() => this.handleMouseEnter(marker)}
          onMouseLeave={() => this.handleMouseLeave(marker)}
        />
      ) : (
        <Marker
          key={i}
          marker={marker}
          onClick={() => this.handleMarkerClick(marker)}
          onMouseEnter={() => this.handleMouseEnter(marker)}
          onMouseLeave={() => this.handleMouseLeave(marker)}
        />
      )
    );

    const provisionalMarker = {
      markerType: 'provisional',
      time: inPoint,
      duration: outPoint ? Math.max(outPoint - inPoint, 0.1) : 0.1
    };

    return (
      <VideoMarkersContext.Provider value={{ videoLength, containerWidth }}>
        <div
          draggable={false}
          className={classnames(this.props.className, styles.markers)}
          ref={this.containerRef}
        >
          {markerElements}
          {!_.isUndefined(inPoint) && (
            <RangeMarkerGroup
              marker={provisionalMarker}
              editable={true}
              onMarkerChange={updates =>
                this.handleMarkerChange(provisionalMarker, updates)
              }
            />
          )}
          <div
            className={classnames(styles.notePanel, {
              [styles.notePanelVisible]: this.state.noteVisible
            })}
            style={{ left: this.state.noteX }}
          >
            {noteMarker && (
              <NoteMarkerDisplay
                noteRef={this.noteRef}
                noteMarker={noteMarker}
              />
            )}
          </div>
        </div>
      </VideoMarkersContext.Provider>
    );
  }
}
