import { gql } from '@apollo/client';

export const updateComment = apolloClient => (
  commentSectionId,
  item,
  updates
) => {
  console.log('updating comment', commentSectionId, item, updates);

  const mutation = gql`
    mutation updateComment($input: UpdateCommentInput!) {
      updateComment(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        commentSectionId,
        commentId: item.id,
        ...updates
      }
    }
  });
};
