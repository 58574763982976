// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Version--version--2SPEmrO-{font-size:65%}\n", ""]);
// Exports
exports.locals = {
	"version": "Version--version--2SPEmrO-"
};
module.exports = exports;
