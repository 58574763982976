import React, { useState, useEffect } from 'react';
import styles from './SelectBoxMobile.scss';
import { isEqual, isArray, isObject, capitalize } from 'lodash';
import { RadioButton } from 'react-toolbox';
import { getFilterType } from 'utils/getFilterType';
import classnames from 'classnames';
import { simulateClickOnFocusedElement } from 'utils/simulateClickOnFocusedElement';
import { SecondaryButton } from 'components/common/button';
import {
  addBodyScrollClass,
  removeBodyScrollClass
} from 'utils/updateBodyScrollClass';

const SelectBoxMobile = ({
  title,
  options,
  selected,
  onChange,
  isFilter,
  onClose,
  placeholder,
  isProgramSelector,
  isStandardSelect,
  content,
  backTitle,
  back
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => setOpen(true), 50);
    addBodyScrollClass();
    return () => removeBodyScrollClass();
  }, []);

  return (
    <div className={styles.overlay} onClick={onClose}>
      <div
        className={classnames(styles.topContainer, {
          [styles.open]: open
        })}
        onClick={e => e.stopPropagation()}
      >
        <div
          className={styles.header}
          onClick={e => {
            e.stopPropagation();
            onClose();
          }}
        >
          {back && (
            <SecondaryButton
              icon="fas fa-angle-left"
              label={backTitle || 'Back'}
              onClick={e => {
                e.stopPropagation();
                back();
              }}
            />
          )}
          <div className={styles.title}>{title}</div>
          <i className="fas fa-chevron-down" />
        </div>
        {content ? (
          content
        ) : (
          <div className={styles.optionsContainer}>
            <ul>
              {isStandardSelect && placeholder && (
                <li
                  onClick={() => onChange(null)}
                  className={classnames({
                    [styles.active]: !selected
                  })}
                  tabIndex="0"
                  onKeyUp={simulateClickOnFocusedElement}
                >
                  <RadioButton
                    label={placeholder}
                    checked={!selected}
                    className={styles.radio}
                    tabIndex="-1"
                  />
                </li>
              )}
              {options &&
                options.map(option => {
                  let label = null;
                  let val = null;
                  let date = null;
                  let active = null;
                  if (isProgramSelector) {
                    val = label = option.label;
                    active = val === selected;
                  } else if (isStandardSelect) {
                    val = option.value;
                    label = option.label;
                    active = selected && val === selected.value;
                  } else {
                    val = isFilter ? capitalize(getFilterType(option)) : option;
                    if (isObject(option) && !isArray(option)) {
                      val = option.title;
                      date = option.date;
                      option = option.index;
                    }
                    label = val;
                    active = isArray(selected)
                      ? isEqual(option, selected)
                      : selected === option;
                  }

                  return (
                    <li
                      key={val}
                      onClick={() => onChange(option)}
                      className={classnames({
                        [styles.active]: active,
                        [styles.disabled]: option && option.disabled
                      })}
                      tabIndex="0"
                      onKeyUp={simulateClickOnFocusedElement}
                    >
                      <RadioButton
                        label={label}
                        value={val}
                        checked={active}
                        className={styles.radio}
                        tabIndex="-1"
                      />
                      <div className={styles.date}>{date && date}</div>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectBoxMobile;
