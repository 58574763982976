import classnames from 'classnames';
import React from 'react';
import styles from './HelpChatButton.scss';
import { MainButton } from './button';

// the action is added by intercom via the css class 'launch-help-chat'
export const HelpChatButton = () => (
  <MainButton
    label="Help Chat"
    icon="fab fa-intercom"
    className={classnames('launch-help-chat', styles.helpChat)}
    aria-label="Help Chat"
    tabIndex="0"
  />
);
