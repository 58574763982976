import React, { useEffect } from 'react';
import {
  addBodyScrollClass,
  removeBodyScrollClass
} from 'utils/updateBodyScrollClass';

export const useToggleBodyScroll = isModalOpen => {
  useEffect(() => {
    if (isModalOpen) {
      addBodyScrollClass();
    } else {
      removeBodyScrollClass();
    }
  }, [isModalOpen]);
};
