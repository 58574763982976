import { gql } from '@apollo/client';

export function enrollUserInProgram(apolloClient) {
  const mutation = gql`
    mutation enrollUserInProgram($input: EnrollUserInProgramInput!) {
      enrollUserInProgram(input: $input)
    }
  `;

  return async (userProgram, adminUser) => {
    await apolloClient.mutate({
      mutation,
      variables: {
        input: {
          enrolleeId: userProgram.id,
          adminUserId: adminUser.id
        }
      },
      refetchQueries: ['getAdminData']
    });
  };
}
