import { gql } from '@apollo/client';

const MOVE_TO_FOLDER = gql`
  mutation moveToFolder($files: [MyFileInput], $folderId: String!) {
    moveToFolder(files: $files, folderId: $folderId)
  }
`;

export const moveToFolder = apolloClient => (files, folderId) =>
  apolloClient
    .mutate({
      mutation: MOVE_TO_FOLDER,
      variables: {
        files,
        folderId
      }
    })
    .then(response => response.data.moveToFolder);
