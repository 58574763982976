import React, { memo } from 'react';
import FocusLock from 'react-focus-lock';
import styles from './EditorForm.scss';
import { Input } from 'react-toolbox/lib/input';
import { simulateClickOnFocusedElement } from 'utils/simulateClickOnFocusedElement';
import { MainButton, SecondaryButton } from 'components/common/button';
import SmashcutBrowseButton from 'components/common/SmashcutBrowseButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import FileTooBigWarningDialog from '../../common/FileTooBigWarningDialog';
import { isEmpty, get } from 'lodash';

export const UploadInfoAndTitleContainer = memo(
  ({
    formInfoContent,
    allowedFormats,
    formAdditionalInfoBoxes,
    formTitle,
    assignmentTitle,
    children
  }) => (
    <>
      <div className={styles.uploadInfoContainer}>
        <div className={styles.infoOverlay} />
        <div className={styles.infoContent}>
          <div>{formInfoContent}</div>
          <div className={styles.specifications}>
            {!isEmpty(allowedFormats) && (
              <div className={styles.infoBox}>
                <span className={styles.infoBoxLeft}>Formats</span>
                {allowedFormats.join(', ')}
              </div>
            )}
            {formAdditionalInfoBoxes}
          </div>
          {children}
        </div>
      </div>
      {!isEmpty(formTitle) && (
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <span className={styles.label}>{formTitle}</span>
            <span>{assignmentTitle}</span>
          </div>
        </div>
      )}
    </>
  )
);

export const EditorForm = ({
  formAdditionalInfoBoxes,
  formInfoContent,
  formTitle,
  formDescriptionInputPlaceholder,
  formUploadingTitle,
  fileNameBlock,
  allowedFormats,
  assignmentTitle,
  description,
  onChangeDescription,
  isUploading,
  progressPercent,
  onFileChosen,
  selectedFileName,
  selectedFileError,
  submitButtonLabel,
  isSubmitDisabled,
  onSubmit,
  onCancel,
  fileTooBigWarning,
  setFileTooBigWarning,
  myFiles,
  hasFileCreated
}) => (
  <FocusLock returnFocus={true} group="group">
    <div className={styles.topContainer}>
      <UploadInfoAndTitleContainer
        formInfoContent={formInfoContent}
        allowedFormats={allowedFormats}
        formAdditionalInfoBoxes={formAdditionalInfoBoxes}
        formTitle={formTitle}
        assignmentTitle={assignmentTitle}
      />
      <div className={styles.formContainer}>
        {fileNameBlock}
        <div className={styles.descriptionInput}>
          <Input
            className={styles.textareaContainer}
            placeholder={formDescriptionInputPlaceholder}
            aria-label={formDescriptionInputPlaceholder}
            type="text"
            multiline
            theme={styles}
            floating={true}
            value={description}
            onChange={onChangeDescription}
            data-cy="projectDescriptionInput"
            disabled={isUploading}
          />
        </div>
        <div className={styles.buttonsContainer}>
          {(() => {
            if (isUploading) {
              return (
                <div className={styles.uploadingBlock}>
                  <div className={styles.uploadStatus}>
                    {formUploadingTitle}{' '}
                    <span>
                      ({Math.floor(progressPercent)}
                      %)
                    </span>
                  </div>
                  <br />
                  <div className={styles.progressContainer}>
                    <ProgressBar
                      type="linear"
                      mode="determinate"
                      value={progressPercent}
                    />
                  </div>
                </div>
              );
            }

            if (
              hasFileCreated ||
              (!isEmpty(myFiles) && get(myFiles, '[0].status') !== 'Ready')
            ) {
              return (
                <div className={styles.spinnerBlock}>
                  <i className={`${styles.spinner} fa fa-spinner`} />
                  <span>
                    {get(myFiles, '[0].status') === 'Encoding'
                      ? 'ENCODING VIDEO'
                      : 'CREATING FILE'}
                  </span>
                </div>
              );
            }

            return (
              <div className={styles.selectedFileBlock}>
                <div className={styles.fileSelector}>
                  <SmashcutBrowseButton
                    label="Choose File"
                    aria-label="Choose File"
                    primary
                    className={styles.selectedFileButton}
                    onChange={onFileChosen}
                    data-cy="fileSelectorButton"
                    onKeyUp={simulateClickOnFocusedElement}
                    tabIndex="0"
                  />
                  {selectedFileName !== '' && (
                    <Input
                      type="text"
                      theme={styles}
                      className={styles.selectedFileInput}
                      aria-label="Selected File"
                      floating={true}
                      required={true}
                      value={selectedFileName}
                      disabled={true}
                    />
                  )}
                </div>
              </div>
            );
          })()}
          <div className={styles.createCancelButtonsBlock}>
            <MainButton
              label={submitButtonLabel}
              aria-label={submitButtonLabel}
              className={styles.createButton}
              disabled={isSubmitDisabled}
              onClick={onSubmit}
              data-cy="uploadButton"
            />
            <SecondaryButton
              label="Cancel"
              aria-label="Cancel"
              onClick={onCancel}
            />
          </div>
        </div>
      </div>
      <div className={styles.selectedFileError}>{selectedFileError}</div>
    </div>
    {fileTooBigWarning && (
      <FileTooBigWarningDialog onOk={() => setFileTooBigWarning(false)} />
    )}
  </FocusLock>
);
