import { actionType } from '../constants/actionTypes';
import { logAndSendError } from 'utils/sentryHelper';
import { authStateChange } from '../../smashcut-client-lib/actions/userActions';

export const impersonate = (user, replace) => {
  return (dispatch, getState, { adminApi }) => {
    dispatch({ type: actionType.impersonate });
    return adminApi
      .impersonate(user.id)
      .then(() => {
        dispatch({ type: actionType.impersonateSuccess, user });
        dispatch(authStateChange());

        setTimeout(() => {
          replace('/');
        }, 2000);
      })
      .catch(e => logAndSendError(e, 'error impersonating user' + user.email));
  };
};
