import * as authUtils from 'common/authUtils';
import config from 'config';
import { actionType } from '../constants';
import { logAndSendError } from 'utils/sentryHelper';
import { actions as appVersionActions } from 'reducers/appVersion';
import { loadAppSettingsDataComplete } from './loadAppSettingsDataComplete';
import {
  discussionMessageReceived,
  loadChannels,
  insertDiscussionData
} from './discussionsActions';
import { updatePushPermissionRequired } from './updatePushPermissionRequired';
import { intercomHelper } from 'utils/intercomHelper';
import { loadUserData } from './loadUserData';
import {
  notificationsReceived,
  tooltipNotificationReceived,
  upcomingLmsReceived
} from 'reducers/dashboard/notifications';
import { LOGIN_ERROR, LOGOUT, REQUEST_LOGIN } from '../reducers/authReducer';
import { subscribeToProjects } from './subscribeToProjects';
import {
  subscribeToProgramInstances,
  unsubscribeFromProgramInstances
} from './subscribeToProgramInstances';
import { gql } from '@apollo/client';
import { isEmpty } from 'lodash';

export const requestLogin = (email, password, isManualLogin = false) => ({
  type: REQUEST_LOGIN,
  payload: { email, password, isManualLogin }
});

export const verifyAccount = (profile, accessToken) => {
  return (dispatch, getState, { apolloClient }) => {
    authUtils.setToken(accessToken);
    return apolloClient
      .query({
        query: gql`
          query verifyAccount {
            verifyAccount
          }
        `
      })
      .then(() => {
        console.log('account verified');
        authUtils.signIn(profile, accessToken);
        dispatch(authStateChange());
      });
  };
};

export const reloadCurrentUser = () => {
  return (dispatch, getState, { api, apolloClient, lessonApi }) => {
    const user = authUtils.getUser();
    return dispatch(loadUserData(user.id));
  };
};

export const authStateChange = () => {
  return (dispatch, getState, { api, apolloClient, lessonApi }) => {
    const user = authUtils.getUser();
    dispatch({ type: actionType.firebaseUp, payload: user });

    cleanup().then(() => {
      if (user) {
        api.user.startUpdatingOnlineStatus(user.id);
        api
          .loadAppSettings()
          .then(appSettings => {
            dispatch(loadAppSettingsDataComplete(appSettings));
          })
          .catch(e => logAndSendError(e, 'error loading app settings'));

        api.appVersion.subscribe(appVersion =>
          dispatch(appVersionActions.updateAppVersion(appVersion))
        );

        dispatch(loadUserData(user.id)).then(user => {
          const currentEnrolleeId =
            user.enrollments.length === 1
              ? user.enrollments[0].id
              : user.currentUserProgramId;

          subscribeToProjects(lessonApi, currentEnrolleeId, dispatch);

          if (!isEmpty(user.enrollments)) {
            api.discussions.subscribe(
              user.id,
              currentEnrolleeId,
              insertDiscussionData(dispatch),
              discussionMessageReceived(dispatch, api, getState),
              channels => {
                loadChannels(dispatch, user, channels);
              }
            );
          }

          subscribeToProgramInstances(apolloClient, dispatch);
        });

        api.tracking.startSession(user);
        intercomHelper().update(user.email);

        api.initMessaging(user.id, message => {
          dispatch({
            type: actionType.messageReceived,
            payload: {
              notification: message
            }
          });
        });

        api.notifications.listenToBadgeNotifications(user.id, notifications =>
          dispatch(notificationsReceived(notifications))
        );
      }
    });

    function cleanup() {
      api.tracking.stopSession();
      api.user.stopUpdatingOnlineStatus();
      api.appVersion.unsubscribe();
      lessonApi.unsubscribe();
      api.discussions.unsubscribe();
      unsubscribeFromProgramInstances();
      api.notifications.unsubscribe();
      return apolloClient.clearStore();
    }
  };
};

export const logout = history => (dispatch, getState, { api, auth0Lock }) => {
  dispatch({ type: LOGOUT });

  if (config.auth0Enabled) {
    auth0Lock.logout({ returnTo: window.location.origin + '/login' });
    authUtils.signOut();
  } else {
    api.signOut();
    if (history) {
      history.push('/login');
    } else {
      location.href = '/login';
    }
  }
};

export const signUp = payload => (dispatch, getState, { api }) => {
  const { email, password, fullName } = payload;
  return api.signUp(email, password, fullName);
};

export const login = payload => (dispatch, getState, { api }) => {
  const email = payload.email.toLocaleLowerCase();
  const isManualLogin = true;
  dispatch(requestLogin(email, payload.password, isManualLogin));
  return api
    .login(email, payload.password)
    .then(data => {
      const { status, token, refreshToken, user } = data;
      if (status === 'SUCCESS') {
        authUtils.signIn(user, token, refreshToken);
        dispatch(authStateChange());
      } else {
        dispatch({ type: LOGIN_ERROR, payload: token });
      }
    })
    .catch(error => dispatch({ type: LOGIN_ERROR, payload: error }));
};
