import { gql } from '@apollo/client';

export function addProgramInstance(apolloClient) {
  const ADD_PROGRAM_INSTANCE = gql`
    mutation addProgramInstance($input: ProgramInstanceInput) {
      addProgramInstance(input: $input) {
        id
      }
    }
  `;

  return async input => {
    return apolloClient.mutate({
      mutation: ADD_PROGRAM_INSTANCE,
      variables: {
        input
      },
      refetchQueries: ['getAdminData']
    });
  };
}
