import { gql } from '@apollo/client';

const loginMutation = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      status
      token
      refreshToken
      user {
        id
        fullName
        firstName
        lastName
        avatar
        email
        enrollments {
          id
        }
      }
    }
  }
`;

export const login = apolloClient => (email, password) =>
  apolloClient
    .mutate({
      mutation: loginMutation,
      variables: { email, password }
    })
    .then(response => response.data.login);
