import { getCurrentUserProgram } from './getCurrentUserProgram';
import { isTest } from 'common/isTest';

const defaultReturnValue = false;

export function getCurrentUserProgramHasEnded(state) {
  if (isTest('ended')) {
    return true;
  }

  const userProgram = getCurrentUserProgram(state);

  if (!userProgram) {
    return defaultReturnValue;
  }

  return userProgram.programInstance.hasEnded === true;
}
