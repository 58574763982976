import { signUrl } from '../../smashcut-player-lib/signUrl';
import { resolveAssetToStoragePath } from '../../common/assetUtils';

export const getContentBucketDownloadUrl = (
  storageService,
  isProd
) => async asset => {
  const storagePath = resolveAssetToStoragePath(asset);
  const maybeGeneration =
    isProd && asset.generation ? `&versionId=${asset.generation}` : '';
  // TODO make dynamic
  const url = `https://smashcut-program-assets.s3.amazonaws.com/${encodeURIComponent(
    storagePath
  )}`;
  const signedUrl = await signUrl(url);
  return `${signedUrl}${maybeGeneration}`;
};
