import { actionType } from '../constants';
import { getCurrentUserId } from '../selectors';

export const updateUser = changes => {
  return (dispatch, getState, { api }) => {
    let state = getState();
    let currentUserId = getCurrentUserId(state);
    api.user.updateUser(currentUserId, changes).then(() => {
      dispatch({
        type: actionType.updateUser,
        changes
      });
    });
  };
};
