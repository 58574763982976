const PREFIX = 'UPLOADS';

export const types = {
  START_UPLOAD: `${PREFIX}/START_UPLOAD`,
  UPLOAD_PROGRESS: `${PREFIX}/UPLOAD_PROGRESS`,
  UPLOAD_FINISHED: `${PREFIX}/UPLOAD_FINISHED`,
  UPLOAD_INTERRUPTED: `${PREFIX}/UPLOAD_INTERRUPTED`
};

const initialState = { progress: 0, uploadTask: null, uploading: null };

export const uploadsReducer = (state = initialState, action) => {
  let nextState = state;
  switch (action.type) {
    case types.START_UPLOAD:
      nextState = {
        uploading: true,
        progress: action.resetProgress ? 0 : state.progress
      };
      break;

    case types.UPLOAD_PROGRESS:
      nextState = {
        ...state,
        progress: action.data.progress,
        uploadTask: action.data.uploadTask
      };
      break;

    case types.UPLOAD_FINISHED:
      nextState = {
        ...state,
        uploading: false,
        uploadRecord: action.uploadRecord
      };
      break;

    case types.UPLOAD_INTERRUPTED:
      nextState = {
        ...state,
        uploading: false
      };
      break;
  }

  return nextState;
};

export class UploadCanceledError extends Error {
  constructor() {
    super('Upload canceled');
    // a workaround to make `instanceof UploadCanceledError` work in babel 6
    this.constructor = UploadCanceledError;
    this.__proto__ = UploadCanceledError.prototype;
  }
}

function startUpload({
  sourceFile,
  fileType,
  uploadOwnerId,
  uploadType,
  onProgress,
  resetProgress = true,
  isSharedFile
}) {
  return (dispatch, getState, { api }) => {
    return new Promise((resolve, reject) => {
      const progressHandler = (uploaded, total, uploadTask) => {
        const { bytesLoaded, bytesTotal } = (onProgress &&
          onProgress(uploaded, total, uploadTask)) || {
          bytesLoaded: uploaded,
          bytesTotal: total
        };
        const progress = (bytesLoaded * 100) / bytesTotal;
        dispatch({
          type: types.UPLOAD_PROGRESS,
          data: {
            progress,
            bytesLoaded,
            bytesTotal,
            uploadTask
          }
        });
      };

      api
        .uploadFile(
          sourceFile,
          fileType,
          uploadOwnerId,
          uploadType,
          progressHandler,
          isSharedFile
        )
        .then(uploadRecord => {
          console.log('Upload Finished', uploadRecord);
          dispatch({ type: types.UPLOAD_FINISHED, uploadRecord });
          resolve(uploadRecord);
        })
        .catch(failedUploadRecord => {
          console.log('Upload Failed', failedUploadRecord);
          dispatch({ type: types.UPLOAD_FINISHED, failedUploadRecord });
          reject(failedUploadRecord);
        });

      dispatch({ type: types.START_UPLOAD, resetProgress });
    });
  };
}

function cancelUpload() {
  return (dispatch, getState) => {
    const state = getState();
    const uploadState = state.uploads;
    if (
      uploadState &&
      uploadState.uploadTask &&
      uploadState.uploadTask.cancel
    ) {
      uploadState.uploadTask.cancel();
    }
  };
}

export const actions = {
  cancelUpload,
  startUpload
};
