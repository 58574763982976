import { selectProject } from './selectProject';

export const loadInitialData = initialProjectId => {
  return dispatch => {
    if (initialProjectId) {
      dispatch(selectProject(initialProjectId));
    } else {
      // that should never happen
      console.warn('Missing project id');
    }
  };
};
