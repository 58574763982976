import { actionType } from '../constants';
import { types as discussionTypes } from '../../reducers/dashboard/discussionTypes';
import { get } from 'lodash';
import { getCurrentUserProgram } from 'smashcut-client-lib/selectors';

export const discussionMessagesReceived = payload => ({
  type: actionType.INSERT_ENTITY_DISCUSSIONS_MESSAGES,
  payload
});

export const discussionLastReadsReceived = payload => ({
  type: actionType.INSERT_ENTITY_DISCUSSIONS_LAST_READS,
  payload
});

export const discussionInsertLastRead = (dispatch, lastRead) =>
  dispatch({
    type: actionType.INSERT_ENTITY_LAST_READ,
    entityType: 'lastReads',
    id: lastRead.receiverId,
    entity: lastRead
  });

export const discussionMessageReceived = (
  dispatch,
  api,
  getState,
  deleteAction
) => message => {
  const state = getState();
  const up = getCurrentUserProgram(state);
  const currentProgramInstanceId = up.programInstanceId;

  if (currentProgramInstanceId !== message.programInstanceId) {
    return;
  }

  const type =
    {
      EDIT: actionType.UPDATE_ENTITY_DISCUSSIONS_MESSAGE,
      DELETE: actionType.REMOVE_ENTITY_DISCUSSIONS_MESSAGE
    }[message.action] || actionType.INSERT_ENTITY_NEW_DISCUSSIONS_MESSAGE;

  dispatch({
    type: deleteAction ? actionType.REMOVE_ENTITY_DISCUSSIONS_MESSAGE : type,
    entityType: 'messages',
    id: message.id,
    entity: message
  });

  const selectedChannelId = get(
    getState(),
    'dashboard.discussions.selectedChannelId'
  );

  if (!message.isGroupMessage && selectedChannelId === message.authorId) {
    api.discussions
      .updateLastRead(message.authorId, message.receiverId)
      .then(lastRead => {
        lastRead && discussionInsertLastRead(dispatch, lastRead);
      });
  }

  if (message.isGroupMessage && selectedChannelId === message.receiverId) {
    api.discussions
      .updateLastRead(message.receiverId, message.authorId)
      .then(lastRead => {
        lastRead && discussionInsertLastRead(dispatch, lastRead);
      });
  }
};

export const insertDiscussionData = dispatch => (
  discussionMessages,
  discussionLastReads
) => {
  discussionMessages &&
    dispatch(discussionMessagesReceived(discussionMessages));
  discussionLastReads &&
    dispatch(discussionLastReadsReceived(discussionLastReads));
};

export const loadChannels = (dispatch, user, channels) => {
  dispatch({ type: discussionTypes.LOAD_CHANNEL_GROUPS_REQUEST });

  const memberMap = channels.reduce((acc, channel) => {
    acc[channel.id] = { ...channel, fullName: channel.name };
    return acc;
  }, {});

  dispatch({
    type: discussionTypes.LOAD_CHANNEL_GROUPS_SUCCESS,
    channels,
    memberMap: { ...memberMap, [user.id]: user }
  });
};
