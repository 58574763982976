import createSagaMiddleware from 'redux-saga';
import initialAppState from '../state/appState';
import coBrowsingMiddleware from '../middleware/coBrowsingMiddleware';
import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';
import reduxLogger from 'redux-logger';
import config from 'config';
import * as Sentry from '@sentry/react';

function initStore(opts) {
  let reducer = opts.reducer;
  let apis = opts.apis;
  let sagaMiddleware = createSagaMiddleware();
  let middleware = prepareMiddleware(opts, apis, sagaMiddleware);
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
  });
  let store = createStore(
    reducer,
    initialAppState,
    compose(middleware, sentryReduxEnhancer)
  );

  sagaMiddleware.run(opts.rootSaga, {
    apis: opts.apis,
    history: opts.history
  });
  return store;
}

function prepareMiddleware(opts, apis, sagaMiddleware) {
  let middlewares = [];

  if (opts.routingMiddleware) {
    middlewares.push(opts.routingMiddleware);
  }
  middlewares.push(coBrowsingMiddleware());
  middlewares.push(thunk.withExtraArgument(apis));
  middlewares.push(sagaMiddleware);
  if (config.reduxLoggerEnabled) {
    middlewares.push(reduxLogger);
  }
  let middleware = composeMiddlewares(middlewares);
  return middleware;
}

function composeMiddlewares(middlewares) {
  let doCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  let middleware = doCompose(applyMiddleware.apply(this, middlewares));
  return middleware;
}

export default initStore;
