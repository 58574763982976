export const actionType = {
  acceptUserToProgramSuccess: 'ACCEPT_USER_TO_PROGRAM_SUCCESS',
  addEnrollee: 'ADD_ENROLLEE',
  addEnrolleeSuccess: 'ADD_ENROLLEE_SUCCESS',
  addProgramInstance: 'ADD_PROGRAM_INSTANCE',
  addProgramInstanceSuccess: 'ADD_PROGRAM_INSTANCE_SUCCESS',
  adminDataUpdated: 'ADMIN_DATA_UPDATED',
  assignMentorToUser: 'ASSIGN_MENTOR_TO_USER',
  assignMentorToUserSuccess: 'ASSIGN_MENTOR_TO_USER_SUCCESS',
  deployProgramRequest: 'DEPLOY_PROGRAM_REQUEST',
  deployProgramResponse: 'DEPLOY_PROGRAM_RESPONSE',
  enrollTrialUser: 'ENROLL_TRIAL_USER',
  enrollTrialUserSuccess: 'ENROLL_TRIAL_USER_SUCCESS',
  enrollUserInProgramSuccess: 'ENROLL_USER_IN_PROGRAM_SUCCESS',
  impersonate: 'IMPERSONATE',
  impersonateExit: 'IMPERSONATE_EXIT',
  impersonateSuccess: 'IMPERSONATE_SUCCESS',
  makeUserMentor: 'MAKE_USER_MENTOR',
  makeUserMentorSuccess: 'MAKE_USER_MENTOR_SUCCESS',
  makeUserStudent: 'MAKE_USER_STUDENT',
  makeUserStudentSuccess: 'MAKE_USER_STUDENT_SUCCESS',
  makeUserInstructor: 'MAKE_USER_INSTRUCTOR',
  makeUserInstructorSuccess: 'MAKE_USER_INSTRUCTOR_SUCCESS',
  removeEnrollee: 'REMOVE_ENROLLEE',
  removeEnrolleeSuccess: 'REMOVE_ENROLLEE_SUCCESS',
  removeTrialUser: 'REMOVE_TRIAL_USER',
  removeTrialUserSuccess: 'REMOVE_TRIAL_USER_SUCCESS',
  setUserIsFreeAccount: 'SET_USER_IS_FREE_ACCOUNT',
  setUserIsFreeAccountSuccess: 'SET_USER_IS_FREE_ACCOUNT_SUCCESS',
  unassignMentorToUser: 'UNASSIGN_MENTOR_TO_USER',
  unassignMentorToUserSuccess: 'UNASSIGN_MENTOR_TO_USER_SUCCESS',
  loadAllUserSuccess: 'LOAD_ALL_USER_SUCCESS'
};
