import LoginForm from './LoginForm';
import LoginPageLayout from './LoginPageLayout';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import qs from 'qs';

const LoginPage = ({ location }) => {
  const searchString = location.search.slice(1);
  const query = qs.parse(searchString);

  return (
    <LoginPageLayout>
      <LoginForm redirect={query.redirect} email={query.email} />
    </LoginPageLayout>
  );
};

export default hot(LoginPage);
