import { gql } from '@apollo/client';

export const saveAnnotation = (apolloClient, compressionService) => (
  commentSectionId,
  commentId,
  width,
  height,
  annotationData
) => {
  let jsonString = JSON.stringify(annotationData);
  let compressedAnnotationData = compressionService.compress(jsonString);
  let data = String.fromCharCode.apply(null, compressedAnnotationData);

  const mutation = gql`
    mutation saveAnnotation($input: SaveAnnotationInput!) {
      saveAnnotation(input: $input)
    }
  `;

  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        commentSectionId,
        commentId,
        width,
        height,
        data
      }
    }
  });
};
