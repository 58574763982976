import { gql } from '@apollo/client';

export function acceptUserToProgram(apolloClient) {
  const mutation = gql`
    mutation acceptUserToProgram($input: AcceptUserToProgramInput!) {
      acceptUserToProgram(input: $input)
    }
  `;

  return async userProgram => {
    await apolloClient.mutate({
      mutation,
      variables: {
        input: {
          enrolleeId: userProgram.id
        },
        refetchQueries: ['getAdminData']
      }
    });
  };
}
