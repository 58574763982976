import React from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './NoteMarkerDisplay.scss';
import SmashcutAvatar from 'components/common/SmashcutAvatar';
import { getString } from '../common/getString';

function extractAvatarUrl(raw) {
  const rex = /<img[^>]+src="?([^"\s]+)"?/gi;
  const m = rex.exec(raw);
  return m && m[1];
}

export const NoteMarkerDisplay = ({ noteRef, noteMarker }) => {
  const avatar =
    noteMarker.type === 'note' ? false : extractAvatarUrl(noteMarker.avatar);
  return (
    <div ref={noteRef}>
      <div className={styles.title}>
        {avatar && (
          <SmashcutAvatar
            holderClassName={styles.avatar}
            src={avatar}
            width={32}
            height={32}
          />
        )}
        <div className={styles.titleContent}>
          <div>{noteMarker.title}</div>
          {noteMarker.comment && (
            <div className={styles.titleSub}>
              {getString(noteMarker.roleLabel)}
            </div>
          )}
        </div>
      </div>
      {noteMarker.text && (
        <ReactMarkdown
          source={noteMarker.teaser || noteMarker.text}
          escapeHtml={false}
        />
      )}
      {noteMarker.comment && <div>{noteMarker.comment}</div>}
    </div>
  );
};
