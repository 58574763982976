import { gql } from '@apollo/client';

export const setIsMentor = apolloClient => async (
  userId,
  isMentor,
  roleLabel
) => {
  const mutation = gql`
    mutation setIsMentor($input: SetIsMentorInput!) {
      setIsMentor(input: $input) {
        id
        isMentor
        roleLabel
      }
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        userId,
        isMentor,
        roleLabel
      }
    },
    refetchQueries: ['adminUsers']
  });
};
