import { gql } from '@apollo/client';

export const handleEncodingError = apolloClient => async (
  item,
  projectId,
  lessonRecordId
) => {
  // console.log('handleEncodingError', item, lessonRecordId);

  const asmt = item.assignment;

  const mutation = gql`
    mutation handleEncodingError($input: HandleEncodingErrorInput!) {
      handleEncodingError(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        lessonRecordId,
        userProgramId: asmt.userProgramId,
        projectId
      }
    }
  });
};
