const PREFIX = 'ADMIN/DELETE_USER/';

export const types = {
  DELETE_USER: `${PREFIX}DELETE_USER`,
  DELETE_USER_ALERT: `${PREFIX}DELETE_USER_ALERT`,
  DELETE_USER_ALERT_OK: `${PREFIX}DELETE_USER_ALERT_OK`,
  DELETE_USER_CONFIRM: `${PREFIX}DELETE_USER_CONFIRM`,
  DELETE_USER_CONFIRM_OK: `${PREFIX}DELETE_USER_CONFIRM_OK`,
  DELETE_USER_CONFIRM_CANCEL: `${PREFIX}DELETE_USER_CONFIRM_CANCEL`,
  DELETE_USER_REQUEST: `${PREFIX}DELETE_USER_REQUEST`,
  DELETE_USER_SUCCESS: `${PREFIX}DELETE_USER_SUCCESS`,
  DELETE_USER_FAILURE: `${PREFIX}DELETE_USER_FAILURE`
};

const initialState = {};

export const deleteUserReducer = (state = initialState, action) => {
  let nextState = state;
  switch (action.type) {
    case types.DELETE_USER:
      nextState = {};
      break;
    case types.DELETE_USER_ALERT:
      nextState = {
        ...state,
        alertVisible: true,
        title: action.payload.title,
        message: action.payload.message
      };
      break;
    case types.DELETE_USER_ALERT_OK:
      nextState = {};
      break;
    case types.DELETE_USER_SUCCESS:
      nextState = {
        ...state,
        alertVisible: true,
        title: action.payload.title,
        message: action.payload.message
      };
      break;
    case types.DELETE_USER_FAILURE:
      nextState = {
        ...state,
        alertVisible: true,
        title: action.payload.title,
        message: action.payload.error
      };
      break;
    case types.DELETE_USER_CONFIRM:
      nextState = {
        ...state,
        confirmVisible: true,
        title: action.payload.title,
        message: action.payload.message
      };
      break;
    case types.DELETE_USER_CONFIRM_CANCEL:
    case types.DELETE_USER_CONFIRM_OK:
      nextState = {};
      break;
  }
  return nextState;
};

const deleteUser = user => {
  return { type: types.DELETE_USER, payload: { user } };
};
const deleteUserRequest = user => {
  return { type: types.DELETE_USER_REQUEST, payload: { user } };
};
const deleteUserSuccess = (message, title) => {
  return { type: types.DELETE_USER_SUCCESS, payload: { message, title } };
};
const deleteUserFailure = (error, title) => {
  return { type: types.DELETE_USER_FAILURE, payload: { error, title } };
};

const deleteUserAlert = (message, title) => {
  return { type: types.DELETE_USER_ALERT, payload: { message, title } };
};
const deleteUserAlertOk = () => {
  return { type: types.DELETE_USER_ALERT_OK };
};

const deleteUserConfirm = (message, title) => {
  return { type: types.DELETE_USER_CONFIRM, payload: { message, title } };
};
const deleteUserConfirmOk = () => {
  return { type: types.DELETE_USER_CONFIRM_OK };
};
const deleteUserConfirmCancel = () => {
  return { type: types.DELETE_USER_CONFIRM_CANCEL };
};

export const actions = {
  deleteUser,
  deleteUserRequest,
  deleteUserSuccess,
  deleteUserFailure,
  deleteUserAlert,
  deleteUserAlertOk,
  deleteUserConfirm,
  deleteUserConfirmOk,
  deleteUserConfirmCancel
};

export const selectors = {
  getDeleteUser: state => state.adminActions.deleteUser
};
