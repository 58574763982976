import { apolloClient } from '../apolloClient';
import { gql } from '@apollo/client';

export function findAsset(id) {
  if (!id) {
    console.warn('id is falsy', id);
    return false;
  }
  const asset = getAssetFromClientCache(id);

  if (!asset) {
    console.warn('no asset found', id);
    return false;
  }

  return asset;
}

export function getAssetFromClientCache(id) {
  const cacheId = apolloClient.cache.identify({
    id,
    __typename: 'ContentAsset'
  });
  // readFragment can read arbitrary records from the cache
  // it returns null if the record or any of the requested props does not exist
  const asset = apolloClient.cache.readFragment({
    id: cacheId,
    fragment: gql`
      fragment MyContentAsset on ContentAsset {
        id
        generation
        name
        storagePath
      }
    `
  });
  return asset;
}

export function resolveAssetToStoragePath(asset) {
  const storagePath =
    asset && (asset.storagePath || asset.originalFileName || asset.name);
  if (!storagePath) {
    console.error('could not resolve asset to storage path', asset);
    return false;
  }
  return storagePath;
}

export function resolveAssetIdToStoragePath(id) {
  const asset = findAsset(id);
  const storagePath = resolveAssetToStoragePath(asset);
  return storagePath;
}
