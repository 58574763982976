import { gql } from '@apollo/client';

export const loadAppSettings = apolloClient => () => {
  const query = gql`
    query m {
      appSettings {
        heroBackgroundUrl
        videoRecorder {
          width
          height
          mimeTypesSortedByPreference
          bandWidth {
            audioBitsPerSecond
            bitsPerSecond
            videoBitsPerSecond
          }
        }
      }
    }
  `;
  return apolloClient
    .query({
      query,
      variables: {}
    })
    .then(response => response.data.appSettings);
};
