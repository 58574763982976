import { addConfirm } from './addConfirm';
import { addProject } from './addProject';
import { addReview } from './addReview';
import { addScreenplay } from './addScreenplay';
import { addImageProject } from './addImageProject';
import { add3D } from './add3D';
import { addNonSupportedFile } from './addNonSupportedFile';
import { handleEncodingError } from './handleEncodingError';
import { removeConfirm } from './removeConfirm';
import { removeReview } from './removeReview';
import { updateProject } from './updateProject';
import { updateScreenplay } from './updateScreenplay';
import { gql } from '@apollo/client';
import { apolloClient } from 'smashcut-client-lib/services';

let subscriptions = {};

const listenProjectChange = gql`
  subscription onProjectChange($enrolleeId: ID) {
    onProjectChange(enrolleeId: $enrolleeId) {
      assignmentId
      baseUrl
      status
      spriteUrl
      description
      date
      dashUrl
      duration
      commentCount
      hlsUrl
      lessonId
      id
      name
      responseType
      vttUrl
      userProgramId
      thumbnailUrl
    }
  }
`;

const subscribe = apolloClient => async (enrolleeId, callback) => {
  if (!subscriptions[enrolleeId]) {
    subscriptions[enrolleeId] = apolloClient
      .subscribe({
        query: listenProjectChange,
        variables: { enrolleeId }
      })
      .subscribe({
        next(data) {
          const project = data.data.onProjectChange;
          if (project) {
            callback(project);
          }
        },
        error(err) {
          console.error('Error subscribing to project change: ', err);
        }
      });
  }
};

const unsubscribe = () => {
  Object.keys(subscriptions).forEach(enrolleeId => {
    if (subscriptions[enrolleeId]) {
      subscriptions[enrolleeId].unsubscribe();
      delete subscriptions[enrolleeId];
    }
  });
};

const api = config => {
  return {
    addConfirm: addConfirm(apolloClient),
    addProject: addProject(apolloClient),
    addReview: addReview(apolloClient),
    addScreenplay: addScreenplay(apolloClient),
    add3D: add3D(apolloClient),
    addImageProject: addImageProject(apolloClient),
    addNonSupportedFile: addNonSupportedFile(apolloClient),
    handleEncodingError: handleEncodingError(apolloClient),
    removeConfirm: removeConfirm(apolloClient),
    removeReview: removeReview(apolloClient),
    updateProject: updateProject(apolloClient),
    updateScreenplay: updateScreenplay(apolloClient),
    subscribe: subscribe(apolloClient),
    unsubscribe
  };
};

export default api;
