import { gql } from '@apollo/client';

export const addTextReply = apolloClient => async (
  commentSectionId,
  parentId,
  replyId,
  author,
  msg
) => {
  const mutation = gql`
    mutation addTextReply($input: AddTextReplyInput!) {
      addTextReply(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        commentSectionId,
        parentId,
        replyId,
        author,
        msg
      }
    }
  });
};
