import { gql } from '@apollo/client';
import { get } from 'lodash';

export const loadAllUsers = apolloClient => async () => {
  const result = await apolloClient.query({
    query: gql`
      query loadAllUsers {
        users {
          id
          currentUserProgramId
          email
          isAdmin
          isHidden
          isMentor
          isPrivate
          isStaff
          fullName
        }
      }
    `
  });
  return get(result, 'data.users');
};
