import { all, call, put, takeEvery } from 'redux-saga/effects';
import { types } from '../reducers/scheduleBookings';
import { gql } from '@apollo/client';

export function* bookSchedule(apolloClient, action) {
  try {
    const result = yield call(apolloClient.mutate, {
      mutation: BOOK_LMS_AND_LINK_IF_NEEDED,
      variables: action
    });
    yield put({ type: types.BOOK_SCHEDULE_SUCCESS, result });
  } catch (e) {
    console.error('Error booking: ', e);
    yield put({ type: types.BOOK_SCHEDULE_FAILURE, error: e });
  }
}

export function* scheduleMeeting(apolloClient, action) {
  try {
    const result = yield call(apolloClient.mutate, {
      mutation: SCHEDULE_MEETING,
      variables: action
    });
    yield put({ type: types.SCHEDULE_MEETING_SUCCESS, result });
  } catch (e) {
    console.error('Error scheduling: ', e);
    yield put({ type: types.SCHEDULE_MEETING_FAILURE, error: e });
  }
}

export function* deleteBooking(apolloClient, action) {
  try {
    const { bookingId } = action;
    // console.log('deleting booking', action);
    // console.log(apolloClient);
    const result = yield call(apolloClient.mutate, {
      mutation: DELETE_LMS_BOOKING,
      variables: {
        bookingId
      }
    });
    // console.log('delete result', result);
    yield put({ type: types.DELETE_BOOKING_SUCCESS, result });
  } catch (e) {
    console.error('Error deleting meeting: ', e);
    yield put({ type: types.DELETE_BOOKING_FAILURE, error: e });
  }
}

export function* loadUserData(apolloClient, action) {
  try {
    const { userId } = action;
    // console.log('loading users data', action);
    const result = yield call(apolloClient.query, {
      query: LOAD_USER_DATA,
      variables: {
        userId
      }
    });
    // console.log('load users data result', result);
    yield put({
      type: types.LOAD_USER_DATA_SUCCESS,
      data: result.data,
      userId
    });
  } catch (e) {
    console.error('Error loading user data: ', e);
    yield put({ type: types.LOAD_USER_DATA_FAILURE, error: e });
  }
}

export function* loadUsersAvailabilityAndBookings(apolloClient, action) {
  try {
    const { firstUserId, secondUserId } = action;
    if (!(firstUserId && secondUserId)) {
      return;
    }
    console.log('loading users avail and bookings', action);
    const result = yield call(apolloClient.query, {
      query: LOAD_USERS_AVAILABILITY_AND_BOOKING,
      fetchPolicy: 'network-only',
      variables: {
        userId1: firstUserId,
        userId2: secondUserId
      }
    });
    console.log('load users availability result', result);
    yield put({
      type: types.LOAD_USERS_AVAILABILITY_AND_BOOKINGS_SUCCESS,
      data: result.data
    });
  } catch (e) {
    yield put({
      type: types.LOAD_USERS_AVAILABILITY_AND_BOOKINGS_FAILURE,
      error: e
    });
  }
}

export function* bookingSaga({ apis }) {
  const apolloClient = apis.apolloClient;
  yield all([
    takeEvery(
      types.SCHEDULE_MEETING_REQUEST,
      scheduleMeeting.bind(this, apolloClient)
    ),
    takeEvery(
      types.BOOK_SCHEDULE_REQUEST,
      bookSchedule.bind(this, apolloClient)
    ),
    takeEvery(
      types.DELETE_BOOKING_REQUEST,
      deleteBooking.bind(this, apolloClient)
    ),
    takeEvery(
      types.LOAD_USER_DATA_REQUEST,
      loadUserData.bind(this, apolloClient)
    ),
    takeEvery(
      types.LOAD_USERS_AVAILABILITY_AND_BOOKINGS_REQUEST,
      loadUsersAvailabilityAndBookings.bind(this, apolloClient)
    )
  ]);
}

const SCHEDULE_MEETING = gql`
  mutation scheduleMeeting($input: ScheduleMeetingInput) {
    scheduleMeeting(input: $input)
  }
`;

const BOOK_LMS_AND_LINK_IF_NEEDED = gql`
  mutation bookLmsAndLinkIfNeeded($input: CreateLMSBookingInput) {
    bookLmsAndLinkIfNeeded(input: $input)
  }
`;

const DELETE_LMS_BOOKING = gql`
  mutation deleteLmsBooking($bookingId: String!) {
    deleteBooking(bookingId: $bookingId)
  }
`;

const userFragment = `
  id
  enrollments {
    application {
      status
    }
    program {
      title
    }
    mentorSessions {
      id
      week {
        id
        index
      }
      enrollee {
        id
        program {
          id
          title
          programContent {
            courses {
              id
              name
            }
          }
        }
      }
      courseId
      bookingId
    }
  }
`;
const LOAD_USER_DATA = gql`
  query userData($userId: String!) {
    user(id: $userId) {
      ${userFragment}
    }
  }
`;

const LOAD_USERS_AVAILABILITY_AND_BOOKING = gql`
  query availability($userId1: String!, $userId2: String!) {
    user2: user(id: $userId2) {
      settings {
        meetingDuration
      }
    }
    user1Availability: allAvailability(userId: $userId1) {
      isDefault
      from
      to
      ranges
      timeZone
    }
    user1Bookings: allBookings(userId: $userId1) {
      id
      firstUserId
      secondUserId
      from
      to
      linkedTo {
        mentorSessionId
        courseId
        weekId
        weekIndex
        enrollee {
          program {
            title
            programContent {
              courses {
                id
                name
              }
            }
          }
        }
      }
    }
    user2Availability: allAvailability(userId: $userId2) {
      isDefault
      from
      to
      ranges
      timeZone
    }
    user2Bookings: allBookings(userId: $userId2) {
      id
      firstUserId
      secondUserId
      from
      to
    }
  }
`;
