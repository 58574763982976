const BASE = 'FILE_DETAILS';

export const types = {
  UPDATE_FILE_REQUEST: `${BASE}/UPDATE_FILE_REQUEST`,
  UPDATE_FILE_SUCCESS: `${BASE}/UPDATE_FILE_SUCCESS`,
  UPDATE_FILE_FAILURE: `${BASE}/UPDATE_FILE_FAILURE`,

  UPDATE_ISVISIBLE_REQUEST: `${BASE}/UPDATE_ISVISIBLE_REQUEST`,
  UPDATE_ISVISIBLE_SUCCESS: `${BASE}/UPDATE_ISVISIBLE_SUCCESS`,
  UPDATE_ISVISIBLE_FAILURE: `${BASE}/UPDATE_ISVISIBLE_FAILURE`,

  CONFIRM_SUCCESS: `${BASE}/CONFIRM_SUCCESS`,
  CLEAR_ERRORS: `${BASE}/CLEAR_ERRORS`
};

export const initialState = {};

export default (state = initialState, action) => {
  // if (action.type.startsWith(BASE))
  // console.log('reducers.fileDetails.action >', action);
  switch (action.type) {
    case types.UPDATE_FILE_REQUEST:
      return {
        ...state,
        updatingFile: true,
        fileChangedSuccess: undefined
      };

    case types.UPDATE_FILE_SUCCESS:
      return {
        ...state,
        updatingFile: false,
        fileChangedSuccess: true
      };

    case types.UPDATE_FILE_FAILURE:
      return {
        ...state,
        updatingFile: false,
        fileChangedSuccess: false
      };

    case types.UPDATE_ISVISIBLE_REQUEST:
      return {
        ...state,
        updatingIsVisible: true,
        isVisibleChangedSuccess: undefined
      };

    case types.UPDATE_ISVISIBLE_SUCCESS:
      return {
        ...state,
        updatingIsVisible: false,
        isVisibleChangedSuccess: true,
        isVisibleChanged: { id: action.id, newVal: action.newIsVisible }
      };

    case types.UPDATE_ISVISIBLE_FAILURE:
      return {
        ...state,
        updatingIsVisible: false,
        isVisibleChangedSuccess: false
      };

    case types.CONFIRM_SUCCESS:
      return {
        ...state,
        fileChangedSuccess: undefined,
        isVisibleChangedSuccess: undefined
      };
    case types.CLEAR_ERRORS:
      return {
        ...state,
        errors: { [action.fieldName]: undefined }
      };
    default:
      return state;
  }
};

export const actions = {
  updateFile: updates => ({
    type: types.UPDATE_FILE_REQUEST,
    updates
  }),
  updateIsVisible: updates => ({
    type: types.UPDATE_ISVISIBLE_REQUEST,
    updates
  }),
  confirmSuccess: () => ({
    type: types.CONFIRM_SUCCESS
  }),
  clearErrorsForField: fieldName => ({
    type: types.CLEAR_ERRORS,
    fieldName
  })
};
