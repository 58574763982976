import { gql } from '@apollo/client';

export const removeConfirm = apolloClient => async (item, type) => {
  const pl = item.gotoLessonPayload;

  const mutation = gql`
    mutation removeConfirm($input: RemoveConfirmInput!) {
      removeConfirm(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        programId: pl.programId,
        courseId: pl.courseId,
        lessonId: pl.lessonId,
        mainAreaId: pl.mainAreaId,
        itemId: pl.subAssignmentId || pl.itemId,
        type
      }
    }
  });
};
