import { getCurrentUserId } from './getCurrentUserId';
import _ from 'lodash';

export function getVisibleProjectsForUser(state, userId) {
  let currentUserId = userId || getCurrentUserId(state);
  return _.pickBy(state.entities.projects, v => {
    return (
      v.permissions &&
      v.permissions[currentUserId] &&
      v.permissions[currentUserId].read
    );
  });
}
