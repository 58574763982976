import { gql } from '@apollo/client';
import { actions as screenplayViewActions } from 'reducers/screenplayView';
import { DASHBOARD_TAB_PATH } from 'components/dashboard/DashboardTabs_TabConstants';
import { isTest } from '../common/isTest';
import { logAndSendError } from 'utils/sentryHelper';
import { selectors as upcomingLmsSelectors } from 'reducers/dashboard/upcomingLms';
import { types } from './officeHoursReducerTypes';

const initialState = {
  current: null,
  error: null,
  project: null,
  projectInfo: {}
};

export default function officeHoursReducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case types.SETUP_OFFICE_HOURS_COMPLETE:
      return {
        ...state,
        newEntry: action.data
      };

    case types.SETUP_OFFICE_HOURS_ERROR:
      return {
        ...state,
        error: action.error
      };

    case types.LOAD_OFFICE_HOURS_COMPLETE:
      return {
        ...state,
        current: action.data
      };

    case types.LOAD_OFFICE_HOURS_ERROR:
      return {
        ...state,
        error: action.error
      };

    case types.SELECT_PROJECT:
      newState = {
        ...state,
        project: action.project,
        projectInfo: action.projectInfo
      };
      return newState;
  }

  return state;
}

function loadOfficeHours(token) {
  return (dispatch, getState, { officeHoursApi }) => {
    dispatch({
      type: types.LOAD_OFFICE_HOURS,
      token
    });
    officeHoursApi
      .loadOfficeHours(token)
      .then(entry => {
        dispatch({
          type: types.LOAD_OFFICE_HOURS_COMPLETE,
          data: entry
        });
      })
      .catch(error => {
        logAndSendError(error, 'Error loading office hours: ' + token);
        dispatch({
          type: types.LOAD_OFFICE_HOURS_ERROR,
          error
        });
      });
  };
}

function setupOfficeHours(firstUserId, secondUserId, startTime, endTime, push) {
  return async (dispatch, getState, { officeHoursApi }) => {
    dispatch({
      type: types.SETUP_OFFICE_HOURS,
      firstUserId,
      secondUserId
    });

    const handleError = e => {
      logAndSendError(e, 'error setting up office hours');
      dispatch({
        type: types.SETUP_OFFICE_HOURS_ERROR,
        error: e
      });
    };

    try {
      const setupResult = await officeHoursApi.setupOfficeHours({
        firstUserId,
        secondUserId,
        startTime,
        endTime
      });

      dispatch({
        type: types.SETUP_OFFICE_HOURS_COMPLETE,
        data: {
          ...setupResult
        }
      });

      const officeHoursId = upcomingLmsSelectors.get(getState()).officeHoursId;
      push('/video-meeting/' + officeHoursId + (isTest() ? '?test=true' : ''));
    } catch (e) {
      handleError(e);
    }
  };
}

const GET_PROJECT_INFO = gql`
  query getProjectInfo($project: ProjectInfoInput) {
    projectInfo(project: $project) {
      id
      name
      pdfUrl
      screenplayText
      type
      uploadDate
      uploader
      uploaderIsMentor
      videoBaseUrl
      videoDashUrl
      videoHlsUrl
      videoSpriteUrl
      videoThumbnailUrl
      videoVttUrl
    }
  }
`;

function selectProject(project, isReplay) {
  return (dispatch, getState, { apolloClient }) => {
    apolloClient
      .query({
        query: GET_PROJECT_INFO,
        variables: {
          project: {
            id: project.id,
            assignmentId: project.assignmentId,
            responseType: project.responseType,
            fileType: project.fileType
          }
        }
      })
      .then(response => {
        console.log('selectProject', project, response);
        const projectInfo = response.data.projectInfo || {};
        if (projectInfo.screenplayText) {
          dispatch(
            screenplayViewActions.screenplayLoaded(projectInfo.screenplayText)
          );
        }
        dispatch({
          type: types.SELECT_PROJECT,
          isReplay: isReplay || false,
          project,
          projectInfo: {
            ...projectInfo,
            isScreenplay: !!projectInfo.screenplayText,
            isPdf: !!projectInfo.pdfUrl,
            isVideo: !!projectInfo.videoBaseUrl
          }
        });
      });
  };
}

function gotoUpcomingLms(replace) {
  return dispatch => {
    return replace(
      `/dashboard/${DASHBOARD_TAB_PATH.UPCOMING_LMS}${
        isTest() ? '?test=true' : ''
      }`
    );
  };
}

export const actions = {
  gotoUpcomingLms,
  loadOfficeHours,
  selectProject,
  setupOfficeHours
};

function getOfficeHours(state) {
  return state.officeHours;
}

export const selectors = {
  getOfficeHours
};
