import { gql } from '@apollo/client';

export function makeUserInstructor(apolloClient) {
  const mutation = gql`
    mutation makeUserInstructor($input: MakeUserInstructorInput!) {
      makeUserInstructor(input: $input)
    }
  `;

  return (userProgramId, isInstructor) => {
    return apolloClient.mutate({
      mutation,
      variables: {
        input: {
          enrolleeId: userProgramId,
          isInstructor
        }
      },
      refetchQueries: ['getAllUserPrograms']
    });
  };
}
