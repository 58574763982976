export const getFileType = (fileName, allowAny) => {
  const ending = ((fileName && fileName.split('.').pop()) || '').toLowerCase();
  switch (ending) {
    case 'pdf':
      return 'PDF';
    case 'avi':
    case 'mov':
    case 'mp4':
    case 'mpeg':
    case 'mpg':
      return 'VIDEO';
    case 'fountain':
      return 'SCREENPLAY';
    case 'jpg':
    case 'jpeg':
    case 'png':
      return 'IMAGE';
    case 'glb':
    case 'gltf':
      return 'GLB';
    default:
      if (allowAny) {
        return ending.toUpperCase();
      }
      console.error('Unexpected file type: ', ending);
      return '';
  }
};
