import { actionType } from '../constants';
import {
  getAddingPin,
  getComment,
  getCommentSection,
  getPin
} from '../selectors';
import { receiveEntity } from './receiveEntity';

export const submitEditedComment = (id, text) => {
  return (dispatch, getState, { api }) => {
    let state = getState();
    let commentSectionId = getCommentSection(state).id;
    let item = { ...getComment(state, id) };
    if (getAddingPin(state)) {
      item.pin = getPin(state);
    } else {
      delete item.pin;
    }
    dispatch({
      type: actionType.submitEditedComment,
      item,
      text
    });
    api.updateTextComment(commentSectionId, item, text);

    let newComment = {
      ...item,
      msg: text
    };
    dispatch(receiveEntity('comments')(id, newComment));
  };
};
