import { getCurrentUser } from './getCurrentUser';
import { getFullUserProgram } from './getFullUserProgram';
import { isEqual } from 'lodash';

const defaultReturnValue = undefined;

const cache = {};

export function getCurrentUserPrograms(state) {
  let user = getCurrentUser(state);
  if (!user) {
    return defaultReturnValue;
  }

  if (!user.programs) {
    // console.warn('Unexpected value for user.programs', user);
    return defaultReturnValue;
  }
  let userProgramIds = Object.keys(user.programs).sort();
  let userPrograms = userProgramIds
    .map(id => getFullUserProgram(state, id))
    .filter(p => p);
  if (userPrograms.length !== userProgramIds.length) {
    // console.log('Not all userPrograms found', userProgramIds, userPrograms);
    return defaultReturnValue;
  }

  if (isEqual(cache.lastArgs, userPrograms)) {
    return cache.result;
  }
  cache.lastArgs = userPrograms;
  cache.result = userPrograms;

  return userPrograms;
}
