export const canViewFile = ({ fileType }) =>
  [
    'FOUNTAIN',
    'SCREENPLAY',
    'VIDEO',
    'IMAGE',
    'MULTI_IMAGE',
    'PDF',
    'GLB',
    'GLTF',
    'NONSUPPORTED'
  ].indexOf(fileType) >= 0;
