import { gql } from '@apollo/client';
import { logAndSendError } from '../../utils/sentryHelper';
import { omit } from 'lodash';

const storageService = (config, apolloClient) => ({
  deleteBlob: async key => {
    console.log('deleting', key);
    return apolloClient.mutate({
      mutation: gql`
        mutation deleteFile($key: String) {
          deleteFile(path: $key)
        }
      `,
      variables: {
        key
      }
    });
  },
  uploadBlob: async (bucket, blob, onProgress) => {
    try {
      const result = await apolloClient.query({
        query: gql`
          query getS3UploadUrl($fileName: String) {
            s3UploadUrl(fileName: $fileName)
          }
        `,
        variables: {
          fileName: bucket
        }
      });

      const uploadUrl = result.data.s3UploadUrl;
      console.log('upload url: ', uploadUrl);

      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const uploadTask = {
          state: 'running',
          cancel() {
            console.log('cancel', this.state);
            this.state = 'canceled';
            xhr.abort();
          }
        };
        xhr.open('PUT', uploadUrl);
        xhr.setRequestHeader('content-disposition', 'attachment');

        xhr.upload.onprogress = evt =>
          onProgress(evt.loaded, evt.total, uploadTask);

        xhr.onreadystatechange = () => {
          if (uploadTask.state === 'canceled') {
            reject(new UploadCanceledError('Upload canceled', 'CANCELED'));
          } else if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              resolve(uploadUrl.split('?')[0]);
            } else {
              logAndSendError(xhr, 'unexpected status:' + xhr.status);
              reject({ message: 'unexpected status:' + xhr.status });
            }
          }
        };
        xhr.send(blob);
      });
    } catch (e) {
      logAndSendError(e, 'error uploading file');
      throw e;
    }
  },
  async uploadLargeBlob(targetPath, blob, onProgress) {
    const AWS_MIN_PART_SIZE = 5 * 1024 * 1024;
    const AWS_MIN_PART_NUMBER = 1;
    const AWS_MAX_PART_NUMBER = 10000;

    const MAX_RETRIES = 5;
    const DEFAULT_PART_SIZE = 1 * 1024 * 1024 * 1024;

    const upload = createUpload(blob, targetPath, onProgress);
    upload.mpu = omit(await createMultiPartUpload(upload), '__typename');
    return Promise.all(
      upload.parts.map(async p => {
        while (
          p.retries < MAX_RETRIES &&
          upload.uploadTask.state === 'running'
        ) {
          try {
            await new Promise(resolve =>
              setTimeout(resolve, 10000 * p.retries)
            );
            const url = await getPartUploadUrl(upload, p);
            await doUpload(upload, p, url);
            return;
          } catch (err) {
            console.error('Error uploading part', err, p);
            p.retries++;
          }
        }
        if (upload.uploadTask.state === 'canceled') {
          throw new UploadCanceledError('Upload canceled', 'CANCELED');
        }
        upload.uploadTask.state = 'error';
        throw new Error('Error uploading');
      })
    )
      .then(() => completeMultiPartUpload(upload))
      .catch(err => {
        abortMultiPartUpload(upload);
        throw err;
      });

    // ----------------------------------------

    function createUpload(blob, targetPath, onProgress) {
      const partSize = calcPartSize(blob);
      const numberOfParts = Math.ceil(blob.size / partSize);
      const targetPathFolder = targetPath
        .split('/')
        .slice(0, -1)
        .join('/');
      const uploadTask = {
        state: 'running',
        cancel() {
          console.log('cancel', this.state);
          this.state = 'canceled';
        }
      };
      const upload = {
        targetPath,
        blob,
        onProgress,
        bytesLoaded: 0,
        bytesTotal: blob.size,
        partSize,
        numberOfParts,
        parts: createParts(numberOfParts, blob.size, partSize),
        uploadTask
      };
      return upload;
    }

    function doUpload(upload, part, uploadUrl) {
      const start = (part.partNumber - 1) * upload.partSize;
      const blob = upload.blob.slice(start, start + part.size);
      part.bytesLoaded = 0;
      return uploadBlob(uploadUrl, blob, upload.uploadTask, (loaded, total) => {
        part.bytesLoaded = loaded || 0;
        upload.bytesLoaded = upload.parts.reduce(
          (acc, p) => (acc += p.bytesLoaded),
          0
        );
        upload.onProgress(
          upload.bytesLoaded,
          upload.bytesTotal,
          upload.uploadTask
        );
      });
    }

    async function uploadBlob(uploadUrl, blob, uploadTask, onProgress) {
      try {
        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.open('PUT', uploadUrl);

          xhr.upload.onprogress = evt => {
            if (uploadTask.state !== 'running') {
              xhr.abort();
            }
            onProgress(evt.loaded, evt.total);
          };

          xhr.onreadystatechange = () => {
            if (uploadTask.state === 'canceled') {
              reject(new UploadCanceledError('Upload canceled', 'CANCELED'));
            } else if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                resolve(uploadUrl.split('?')[0]);
              } else {
                logAndSendError(xhr, 'unexpected status:' + xhr.status);
                reject({ message: 'unexpected status:' + xhr.status });
              }
            }
          };
          xhr.send(blob);
        });
      } catch (e) {
        logAndSendError(e, 'error uploading file');
        throw e;
      }
    }

    async function createMultiPartUpload(upload) {
      try {
        const response = await apolloClient.query({
          query: gql`
            query createMultiPartUpload($input: MultiPartUploadInput!) {
              mpu: createMultiPartUpload(input: $input) {
                Bucket
                Key
                UploadId
              }
            }
          `,
          variables: {
            input: {
              targetPath: upload.targetPath,
              contentType: upload.blob.type
            }
          }
        });
        console.info('createMultiPartUpload', response);
        return response.data.mpu;
      } catch (error) {
        console.error('createMultiPartUpload', error);
        throw error;
      }
    }

    async function getPartUploadUrl(upload, part) {
      try {
        const response = await apolloClient.query({
          query: gql`
            query getPartUploadUrl($input: MultiPartUploadInput!) {
              url: getPartUploadUrl(input: $input)
            }
          `,
          variables: {
            input: {
              ...upload.mpu,
              PartNumber: part.partNumber
            }
          }
        });
        console.info('getPartUploadUrl', response);
        return response.data.url;
      } catch (error) {
        console.error('getPartUploadUrl', error);
        throw error;
      }
    }

    async function completeMultiPartUpload(upload) {
      try {
        const response = await apolloClient.query({
          query: gql`
            query completeMultiPartUpload($input: MultiPartUploadInput!) {
              url: completeMultiPartUpload(input: $input)
            }
          `,
          variables: {
            input: upload.mpu
          }
        });
        console.info('completeMultiPartUpload', response);
        const url = response.data.url;
        return url;
      } catch (error) {
        console.error('completeMultiPartUpload', error);
        throw error;
      }
    }

    async function abortMultiPartUpload(upload) {
      try {
        const response = await apolloClient.query({
          query: gql`
            query abortMultiPartUpload($input: MultiPartUploadInput!) {
              abortMultiPartUpload(input: $input)
            }
          `,
          variables: {
            input: upload.mpu
          }
        });
        console.info('abortMultiPartUpload', response);
      } catch (error) {
        console.error('abortMultiPartUpload', error);
      }
    }

    function createParts(numberOfParts, totalSize, partSize) {
      const parts = [];
      for (let i = AWS_MIN_PART_NUMBER; i <= numberOfParts; i++) {
        const part = {
          partNumber: i,
          retries: 0,
          size: partSize,
          bytesLoaded: 0
        };
        parts.push(part);
      }
      parts[parts.length - 1].size =
        totalSize % partSize ? totalSize % partSize : partSize;
      return parts;
    }

    function calcPartSize(blob) {
      let partSize = DEFAULT_PART_SIZE;
      if (blob.size / AWS_MAX_PART_NUMBER > partSize) {
        partSize = Math.ceil(blob.size / AWS_MAX_PART_NUMBER);
      }
      return partSize;
    }
  }
});

class UploadCanceledError extends Error {
  code = '';

  constructor(msg, code) {
    super(msg);
    this.code = code;
  }
}

export default storageService;
