import { actionType } from '../constants';

export const loadAnnotation = (projectId, commentId) => {
  return (dispatch, getState, { api }) => {
    dispatch({
      type: actionType.loadAnnotation,
      commentId
    });

    api.loadAnnotation(projectId, commentId).then(annotation => {
      dispatch({
        type: actionType.loadAnnotationComplete,
        annotation
      });
    });
  };
};
