import React from 'react';
import classnames from 'classnames';
import styles from './VideoMarker.scss';
import clickdrag from './clickDrag';
import { VideoMarkersContext } from './VideoMarkersContext';

export class Marker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lastPositionX: 0,
      lastPositionY: 0,
      currentX: 0,
      currentY: 0
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.marker != this.props.marker) {
      this.setState({
        lastPositionX: 0,
        lastPositionY: 0,
        currentX: 0,
        currentY: 0
      });
    } else if (nextProps.dataDrag && nextProps.dataDrag.isMoving) {
      this.setState({
        currentX: this.state.lastPositionX + nextProps.dataDrag.moveDeltaX
      });
      const delta = this.state.currentX - this.state.lastPositionX;
      if (delta) {
        this.props.onDrag && this.props.onDrag(delta);
      }
    } else if (nextProps.dataDrag) {
      const delta = this.state.currentX - this.state.lastPositionX;
      if (delta) {
        this.props.onDragStop && this.props.onDragStop(delta);
      }
      this.setState({
        lastPositionX: this.state.currentX,
        lastPositionY: this.state.currentY
      });
    }
  }

  render() {
    const renderFn = contextValues => {
      const translation =
        'translate(' +
        this.state.currentX +
        'px, ' +
        this.state.currentY +
        'px)';

      const { videoLength, containerWidth } = contextValues;

      const { marker, onClick, onMouseEnter, onMouseLeave } = this.props;

      const side = this.props.side || 'left';
      const left =
        side == 'left'
          ? (marker.time * containerWidth) / videoLength + 3
          : ((marker.time + marker.duration) * containerWidth) / videoLength +
            3;
      return (
        <div
          style={{
            position: 'absolute',
            left: `${left}px`,
            transform: translation
          }}
        >
          <div
            draggable={false}
            onClick={() => onClick && onClick(marker)}
            onMouseEnter={() => onMouseEnter && onMouseEnter(marker)}
            onMouseLeave={() => onMouseLeave && onMouseLeave(marker)}
            className={classnames(
              marker.duration > 0 ? styles.draggableMarker : styles.marker,
              styles[side],
              {
                [styles[marker.markerType]]: true,
                [styles.rangeComment]: marker.duration
              }
            )}
          />
        </div>
      );
    };

    return (
      <VideoMarkersContext.Consumer>
        {values => renderFn(values)}
      </VideoMarkersContext.Consumer>
    );
  }
}

export const DraggableMarker = clickdrag(Marker, {});
