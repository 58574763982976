import { types as userProjectType } from './userProjects';
import { logAndSendError } from 'utils/sentryHelper';

const PREFIX = 'DASHBOARD_CREATE_PROJECT';

export const types = {
  START_UPLOAD: `${PREFIX}/START_UPLOAD`,
  UPLOAD_PROGRESS: `${PREFIX}/UPLOAD_PROGRESS`,
  UPLOAD_FINISHED: `${PREFIX}/UPLOAD_FINISHED`,
  UPLOAD_INTERRUPTED: `${PREFIX}/UPLOAD_INTERRUPTED`,
  CREATE_PROJECT: `${PREFIX}/CREATE_PROJECT`,
  PROJECT_CREATED: `${PREFIX}/PROJECT_CREATED`
};

const initialState = { progress: 0, uploadTask: null };

export const createProjectReducer = (state = initialState, action) => {
  let nextState = state;
  switch (action.type) {
    case types.START_UPLOAD:
      nextState = {
        ...state,
        uploading: true
      };
      break;

    case types.UPLOAD_PROGRESS:
      nextState = {
        ...state,
        progress: action.data.progress,
        uploadTask: action.data.uploadTask
      };
      break;

    case types.UPLOAD_FINISHED:
      nextState = {
        ...state,
        uploading: false,
        videoUrl: action.videoUrl
      };
      break;

    case types.UPLOAD_INTERRUPTED:
      nextState = {
        ...state,
        uploading: false
      };
      break;

    case types.CREATE_PROJECT:
      nextState = {
        ...state,
        creatingProject: true
      };
      break;

    case types.PROJECT_CREATED:
      nextState = {
        ...state,
        progress: 0,
        videoUrl: undefined,
        creatingProject: false,
        projectId: action.projectId
      };
      break;

    case userProjectType.PROJECT_CREATOR_CLOSE:
      nextState = {
        ...state,
        progress: 0,
        videoUrl: undefined,
        creatingProject: false,
        projectId: undefined
      };
      break;
  }

  return nextState;
};

function startUpload(path, file) {
  return (dispatch, getState, { api }) => {
    let onProgress = (uploaded, total, uploadTask) => {
      let progress = (uploaded / total) * 100;
      dispatch({
        type: types.UPLOAD_PROGRESS,
        data: {
          progress,
          uploadTask
        }
      });
    };

    api
      .uploadVideo(path, file, onProgress)
      .then(url => dispatch({ type: types.UPLOAD_FINISHED, videoUrl: url }))
      .catch(e => logAndSendError(e, 'error uploading video'));
    dispatch({ type: types.START_UPLOAD });
  };
}

function createProject(name, description, file, onProjectCreated) {
  return (dispatch, getState, { api }) => {
    const onProgress = (uploaded, total, uploadTask) => {
      const progress = (uploaded / total) * 100;
      const cancelFn = uploadTask.cancel;

      uploadTask.cancel = () => {
        dispatch({ type: types.UPLOAD_INTERRUPTED });

        cancelFn();
      };

      dispatch({
        type: types.UPLOAD_PROGRESS,
        data: {
          progress,
          uploadTask
        }
      });
    };

    const path = `/upload/video/provisional/${file.name}`;

    api
      .uploadVideo(path, file, onProgress)
      .then(url => {
        dispatch({ type: types.UPLOAD_FINISHED, videoUrl: url });

        api
          .createProject(name, description, url)
          .then(project => {
            let projectId = project && project.id;
            onProjectCreated && onProjectCreated(projectId);
            dispatch({ type: types.PROJECT_CREATED, projectId });

            api.startEncoding(projectId);
          })
          .catch(err => logAndSendError(err, 'create project error'));
        dispatch({ type: types.CREATE_PROJECT });
      })
      .catch(e => logAndSendError(e, 'error uploading video'));

    dispatch({ type: types.START_UPLOAD });
  };
}

export const actions = {
  startUpload,
  createProject
};
