import { gql } from '@apollo/client';

export const setIsHidden = apolloClient => async (userId, isHidden) => {
  const mutation = gql`
    mutation setIsHidden($input: SetIsHiddenInput!) {
      setIsHidden(input: $input) {
        id
        isHidden
      }
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        userId,
        isHidden
      }
    }
  });
};
