import { gql } from '@apollo/client';

export function toggleProgramHasEnded(apolloClient) {
  const mutation = gql`
    mutation toggleProgramHasEnded($programInstanceId: ID!) {
      toggleProgramHasEnded(programInstanceId: $programInstanceId)
    }
  `;

  return async programInstanceId => {
    console.log('Toggle Prog', programInstanceId);
    await apolloClient.mutate({
      mutation,
      variables: {
        programInstanceId
      },
      refetchQueries: ['getAdminData']
    });
  };
}
