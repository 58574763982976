import { actionType } from '../constants';

const messagesReducer = (state = [], action) => {
  let nextState = state;

  switch (action.type) {
    case actionType.messageReceived:
      // console.log('messageReceived', action);
      nextState = state.slice();
      nextState.unshift(action.payload);
      break;
  }

  return nextState;
};

export default messagesReducer;
