import { gql } from '@apollo/client';

export const updateUserProgramDetails = apolloClient => (
  userId,
  userProgramId,
  applicantDetails
) => {
  const mutation = gql`
    mutation updateUserProgramDetails($input: UpdateUserProgramDetailsInput!) {
      enrollee: updateUserProgramDetails(input: $input) {
        id
        applicantDetails {
          audio
          camera
          other
          software
          whyFilmmaker
          workUrls
          howDidYouHearAbout
        }
      }
    }
  `;
  return apolloClient
    .mutate({
      mutation,
      variables: {
        input: {
          userProgramId,
          ...applicantDetails
        }
      }
    })
    .then(response => response.data.enrollee);
};
