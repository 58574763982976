export class KeyboardHandlerManager {
  constructor() {
    this.handlerStack = [];
    if (window && window.document) {
      window.document.addEventListener('keyup', this.handleKeyboardEvent);
    }
  }

  addKeyboardHandler(handlerFn) {
    let index = this.handlerStack.indexOf(handlerFn);
    if (index === -1) {
      this.handlerStack.push(handlerFn);
    }
  }

  removeKeyboardHandler(handlerFn) {
    let index = this.handlerStack.indexOf(handlerFn);
    if (index >= 0) {
      while (this.handlerStack.length > index) {
        this.handlerStack.pop();
      }
    }
  }

  handleKeyboardEvent = evt => {
    // console.log('handleKeyboardEvent', evt)
    for (let index = this.handlerStack.length - 1; index >= 0; index--) {
      let handlerFn = this.handlerStack[index];
      let keepGoing = handlerFn(evt);
      if (!keepGoing) {
        return;
      }
    }
  };
}

const keyboardHandlerManager = new KeyboardHandlerManager();

export default keyboardHandlerManager;
