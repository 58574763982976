import { actionType } from '../constants';
import { logAndSendError } from 'utils/sentryHelper';

export const updateUserProgramDetails = (
  userId,
  userProgramId,
  applicantDetails
) => {
  return async (dispatch, getState, { api }) => {
    dispatch({
      type: actionType.UPDATE_USER_PROGRAM_DETAILS,
      userId,
      userProgramId,
      applicantDetails
    });

    try {
      const updateUserProgramDetailsPromise = api.updateUserProgramDetails(
        userId,
        userProgramId,
        applicantDetails
      );

      const updateUserPromise = applicantDetails.phone
        ? api.user.updateUser(userId, {
            smsPhoneNumber: applicantDetails.phone
          })
        : Promise.resolve();

      await Promise.all([updateUserProgramDetailsPromise, updateUserPromise]);

      dispatch({
        type: actionType.UPDATE_USER_PROGRAM_DETAILS_SUCCESS
      });
    } catch (error) {
      logAndSendError(error, 'error applying');
    }
  };
};
