import { gql } from '@apollo/client';

export const addNonSupportedFile = apolloClient => async (
  item,
  description,
  uploadRecord
) => {
  console.log('addNonSupportedFile', item, description, uploadRecord);

  const asmt = item.assignment;

  const mutation = gql`
    mutation addNonSupportedFileProject($input: AddNonSupportedFileInput!) {
      addNonSupportedFileProject(input: $input) {
        nonSupportedFileProject {
          id
          fileSize
          downloadUrl
          assignmentId
          lessonId
          courseId
          userProgramId
          responseType
        }
        lessonRecord {
          id
          userProgramId
          lessonId
          courseId
          assignmentId
          created
          status
          assignmentResponse {
            type
            projectId
          }
        }
      }
    }
  `;

  return apolloClient
    .mutate({
      mutation,
      variables: {
        input: {
          userProgramId: asmt.userProgramId,
          courseId: asmt.courseId,
          lessonId: asmt.lessonId,
          assignmentId: asmt.id,
          title: asmt.title,
          description,
          uploadRecord: {
            id: uploadRecord.id,
            ownerId: uploadRecord.ownerId,
            downloadUrl: uploadRecord.downloadUrl,
            fileName: uploadRecord.fileName,
            fileSize: uploadRecord.fileSize,
            status: uploadRecord.status
          }
        }
      },
      refetchQueries: ['lessonUpdate']
    })
    .then(response => response.data.addNonSupportedFileProject);
};
