import {
  all,
  call,
  put,
  select,
  takeEvery,
  throttle
} from 'redux-saga/effects';
import {
  get,
  isEmpty,
  isUndefined,
  omit,
  union,
  defaults,
  includes,
  omitBy,
  isNil
} from 'lodash';
import { types, selectors } from '../reducers/videoConference';
import { types as screenplayViewTypes } from 'reducers/screenplayView';
import { actionType as sclActionType } from 'smashcut-client-lib/constants';
import * as sclSelectors from 'smashcut-client-lib/selectors';

import { gql } from '@apollo/client';
import { canViewFile } from '../utils/canViewFile';

export function* createConference(
  apolloClient,
  {
    hostId,
    bookerId,
    name,
    startDate,
    participants,
    conferenceType,
    duration,
    recurringDays,
    recurringEndDate
  }
) {
  try {
    console.log('[videoconf saga] [ createConference ]', {
      hostId,
      bookerId,
      name,
      startDate,
      participants,
      recurringDays,
      recurringEndDate
    });
    const state = yield select();
    const userId = sclSelectors.getCurrentUser(state).id;
    const result = yield call(apolloClient.mutate, {
      mutation: mutations.CREATE_CONFERENCE,
      variables: {
        input: {
          hostId,
          bookerId,
          name,
          startDate,
          participantIds: participants,
          type: conferenceType,
          duration,
          recurringDays,
          recurringEndDate,
          settings: {
            participantsCanPresent: true,
            participantsCanShareScreen: true,
            participantsCanShareCam: true
          }
        }
      },
      optimisticResponse: {
        createVideoConference: 'ok'
      },
      refetchQueries: ['listConferences'],
      update: store => {
        const data = store.readQuery({
          query: queries.LIST_CONFERENCES,
          variables: {
            hostId: userId
          }
        });

        const newData = {
          ...data,
          videoConferencesForHost: [
            ...data.videoConferencesForHost,
            {
              id: '??' + new Date().getTime(),
              participantIds: [],
              name,
              created: new Date().getTime(),
              startDate: new Date().getTime(),
              __typename: 'VideoConference'
            }
          ]
        };

        store.writeQuery({
          query: queries.LIST_CONFERENCES,
          variables: {
            hostId: userId
          },
          data: newData
        });
      }
    });
    yield put({
      type: types.CREATE_CONFERENCE_SUCCESS,
      conferenceId: result.data.createVideoConference
    });
  } catch (e) {
    console.error('[videoconf saga] [ createConference ] error: ', e);
    yield put({ type: types.CREATE_CONFERENCE_FAILURE, error: e });
  }
}

export function* updateConference(
  apolloClient,
  { conferenceId, conference, noRefetch }
) {
  try {
    const state = yield select();
    const userId = sclSelectors.getCurrentUser(state).id;
    const result = yield call(apolloClient.mutate, {
      mutation: mutations.UPDATE_VIDEO_CONFERENCE,
      variables: {
        id: conferenceId,
        input: conference
      },
      optimisticResponse: noRefetch
        ? undefined
        : {
            updateVideoConference: {
              id: conferenceId
            }
          },
      refetchQueries: noRefetch ? [] : ['listConferences', 'getAllBookings'],
      update: store => {
        if (
          !(
            store.data.data.ROOT_QUERY &&
            store.data.data.ROOT_QUERY[
              `videoConferencesForHost({"hostId":"${userId}"})`
            ]
          )
        ) {
          return;
        }
        const data = store.readQuery({
          query: queries.LIST_CONFERENCES,
          variables: {
            hostId: userId
          }
        });

        const newData = {
          ...data,
          videoConferencesForHost: data.videoConferencesForHost.map(conf => {
            if (conf.id === conferenceId) {
              return { ...conf, ...conference };
            }
            return conf;
          })
        };

        store.writeQuery({
          query: queries.LIST_CONFERENCES,
          variables: {
            hostId: userId
          },
          data: newData
        });
      }
    });

    yield put({
      type: types.UPDATE_CONFERENCE_SUCCESS,
      conference: result.data.updateVideoConference
    });
  } catch (e) {
    console.error('[videoconf saga] [ createConference ] error: ', e);
    yield put({ type: types.UPDATE_CONFERENCE_FAILURE, error: e });
  }
}

export function* deleteConference(apolloClient, { conferenceId }) {
  try {
    const state = yield select();
    const userId = sclSelectors.getCurrentUser(state).id;
    yield call(apolloClient.mutate, {
      mutation: mutations.DELETE_VIDEO_CONFERENCE,
      variables: {
        id: conferenceId
      },
      optimisticResponse: {
        deleteVideoConference: 'ok'
      },
      refetchQueries: ['listConferences', 'adminConferences'],
      update: store => {
        try {
          const data = store.readQuery({
            query: queries.LIST_CONFERENCES,
            variables: {
              hostId: userId
            }
          });
          const newData = {
            ...data,
            videoConferencesForHost: data.videoConferencesForHost.filter(
              conf => conf.id !== conferenceId
            )
          };

          store.writeQuery({
            query: queries.LIST_CONFERENCES,
            variables: {
              hostId: userId
            },
            data: newData
          });
        } catch (e) {
          console.log(
            '[videoconf saga] [ deleteConference ] cant update cache'
          );
        }
      }
    });
    yield put({
      type: types.DELETE_CONFERENCE_SUCCESS
    });
  } catch (e) {
    console.error('[videoconf saga] [ deleteConference ] error: ', e);
    yield put({ type: types.DELETE_CONFERENCE_FAILURE, error: e });
  }
}

export function* initializeConference(
  apolloClient,
  { conferenceId, userId, opentokSessionId, opentokToken }
) {
  try {
    //    console.log(
    //      '[videoconf saga] [ initializeConference ]',
    //      conferenceId,
    //      userId,
    //      opentokSessionId,
    //      opentokToken
    //    );
    let variables = { id: conferenceId, userId };
    if (opentokSessionId && opentokToken) {
      variables = { ...variables, opentokSessionId, opentokToken };
    }
    const result = yield call(apolloClient.mutate, {
      mutation: mutations.INITIALIZE_CONFERENCE,
      variables
    });
    console.log(
      '[videoconf saga] Loaded conference: ',
      result.data.initializeVideoConference
    );
    if (isEmpty(result.data.initializeVideoConference)) {
      yield put({
        type: types.INITIALIZE_CONFERENCE_FAILURE,
        error: 'Conference not found'
      });
    } else {
      yield put({
        type: types.INITIALIZE_CONFERENCE_SUCCESS,
        conference: result.data.initializeVideoConference
      });

      yield select();
      if (!isEmpty(result.data.initializeVideoConference.selectedMedia)) {
        yield put({
          type: types.SELECT_MEDIA,
          media: result.data.initializeVideoConference.selectedMedia,
          isInitial: true
        });
      }
    }
  } catch (e) {
    console.error('[videoconf saga] [ initializeConference ] error: ', e);
    yield put({ type: types.INITIALIZE_CONFERENCE_FAILURE, error: e });
  }
}

export function* updateSettings(
  apolloClient,
  { conferenceId, settings, dontPersist }
) {
  try {
    console.log(
      '[videoconf saga] [ updateSettings ] ',
      conferenceId,
      settings,
      dontPersist
    );

    if (dontPersist) {
      return;
    }
    const state = yield select();
    const conference = state.videoConference.conference;
    const omitTypename = settings => omit(settings, '__typename');
    const newSettings = defaults(
      omitTypename(settings),
      omitTypename(conference.settings)
    );
    console.log('new settings are', newSettings);
    yield call(apolloClient.mutate, {
      mutation: mutations.UPDATE_VIDEO_CONFERENCE,
      variables: {
        id: conferenceId,
        input: {
          settings: newSettings
        }
      }
    });
  } catch (e) {
    console.error('[videoconf saga] [ updateSettings ] error: ', e);
  }
}

export function* loadUser(apolloClient, { userId }) {
  try {
    // console.log('[videoconf saga] [ loadUser ]', userId);

    const result = yield call(apolloClient.query, {
      query: queries.GET_USER,
      variables: {
        userId
      }
    });
    let user = result.data.user;

    const blob = yield call(fetchAvatar, user);
    user = yield call(updateUserAvatar, user, blob);
    // console.log('[videoconf saga] [ loadUser ]', user);

    yield put({
      type: types.LOAD_USER_SUCCESS,
      user
    });
  } catch (e) {
    console.error('nok', e);
    yield put({
      type: types.LOAD_USER_FAILURE,
      error: 'An error occurred while loading user'
    });
  }

  // ------------------------------

  function fetchAvatar(user) {
    return fetch(user.avatar)
      .then(function(response) {
        if (response.ok) {
          return response.blob();
        }
        throw new Error('Network response was not ok.');
      })
      .catch(function(error) {
        console.error(
          'There has been a problem with your fetch operation: ',
          error.message
        );
      });
  }

  function updateUserAvatar(user, blob) {
    if (!blob) return user;

    const cache = apolloClient.cache;
    const objectUrl = URL.createObjectURL(blob);
    cache.modify({
      id: cache.identify(user),
      fields: {
        avatar() {
          return objectUrl;
        }
      }
      /* broadcast: false // Include this to prevent automatic query refresh */
    });

    const response = cache.readQuery({
      query: queries.GET_USER,
      variables: {
        userId
      }
    });
    return response.user;
  }
}

export function* getBrowsableMedia(apolloClient, api, { userId }) {
  try {
    // console.log('[videoconf saga] [ getBrowsableMedia ]', userId);

    const result = yield call(apolloClient.query, {
      query: queries.LOAD_BROWSABLE_MEDIA,
      variables: {
        userIds: [userId]
      },
      fetchPolicy: 'network-only'
    });
    // console.log('[videoconf saga] [ getBrowsableMedia ]', result);

    const browsableMedia = result.data.shareableFilesForUsers.filter(
      f => canViewFile(f) || f.folder
    );
    // console.log('[videoconf saga] [ getBrowsableMedia ]', browsableMedia);

    yield call(resolveLessonPdfs, browsableMedia);
    yield put({
      type: types.LOAD_BROWSABLE_MEDIA_SUCCESS,
      userId,
      browsableMedia
    });
  } catch (e) {
    console.warn('nok', e);
    yield put({
      type: types.LOAD_BROWSABLE_MEDIA_FAILURE,
      error: 'An error occurred while loading user media'
    });
  }

  // -----------------------------------

  async function resolveLessonPdfs(browsableMedia) {
    return Promise.all(
      browsableMedia
        .filter(
          bm =>
            bm.uploadType === 'LESSON_PDF' &&
            bm.downloadUrl.indexOf('http') !== 0
        )
        .map(async bm => ({
          ...bm,
          downloadUrl: await api.getContentBucketDownloadUrl({
            name: bm.downloadUrl
          })
        }))
    );
  }
}

export function* selectMedia(
  apolloClient,
  { media, initiatorUserId, isInitial }
) {
  console.log('[videoconf saga] [ selectMedia ]', {
    media,
    initiatorUserId,
    isInitial
  });

  const state = yield select();
  const conference = state.videoConference.conference;
  const conferenceId = conference.id;
  const hostId = conference.hostId;
  const userId = sclSelectors.getCurrentUserId(state);

  if (initiatorUserId === userId && !isInitial) {
    console.log(
      '[videoconf saga] [ selectMedia ] saving selected media',
      media
    );

    const selectedMedia = media ? omit(media, '__typename', 'owner') : null;
    if (selectedMedia && selectedMedia.images) {
      selectedMedia.images = selectedMedia.images.map(i =>
        omit(i, '__typename')
      );
    }
    if (media && media.isPreloaded) {
      const GET_INLINE_URL = gql`
        query getInlineUrl($url: String) {
          getInlineDispositionUrl(url: $url)
        }
      `;
      const response = yield call(apolloClient.query, {
        query: GET_INLINE_URL,
        variables: { url: media.baseUrl },
        fetchPolicy: 'network-only'
      });

      if (response.error) {
        console.error('Error signing pdf url', response.error);
      } else {
        media.downloadUrl = response.data.getInlineDispositionUrl;
      }
    }
    yield call(apolloClient.mutate, {
      mutation: mutations.UPDATE_VIDEO_CONFERENCE,
      variables: {
        id: conferenceId,
        input: {
          selectedMedia,
          currentPosition: 0,
          isPlaying: false,
          presentingUserId: media ? initiatorUserId : null
        }
      }
    });
  }

  if (!isInitial && media) {
    yield put({
      type: types.SHOW_NOTIFICATION,
      notification: {
        topLabel: hostId ? 'Host opened media' : 'Media opened',
        bottomLabel: media.title,
        icon: media.fileType === 'FOUNTAIN' ? 'fa-book-open' : 'fa-play-circle'
      }
    });
  }

  if (media && media.fileType === 'FOUNTAIN') {
    console.log(
      '[videoconf saga] [ selectMedia ] loading screenplay text ',
      media.id
    );
    let screenplayText;

    if (media.uploadType === 'MY_FILE') {
      const result = yield call(apolloClient.query, {
        query: queries.LOAD_MYFILE,
        variables: {
          id: media.id
        }
      });
      screenplayText = result.data.myFile.screenplayText;
    } else {
      const result = yield call(apolloClient.query, {
        query: queries.LOAD_SCREENPLAY,
        variables: {
          id: media.id
        }
      });
      screenplayText = get(result, 'data.screenplay.text', '');
    }
    yield put({
      type: screenplayViewTypes.SCREENPLAY_LOADED,
      screenplay: screenplayText
    });
  }
}

export function* selectImage(apolloClient, { imageIndex }) {
  console.log('[videoconf saga] [ selectImage ]', {
    imageIndex
  });

  try {
    const state = yield select();
    const conference = state.videoConference.conference;
    const conferenceId = conference.id;
    let input = {
      selectedImageIndex: imageIndex
    };

    console.log('updating', input);

    yield call(apolloClient.mutate, {
      mutation: mutations.UPDATE_VIDEO_CONFERENCE,
      variables: {
        id: conferenceId,
        input
      }
    });
  } catch (e) {
    console.warn('error saving image index', e);
  }
}

export function* savePosition(apolloClient, action) {
  try {
    if (action.isReplay) {
      // Don't trigger save for replay actions
      return;
    }

    const state = yield select();
    const hostId = get(state, 'videoConference.conference.host.id');
    const userId = sclSelectors.getCurrentUserId(state);
    const conferenceId = get(state, 'videoConference.conference.id');
    const hostHasJoined = selectors.getHostHasJoined(state);

    if (!conferenceId) {
      return;
    }

    if (userId && hostId && hostHasJoined && hostId !== userId) {
      // console.log('not saving position for non host');
      return;
    }

    if (userId && !hostHasJoined) {
      const coHostIds = get(state, 'videoConference.conference.coHostIds');
      if (!includes(coHostIds, userId)) {
        return;
      }
    }

    let position, isPlaying;
    if (!isUndefined(action.value)) {
      position = action.value;
    } else if (!isUndefined(action.position)) {
      position = action.position;
    } else if (!isUndefined(action.time)) {
      position = action.time;
    } else if (!isUndefined(action.index)) {
      position = action.index;
    } else if (!isUndefined(action.page)) {
      position = action.page;
    }

    if (action.type === sclActionType.playerIsPlaying) {
      isPlaying = true;
    }
    if (action.type === sclActionType.playerIsPaused) {
      isPlaying = false;
    }

    // console.log(
    //   '[videoconf saga] [ savePosition ]',
    //   position,
    //   ' isPlaying=',
    //   isPlaying
    // );

    let input = {
      currentPosition: position
    };
    if (!isUndefined(isPlaying)) {
      input.isPlaying = isPlaying;
    }
    yield call(apolloClient.mutate, {
      mutation: mutations.UPDATE_VIDEO_CONFERENCE,
      variables: {
        id: conferenceId,
        input
      }
    });
  } catch (e) {
    console.warn('error saving media position', e);
  }
}

export function* addOrRemoveCoHost(apolloClient, action) {
  try {
    const state = yield select();
    sclSelectors.getCurrentUserId(state);
    const conferenceId = get(state, 'videoConference.conference.id');
    const conference = get(state, 'videoConference.conference');
    const presentingUserId = selectors.getPresentingUserId(state);
    let coHosts = conference.coHostIds || [];
    let raisedHands = conference.raisedHands || [];

    if (action.isAdding) {
      //TODO: Post MVP: coHosts = union(coHosts, [action.userId]);
      coHosts = [action.userId];
      raisedHands = raisedHands.filter(id => id !== action.userId);
    } else {
      coHosts = coHosts.filter(id => id !== action.userId);

      if (presentingUserId === action.userId) {
        // Co-host was presenting media
        console.log('Co-host session finished, stopping presentation');
        yield put({
          type: types.SELECT_MEDIA,
          media: undefined
        });
      }
    }

    let input = {
      coHostIds: coHosts,
      raisedHands
    };

    if (!action.isAdding && presentingUserId === action.userId) {
      input.selectedMedia = null;
      input.presentingUserId = null;
    }

    console.log('updating', input);

    yield call(apolloClient.mutate, {
      mutation: mutations.UPDATE_VIDEO_CONFERENCE,
      variables: {
        id: conferenceId,
        input
      }
    });
  } catch (e) {
    console.warn('error updating co hosts', e);
  }
}

export function* raiseOrLowerHand(apolloClient, action) {
  try {
    const state = yield select();
    const conferenceId = get(state, 'videoConference.conference.id');
    const conference = get(state, 'videoConference.conference');
    const users = get(state, 'videoConference.users');
    const fullName = get(users[action.userId], 'fullName');
    const avatar = get(users[action.userId], 'avatar');
    const hostId = get(state, 'videoConference.conference.host.id');
    const userId = sclSelectors.getCurrentUserId(state);
    const isHost = hostId === userId;
    let raisedHands = conference.raisedHands || [];

    if (action.type === types.RAISE_HAND) {
      raisedHands = union(raisedHands, [action.userId]);
    } else {
      raisedHands = raisedHands.filter(id => id !== action.userId);
    }

    if (isHost) {
      yield put({
        type: types.SHOW_NOTIFICATION,
        notification: {
          topLabel:
            action.type === types.RAISE_HAND
              ? `${fullName} raised their hand`
              : `${fullName} lowered their hand`,
          icon:
            action.type === types.RAISE_HAND
              ? 'fas fa-hand-paper'
              : 'far fa-hand-paper',
          avatar
        }
      });
    }

    let input = {
      raisedHands
    };

    console.log('updating', input);

    yield call(apolloClient.mutate, {
      mutation: mutations.UPDATE_VIDEO_CONFERENCE,
      variables: {
        id: conferenceId,
        input
      }
    });
  } catch (e) {
    console.warn('error updating raised hands', e);
  }
}

export function* clearRaisedHands(apolloClient) {
  console.log('[Video Conf Saga] Clearing raised hands');
  try {
    const state = yield select();
    const conferenceId = get(state, 'videoConference.conference.id');
    const conference = get(state, 'videoConference.conference');
    const userId = sclSelectors.getCurrentUserId(state);
    let raisedHands = conference.lastRaisedHands || [];

    if (includes(raisedHands, userId)) {
      yield put({
        type: types.SHOW_NOTIFICATION,
        notification: {
          topLabel: 'Host cleared raised hands',
          icon: 'fa-hand-paper'
        }
      });
    }

    let input = {
      raisedHands: []
    };

    yield call(apolloClient.mutate, {
      mutation: mutations.UPDATE_VIDEO_CONFERENCE,
      variables: {
        id: conferenceId,
        input
      }
    });
  } catch (e) {
    console.warn('error updating raised hands', e);
  }
}

export function* changeHandIcon(apolloClient, mainApi, action) {
  const { handIconIndex, userId } = action.userId;

  yield call(mainApi.user.updateSettings, userId, { handIconIndex });
  yield put({
    type: sclActionType.UPDATE_ENTITY_USER,
    id: userId,
    payload: { settings: { handIconIndex } }
  });
}

export function* seekVideoIfNeeded() {
  const state = yield select();
  const conference = get(state, 'videoConference.conference');
  const selectedMedia = get(conference, 'selectedMedia');

  if (
    selectedMedia &&
    selectedMedia.fileType === 'VIDEO' &&
    conference.currentPosition > 0
  ) {
    console.log(
      'Seeking video to ',
      conference.currentPosition,
      ' isPlaying=',
      conference.isPlaying
    );
    yield put({
      type: conference.isPlaying
        ? sclActionType.playerIsPlaying
        : sclActionType.playerIsPaused,
      time: conference.isPlaying
        ? conference.currentPosition + 8
        : conference.currentPosition,
      isReplay: true
    });
  }
}

export function* handleHostLeft(apolloClient) {
  const state = yield select();
  const conference = get(state, 'videoConference.conference');
  const selectedMedia = get(conference, 'selectedMedia');

  if (
    selectedMedia &&
    selectedMedia.fileType === 'VIDEO' &&
    conference.currentPosition > 0
  ) {
    console.log(
      'Pausing video when host leaves',
      conference.currentPosition,
      ' isPlaying=',
      conference.isPlaying
    );
    yield put({
      type: sclActionType.playerIsPaused,
      isReplay: true
    });
    yield call(apolloClient.mutate, {
      mutation: mutations.UPDATE_VIDEO_CONFERENCE,
      variables: {
        id: conference.id,
        input: {
          isPlaying: false
        }
      }
    });
  }
}

export function* scrollScreenplayIfNeeded() {
  const state = yield select();
  const conference = get(state, 'videoConference.conference');
  const selectedMedia = get(conference, 'selectedMedia');

  if (selectedMedia && selectedMedia.fileType === 'FOUNTAIN') {
    console.log(
      '[videoconf saga] initial scrolling screenplay to ',
      conference.currentPosition
    );
    yield put({
      type: screenplayViewTypes.SELECT_CHUNK,
      index: conference.currentPosition,
      isReplay: true
    });
  }
}

export function* scrollScreenplayAfterSelect() {
  const state = yield select();
  const conference = get(state, 'videoConference.conference');
  const selectedMedia = get(conference, 'selectedMedia');

  if (selectedMedia && selectedMedia.fileType === 'FOUNTAIN') {
    yield put({
      type: screenplayViewTypes.SELECT_CHUNK,
      index: null,
      isReplay: true
    });

    yield put({
      type: screenplayViewTypes.SCROLL_TO_CHUNK,
      scrollToChunkIndex: 0,
      isReplay: true
    });
  }
}

export function* saveChatMessage(
  apolloClient,
  { senderId, message, timestamp }
) {
  try {
    const state = yield select();
    const conference = get(state, 'videoConference.conference');
    console.log(
      'saving chat message',
      conference.id,
      senderId,
      message,
      timestamp
    );
    yield call(apolloClient.mutate, {
      mutation: mutations.SAVE_CHAT_MESSAGE,
      variables: {
        conferenceId: conference.id,
        senderId,
        message,
        timestamp
      }
    });
  } catch (e) {
    console.warn('error saving chat messagee', e);
  }
}

export function* startArchiving(
  apolloClient,
  { tabStreamId, participantStreamIds }
) {
  try {
    const state = yield select();
    const conference = get(state, 'videoConference.conference');
    console.log('starting archiving', {
      conferenceId: conference.id,
      tabStreamId,
      participantStreamIds
    });
    yield call(apolloClient.mutate, {
      mutation: mutations.START_ARCHIVING,
      variables: {
        input: {
          conferenceId: conference.id,
          tabStreamId,
          participantStreamIds
        }
      }
    });

    yield put({
      type: types.START_ARCHIVING_SUCCESS
    });
  } catch (e) {
    yield put({
      type: types.START_ARCHIVING_FAILURE
    });
    console.warn('error starting archiving', e);
  }
}

export function* startRemoteArchiving(apolloClient) {
  try {
    const state = yield select();
    const conference = get(state, 'videoConference.conference');
    console.log('starting remote archiving', {
      conferenceId: conference.id
    });
    const result = yield call(apolloClient.mutate, {
      mutation: mutations.START_REMOTE_ARCHIVING,
      variables: {
        input: {
          conferenceId: conference.id
        }
      }
    });

    console.log('startRemoteArchiving result', result);
    yield put({
      type: types.START_REMOTE_ARCHIVING_SUCCESS
    });
  } catch (e) {
    yield put({
      type: types.START_REMOTE_ARCHIVING_FAILURE,
      error: e
    });
    console.warn('error starting remote archiving', e);
  }
}

export function* stopArchiving(apolloClient) {
  try {
    const state = yield select();
    const conference = get(state, 'videoConference.conference');
    console.log('stopping archiving', {
      conferenceId: conference.id
    });
    yield call(apolloClient.mutate, {
      mutation: mutations.STOP_ARCHIVING,
      variables: {
        conferenceId: conference.id
      }
    });

    yield put({
      type: types.STOP_ARCHIVING_SUCCESS
    });
  } catch (e) {
    yield put({
      type: types.STOP_ARCHIVING_FAILURE
    });
    console.warn('error stopping archiving', e);
  }
}

export function* addStreamToArchive(
  apolloClient,
  { streamId, hasAudio, hasVideo }
) {
  try {
    const state = yield select();
    const conference = get(state, 'videoConference.conference');
    console.log('adding stream to archive', {
      conferenceId: conference.id,
      streamId
    });

    yield call(apolloClient.mutate, {
      mutation: mutations.ADD_STREAM_TO_ARCHIVE,
      variables: {
        input: omitBy(
          {
            conferenceId: conference.id,
            streamId,
            hasAudio,
            hasVideo
          },
          isNil
        )
      }
    });

    yield put({
      type: types.ADD_STREAM_TO_ARCHIVE_SUCCESS
    });
  } catch (e) {
    yield put({
      type: types.ADD_STREAM_TO_ARCHIVE_FAILURE
    });
    console.warn('error adding stream to archive', e);
  }
}

export function* claimHost(apolloClient, { userId }) {
  const state = yield select();
  const conference = get(state, 'videoConference.conference');

  console.log('Claiming host', { userId });

  try {
    const result = yield call(apolloClient.mutate, {
      mutation: mutations.CLAIM_HOST,
      variables: {
        id: conference.id,
        input: {
          conferenceId: conference.id,
          userId: userId
        }
      }
    });
    console.log('claim host result: ', result);
    if (result.data.claimHost?.newHost) {
      yield put({
        type: types.ACCEPT_CLAIM_SUCCESS,
        userId,
        newHost: result.data.claimHost.newHost
      });
    } else {
      yield put({
        type: types.CLAIM_HOST_SUCCESS,
        userId
      });
    }
  } catch (e) {
    yield put({
      type: types.CLAIM_HOST_FAILURE
    });
  }
}

export function* acceptClaim(apolloClient, { isReplay }) {
  const state = yield select();
  const conference = get(state, 'videoConference.conference');
  const userId = conference.pendingHostClaimRequestUserId;

  console.log('Accepting claim', { userId, isReplay });

  try {
    const result = yield call(apolloClient.mutate, {
      mutation: mutations.ACCEPT_CLAIM,
      variables: {
        id: conference.id,
        input: {
          conferenceId: conference.id
        }
      }
    });
    console.log('accept claim result:', result);
    yield put({
      type: types.ACCEPT_CLAIM_SUCCESS,
      userId,
      newHost: result.data.acceptClaim.newHost
    });
  } catch (e) {
    yield put({
      type: types.ACCEPT_CLAIM_FAILURE
    });
  }
}

export function* declineClaim(apolloClient, { isReplay }) {
  const state = yield select();
  const conference = get(state, 'videoConference.conference');
  const userId = conference.pendingHostClaimRequestUserId;

  console.log('Declining claim', { userId, isReplay });

  try {
    const result = yield call(apolloClient.mutate, {
      mutation: mutations.DECLINE_CLAIM,
      variables: {
        id: conference.id,
        input: {
          conferenceId: conference.id
        }
      }
    });
    console.log('decline claim result: ', result);
    yield put({
      type: types.DECLINE_CLAIM_SUCCESS,
      userId
    });
  } catch (e) {
    yield put({
      type: types.DECLINE_CLAIM_FAILURE
    });
  }
}

export function* cancelClaim(apolloClient, { isReplay }) {
  const state = yield select();
  const conference = get(state, 'videoConference.conference');
  const userId = conference.pendingHostClaimRequestUserId;

  console.log('Cancelling claim', { userId, isReplay });

  try {
    const result = yield call(apolloClient.mutate, {
      mutation: mutations.CANCEL_CLAIM,
      variables: {
        id: conference.id,
        input: {
          conferenceId: conference.id
        }
      }
    });
    console.log('cancel claim result: ', result);
    yield put({
      type: types.CANCEL_CLAIM_SUCCESS,
      userId
    });
  } catch (e) {
    yield put({
      type: types.CANCEL_CLAIM_FAILURE
    });
  }
}

export function* handoffHost(apolloClient, { userId }) {
  const state = yield select();
  const conference = get(state, 'videoConference.conference');

  console.log('Handing off host to', { userId });

  try {
    const result = yield call(apolloClient.mutate, {
      mutation: mutations.HANDOFF_HOST,
      variables: {
        input: {
          conferenceId: conference.id,
          userId
        }
      }
    });
    console.log('handoff host result: ', result);
    yield put({
      type: types.HANDOFF_HOST_SUCCESS,
      userId
    });
  } catch (e) {
    yield put({
      type: types.HANDOFF_HOST_FAILURE
    });
  }
}

export function* acceptHandoff(apolloClient, { isReplay }) {
  const state = yield select();
  const conference = get(state, 'videoConference.conference');
  const userId = conference.pendingHostHandoffRequestUserId;

  console.log('Accepting handoff', userId, isReplay);

  try {
    const result = yield call(apolloClient.mutate, {
      mutation: mutations.ACCEPT_HANDOFF,
      variables: {
        input: {
          conferenceId: conference.id
        }
      }
    });
    console.log('accept handoff result', result);
    yield put({
      type: types.ACCEPT_HANDOFF_SUCCESS,
      userId,
      newHost: result.data.acceptHandoff.newHost
    });
  } catch (e) {
    yield put({
      type: types.ACCEPT_HANDOFF_FAILURE
    });
  }
}

export function* declineHandoff(apolloClient, { isReplay }) {
  const state = yield select();
  const conference = get(state, 'videoConference.conference');
  const userId = conference.pendingHostHandoffRequestUserId;

  console.log('Declining handoff', userId, isReplay);

  try {
    const result = yield call(apolloClient.mutate, {
      mutation: mutations.DECLINE_HANDOFF,
      variables: {
        input: {
          conferenceId: conference.id
        }
      }
    });
    console.log('decline handoff result', result);
    yield put({
      type: types.DECLINE_HANDOFF_SUCCESS,
      userId,
      newHost: result.data.declineHandoff.newHost
    });
  } catch (e) {
    yield put({
      type: types.DECLINE_HANDOFF_FAILURE
    });
  }
}

export function* cancelHandoff(apolloClient, { isReplay }) {
  const state = yield select();
  const conference = get(state, 'videoConference.conference');
  const userId = conference.pendingHostClaimRequestUserId;

  console.log('Cancelling handoff', userId, isReplay);

  try {
    const result = yield call(apolloClient.mutate, {
      mutation: mutations.CANCEL_HANDOFF,
      variables: {
        id: conference.id,
        input: {
          conferenceId: conference.id
        }
      }
    });
    console.log('cancel claim result', result);
    yield put({
      type: types.CANCEL_HANDOFF_SUCCESS,
      userId
    });
  } catch (e) {
    yield put({
      type: types.CANCEL_HANDOFF_FAILURE
    });
  }
}

export function* videoConferenceSaga({ apis }) {
  yield all([
    takeEvery(
      types.CREATE_CONFERENCE_REQUEST,
      createConference.bind(this, apis.apolloClient)
    ),

    takeEvery(
      types.UPDATE_CONFERENCE_REQUEST,
      updateConference.bind(this, apis.apolloClient)
    ),

    takeEvery(
      types.DELETE_CONFERENCE_REQUEST,
      deleteConference.bind(this, apis.apolloClient)
    ),

    takeEvery(
      types.INITIALIZE_CONFERENCE_REQUEST,
      initializeConference.bind(this, apis.apolloClient)
    ),

    takeEvery(types.LOAD_USER_REQUEST, loadUser.bind(this, apis.apolloClient)),

    takeEvery(
      types.LOAD_BROWSABLE_MEDIA_REQUEST,
      getBrowsableMedia.bind(this, apis.apolloClient, apis.api)
    ),

    takeEvery(types.SELECT_MEDIA, selectMedia.bind(this, apis.apolloClient)),

    takeEvery(
      types.ADD_OR_REMOVE_CO_HOST,
      addOrRemoveCoHost.bind(this, apis.apolloClient)
    ),

    takeEvery(
      [types.RAISE_HAND, types.LOWER_HAND],
      raiseOrLowerHand.bind(this, apis.apolloClient)
    ),

    takeEvery(
      types.CLEAR_RAISED_HANDS,
      clearRaisedHands.bind(this, apis.apolloClient)
    ),

    takeEvery(
      types.CHANGE_HAND_ICON,
      changeHandIcon.bind(this, apis.apolloClient, apis.api)
    ),

    takeEvery(
      [
        screenplayViewTypes.SELECT_CHUNK,
        sclActionType.playerIsPaused,
        sclActionType.playerIsPlaying,
        sclActionType.seekVideoPosition,
        types.GOTO_PDF_PAGE
      ],
      savePosition.bind(this, apis.apolloClient)
    ),

    throttle(
      2000,
      sclActionType.updateCurrentTime,
      savePosition.bind(this, apis.apolloClient)
    ),

    takeEvery(sclActionType.playerReady, seekVideoIfNeeded.bind(this)),

    takeEvery(types.HOST_LEFT, handleHostLeft.bind(this, apis.apolloClient)),

    takeEvery(
      screenplayViewTypes.SCREENPLAY_RENDERED,
      scrollScreenplayIfNeeded.bind(this)
    ),

    takeEvery(types.SELECT_MEDIA, scrollScreenplayAfterSelect.bind(this)),

    takeEvery(types.SELECT_IMAGE, selectImage.bind(this, apis.apolloClient)),

    takeEvery(
      types.SAVE_CHAT_MESSAGE_REQUEST,
      saveChatMessage.bind(this, apis.apolloClient)
    ),

    takeEvery(
      types.UPDATE_SETTINGS,
      updateSettings.bind(this, apis.apolloClient)
    ),

    takeEvery(
      types.START_ARCHIVING_REQUEST,
      startArchiving.bind(this, apis.apolloClient)
    ),

    takeEvery(
      types.STOP_ARCHIVING_REQUEST,
      stopArchiving.bind(this, apis.apolloClient)
    ),

    takeEvery(
      types.ADD_STREAM_TO_ARCHIVE_REQUEST,
      addStreamToArchive.bind(this, apis.apolloClient)
    ),
    takeEvery(
      types.START_REMOTE_ARCHIVING_REQUEST,
      startRemoteArchiving.bind(this, apis.apolloClient)
    ),
    takeEvery(
      types.CLAIM_HOST_REQUEST,
      claimHost.bind(this, apis.apolloClient)
    ),
    takeEvery(
      types.ACCEPT_CLAIM_REQUEST,
      acceptClaim.bind(this, apis.apolloClient)
    ),
    takeEvery(
      types.DECLINE_CLAIM_REQUEST,
      declineClaim.bind(this, apis.apolloClient)
    ),
    takeEvery(
      types.CANCEL_CLAIM_REQUEST,
      cancelClaim.bind(this, apis.apolloClient)
    ),
    takeEvery(
      types.HANDOFF_HOST_REQUEST,
      handoffHost.bind(this, apis.apolloClient)
    ),
    takeEvery(
      types.ACCEPT_HANDOFF_REQUEST,
      acceptHandoff.bind(this, apis.apolloClient)
    ),
    takeEvery(
      types.DECLINE_HANDOFF_REQUEST,
      declineHandoff.bind(this, apis.apolloClient)
    ),
    takeEvery(
      types.CANCEL_HANDOFF_REQUEST,
      cancelHandoff.bind(this, apis.apolloClient)
    )
  ]);
}

export const queries = {
  GET_USER: gql`
    query getUser($userId: String!) {
      user(id: $userId) {
        id
        fullName
        avatar
        isMentor
        settings {
          handIconIndex
        }
      }
    }
  `,
  LOAD_BROWSABLE_MEDIA: gql`
    query getShareableFilesForUsers($userIds: [String]) {
      shareableFilesForUsers(userIds: $userIds) {
        id
        baseUrl
        commentSectionId
        created
        dashUrl
        downloadUrl
        duration
        fileType
        folder
        hlsUrl
        imageIndex
        isPreloaded
        isSharedFile
        ownerId
        parentId
        projectLink
        spriteUrl
        thumbnailUrl
        title
        uploadType
        vttUrl
        images {
          downloadUrl
          uploadId
        }
        owner {
          id
          fullName
        }
      }
    }
  `,
  LOAD_SCREENPLAY: gql`
    query getScreenplay($id: ID) {
      screenplay(id: $id) {
        id
        text
      }
    }
  `,
  LOAD_MYFILE: gql`
    query getMyFile($id: ID) {
      myFile(myFileId: $id) {
        id
        screenplayText
      }
    }
  `,
  LIST_CONFERENCES: gql`
    query listConferences($hostId: ID!) {
      videoConferencesForHost(hostId: $hostId) {
        id
        type
        duration
        name
        host {
          id
          fullName
          avatar
        }
        created
        startDate
        participantIds
        recurringDays
        recurringEndDate
      }
    }
  `
};

export const mutations = {
  CREATE_CONFERENCE: gql`
    mutation createVideoConference(
      $input: CreateVideoConferenceInput
      $hostId: ID
    ) {
      createVideoConference(input: $input, hostId: $hostId)
    }
  `,
  INITIALIZE_CONFERENCE: gql`
    mutation initializeVideoConference(
      $id: ID!
      $userId: ID
      $opentokSessionId: String
      $opentokToken: String
    ) {
      initializeVideoConference(
        id: $id
        userId: $userId
        opentokSessionId: $opentokSessionId
        opentokToken: $opentokToken
      ) {
        id
        type
        enabled
        duration
        coHostIds
        raisedHands
        opentokToken
        opentokSessionId
        startDate
        currentPosition
        isPlaying
        presentingUserId
        selectedImageIndex
        parentId
        name
        pendingHostClaimRequestUserId
        pendingHostHandoffRequestUserId
        breakoutRooms {
          id
          name
          enabled
        }
        settings {
          gridViewDisabled
          webcamsDisabled
          micsDisabled
          participantsCanMute
          participantsCanMuteEveryone
          participantsCanPresent
          participantsCanShareScreen
          participantsCanShareCam
        }
        host {
          id
          fullName
          avatar
        }
        chatMessages {
          senderId
          sender {
            id
            fullName
            avatar
          }
          message
          timestamp
        }
        participants {
          id
          fullName
          avatar
          isMentor
          settings {
            handIconIndex
          }
        }
        selectedMedia {
          id
          uploadType
          fileType
          title
          baseUrl
          thumbnailUrl
          dashUrl
          vttUrl
          downloadUrl
          projectLink
          ownerId
          owner {
            id
            fullName
          }
          imageIndex
          images {
            downloadUrl
            uploadId
          }
        }
        archiveId
        archive {
          id
          name
          createdAt
          status
          duration
          sessionId
          initiatorUserId
        }
      }
    }
  `,
  UPDATE_VIDEO_CONFERENCE: gql`
    mutation updateVideoConference(
      $id: ID!
      $input: UpdateVideoConferenceInput
    ) {
      updateVideoConference(id: $id, input: $input) {
        id
        breakoutRooms {
          id
          name
          enabled
        }
      }
    }
  `,
  DELETE_VIDEO_CONFERENCE: gql`
    mutation deleteVideoConference($id: ID!) {
      deleteVideoConference(id: $id)
    }
  `,
  SAVE_CHAT_MESSAGE: gql`
    mutation saveVideoConferenceChatMessage(
      $conferenceId: ID!
      $senderId: ID!
      $message: String
    ) {
      saveVideoConferenceChatMessage(
        conferenceId: $conferenceId
        senderId: $senderId
        message: $message
      )
    }
  `,
  START_ARCHIVING: gql`
    mutation startArchiving($input: StartArchivingInput!) {
      startArchiving(input: $input) {
        id
        createdAt
        name
      }
    }
  `,
  STOP_ARCHIVING: gql`
    mutation stopArchiving($conferenceId: ID!) {
      stopArchiving(conferenceId: $conferenceId)
    }
  `,
  START_REMOTE_ARCHIVING: gql`
    mutation startRemoteArchiving($input: StartRemoteArchivingInput!) {
      startRemoteArchiving(input: $input) {
        eta
      }
    }
  `,

  ADD_STREAM_TO_ARCHIVE: gql`
    mutation addStreamToArchive($input: AddStreamToArchiveInput!) {
      addStreamToArchive(input: $input)
    }
  `,
  CLAIM_HOST: gql`
    mutation claimHost($input: ClaimHostInput!) {
      claimHost(input: $input) {
        newHost {
          id
          fullName
          avatar
        }
      }
    }
  `,
  ACCEPT_CLAIM: gql`
    mutation acceptClaim($input: AcceptClaimInput!) {
      acceptClaim(input: $input) {
        newHost {
          id
          fullName
          avatar
        }
      }
    }
  `,
  DECLINE_CLAIM: gql`
    mutation declineClaim($input: DeclineClaimInput!) {
      declineClaim(input: $input)
    }
  `,
  CANCEL_CLAIM: gql`
    mutation cancelClaim($input: CancelClaimInput!) {
      cancelClaim(input: $input)
    }
  `,
  HANDOFF_HOST: gql`
    mutation handoffHost($input: HandoffHostInput!) {
      handoffHost(input: $input)
    }
  `,
  ACCEPT_HANDOFF: gql`
    mutation acceptHandoff($input: AcceptHandoffInput!) {
      acceptHandoff(input: $input) {
        newHost {
          id
          fullName
          avatar
        }
      }
    }
  `,
  DECLINE_HANDOFF: gql`
    mutation declineHandoff($input: DeclineHandoffInput!) {
      declineHandoff(input: $input)
    }
  `,
  CANCEL_HANDOFF: gql`
    mutation cancelHandoff($input: CancelHandoffInput!) {
      cancelHandoff(input: $input)
    }
  `
};
