// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PopupDialog--closeButton--1u8_Q4BT{position:absolute;right:15px;top:15px;cursor:pointer}.PopupDialog--closeButton--1u8_Q4BT:hover{color:white}\n", ""]);
// Exports
exports.locals = {
	"closeButton": "PopupDialog--closeButton--1u8_Q4BT"
};
module.exports = exports;
