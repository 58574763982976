import { gql } from '@apollo/client';

export const updateDisabledDashboardTabs = apolloClient => async (
  programInstanceId,
  disabledDashboardTabs
) =>
  await apolloClient.mutate({
    mutation: gql`
      mutation updateDisabledDashboardTabs(
        $input: updateDisabledDashboardTabsInput!
      ) {
        updateDisabledDashboardTabs(input: $input)
      }
    `,
    variables: {
      input: {
        programInstanceId,
        disabledDashboardTabs
      }
    },
    refetchQueries: ['getAdminData']
  });
