import { gql } from '@apollo/client';

export const deleteOfficeHours = apolloClient => officeHoursId => {
  const mutation = gql`
    mutation deleteOfficeHours($input: DeleteOfficeHoursInput!) {
      deleteOfficeHours(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        officeHoursId
      }
    }
  });
};
