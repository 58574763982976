import { gql } from '@apollo/client';

export const updateTextReply = apolloClient => (
  commentSectionId,
  item,
  msg
) => {
  const mutation = gql`
    mutation updateTextReply($input: UpdateTextReplyInput!) {
      updateTextReply(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        commentSectionId,
        replyId: item.id,
        msg
      }
    }
  });
};
