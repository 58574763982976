import { gql } from '@apollo/client';

export const toggleIsUsingCrewFiles = apolloClient => async programInstanceId => {
  return await apolloClient.mutate({
    mutation: gql`
      mutation toggleIsUsingCrewFiles($programInstanceId: ID!) {
        toggleIsUsingCrewFiles(programInstanceId: $programInstanceId)
      }
    `,
    variables: {
      programInstanceId
    },
    refetchQueries: ['getAdminData']
  });
};
