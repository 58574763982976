import { getListOfComments } from './getListOfComments';
import { getComment } from './getComment';

export function findCommentIdByTime(state, currentTime) {
  let commentIds = getListOfComments(state);
  for (let i = commentIds.length - 1; i >= 0; i--) {
    let id = commentIds[i];
    let c = getComment(state, id);
    if (currentTime >= c.time) {
      return id;
    }
  }
  return 'none';
}
