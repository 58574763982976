import './helpers/configLogrocket.js';
import 'babel-polyfill';
import 'styles/global.scss';
import './fontAwesomeLib';

import App from './App';
import dateformat from 'dateformat';
import Modal from 'react-modal';
import React from 'react';
import TWEEN from 'tween.js';
import { apolloClient } from './apolloClient';
import { render } from 'react-dom';
import MainRouter from './MainRouter';

dateformat.masks.default = "mmmm d 'at' h:MM TT";

let animate = time => {
  requestAnimationFrame(animate);
  TWEEN.update(time);
};
requestAnimationFrame(animate);

Modal.setAppElement('#app');

render(
  <App apolloClient={apolloClient} MainRouter={MainRouter} />,
  document.getElementById('app')
);
