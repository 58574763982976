import { actionType } from '../constants';
import { logAndSendError } from 'utils/sentryHelper';

export const addVideoComment = payload => {
  return (dispatch, getState, { api }) => {
    // the payload is created when the video is saved, see saveRecordedVideo
    return api
      .addVideoComment(payload)
      .then(() => {
        return dispatch({
          type: actionType.addVideoComment
        });
      })
      .catch(e => logAndSendError(e, 'error adding video comment'));
  };
};
