// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PopupDialogTheme--wrapper--utjhSEQ0{z-index:10000}.PopupDialogTheme--dialog--3JDdr8wG{background-color:#333;width:600px}@media screen and (min-width: 1440px){.PopupDialogTheme--dialog--3JDdr8wG{width:700px}}@media screen and (min-width: 1920px){.PopupDialogTheme--dialog--3JDdr8wG{width:800px}}.PopupDialogTheme--body--363uIKLa{padding:0}.PopupDialogTheme--title--1IFXnCLE{margin:0;padding:20px;height:60px;background-color:#363636;font-size:20px;font-weight:bold;color:#ffffff}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "PopupDialogTheme--wrapper--utjhSEQ0",
	"dialog": "PopupDialogTheme--dialog--3JDdr8wG",
	"body": "PopupDialogTheme--body--363uIKLa",
	"title": "PopupDialogTheme--title--1IFXnCLE"
};
module.exports = exports;
