import config from 'config';
import { stripSpecialCharacters } from 'utils/stripSpecialCharacters';
import { gql } from '@apollo/client';
import { omit } from 'lodash';
import { uid } from 'utils/uid';
import { logAndSendError } from '../../utils/sentryHelper';

const UPLOAD_MUTATION = gql`
  mutation addUpload($input: AddUploadInput) {
    addUpload(input: $input) {
      id
      downloadUrl
      fileName
      fileType
      fileMimeType
      fileSize
      ownerId
      started
      finished
      status
      uploadPath
      uploadType
      uploadDurationMs
    }
  }
`;

export const uploadFile = (apolloClient, storageService) => async (
  file,
  fileType,
  uploadOwnerId,
  uploadType,
  progressHandler,
  isSharedFile
) => {
  console.log(file, fileType, uploadOwnerId, uploadType, progressHandler);
  const started = new Date();

  const newId = uid();
  let uploadErrorData = {};
  let uploadData = {};

  try {
    const safeFileName =
      (file.name && stripSpecialCharacters(file.name)) || 'blob';
    const targetPath = `uploads/user/${uploadOwnerId}/uploadId/${newId}/${safeFileName}`;

    const upload = (uploadErrorData = {
      id: newId,
      fileName: safeFileName || '',
      fileType: fileType,
      fileMimeType: file.type,
      fileSize: file.size,
      ownerId: uploadOwnerId,
      started: started.toISOString(),
      status: 'UPLOADING',
      uploadPath: targetPath,
      uploadType
    });

    const result = await apolloClient.mutate({
      mutation: UPLOAD_MUTATION,
      variables: {
        input: upload
      }
    });

    if (result.error || !result.data.addUpload) {
      throw new Error('Error creating upload record');
    }

    uploadData = uploadErrorData = omit(result.data.addUpload, '__typename');

    const url =
      config.useMultipartUpload || file.size > 4 * 1024 * 1024 * 1024
        ? await storageService.uploadLargeBlob(
            targetPath,
            file,
            progressHandler
          )
        : await storageService.uploadBlob(targetPath, file, progressHandler);

    const finished = new Date();
    const uploadDurationMs = finished - started;
    const finishedUpload = (uploadErrorData = {
      ...uploadData,
      uploadPath: decodeURIComponent(
        url
          .split('/')
          .slice(3)
          .join('/')
      ),
      downloadUrl: url,
      status: 'COMPLETE',
      finished: finished.toISOString(),
      uploadDurationMs
    });

    const finalResult = await apolloClient.mutate({
      mutation: UPLOAD_MUTATION,
      variables: {
        input: finishedUpload
      }
    });

    if (finalResult.error || !finalResult.data.addUpload) {
      throw new Error('Error updating upload record');
    }

    return omit(finalResult.data.addUpload, '__typename');
  } catch (err) {
    logAndSendError(err, 'uploadFile');

    const finished = new Date();
    const uploadDurationMs = finished - started;
    const finishedUpload = {
      ...uploadErrorData,
      downloadUrl: 'n/a',
      status: err.code === 'CANCELED' ? 'CANCELED' : 'ERROR',
      finished: finished.toISOString(),
      uploadDurationMs,
      error: (err && JSON.stringify(err)) || 'Unexpected Error'
    };

    const finalResult = await apolloClient.mutate({
      mutation: UPLOAD_MUTATION,
      variables: {
        input: finishedUpload
      }
    });

    if (finalResult.error || !finalResult.data.addUpload) {
      throw new Error(finalResult.error || 'Unknown error uploading file');
    }

    throw omit(finalResult.data.addUpload, '__typename');
  }
};
