import { gql } from '@apollo/client';

export const addVideoComment = apolloClient => ({
  commentSectionId,
  commentId,
  author,
  time,
  url,
  videoMetadata
}) => {
  const mutation = gql`
    mutation addVideoComment($input: AddVideoCommentInput!) {
      addVideoComment(input: $input)
    }
  `;
  const input = {
    commentSectionId,
    commentId,
    author,
    url,
    videoMetadata: {
      videoHeight: videoMetadata.videoHeight,
      videoWidth: videoMetadata.videoWidth
    }
  };

  // reviews do not have a time
  if (!isNaN(time) && time >= 0) {
    input.time = time;
  }
  return apolloClient.mutate({
    mutation,
    variables: {
      input
    }
  });
};
