// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".updateBodyScrollClass--noScroll--GYwSyLlG {\n  overflow: hidden;\n  position: relative;\n  height: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"noScroll": "updateBodyScrollClass--noScroll--GYwSyLlG"
};
module.exports = exports;
