import { gql } from '@apollo/client';

export const loadOfficeHours = apolloClient => officeHoursId => {
  const mutation = gql`
    mutation loadOfficeHours($input: LoadOfficeHoursInput!) {
      loadOfficeHours(input: $input) {
        id
        firstUserId
        secondUserId
        startTime
        endTime
        sessionId
        token
        conferenceId
      }
    }
  `;
  return apolloClient
    .mutate({
      mutation,
      variables: {
        input: {
          officeHoursId
        }
      }
    })
    .then(response => response.data.loadOfficeHours);
};
