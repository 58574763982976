import React from 'react';
import { Button as ReactToolboxButton } from 'react-toolbox/lib/button';
import { themr } from 'react-css-themr';
import { BUTTON } from 'react-toolbox/lib/identifiers';
import PropTypes from 'prop-types';

const SmashcutButton = ({ label, theme, children, ...rest }) => {
  return (
    <ReactToolboxButton theme={theme} {...rest}>
      {!children && <span className={theme.label}>{label}</span>}
      {children}
    </ReactToolboxButton>
  );
};

SmashcutButton.propTypes = {
  label: PropTypes.string
};

export default themr(BUTTON)(SmashcutButton);
