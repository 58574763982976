import { deleteOfficeHours } from './deleteOfficeHours';
import { loadOfficeHours } from './loadOfficeHours';
import { setupOfficeHours } from './setupOfficeHours';
import { apolloClient } from '../../smashcut-client-lib/services';

export const officeHoursApi = config => {
  return {
    deleteOfficeHours: deleteOfficeHours(apolloClient),
    loadOfficeHours: loadOfficeHours(apolloClient),
    setupOfficeHours: setupOfficeHours(apolloClient)
  };
};
