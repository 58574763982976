import { actionType } from '../constants';
import { logAndSendError } from 'utils/sentryHelper';
import { sendEmailVerification } from './sendEmailVerification';
import * as authUtils from 'common/authUtils';
import { authStateChange } from './userActions';

export const createUser = input => {
  return (dispatch, getState, { api }) => {
    dispatch({ type: actionType.createUser });
    console.log('creating user', input);
    api.user
      .createUser(input)
      .then(data => {
        console.log('create account result: ', data);
        const { status, token, refreshToken, user } = data.data.signup;
        if (status === 'SUCCESS') {
          console.log('signing in with', user, token);
          authUtils.signIn(user, token, refreshToken);

          dispatch(authStateChange());

          api.user.loadUser(user.id).then(user => {
            dispatch({ type: actionType.firebaseUp, payload: user });
          });

          return dispatch({
            type: actionType.createUserComplete,
            payload: user
          });
        }
      })
      .then(user => {
        // dispatch(sendEmailVerification(user));
      })
      .catch(error => {
        console.log('err', error);
        const message = error.graphQLErrors[0].message;
        dispatch({ type: actionType.createUserError, payload: message });
        logAndSendError(error, 'error creating user');
      });
  };
};
