import { actionType } from '../constants/actionTypes';
import { logAndSendError } from 'utils/sentryHelper';

export const deployProgram = params => {
  return (dispatch, getState, { adminApi }) => {
    dispatch({ type: actionType.deployProgramRequest });
    return adminApi
      .deployProgram(params)
      .then(response =>
        dispatch({ type: actionType.deployProgramResponse, message: response })
      )
      .catch(e => logAndSendError(e, 'error deploying program'));
  };
};
