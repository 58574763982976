// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HelpModal--topContainer--_B0SXexf .HelpModal--overlay--1jHbHHLQ{position:fixed;left:0;top:0;right:0;bottom:0;background-color:#000000;opacity:0.4;z-index:9}.HelpModal--topContainer--_B0SXexf .HelpModal--popup--24-WM25B{background-color:#303030;max-width:477px;position:fixed;left:50%;top:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);z-index:15;-webkit-box-shadow:0 2px 4px 0 rgba(0, 0, 0, .15);box-shadow:0 2px 4px 0 rgba(0, 0, 0, .15)}.HelpModal--topContainer--_B0SXexf .HelpModal--header--ToViKOMl{background-color:#363636;color:#ffffff;font-size:20px;padding:20px;font-weight:bold;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.HelpModal--topContainer--_B0SXexf .HelpModal--header--ToViKOMl .HelpModal--title--2IHBTGCA{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}.HelpModal--topContainer--_B0SXexf .HelpModal--header--ToViKOMl .HelpModal--needHelp--33dFrhvW{font-size:14px}.HelpModal--topContainer--_B0SXexf .HelpModal--header--ToViKOMl .HelpModal--closeButton--3lDU28ai{color:#ffffff;font-size:20px;cursor:pointer}.HelpModal--topContainer--_B0SXexf .HelpModal--content--3WpFPs3I{padding:25px 20px;background-color:#ffffff;color:#000000}.HelpModal--topContainer--_B0SXexf .HelpModal--content--3WpFPs3I .HelpModal--markDownDisplay--8us-odN2{background-color:#ffffff;padding:0 20px 20px 20px}\n", ""]);
// Exports
exports.locals = {
	"topContainer": "HelpModal--topContainer--_B0SXexf",
	"overlay": "HelpModal--overlay--1jHbHHLQ",
	"popup": "HelpModal--popup--24-WM25B",
	"header": "HelpModal--header--ToViKOMl",
	"title": "HelpModal--title--2IHBTGCA",
	"needHelp": "HelpModal--needHelp--33dFrhvW",
	"closeButton": "HelpModal--closeButton--3lDU28ai",
	"content": "HelpModal--content--3WpFPs3I",
	"markDownDisplay": "HelpModal--markDownDisplay--8us-odN2"
};
module.exports = exports;
