import {
  reduce,
  defaults,
  union,
  difference,
  some,
  every,
  without
} from 'lodash';

const prefix = 'VIDEO_CONFERENCE';

export const types = {
  CREATE_CONFERENCE_REQUEST: `${prefix}/CREATE_CONFERENCE_REQUEST`,
  CREATE_CONFERENCE_SUCCESS: `${prefix}/CREATE_CONFERENCE_SUCCESS`,
  CREATE_CONFERENCE_FAILURE: `${prefix}/CREATE_CONFERENCE_FAILURE`,
  UPDATE_CONFERENCE_REQUEST: `${prefix}/UPDATE_CONFERENCE_REQUEST`,
  UPDATE_CONFERENCE_SUCCESS: `${prefix}/UPDATE_CONFERENCE_SUCCESS`,
  UPDATE_CONFERENCE_FAILURE: `${prefix}/UPDATE_CONFERENCE_FAILURE`,
  DELETE_CONFERENCE_REQUEST: `${prefix}/DELETE_CONFERENCE_REQUEST`,
  DELETE_CONFERENCE_SUCCESS: `${prefix}/DELETE_CONFERENCE_SUCCESS`,
  DELETE_CONFERENCE_FAILURE: `${prefix}/DELETE_CONFERENCE_FAILURE`,
  INITIALIZE_CONFERENCE_REQUEST: `${prefix}/INITIALIZE_CONFERENCE_REQUEST`,
  INITIALIZE_CONFERENCE_SUCCESS: `${prefix}/INITIALIZE_CONFERENCE_SUCCESS`,
  INITIALIZE_CONFERENCE_FAILURE: `${prefix}/INITIALIZE_CONFERENCE_FAILURE`,
  FINISH_CONFERENCE: `${prefix}/FINISH_CONFERENCE`,
  SESSION_CONNECTED: `${prefix}/SESSION_CONNECTED`,
  SESSION_DISCONNECTED: `${prefix}/SESSION_DISCONNECTED`,
  LOAD_USER_REQUEST: `${prefix}/LOAD_USER_REQUEST`,
  LOAD_USER_SUCCESS: `${prefix}/LOAD_USER_SUCCESS`,
  LOAD_USER_FAILURE: `${prefix}/LOAD_USER_FAILURE`,
  SAVE_CHAT_MESSAGE_REQUEST: `${prefix}/SAVE_CHAT_MESSAGE_REQUEST`,
  SAVE_CHAT_MESSAGE_SUCCESS: `${prefix}/SAVE_CHAT_MESSAGE_SUCCESS`,
  SAVE_CHAT_MESSAGE_FAILURE: `${prefix}/SAVE_CHAT_MESSAGE_FAILURE`,
  LOAD_BROWSABLE_MEDIA_REQUEST: `${prefix}/LOAD_BROWSABLE_MEDIA_REQUEST`,
  LOAD_BROWSABLE_MEDIA_SUCCESS: `${prefix}/LOAD_BROWSABLE_MEDIA_SUCCESS`,
  LOAD_BROWSABLE_MEDIA_FAILURE: `${prefix}/LOAD_BROWSABLE_MEDIA_FAILURE`,
  SELECT_MEDIA: `${prefix}/SELECT_MEDIA`,
  UPDATE_SETTINGS: `${prefix}/UPDATE_SETTINGS`,
  UPDATE_BREAKOUT_ROOMS: `${prefix}/UPDATE_BREAKOUT_ROOMS`,
  SHOW_NOTIFICATION: `${prefix}/SHOW_NOTIFICATION`,
  SHOW_BECAME_CO_HOST_WARNING: `${prefix}/SHOW_BECAME_CO_HOST_WARNING`,
  HIDE_BECAME_CO_HOST_WARNING: `${prefix}/HIDE_BECAME_CO_HOST_WARNING`,
  SHOW_COMMENTS: `${prefix}/SHOW_COMMENTS`,
  HIDE_COMMENTS: `${prefix}/HIDE_COMMENTS`,
  SHOW_LEFT_PANEL: `${prefix}/SHOW_LEFT_PANEL`,
  HIDE_LEFT_PANEL: `${prefix}/HIDE_LEFT_PANEL`,
  SHOW_MANAGE_ROLES: `${prefix}/SHOW_MANAGE_ROLES`,
  HIDE_MANAGE_ROLES: `${prefix}/HIDE_MANAGE_ROLES`,
  SHOW_CHANGE_PERMISSIONS_MODAL: `${prefix}/SHOW_CHANGE_PERMISSION_MODAL`,
  HIDE_CHANGE_PERMISSIONS_MODAL: `${prefix}/HIDE_CHANGE_PERMISSION_MODAL`,
  ENTER_FOCUS_MODE: `${prefix}/ENTER_FOCUS_MODE`,
  EXIT_FOCUS_MODE: `${prefix}/EXIT_FOCUS_MODE`,
  ADD_OR_REMOVE_CO_HOST: `${prefix}/ADD_OR_REMOVE_CO_HOST`,
  RAISE_HAND: `${prefix}/RAISE_HAND`,
  LOWER_HAND: `${prefix}/LOWER_HAND`,
  PIN_USER_TO_SPEAKER_VIEW: `${prefix}/PIN_USER_TO_SPEAKER_VIEW`,
  UNPIN_USER_FROM_SPEAKER_VIEW: `${prefix}/UNPIN_USER_FROM_SPEAKER_VIEW`,
  HOST_JOINED: `${prefix}/HOST_JOINED`,
  HOST_LEFT: `${prefix}/HOST_LEFT`,
  HOST_STARTED_BROWSING: `${prefix}/HOST_STARTED_BROWSING`,
  HOST_STOPPED_BROWSING: `${prefix}/HOST_STOPPED_BROWSING`,
  SHOW_MEDIA_BROWSER: `${prefix}/SHOW_MEDIA_BROWSER`,
  HIDE_MEDIA_BROWSER: `${prefix}/HIDE_MEDIA_BROWSER`,
  GOTO_PDF_PAGE: `${prefix}/GOTO_PDF_PAGE`,
  SET_PDF_SCALE: `${prefix}/SET_PDF_SCALE`,
  SELECT_IMAGE: `${prefix}/SELECT_IMAGE`,
  CHANGE_HAND_ICON: `${prefix}/CHANGE_HAND_ICON`,
  CLEAR_RAISED_HANDS: `${prefix}/CLEAR_RAISED_HANDS`,
  START_ARCHIVING_REQUEST: `${prefix}/START_ARCHIVING_REQUEST`,
  START_ARCHIVING_SUCCESS: `${prefix}/START_ARCHIVING_SUCCESS`,
  START_ARCHIVING_FAILURE: `${prefix}/START_ARCHIVING_FAILURE`,
  START_REMOTE_ARCHIVING_REQUEST: `${prefix}/START_REMOTE_ARCHIVING_REQUEST`,
  START_REMOTE_ARCHIVING_SUCCESS: `${prefix}/START_REMOTE_ARCHIVING_SUCCESS`,
  START_REMOTE_ARCHIVING_FAILURE: `${prefix}/START_REMOTE_ARCHIVING_FAILURE`,
  STOP_ARCHIVING_REQUEST: `${prefix}/STOP_ARCHIVING_REQUEST`,
  STOP_ARCHIVING_SUCCESS: `${prefix}/STOP_ARCHIVING_SUCCESS`,
  STOP_ARCHIVING_FAILURE: `${prefix}/STOP_ARCHIVING_FAILURE`,
  ADD_STREAM_TO_ARCHIVE_REQUEST: `${prefix}/ADD_STREAM_TO_ARCHIVE_REQUEST`,
  ADD_STREAM_TO_ARCHIVE_SUCCESS: `${prefix}/ADD_STREAM_TO_ARCHIVE_SUCCESS`,
  ADD_STREAM_TO_ARCHIVE_FAILURE: `${prefix}/ADD_STREAM_TO_ARCHIVE_FAILURE`,
  SET_ARCHIVE_TAB_CAPTURED_FLAG: `${prefix}/SET_ARCHIVE_TAB_CAPTURED_FLAG`,
  SET_IS_ARCHIVING_FLAG: `${prefix}/SET_IS_ARCHIVING_FLAG`,
  USER_JOINED: `${prefix}/USER_JOINED`,
  USER_LEFT: `${prefix}/USER_LEFT`,
  CLAIM_HOST_REQUEST: `${prefix}/CLAIM_HOST_REQUEST`,
  CLAIM_HOST_SUCCESS: `${prefix}/CLAIM_HOST_SUCCESS`,
  CLAIM_HOST_FAILURE: `${prefix}/CLAIM_HOST_FAILURE`,
  ACCEPT_CLAIM_REQUEST: `${prefix}/ACCEPT_CLAIM_REQUEST`,
  ACCEPT_CLAIM_SUCCESS: `${prefix}/ACCEPT_CLAIM_SUCCESS`,
  ACCEPT_CLAIM_FAILURE: `${prefix}/ACCEPT_CLAIM_FAILURE`,
  DECLINE_CLAIM_REQUEST: `${prefix}/DECLINE_CLAIM_REQUEST`,
  DECLINE_CLAIM_SUCCESS: `${prefix}/DECLINE_CLAIM_SUCCESS`,
  DECLINE_CLAIM_FAILURE: `${prefix}/DECLINE_CLAIM_FAILURE`,
  CANCEL_CLAIM_REQUEST: `${prefix}/CANCEL_CLAIM_REQUEST`,
  CANCEL_CLAIM_SUCCESS: `${prefix}/CANCEL_CLAIM_SUCCESS`,
  CANCEL_CLAIM_FAILURE: `${prefix}/CANCEL_CLAIM_FAILURE`,
  HANDOFF_HOST_REQUEST: `${prefix}/HANDOFF_HOST_REQUEST`,
  HANDOFF_HOST_SUCCESS: `${prefix}/HANDOFF_HOST_SUCCESS`,
  HANDOFF_HOST_FAILURE: `${prefix}/HANDOFF_HOST_FAILURE`,
  ACCEPT_HANDOFF_REQUEST: `${prefix}/ACCEPT_HANDOFF_REQUEST`,
  ACCEPT_HANDOFF_SUCCESS: `${prefix}/ACCEPT_HANDOFF_SUCCESS`,
  ACCEPT_HANDOFF_FAILURE: `${prefix}/ACCEPT_HANDOFF_FAILURE`,
  DECLINE_HANDOFF_REQUEST: `${prefix}/DECLINE_HANDOFF_REQUEST`,
  DECLINE_HANDOFF_SUCCESS: `${prefix}/DECLINE_HANDOFF_SUCCESS`,
  DECLINE_HANDOFF_FAILURE: `${prefix}/DECLINE_HANDOFF_FAILURE`,
  CANCEL_HANDOFF_REQUEST: `${prefix}/CANCEL_HANDOFF_REQUEST`,
  CANCEL_HANDOFF_SUCCESS: `${prefix}/CANCEL_HANDOFF_SUCCESS`,
  CANCEL_HANDOFF_FAILURE: `${prefix}/CANCEL_HANDOFF_FAILURE`,
  ACTIVE_SPEAKER_CHANGED: `${prefix}/ACTIVE_SPEAKER_CHANGED`
};

export const initialState = {
  users: {},
  mediaPerUser: {},
  isLoadingBrowsableMedia: false,
  pdfPage: 1,
  pdfScale: 1,
  leftPanelVisible: true,
  focusMode: false,
  archiveTabCaptured: false,
  isManageRolesVisible: false,
  activeSpeaker: undefined
};

export const videoConferenceReducer = (
  state = initialState,
  action,
  fullState
) => {
  let nextState = state;

  const pinUser = state => {
    return {
      ...state,
      speakerViewPinnedUsers: union(state.speakerViewPinnedUsers || [], [
        action.userId
      ])
    };
  };

  const unpinUser = state => {
    return {
      ...state,
      speakerViewPinnedUsers: difference(state.speakerViewPinnedUsers || [], [
        action.userId
      ])
    };
  };

  const lowerOrRaiseHand = state => {
    const raisedHands = state.conference.raisedHands || [];
    if (action.type === types.RAISE_HAND) {
      state.conference.raisedHands = union(raisedHands, [action.userId]);
    } else {
      state.conference.raisedHands = raisedHands.filter(
        id => id !== action.userId
      );
    }
    return {
      ...state,
      conference: { ...state.conference }
    };
  };

  const getBreakoutRoomsState = currentBreakoutRooms => {
    const currentBreakoutRoomsEnabled = some(
      currentBreakoutRooms,
      r => r.enabled
    );
    return currentBreakoutRoomsEnabled;
  };

  switch (action.type) {
    case types.INITIALIZE_CONFERENCE_REQUEST:
      nextState = {
        ...state,
        conference: null,
        selectedMedia: null
      };
      break;

    case types.INITIALIZE_CONFERENCE_SUCCESS:
      const { host } = action.conference;
      let users = reduce(
        action.conference.participants,
        (acc, val) => {
          acc[val.id] = val;
          return acc;
        },
        {}
      );
      if (host) {
        users[host.id] = host;
      }

      nextState = {
        ...state,
        conference: action.conference,
        users,
        presentingUserId: action.conference.presentingUserId,
        leftPanelVisible: true,
        focusMode: false,
        settings: defaults(action.conference.settings, {
          webcamsDisabled: false,
          micsDisabled: false,
          participantsCanPresent: false,
          participantsCanShareScreen: false,
          participantsCanShareCam: false,
          participantsCanMuteEveryone: false,
          participantsCanMute: false
        }),
        breakoutRoomsAreEnabled: getBreakoutRoomsState(
          action.conference.breakoutRooms
        ),
        archiving:
          action.conference.archive?.status === 'started' ||
          action.conference.archive?.status === 'paused'
      };
      break;

    case types.FINISH_CONFERENCE:
      nextState = {};
      break;

    // case officeHoursTypes.LOAD_OFFICE_HOURS_COMPLETE:
    //   // We create a "hostless" conference for the 1 to 1 meeting
    //   nextState = {
    //     ...state,
    //     conference: {
    //       id: action.data.id
    //     }
    //   };
    //   break;
    //
    case types.INITIALIZE_CONFERENCE_FAILURE:
      nextState = {
        ...state,
        error: action.error
      };
      break;

    case types.LOAD_USER_SUCCESS:
      nextState = {
        ...state,
        users: {
          ...state.users,
          [action.user.id]: action.user
        }
      };
      break;

    case types.LOAD_BROWSABLE_MEDIA_REQUEST:
      nextState = {
        ...state,
        isLoadingBrowsableMedia: true,
        mediaPerUser: {}
      };
      break;

    case types.LOAD_BROWSABLE_MEDIA_FAILURE:
      nextState = {
        ...state,
        isLoadingBrowsableMedia: false
      };
      break;

    case types.LOAD_BROWSABLE_MEDIA_SUCCESS:
      let media = action.browsableMedia.map(file => ({
        ...file,
        id: file.id.split('#')[0],
        children: null,
        isFolder: !!file.folder
      }));

      let mediaPerUser = { [action.userId]: media };
      nextState = {
        ...state,
        isLoadingBrowsableMedia: false,
        mediaPerUser
      };
      break;

    case types.HOST_JOINED:
      nextState = {
        ...state,
        hostHasJoined: true
      };
      break;

    case types.HOST_LEFT:
      nextState = {
        ...state,
        hostHasJoined: false,
        hostIsBrowsing: false,
        conference: {
          ...state.conference,
          isPlaying: false
        }
      };
      break;

    case types.SELECT_MEDIA:
      const selectedMedia = action.media && action.media.id && action.media;
      nextState = {
        ...state,
        hostIsBrowsing: false,
        selectedMedia,
        presentingUserId:
          action.media && action.media.id
            ? action.initiatorUserId || state.presentingUserId
            : null
      };

      if (selectedMedia && selectedMedia.fileType === 'PDF') {
        nextState.pdfPage = state.conference.currentPosition || 1;
      }

      if (!(action.media && action.media.id)) {
        nextState.commentsVisible = false;
      }

      if (!action.isInitial) {
        nextState.conference.currentPosition = null;
        nextState.conference.isPlaying = false;
        nextState.conference.selectedImageIndex = 0;
        nextState.pdfPage = 1;
      }

      break;

    case types.SELECT_IMAGE:
      nextState = {
        ...state,
        conference: {
          ...state.conference,
          selectedImageIndex: action.imageIndex
        }
      };
      break;

    case types.SHOW_NOTIFICATION:
      nextState = { ...state, notification: action.notification };
      break;

    case types.HOST_STARTED_BROWSING:
      nextState = { ...state, hostIsBrowsing: true };
      break;

    case types.HOST_STOPPED_BROWSING:
      nextState = { ...state, hostIsBrowsing: false };
      break;

    case types.ADD_OR_REMOVE_CO_HOST:
      {
        let coHosts = state.conference.coHostIds || [];
        if (action.isAdding) {
          state.conference.coHostIds = [action.userId];

          // lower the hand of the promoted student
          state.conference.raisedHands = (
            state.conference.raisedHands || []
          ).filter(id => id !== action.userId);
        } else {
          state.conference.coHostIds = coHosts.filter(
            id => id !== action.userId
          );
        }
        nextState = {
          ...state,
          conference: { ...state.conference }
        };
      }
      break;

    case types.RAISE_HAND:
    case types.LOWER_HAND:
      nextState = lowerOrRaiseHand(state);
      break;

    case types.CLEAR_RAISED_HANDS:
      nextState = {
        ...state,
        conference: {
          ...state.conference,
          raisedHands: [],
          lastRaisedHands: state.conference.raisedHands
        }
      };
      break;

    case types.GOTO_PDF_PAGE:
      nextState = {
        ...state,
        pdfPage: action.page
      };
      break;

    case types.SET_PDF_SCALE:
      nextState = {
        ...state,
        pdfScale: action.scale
      };
      break;

    case types.UPDATE_SETTINGS:
      nextState = {
        ...state,
        settings: defaults(action.settings, state.settings)
      };
      break;

    case types.UPDATE_CONFERENCE_SUCCESS:
      {
        const breakoutRoomsAreEnabled = getBreakoutRoomsState(
          action.conference.breakoutRooms
        );
        nextState = {
          ...state,
          conference: {
            ...state.conference,
            breakoutRooms: action.conference.breakoutRooms
          },
          breakoutRoomsAreEnabled
        };
      }
      break;

    case types.SHOW_COMMENTS:
      nextState = {
        ...state,
        commentsVisible: true
      };
      break;

    case types.HIDE_COMMENTS:
      nextState = {
        ...state,
        commentsVisible: false
      };
      break;

    case types.SHOW_LEFT_PANEL:
      nextState = {
        ...state,
        leftPanelVisible: true
      };
      break;

    case types.HIDE_LEFT_PANEL:
      nextState = {
        ...state,
        leftPanelVisible: false
      };
      break;

    case types.ENTER_FOCUS_MODE:
      nextState = {
        ...state,
        focusMode: true,
        leftPanelStateOnEnterFocusMode: state.leftPanelVisible,
        leftPanelVisible: false
      };
      break;

    case types.EXIT_FOCUS_MODE:
      nextState = {
        ...state,
        focusMode: false,
        leftPanelVisible: state.leftPanelStateOnEnterFocusMode
      };
      break;

    case types.SHOW_MEDIA_BROWSER:
      nextState = {
        ...state,
        mediaBrowserVisible: true
      };
      break;

    case types.HIDE_MEDIA_BROWSER:
      nextState = {
        ...state,
        mediaBrowserVisible: false
      };
      break;

    case types.SHOW_BECAME_CO_HOST_WARNING:
      nextState = { ...state, becameCoHostWarningVisible: true };
      break;

    case types.HIDE_BECAME_CO_HOST_WARNING:
      nextState = { ...state, becameCoHostWarningVisible: false };
      break;

    case types.CHANGE_HAND_ICON:
      const { handIconIndex, userId } = action.userId;
      nextState = {
        ...state,
        users: {
          ...state.users,
          [userId]: {
            ...state.users[userId],
            settings: { ...state.users[userId].settings, handIconIndex }
          }
        }
      };
      break;

    case types.START_ARCHIVING_REQUEST:
      nextState = {
        ...state,
        archiving: true,
        archiveTabCaptured: true
      };
      break;

    case types.START_ARCHIVING_FAILURE:
      nextState = {
        ...state,
        archiving: false,
        archiveTabCaptured: false
      };
      break;

    case types.STOP_ARCHIVING_SUCCESS:
      nextState = {
        ...state,
        archiving: false,
        archiveTabCaptured: false
      };
      break;

    case types.SET_ARCHIVE_TAB_CAPTURED_FLAG:
      nextState = {
        ...state,
        archiveTabCaptured: action.value
      };
      break;

    case types.SET_IS_ARCHIVING_FLAG:
      nextState = {
        ...state,
        archiving: action.value,
        remoteArchiveRequested: action.value
          ? false
          : state.remoteArchiveRequested
      };
      break;

    case types.PIN_USER_TO_SPEAKER_VIEW:
      nextState = pinUser(state);
      break;

    case types.UNPIN_USER_FROM_SPEAKER_VIEW:
      nextState = unpinUser(state);
      break;

    case types.USER_LEFT:
      nextState = unpinUser(lowerOrRaiseHand(state));
      break;

    case types.START_REMOTE_ARCHIVING_REQUEST:
      nextState = {
        ...state,
        remoteArchiveRequested: true,
        remoteArchiveError: undefined
      };
      break;

    case types.START_REMOTE_ARCHIVING_FAILURE:
      nextState = {
        ...state,
        remoteArchiveRequested: false,
        remoteArchiveError: action.error
      };
      break;

    case types.UPDATE_BREAKOUT_ROOMS:
      {
        const breakoutRoomsAreEnabled = getBreakoutRoomsState(
          action.breakoutRooms
        );
        nextState = {
          ...state,
          conference: {
            ...state.conference,
            breakoutRooms: action.breakoutRooms
          },
          updateFromSignal: action.fromSignal,
          breakoutRoomsAreEnabled
        };
      }
      break;

    case types.CLAIM_HOST_REQUEST:
      nextState = {
        ...state
      };
      break;
    case types.CLAIM_HOST_SUCCESS:
      nextState = {
        ...state,
        claimHostDeclinedFor: undefined,
        conference: {
          ...state.conference,
          pendingHostClaimRequestUserId: action.userId
        }
      };
      break;

    case types.ACCEPT_CLAIM_SUCCESS:
      nextState = {
        ...state,
        conference: {
          ...state.conference,
          hostId: action.userId,
          host: action.newHost,
          pendingHostClaimRequestUserId: null,
          coHostIds:
            state.conference.coHostIds &&
            without(state.conference.coHostIds, action.userId)
        }
      };
      break;

    case types.DECLINE_CLAIM_SUCCESS:
      console.log('decline claim success', action);
      nextState = {
        ...state,
        claimHostDeclinedFor: state.conference.pendingHostClaimRequestUserId,
        conference: {
          ...state.conference,
          pendingHostClaimRequestUserId: null
        }
      };
      break;

    case types.CANCEL_CLAIM_SUCCESS:
      console.log('cancel claim success', action);
      nextState = {
        ...state,
        conference: {
          ...state.conference,
          pendingHostClaimRequestUserId: null
        }
      };
      break;

    case types.HANDOFF_HOST_SUCCESS:
      nextState = {
        ...state,
        handoffHostDeclinedFor: undefined,
        conference: {
          ...state.conference,
          pendingHostHandoffRequestUserId: action.userId
        }
      };
      break;

    case types.ACCEPT_HANDOFF_SUCCESS:
      nextState = {
        ...state,
        conference: {
          ...state.conference,
          hostId: action.userId,
          host: action.newHost,
          pendingHostHandoffRequestUserId: null,
          coHostIds:
            state.conference.coHostIds &&
            without(state.conference.coHostIds, action.userId)
        }
      };
      break;

    case types.CANCEL_HANDOFF_SUCCESS:
      console.log('cancel handoff success', action);
      nextState = {
        ...state,
        conference: {
          ...state.conference,
          pendingHostHandoffRequestUserId: null
        }
      };
      break;

    case types.DECLINE_HANDOFF_SUCCESS:
      console.log('decline handoff success', action);
      nextState = {
        ...state,
        handoffHostDeclinedFor:
          state.conference.pendingHostHandoffRequestUserId,
        conference: {
          ...state.conference,
          pendingHostHandoffRequestUserId: null
        }
      };
      break;

    case types.SHOW_MANAGE_ROLES:
      console.log('show ma ro', action);
      nextState = { ...state, isManageRolesVisible: true };
      break;

    case types.HIDE_MANAGE_ROLES:
      nextState = { ...state, isManageRolesVisible: false };
      break;

    case types.SHOW_CHANGE_PERMISSIONS_MODAL:
      console.log('show chpe', action);
      nextState = { ...state, changePermissionsModalVisibleFor: action.userId };
      break;

    case types.HIDE_CHANGE_PERMISSIONS_MODAL:
      nextState = { ...state, changePermissionsModalVisibleFor: undefined };
      break;

    case types.ACTIVE_SPEAKER_CHANGED:
      nextState = { ...state, activeSpeaker: action.activeSpeaker };
      break;
  }

  return nextState;
};

const loadUser = userId => ({
  type: types.LOAD_USER_REQUEST,
  userId
});

const loadBrowsableMedia = userId => ({
  type: types.LOAD_BROWSABLE_MEDIA_REQUEST,
  userId
});

const selectMedia = ({ media, initiatorUserId, isInitial }) => ({
  type: types.SELECT_MEDIA,
  media,
  initiatorUserId,
  isInitial
});

const initializeConference = (
  conferenceId,
  userId,
  opentokSessionId,
  opentokToken
) => ({
  type: types.INITIALIZE_CONFERENCE_REQUEST,
  conferenceId,
  userId,
  opentokSessionId,
  opentokToken
});

const updateConference = (conferenceId, conference, noRefetch) => ({
  type: types.UPDATE_CONFERENCE_REQUEST,
  conferenceId,
  conference,
  noRefetch
});

const deleteConference = conferenceId => ({
  type: types.DELETE_CONFERENCE_REQUEST,
  conferenceId
});

const createConference = ({
  hostId,
  bookerId,
  name,
  startDate,
  participants,
  conferenceType,
  duration,
  recurringDays,
  recurringEndDate
}) => ({
  type: types.CREATE_CONFERENCE_REQUEST,
  bookerId,
  hostId,
  name,
  startDate,
  participants,
  conferenceType,
  duration,
  recurringDays,
  recurringEndDate
});

const sessionConnected = () => ({
  type: types.SESSION_CONNECTED
});

const sessionDisconnected = () => ({
  type: types.SESSION_DISCONNECTED
});

const hostJoined = () => ({
  type: types.HOST_JOINED
});

const hostLeft = () => ({
  type: types.HOST_LEFT
});

const hostStartedBrowsing = () => ({
  type: types.HOST_STARTED_BROWSING
});

const hostStoppedBrowsing = () => ({
  type: types.HOST_STOPPED_BROWSING
});

const saveChatMessage = (senderId, message, timestamp) => ({
  type: types.SAVE_CHAT_MESSAGE_REQUEST,
  senderId,
  message,
  timestamp
});

const addOrRemoveCoHost = (userId, isAdding) => ({
  type: types.ADD_OR_REMOVE_CO_HOST,
  userId,
  isAdding
});

const raiseHand = userId => ({
  type: types.RAISE_HAND,
  userId
});

const lowerHand = userId => ({
  type: types.LOWER_HAND,
  userId
});

const pinUserToSpeakerView = userId => ({
  type: types.PIN_USER_TO_SPEAKER_VIEW,
  userId
});
const unPinUserFromSpeakerView = userId => ({
  type: types.UNPIN_USER_FROM_SPEAKER_VIEW,
  userId
});

const showNotification = notification => ({
  type: types.SHOW_NOTIFICATION,
  notification
});

const showComments = () => ({
  type: types.SHOW_COMMENTS
});

const hideComments = () => ({
  type: types.HIDE_COMMENTS
});

const showLeftPanel = () => ({
  type: types.SHOW_LEFT_PANEL
});

const hideLeftPanel = () => ({
  type: types.HIDE_LEFT_PANEL
});

const showManageRoles = () => ({
  type: types.SHOW_MANAGE_ROLES
});
const hideManageRoles = () => ({
  type: types.HIDE_MANAGE_ROLES
});

const showChangePermissionsModal = userId => ({
  type: types.SHOW_CHANGE_PERMISSIONS_MODAL,
  userId
});

const hideChangePermissionsModal = () => ({
  type: types.HIDE_CHANGE_PERMISSIONS_MODAL
});

const enterFocusMode = () => ({
  type: types.ENTER_FOCUS_MODE
});

const exitFocusMode = () => ({
  type: types.EXIT_FOCUS_MODE
});

const showMediaBrowser = () => ({
  type: types.SHOW_MEDIA_BROWSER
});

const hideMediaBrowser = () => ({
  type: types.HIDE_MEDIA_BROWSER
});

const finishConference = () => ({
  type: types.FINISH_CONFERENCE
});

const showBecameCoHostWarning = () => ({
  type: types.SHOW_BECAME_CO_HOST_WARNING
});

const hideBecameCoHostWarning = () => ({
  type: types.HIDE_BECAME_CO_HOST_WARNING
});

const gotoPdfPage = page => ({
  type: types.GOTO_PDF_PAGE,
  page
});

const setPdfScale = scale => ({
  type: types.SET_PDF_SCALE,
  scale
});

const selectImage = imageIndex => ({
  type: types.SELECT_IMAGE,
  imageIndex
});

const updateSettings = (conferenceId, settings, dontPersist) => ({
  type: types.UPDATE_SETTINGS,
  conferenceId,
  settings,
  dontPersist
});

const updateBreakoutRooms = (breakoutRooms, fromSignal) => ({
  type: types.UPDATE_BREAKOUT_ROOMS,
  breakoutRooms,
  fromSignal
});

const changeHandIcon = (userId, handIconIndex) => ({
  type: types.CHANGE_HAND_ICON,
  userId,
  handIconIndex
});

const clearRaisedHands = () => ({
  type: types.CLEAR_RAISED_HANDS
});

const startArchiving = (tabStreamId, participantStreamIds) => ({
  type: types.START_ARCHIVING_REQUEST,
  tabStreamId,
  participantStreamIds
});

const stopArchiving = () => ({
  type: types.STOP_ARCHIVING_REQUEST
});

const startRemoteArchiving = () => ({
  type: types.START_REMOTE_ARCHIVING_REQUEST
});

const addStreamToArchive = (streamId, hasAudio, hasVideo) => ({
  type: types.ADD_STREAM_TO_ARCHIVE_REQUEST,
  streamId,
  hasAudio,
  hasVideo
});

const setArchiveTabCapturedFlag = value => ({
  type: types.SET_ARCHIVE_TAB_CAPTURED_FLAG,
  value
});

const setIsArchivingFlag = value => ({
  type: types.SET_IS_ARCHIVING_FLAG,
  value
});

const userJoined = userId => ({
  type: types.USER_JOINED,
  userId
});

const userLeft = userId => ({
  type: types.USER_LEFT,
  userId
});

const claimHost = userId => ({
  type: types.CLAIM_HOST_REQUEST,
  userId
});

const acceptClaim = () => ({
  type: types.ACCEPT_CLAIM_REQUEST
});

const declineClaim = () => ({
  type: types.DECLINE_CLAIM_REQUEST
});

const cancelClaim = () => ({
  type: types.CANCEL_CLAIM_REQUEST
});

const handoffHost = userId => ({
  type: types.HANDOFF_HOST_REQUEST,
  userId
});

const cancelHandoff = userId => ({
  type: types.CANCEL_HANDOFF_REQUEST,
  userId
});

const acceptHandoff = () => ({
  type: types.ACCEPT_HANDOFF_REQUEST
});

const declineHandoff = () => ({
  type: types.DECLINE_HANDOFF_REQUEST
});

const activeSpeakerChanged = activeSpeaker => ({
  type: types.ACTIVE_SPEAKER_CHANGED,
  activeSpeaker
});

export const actions = {
  createConference,
  updateConference,
  deleteConference,
  initializeConference,
  loadUser,
  loadBrowsableMedia,
  hostJoined,
  hostLeft,
  hostStartedBrowsing,
  hostStoppedBrowsing,
  selectMedia,
  sessionConnected,
  sessionDisconnected,
  saveChatMessage,
  addOrRemoveCoHost,
  raiseHand,
  lowerHand,
  showNotification,
  updateSettings,
  showComments,
  hideComments,
  showLeftPanel,
  hideLeftPanel,
  enterFocusMode,
  exitFocusMode,
  showMediaBrowser,
  hideMediaBrowser,
  finishConference,
  showBecameCoHostWarning,
  hideBecameCoHostWarning,
  gotoPdfPage,
  setPdfScale,
  selectImage,
  changeHandIcon,
  clearRaisedHands,
  startArchiving,
  stopArchiving,
  addStreamToArchive,
  setArchiveTabCapturedFlag,
  setIsArchivingFlag,
  pinUserToSpeakerView,
  unPinUserFromSpeakerView,
  userJoined,
  userLeft,
  updateBreakoutRooms,
  startRemoteArchiving,
  claimHost,
  acceptClaim,
  declineClaim,
  cancelClaim,
  handoffHost,
  acceptHandoff,
  cancelHandoff,
  declineHandoff,
  showManageRoles,
  hideManageRoles,
  showChangePermissionsModal,
  hideChangePermissionsModal,
  activeSpeakerChanged
};

export const selectors = {
  getConference: state => state.videoConference.conference,
  getPdfPage: state => state.videoConference.pdfPage,
  getPdfScale: state => state.videoConference.pdfScale,
  getUser: (state, userId) => (state.videoConference.users || {})[userId],
  getUsers: state => state.videoConference.users,
  getHostHasJoined: state => state.videoConference.hostHasJoined,
  getIsLoadingBrowsableMedia: state =>
    state.videoConference.isLoadingBrowsableMedia,
  getBrowsableMedia: state => state.videoConference.mediaPerUser,
  getBrowsableMediaForUser: (state, userId) =>
    state.videoConference.mediaPerUser[userId],
  getSelectedMedia: state => state.videoConference.selectedMedia,
  getPresentingUserId: state => state.videoConference.presentingUserId,
  getError: state => state.videoConference.error,
  getNotification: state => state.videoConference.notification,
  getShouldRefetch: state => state.videoConference.shouldRefetch,
  getHostIsBrowsing: state => state.videoConference.hostIsBrowsing,
  getCoHosts: state => state.videoConference.coHosts,
  getSettings: state => state.videoConference.settings,
  getUpdateFromSignal: state => state.videoConference.updateFromSignal,
  getCommentsVisible: state => state.videoConference.commentsVisible,
  getLeftPanelVisible: state => state.videoConference.leftPanelVisible,
  getFocusMode: state => state.videoConference.focusMode,
  getMediaBrowserVisible: state => state.videoConference.mediaBrowserVisible,
  getBecameCoHostWarningVisible: state =>
    state.videoConference.becameCoHostWarningVisible,
  getRaisedHands: state => state.videoConference.conference.raisedHands,
  getSelectedImageIndex: state =>
    state.videoConference.conference.selectedImageIndex || 0,
  getIsArchiving: state => !!state.videoConference.archiving,
  getIsArchiveTabCaptured: state => !!state.videoConference.archiveTabCaptured,
  getSpeakerViewPinnedUsers: state =>
    state.videoConference.speakerViewPinnedUsers,
  getBreakoutRoomsAreEnabled: state =>
    state.videoConference.breakoutRoomsAreEnabled,
  getRemoteArchiveRequested: state =>
    state.videoConference.remoteArchiveRequested,
  getRemoteArchiveError: state => state.videoConference.remoteArchiveError,
  getClaimHostDeclinedFor: state => state.videoConference.claimHostDeclinedFor,
  getHandoffHostDeclinedFor: state =>
    state.videoConference.handoffHostDeclinedFor,
  getIsManageRolesVisible: state => state.videoConference.isManageRolesVisible,
  getChangePermissionsModalVisibleFor: state =>
    state.videoConference.changePermissionsModalVisibleFor,
  getActiveSpeaker: state => state.videoConference.activeSpeaker
};

export const IGNORED_STREAMS = ['camera_test', 'archive', 'video_audio'];
export const AUTO_MUTE_AFTER = 4;
