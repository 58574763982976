import React, { useState, useEffect, useRef } from 'react';
import styles from './VideoMobileControl.scss';
import { isNumber, isNaN } from 'lodash';
import { SecondaryButton } from 'components/common/button';
import { useHistory, useRouteMatch } from 'react-router-dom';
import classnames from 'classnames';
import { isIos } from 'components/common/isIos';

const eventFire = (el, etype) => {
  if (el.fireEvent) {
    el.fireEvent('on' + etype);
  } else {
    let evObj = document.createEvent('Events');
    evObj.initEvent(etype, true, false);
    el.dispatchEvent(evObj);
  }
};

const time = seconds => {
  const format = val => `0${Math.floor(val)}`.slice(-2);
  const hours = seconds / 3600;
  const minutes = (seconds % 3600) / 60;
  return [hours >= 1 ? hours : null, minutes, seconds % 60]
    .filter(x => isNumber(x))
    .map(x => {
      if (isNaN(x)) {
        return 0;
      }
      return x;
    })
    .map(format)
    .join(':');
};

const fullScreen = () => {
  eventFire(document.querySelectorAll('.vjs-fullscreen-control')[0], 'click');
};

const VideoMobileControl = ({ player, closeDisabled, fromUrl }) => {
  const volumeLevel = player.getVolume();
  const isPlaying = player.isPlaying();
  const [isShow, setIsShow] = useState(true);
  const [isReadyToPlay, setIsReadyToPlay] = useState(true);
  const timer = useRef(null);
  const history = useHistory();
  const match = useRouteMatch();
  const { lessonId, mainAreaId } = match.params;

  const hidePlayer = () => {
    timer.current = setTimeout(() => setIsShow(false), 5000);
  };

  const playPause = e => {
    e.stopPropagation();

    if (isReadyToPlay) {
      setIsReadyToPlay(false);
    }

    if (isPlaying) {
      player.pause();
      clearTimeout(timer.current);
    } else {
      player.play();
      setIsShow(false);
    }
  };

  useEffect(() => {
    isShow && isPlaying && hidePlayer();
  }, [isPlaying, isShow]);

  useEffect(() => {
    !isReadyToPlay && setIsReadyToPlay(true);
  }, [lessonId, mainAreaId]);

  return (
    <div
      className={classnames(styles.mobileControl, {
        [styles.hide]: !isShow
      })}
      onClick={() => setIsShow(true)}
    >
      {isShow && (
        <>
          {!closeDisabled && (
            <SecondaryButton
              icon="fas fa-times"
              label="Close"
              iconPosition="right"
              onClick={() => history.push(fromUrl || '/')}
              className={styles.closeButton}
            />
          )}
          <div className={styles.playContols}>
            {!isReadyToPlay && (
              <span>
                <i
                  className="fas fa-undo"
                  onClick={() => player.seek(player.getCurrentTime() - 10)}
                />
                <span className={styles.label}>10s</span>
              </span>
            )}
            <span>
              <i
                className={`fas fa-${isPlaying ? 'pause' : 'play'}`}
                onClick={e => playPause(e)}
              />
              <span className={styles.label}>
                {isPlaying ? 'Pause' : 'Play'}
              </span>
            </span>
            {!isReadyToPlay && (
              <span>
                <i
                  className="fas fa-redo"
                  onClick={() => player.seek(player.getCurrentTime() + 10)}
                />
                <span className={styles.label}>10s</span>
              </span>
            )}
          </div>
          {!isReadyToPlay && (
            <>
              <div className={styles.time}>
                {time(player.getCurrentTime())} / {time(player.getDuration())}
              </div>
              <div>
                {!isIos && (
                  <div
                    className={classnames(styles.volume, {
                      [styles.disabled]: !isPlaying
                    })}
                  >
                    {volumeLevel === 0 ? (
                      <i
                        className="fas fa-volume-mute"
                        onClick={() => {
                          player.volume(1);
                        }}
                      />
                    ) : (
                      <i
                        className="fas fa-volume-up"
                        onClick={() => player.volume(0)}
                      />
                    )}
                  </div>
                )}
                <div className={styles.fullScreen}>
                  <i className="fas fa-expand" onClick={fullScreen} />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default VideoMobileControl;
