import Dialog from 'react-toolbox/lib/dialog';
import dialogTheme from './PopupDialogTheme.scss';
import keyboardHandlerManager from 'common/KeyboardHandlerManager';
import React, { useEffect } from 'react';
import styles from './PopupDialog.scss';
import { simulateClickOnFocusedElement } from 'utils/simulateClickOnFocusedElement';

export const PopupDialog = ({ onClose, children, open, title, theme }) => {
  useEffect(() => {
    const onKeyUp = e => {
      switch (e.key) {
        case 'Escape':
          onClose && onClose();
          break;
      }
      return false;
    };

    if (open) {
      keyboardHandlerManager.addKeyboardHandler(onKeyUp);
    } else {
      keyboardHandlerManager.removeKeyboardHandler(onKeyUp);
    }

    return () => keyboardHandlerManager.removeKeyboardHandler(onKeyUp);
  }, [open]);

  return open ? (
    <Dialog active={true} title={title} theme={theme || dialogTheme}>
      {children}
      {onClose && (
        <div
          className={styles.closeButton}
          onClick={onClose}
          data-cy="closeButton"
          aria-label="Close"
          role="button"
          tabIndex="0"
          onKeyUp={simulateClickOnFocusedElement}
        >
          <i className="fa fa-times" />
        </div>
      )}
    </Dialog>
  ) : null;
};
