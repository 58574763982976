import { actionType } from '../constants';
import { getComment } from '../selectors';
import { LOGOUT } from './authReducer';

const initialState = [];

const listOfCommentsReducer = (state = initialState, action, fullState) => {
  let nextState = state;

  switch (action.type) {
    case actionType.COMMENT_SECTION_START_LOADING:
    case LOGOUT:
      nextState = [];
      break;

    case actionType.INSERT_ENTITY_COMMENTS:
      nextState = insertIntoListOfComments(state, action.entity, fullState);
      break;

    case actionType.UPDATE_ENTITY_COMMENTS:
      if (!isInListOfComments(state, action.entity)) {
        nextState = insertIntoListOfComments(state, action.entity, fullState);
      }
      break;

    case actionType.REMOVE_ENTITY_COMMENTS:
      nextState = removeFromListOfComments(state, action.id);
      break;
  }

  return nextState;
};

function isInListOfComments(comments, item) {
  return comments.indexOf(item.id) >= 0;
}

function removeFromListOfComments(comments, id) {
  let index = findRemoveIndex(comments, id);
  let result = comments.slice();
  if (index >= 0) {
    result.splice(index, 1);
  }
  return result;
}

function findRemoveIndex(comments, id) {
  return comments.indexOf(id);
}

function insertIntoListOfComments(comments, item, fullState) {
  let result = comments.slice();
  result.push(item.id);
  result.sort(byTimeAndCreated);
  return result;

  // ----------------------------

  function byTimeAndCreated(a, b) {
    const ca = getComment(fullState, a) || item;
    const cb = getComment(fullState, b) || item;
    if (isNaN(ca.time)) {
      if (isNaN(cb.time)) {
        return ca.created - cb.created;
      } else {
        return -1;
      }
    } else {
      if (isNaN(cb.time)) {
        return 1;
      } else {
        if (ca.time === cb.time) {
          return ca.created - cb.created;
        } else {
          return ca.time - cb.time;
        }
      }
    }
  }
}

export default listOfCommentsReducer;
