import { actionType } from '../constants';
import { getReply, getCommentSection } from '../selectors';
import { receiveEntity } from './receiveEntity';

export const submitEditedReply = (id, text) => {
  return (dispatch, getState, { api }) => {
    let state = getState();
    let item = getReply(state, id);
    let commentSectionId = getCommentSection(state).id;
    dispatch({
      type: actionType.submitEditedReply,
      item,
      text
    });
    api.updateTextReply(commentSectionId, item, text);
    let newReply = {
      ...item,
      msg: text
    };
    dispatch(receiveEntity('replies')(id, newReply));
  };
};
