import { gql } from '@apollo/client';

export function updatePassword(apolloClient) {
  const mutation = gql`
    mutation updatePassword($input: UpdatePasswordInput!) {
      updatePassword(input: $input)
    }
  `;

  return (userId, newPassword) => {
    return apolloClient.mutate({
      mutation,
      variables: {
        input: { userId, newPassword }
      },
      refetchQueries: ['getAdminData']
    });
  };
}
