import { gql } from '@apollo/client';

export const updateUserSettings = apolloClient => async (userId, settings) => {
  const mutation = gql`
    mutation updateUserSettings($input: UpdateUserSettingsInput!) {
      updateUserSettings(input: $input) {
        id
        settings {
          handIconIndex
          panePosition
          emailNotifications
          smsNotifications
          meetingDuration
        }
      }
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: {
        userId,
        ...settings
      }
    }
  });
};
