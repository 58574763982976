import React from 'react';
import styles from 'components/lesson/editorForms/EditorForm.scss';
import { actions as myFileActions } from 'reducers/dashboard/myFiles';
import { connect } from 'react-redux';
import { Input } from 'react-toolbox/lib/input';
import { selectors as myFileSelectors } from 'reducers/dashboard/myFiles';
import { helpCenterUrl } from 'themes/partnerConfig';
import { getCurrentUser } from 'smashcut-client-lib/selectors';
import {
  IMAGE_UPLOAD_LIMIT_MBS,
  IMAGE_UPLOAD_LIMIT_BYTES,
  VIDEO_UPLOAD_LIMIT_GBS,
  VIDE0_UPLOAD_LIMIT_BYTES
} from 'components/common/UploadLimits';
import { EditorForm } from 'components/lesson/editorForms/EditorForm';
import { useEditorFormHook } from 'components/lesson/editorForms/useEditorFormHook';

const CreateMyFileForm = ({
  description: fileDescription,
  crewId,
  projectId,
  onClose,
  myFiles,
  uploadData,
  user,
  parentId,
  isSharedFile,
  addMyFile,
  allowedFormats
}) => {
  const handleCreate = () => {
    if (handleValidateSubmit()) {
      addMyFile(
        myFileName,
        description,
        selectedFile,
        crewId,
        projectId,
        parentId,
        isSharedFile
      );
      setHasFileCreated(true);
    }
  };

  const {
    description,
    selectedFile,
    selectedFileError,
    handleCancel,
    handleFileChosen,
    handleChangeDescription,
    selectedFileName,
    fileTooBigWarning,
    setFileTooBigWarning,
    setHasFileCreated,
    myFileName,
    setMyFileName,
    myFileNameError,
    handleValidateSubmit
  } = useEditorFormHook(
    allowedFormats,
    fileDescription,
    handleCreate,
    selectedFile,
    false,
    onClose,
    uploadData,
    myFiles
  );

  const fileChosenWithValidate = file => {
    if (
      (!user.isMentor &&
        file.type.includes('video') &&
        file.size > VIDE0_UPLOAD_LIMIT_BYTES) ||
      (!user.isMentor &&
        file.type.includes('image') &&
        file.size > IMAGE_UPLOAD_LIMIT_BYTES) ||
      (!user.isMentor && file.size > VIDE0_UPLOAD_LIMIT_BYTES)
    ) {
      setFileTooBigWarning(true);
      return;
    }
    handleFileChosen(file);
  };

  const { progress, uploading } = uploadData;

  return (
    <EditorForm
      formInfoContent=""
      formTitle=""
      formDescriptionInputPlaceholder="Say something about your file..."
      formUploadingTitle="UPLOADING FILE"
      allowedFormats={allowedFormats}
      formAdditionalInfoBoxes={
        <div className={styles.infoContent}>
          <p>
            Note: Smashcut fully supports several file types. These files can be
            reviewed, commented on directly, shared, downloaded, and screened
            during live video meetings using the Present Media button.
          </p>
          <div className={styles.specifications}>
            <div className={styles.infoBox}>
              <span className={styles.infoBoxLeft}>
                Fully Supported Formats
              </span>
              H.264, .mov, .mp4, .fountain, PDF, JPEG, PNG, .glb, .gltf
            </div>
            <div className={styles.infoBox}>
              <span className={styles.infoBoxLeft}>Max Image Size</span>
              {`${IMAGE_UPLOAD_LIMIT_MBS} mb`}
            </div>
            <div className={styles.infoBox}>
              <span className={styles.infoBoxLeft}>Max File Size</span>
              {`${VIDEO_UPLOAD_LIMIT_GBS} gb`}
            </div>
          </div>
          <p>
            *All other formats (e.g. audio) are limited to sharing
            functionality. They can only be uploaded and shared for other users
            to download.{' '}
          </p>
          <p>
            Need help? Review our uploading guidelines in the{' '}
            <a href={helpCenterUrl} target="_blank">
              Help Center
            </a>
            .
          </p>
        </div>
      }
      fileNameBlock={
        <div className={styles.fileNameContainer}>
          <Input
            placeholder="Title *"
            aria-label="Title *"
            type="text"
            error={myFileNameError}
            theme={styles}
            floating={false}
            value={myFileName}
            required={true}
            onChange={setMyFileName}
            disabled={uploading}
            autoFocus={true}
          />
        </div>
      }
      description={description}
      onChangeDescription={handleChangeDescription}
      isUploading={uploading}
      progressPercent={progress}
      onFileChosen={fileChosenWithValidate}
      selectedFileName={selectedFileName}
      selectedFileError={selectedFileError}
      submitButtonLabel="Upload"
      isSubmitDisabled={
        selectedFileName === '' || uploading || myFileName === ''
      }
      onSubmit={handleCreate}
      onCancel={handleCancel}
      fileTooBigWarning={fileTooBigWarning}
      setFileTooBigWarning={setFileTooBigWarning}
      myFiles={myFiles}
    />
  );
};

export default connect(
  state => {
    const myFiles = myFileSelectors.getAddedMyFiles(state);
    const {
      parentId,
      isSharedFile,
      crewId,
      projectId
    } = myFileSelectors.getMyFileCreator(state);
    const allowedFormats = [];
    return {
      myFiles,
      parentId,
      isSharedFile,
      allowedFormats,
      crewId,
      projectId,
      uploadData: state.uploads,
      user: getCurrentUser(state)
    };
  },
  {
    addMyFile: myFileActions.addMyFile
  }
)(CreateMyFileForm);
