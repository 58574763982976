import withSizes from 'react-sizes';

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 1000,
  isMiniScreen: width <= 500
});

export const withDeviceDetector = withSizes(mapSizesToProps);

// make it available without using a hoc
export const isMobile = () => {
  return mapSizesToProps({ width: window.innerWidth }).isMobile;
};
