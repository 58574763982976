import React from 'react';
import styles from './DoneAnimation.scss';
import classnames from 'classnames';

export const DoneAnimation = ({ visible, error }) =>
  visible && (
    <div className={classnames(styles.loader, error && styles.error)}>
      <div className={styles.checkmark}></div>
    </div>
  );
