import React, { useEffect } from 'react';
import styles from './AfterLoginProgramSelector.scss';
import { HeaderLogo } from 'themes/HeaderLogo';
import { ErrorButton } from '../common/button';
import dateformat from 'dateformat';
import { connect } from 'react-redux';
import { getCurrentUser } from 'smashcut-client-lib/selectors';
import { useHistory } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';
import {
  addBodyScrollClass,
  removeBodyScrollClass
} from 'utils/updateBodyScrollClass';

export const formatProgramDates = option => {
  const startDate = new Date(option.startDate);
  const endDate = new Date(option.endDate);
  return dateformat(startDate, 'mmm d, yyyy');
};

const AfterLoginProgramSelector = ({ options, user, onChange }) => {
  const history = useHistory();
  const height = use100vh();

  useEffect(() => {
    addBodyScrollClass();
    return () => removeBodyScrollClass();
  }, []);

  return (
    <div className={styles.topContainer} style={{ height }}>
      <HeaderLogo />
      <div className={styles.content}>
        <h2>Select a Program</h2>
        <div className={styles.options}>
          <ul>
            {options.map(option => (
              <li key={option.id} onClick={() => onChange(option.id)}>
                <div>
                  <div>{option.label}</div>
                  <div>{formatProgramDates(option)}</div>
                </div>
                <i className="fas fa-angle-right" />
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.user}>
          Logged in as <span>{user.fullName}</span>
        </div>
        <ErrorButton
          label="Log out"
          icon="fas fa-sign-out-alt"
          iconPosition="right"
          onClick={() => history.push('/logout')}
        />
      </div>
    </div>
  );
};

export default connect(
  state => ({
    user: getCurrentUser(state)
  }),
  {}
)(AfterLoginProgramSelector);
