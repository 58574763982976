import { getListOfComments } from './getListOfComments';
import { getComment } from './getComment';
import { getListOfReplies } from './getListOfReplies';

export function getPins(state, imageId) {
  const commentIds = getListOfComments(state);

  const pins = commentIds
    .map(id => getComment(state, id))
    .filter(c => c && c.pin && c.imageId === imageId)
    .map(c => ({
      ...c.pin,
      comment: c,
      numberOfReplies: getListOfReplies(state, c.id).length
    }));

  return pins;
}
