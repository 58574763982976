import { actionType } from '../constants';
import { getReply } from '../selectors';
import { LOGOUT } from './authReducer';

const listOfRepliesReducer = (state, action, fullState) => {
  let nextState = state,
    parentId,
    reply,
    replies;

  switch (action.type) {
    case actionType.COMMENT_SECTION_START_LOADING:
    case LOGOUT:
      nextState = {};
      break;

    case actionType.INSERT_ENTITY_REPLIES:
    case actionType.UPDATE_ENTITY_REPLIES:
      if (!isInListOfReplies(state, action.entity)) {
        parentId = action.entity.parentId;
        nextState = { ...state };
        nextState[parentId] = nextState[parentId]
          ? nextState[parentId].slice()
          : [];
        insertReplyId(nextState[parentId], action.entity, fullState);
      }
      break;

    case actionType.REMOVE_ENTITY_REPLIES:
      nextState = { ...state };
      reply = getReply(fullState, action.id);
      if (!reply || !nextState[reply.parentId]) {
        break;
      }
      replies = nextState[reply.parentId] = nextState[reply.parentId].slice();
      removeFromReplies(replies, reply.id);
      break;

    case actionType.INSERT_ENTITY_COMMENTS:
      nextState = { ...state };
      nextState[action.id] = [];
      break;

    case actionType.UPDATE_ENTITY_COMMENTS:
      if (!state[action.id]) {
        nextState = { ...state };
        nextState[action.id] = [];
      }
      break;

    case actionType.REMOVE_ENTITY_COMMENTS:
      nextState = { ...state };
      delete nextState[action.id];
      break;
  }
  return nextState;
};

function insertReplyId(replyIds, reply, fullState) {
  let index = findInsertIndex(replyIds, reply, fullState);
  replyIds.splice(index, 0, reply.id);
}

function findInsertIndex(replyIds, reply, fullState) {
  for (let i = replyIds.length - 1; i >= 0; i--) {
    let ri = getReply(fullState, replyIds[i]);
    if (reply.created >= ri.created) {
      return i + 1;
    }
  }
  return 0;
}

function isInListOfReplies(replies, item) {
  let parentId = item.parentId;
  let repliesOfParent = replies[parentId];
  return repliesOfParent && repliesOfParent.indexOf(item.id) >= 0;
}

function removeFromReplies(replies, id) {
  let index = replies.indexOf(id);
  replies.splice(index, 1);
}

export default listOfRepliesReducer;
