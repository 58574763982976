import React from 'react';
import classnames from 'classnames';
import styles from './CookieConsent.scss';
import { MainButton } from './button';

const CookieSnackBar = ({ active, onContinue }) => (
  <div className={classnames(styles.snackBar, active && styles.active)}>
    <div className={styles.text}>
      <div className={styles.big}>Cookies on Smashcut</div>
      <div className={styles.mainText}>
        We use cookies to ensure we provide the best experience on our website.
        If you continue without changing your settings, we will assume that you
        are ok to receive cookies on Smashcut. However, you can{' '}
        <a
          href="https://intercom.help/smashcut/en/articles/3104988-how-to-change-my-cookie-settings"
          target="_blank"
          aria-label="Change My Cookie settings (New Window)"
        >
          change your cookie settings
        </a>{' '}
        at any time.{' '}
        <a
          href="https://intercom.help/smashcut/en/articles/3104994-what-are-cookies"
          target="_blank"
          aria-label="More about cookies (New Window)"
        >
          Find out more about cookies
        </a>
        .
      </div>
    </div>
    <div className={styles.buttons}>
      <MainButton
        label="Continue"
        onClick={onContinue}
        data-cy="acceptCookies"
      />
    </div>
  </div>
);

class CookieConsent extends React.Component {
  constructor(props) {
    super(props);

    const cookieConsentAccepted = window.localStorage.getItem(
      'cookieConsentAccepted'
    );

    this.state = {
      active: cookieConsentAccepted !== 'true'
    };
  }

  handleContinue = () => {
    this.setState({ active: false });
    window.localStorage.setItem('cookieConsentAccepted', 'true');
  };

  render() {
    return (
      <CookieSnackBar
        active={this.state.active}
        onContinue={this.handleContinue}
      >
        We use cookies to ensure we provide the best experience on our website.
        If you continue without changing your settings, we will assume that you
        are ok to receive cookies on Smashcut. However, you can change your
        cookie settings at any time. Find out more about cookies
      </CookieSnackBar>
    );
  }
}

export default CookieConsent;
