// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CookieConsent--snackBar--2uOzuP_X{color:#ffffff;background-color:#000000;position:fixed;left:0;right:0;bottom:0;min-height:86px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-transform:translateY(300px);transform:translateY(300px);-webkit-transition:-webkit-transform 0.5s;transition:-webkit-transform 0.5s;transition:transform 0.5s;transition:transform 0.5s, -webkit-transform 0.5s;z-index:1100;padding:20px 100px}@media (max-width: 1000px){.CookieConsent--snackBar--2uOzuP_X{padding:20px}}@media (max-width: 500px){.CookieConsent--snackBar--2uOzuP_X{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}}.CookieConsent--snackBar--2uOzuP_X.CookieConsent--active--3pE8ZeQq{-webkit-transform:translateY(0px);transform:translateY(0px)}.CookieConsent--snackBar--2uOzuP_X .CookieConsent--text--1iA7UJDh{margin-right:30px;font-size:14px}.CookieConsent--snackBar--2uOzuP_X .CookieConsent--text--1iA7UJDh .CookieConsent--big--4Gy4tEpG{font-size:18px;font-weight:bold;margin-bottom:5px}.CookieConsent--snackBar--2uOzuP_X .CookieConsent--mainText--2EFtFfZJ{max-width:900px}.CookieConsent--snackBar--2uOzuP_X .CookieConsent--buttons--3QEk-HAe{display:-webkit-box;display:-ms-flexbox;display:flex}.CookieConsent--snackBar--2uOzuP_X .CookieConsent--buttons--3QEk-HAe>*:not(:last-child){margin-right:20px}@media (max-width: 500px){.CookieConsent--snackBar--2uOzuP_X .CookieConsent--buttons--3QEk-HAe{width:100%;margin-top:20px}}@media (max-width: 500px){.CookieConsent--snackBar--2uOzuP_X .CookieConsent--buttons--3QEk-HAe button{width:100%}}.CookieConsent--secondButton--1ei2GLqL{color:#cccccc !important}\n", ""]);
// Exports
exports.locals = {
	"snackBar": "CookieConsent--snackBar--2uOzuP_X",
	"active": "CookieConsent--active--3pE8ZeQq",
	"text": "CookieConsent--text--1iA7UJDh",
	"big": "CookieConsent--big--4Gy4tEpG",
	"mainText": "CookieConsent--mainText--2EFtFfZJ",
	"buttons": "CookieConsent--buttons--3QEk-HAe",
	"secondButton": "CookieConsent--secondButton--1ei2GLqL"
};
module.exports = exports;
