import { actionType } from '../constants';
import { selectProject } from './selectProject';
import { types as officeHoursTypes } from '../../reducers/officeHoursReducerTypes';
import { actions } from '../../reducers/officeHoursReducer';

export const replayAction = replayItem => {
  return (dispatch /*, getState, {api}*/) => {
    // console.log('replayItem', replayItem);
    replayItem.action.isReplay = true;
    switch (replayItem.action.type) {
      case actionType.selectProject:
        dispatch(selectProject(replayItem.action.id, true));
        break;
      case officeHoursTypes.SELECT_PROJECT:
        dispatch(actions.selectProject(replayItem.action.project, true));
        break;
      default:
        dispatch(replayItem.action);
    }
  };
};
