import Copyright from 'components/common/Copyright';
import LoginPageLogo from 'themes/LoginPageLogo';
import React from 'react';
import styles from './LoginPageLayout.scss';
import Version from 'components/common/Version';
import { footerLinks } from 'themes/partnerConfig';
import { withDeviceDetector } from 'components/common/withDeviceDetector';

const LoginPageLayout = ({ children, isMobile }) => (
  <div className={styles.loginPageLayout}>
    <div className={styles.leftColumn}>
      <div className={styles.fakeImage} />
    </div>
    <div className={styles.rightColumn}>
      <LoginPageLogo />
      <div className={styles.content}>{children}</div>
      <div className={styles.footer}>
        {isMobile ? (
          <div className={styles.links}>
            <a
              href={footerLinks.termsOfServiceUrl}
              target="_blank"
              aria-label="Terms of Service (New Window)"
            >
              Terms of Service
            </a>
            <a
              href={footerLinks.privacyPolicyUrl}
              target="_blank"
              aria-label="Privacy Policy (New Window)"
            >
              Privacy Policy
            </a>
            <div className={styles.poweredBy}>powered by Smashcut</div>
          </div>
        ) : (
          <>
            <Copyright />
            <Version />
          </>
        )}
      </div>
    </div>
  </div>
);

export default withDeviceDetector(LoginPageLayout);
