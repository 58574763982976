import { actionType } from '../constants';

const newReplyEditorReducer = (state, action) => {
  let nextState = state;

  switch (action.type) {
    case actionType.startReplying:
      nextState = {
        ...state,
        parentId: action.parentId
      };
      break;

    case actionType.startEditingComment:
    case actionType.addTextReply:
    case actionType.cancelReplying:
      nextState = {
        ...state,
        parentId: 'none'
      };
      break;
  }
  return nextState;
};

export default newReplyEditorReducer;
