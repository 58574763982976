// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Preloader--tooltip_grey--Gbn_ALeG,.Preloader--tooltip--WnWtOMnE{background-color:#444;border-radius:4px;font-weight:500;font-size:14px;opacity:1 !important;-webkit-box-shadow:0px 2px 4px rgba(0, 0, 0, .24);box-shadow:0px 2px 4px rgba(0, 0, 0, .24)}.Preloader--mobileMessage--PhKufpry{margin-top:30px;text-align:center}\n", ""]);
// Exports
exports.locals = {
	"tooltip_grey": "Preloader--tooltip_grey--Gbn_ALeG",
	"tooltip": "Preloader--tooltip--WnWtOMnE",
	"mobileMessage": "Preloader--mobileMessage--PhKufpry"
};
module.exports = exports;
