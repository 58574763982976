import { apolloClient } from '../../apolloClient';
import { gql } from '@apollo/client';
import { find, filter, orderBy, clone } from 'lodash';

const MESSAGE_FRAGMENT = `
  content
  id
  chatId
  created
  lastUpdated
  sender
  file {
    baseUrl
    commentSectionId
    created
    dashUrl
    description
    downloadUrl
    duration
    fileType
    hlsUrl
    id
    ownerId
    spriteUrl
    thumbnailUrl
    title
    status
    uploadType
    vttUrl
    projectLink
    folder
    isPreloaded
    imageIndex
    images {
      uploadId
      downloadUrl
    }
  }
  video {
    downloadUrl
    fileMimeType
    fileName
    fileSize
    fileType
    finished
    id
    ownerId
    started
    status
    uploadDurationMs
    uploadPath
    uploadType
  }
`;

const GET_CREW_MESSAGES = gql`
  query getCrewMessages($id: String!) {
    crew(id: $id) {
      id
      messages {
        ${MESSAGE_FRAGMENT}
      }
    }
  }
`;

const listenCrewMessagesSubscription = gql`
  subscription onCrewMessage($crewId: String) {
    onCrewMessage(crewId: $crewId) {
      ${MESSAGE_FRAGMENT}
    }
  }
`;

let messages = [];

export const subscribeToMessages = apolloClient => async (crewId, callback) => {
  messages = [];
  const result = await apolloClient.query({
    query: GET_CREW_MESSAGES,
    fetchPolicy: 'network-only',
    variables: {
      id: crewId
    }
  });

  messages = orderBy(result.data.crew.messages, ['created'], ['asc']);

  console.log('msgs', result);
  callback(clone(messages));

  return apolloClient
    .subscribe({
      query: listenCrewMessagesSubscription,
      variables: {
        crewId
      }
    })
    .subscribe({
      next(data) {
        console.log('message sub', data.data);
        const message = data.data.onCrewMessage;
        if (message.sender === 'DELETE_MESSAGE') {
          // strange sender
          console.log('removing message', message);
          messages = filter(messages, m => m.id !== message.id);
          callback(messages);
        } else {
          const existingMessage = find(messages, m => m.id === message.id);
          if (!existingMessage) {
            messages = [...messages, message];
            callback(messages);
          }
        }
      },
      error(err) {
        console.error('err', err);
      }
    });
};
