import { mapValues } from 'lodash';

export const calculateDashboardTabsVisibility = ({
  isMentor,
  programHasEnded,
  shouldHideLmsFromStudents,
  shouldShowLms,
  shouldShowPreProgram,
  shouldShowNoProgram,
  shouldShowMyRecordings
}) => {
  const visibleBase = {
    activities: false,
    calendar: !shouldShowPreProgram,
    community: !isMentor,
    conferences: isMentor,
    crews: isMentor,
    discussions: true,
    meetingArchives: shouldShowMyRecordings,
    myFiles: true,
    notifications: false,
    preStart: false,
    projects: !isMentor,
    projectsForReview: isMentor,
    scheduleMeetings:
      !isMentor &&
      !programHasEnded &&
      shouldShowLms &&
      !shouldHideLmsFromStudents,
    students: isMentor,
    syllabus: true,
    upcomingVideoMeetings:
      !programHasEnded &&
      shouldShowLms &&
      (isMentor || !shouldHideLmsFromStudents),
    welcomeNoProgram: false
  };

  const allValuesFalse = mapValues(visibleBase, () => false);

  if (shouldShowPreProgram) {
    return {
      ...allValuesFalse,
      preStart: true,
      discussions: true,
      myFiles: true
    };
  }

  if (shouldShowNoProgram) {
    return {
      ...allValuesFalse,
      welcomeNoProgram: true,
      myFiles: true
    };
  }
  return visibleBase;
};
