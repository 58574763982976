import { actionType } from '../constants';
import { getCurrentUserId } from '../selectors';
import { authStateChange } from './userActions';

export const updateUserAndLogin = (userId, updates) => {
  return (dispatch, getState, { api }) => {
    let state = getState();
    console.log('updating address', userId, updates);
    return api.user.updateUser(userId, updates).then(() => {
      console.log('updated address');
    });
  };
};
