const prefix = 'HOTSPOT';
import { actionType } from '../smashcut-client-lib/constants';
import { get } from 'lodash';

export const types = {
  ADD_HOTSPOT: `${prefix}/ADD_HOTSPOT`,
  HOTSPOT_ADDED: `${prefix}/HOTSPOT_ADDED`,
  CHANGE_LABEL: `${prefix}/CHANGE_LABEL`,
  SET_HOTPOINTS: `${prefix}/SET_HOTPOINTS`
};

export const initialState = {
  isAddingHotSpot: false,
  hotSpotLabel: '',
  hotPoints: {},
  visible: false,
  commentToGo: null
};

export const hotSpotReducer = (state = initialState, action) => {
  let nextState = state;

  switch (action.type) {
    case types.ADD_HOTSPOT:
      nextState = {
        ...state,
        isAddingHotSpot: true
      };
      break;
    case types.HOTSPOT_ADDED:
      nextState = {
        ...state,
        isAddingHotSpot: false,
        visible: true
      };
      break;
    case types.CHANGE_LABEL:
      nextState = {
        ...state,
        hotSpotLabel: action.text
      };
      break;
    case types.SET_HOTPOINTS:
      nextState = {
        ...state,
        hotPoints: action.hotPoints
      };
      break;
    case actionType.resetPoints:
    case actionType.cancelAddingComment:
      nextState = {
        ...state,
        hotPoints: {},
        visible: false,
        isAddingHotSpot: false,
        hotSpotLabel: ''
      };
      break;
    case actionType.gotoComment:
      nextState = {
        ...state,
        commentToGo: action.id
      };
      break;
  }

  return nextState;
};

export const actions = {
  addHotSpot: () => ({
    type: types.ADD_HOTSPOT
  }),
  hotSpotAdded: () => ({
    type: types.HOTSPOT_ADDED
  }),
  changehotSpotLabel: text => ({
    type: types.CHANGE_LABEL,
    text
  }),
  setHotPoints: hotPoints => ({
    type: types.SET_HOTPOINTS,
    hotPoints
  })
};

export const selectors = {
  getHotSpotStatus: state => get(state, 'hotspot.isAddingHotSpot'),
  getHotSpotLabel: state => get(state, 'hotspot.hotSpotLabel'),
  getHotPoints: state => get(state, 'hotspot.hotPoints'),
  getBaseHotStopVisible: state => get(state, 'hotspot.visible'),
  getCommentToGo: state => get(state, 'hotspot.commentToGo')
};
