import React from 'react';
import { connect } from 'react-redux';
import {
  actions as selectProgramActions,
  selectors as selectProgramSelectors
} from './selectProgramReducer';
import SelectProgram from './SelectProgram';

const SelectProgramPopup = ({ isPopupVisible, hidePopup }) =>
  isPopupVisible ? (
    <SelectProgram
      onCancel={hidePopup}
      onSelect={hidePopup}
      showCancelButton={true}
    />
  ) : null;

export const mapStateToProps = state => ({
  isPopupVisible: selectProgramSelectors.getIsPopupVisible(state)
});

export const mapDispatchToProps = {
  hidePopup: selectProgramActions.hidePopup
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectProgramPopup);
