// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Copyright--copyright--3wxs-3lF{font-size:80%}\n", ""]);
// Exports
exports.locals = {
	"copyright": "Copyright--copyright--3wxs-3lF"
};
module.exports = exports;
