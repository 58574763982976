import NotificationSettingsPopup from './NotificationSettingsPopup';
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';

export class NotificationSettingsPopupContainer extends React.Component {
  render() {
    const { notificationsSettingsVisible } = this.props;
    return notificationsSettingsVisible ? <NotificationSettingsPopup /> : null;
  }
}

export const mapStateToProps = state => {
  const notificationsSettingsVisible = get(
    state,
    'dashboard.notifications.notificationsSettingsVisible'
  );
  return {
    notificationsSettingsVisible
  };
};

export default connect(
  mapStateToProps,
  () => ({})
)(NotificationSettingsPopupContainer);
