import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faArchive,
  faAngleDoubleRight,
  faChevronDown,
  faBraille,
  faCheck,
  faChevronUp,
  faComment,
  faCommentAlt,
  faEllipsisV,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileAlt,
  faFileUpload,
  faGripLines,
  faImages,
  faInfo,
  faInfoCircle,
  faMapPin,
  faMinus,
  faPaintBrush,
  faPlus,
  faQuestion,
  faThumbsUp,
  faTimes,
  faUpload,
  faVideo,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons';

// add all icons you want to use
// Note the case of the icon name
//  <FontAwesomeIcon icon="window-close" />
library.add(
  faAngleDoubleRight,
  faArchive,
  faBraille,
  faCheck,
  faChevronDown,
  faChevronUp,
  faComment,
  faCommentAlt,
  faEllipsisV,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileAlt,
  faFileUpload,
  faGripLines,
  faImages,
  faInfo,
  faInfoCircle,
  faMapPin,
  faMinus,
  faPaintBrush,
  faPlus,
  faQuestion,
  faThumbsUp,
  faTimes,
  faUpload,
  faVideo,
  faWindowClose
);
