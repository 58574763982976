import { gql } from '@apollo/client';

export const setCurrentUserProgramId = apolloClient => (
  userId,
  userProgramId
) => {
  const mutation = gql`
    mutation setCurrentUserProgramId($userId: ID!, $userProgramId: ID!) {
      setCurrentUserProgramId(userId: $userId, userProgramId: $userProgramId) {
        id
        currentUserProgramId
      }
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      userId,
      userProgramId
    }
  });
};
