import { actionType } from '../constants';

const appSettingsReducer = (state, action) => {
  let nextState = state;

  switch (action.type) {
    case actionType.loadAppSettingsDataComplete:
      nextState = { ...action.appSettings, loaded: true };
      break;
  }

  return nextState;
};

export default appSettingsReducer;
