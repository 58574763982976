import * as Sentry from '@sentry/react';
import config from 'config';
import LogRocket from 'logrocket';
import version from './version';
import { Integrations } from '@sentry/tracing';

let store = { dispatch: () => {} };

export const configSentry = ({ history }) => {
  if (config.sentryEnabled) {
    Sentry.init({
      dsn: config.sentry,
      environment: config.appEnv,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'GraphQL error: Not Authorised',
        `We weren't granted permission`,
        `The play() request was interrupted by`,
        `Cannot read property 'classList' of null`,
        'ChunkLoadError',
        'Upload canceled',
        'NotAllowedError'
      ],

      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
        })
      ],
      tracesSampleRate: 1,

      beforeSend: event => {
        if (!(event.extra && event.extra.type === 'handled')) {
          // console.error('Unhandled sentry error', event);
        }
        event.extra = {
          ...event.extra,
          environment: config.appEnv,
          sessionURL: LogRocket.sessionURL,
          release: version.appVersion
        };
        return event;
      }
    });
  }
};
export const setStore = initializedStore => {
  store = initializedStore;
};
