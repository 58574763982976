import React from 'react';
import { SmashcutDropdown } from 'components/common/SmashcutDropdown';
import SmashcutPhoneInput from '../common/SmashcutPhoneInput';
import styles from './NotificationSettingsPopup.scss';
import { actions as notificationsActions } from 'reducers/dashboard/notifications';
import { connect } from 'react-redux';
import { defaults, omitBy, isNull } from 'lodash';
import { getCurrentUser } from '../../smashcut-client-lib/selectors/index';
import { MainButton, SecondaryButton } from 'components/common/button';
import { updateUser, updateUserSettings } from 'smashcut-client-lib/actions';
import { simulateClickOnFocusedElement } from 'utils/simulateClickOnFocusedElement';
import FocusLock from 'react-focus-lock';
import { validatePhone } from '../../utils/validators';

class NotificationSettingsPopup extends React.Component {
  constructor(props) {
    super(props);

    const user = this.props.user || {};
    const nonNullSettings = omitBy(user.settings, isNull);
    const settings = defaults(nonNullSettings, {
      emailNotifications: true,
      smsNotifications: false
    });
    this.state = {
      ...settings,
      phone: user.smsPhoneNumber || ''
    };
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (np.user && np.user !== this.props.user) {
      const nonNullSettings = omitBy(np.user.settings, isNull);
      const settings = defaults(nonNullSettings, {
        emailNotifications: true,
        smsNotifications: false
      });
      const newState = {
        emailNotifications: settings.emailNotifications,
        smsNotifications: settings.smsNotifications,
        phone: np.user.smsPhoneNumber || ''
      };
      this.setState(newState);
    }
  }

  handleSmsChange = newVal => this.setState({ smsNotifications: newVal.value });

  handleEmailChange = newVal =>
    this.setState({ emailNotifications: newVal.value });

  handlePhoneChange = newVal => {
    this.setState({ phone: newVal });
  };

  handleSave = () => {
    const settings = {
      emailNotifications: this.state.emailNotifications,
      smsNotifications: this.state.smsNotifications
    };

    let valid = true;

    const trimmedNumber = this.state.phone.trim();
    if (this.state.smsNotifications) {
      valid = validatePhone(trimmedNumber);
    }

    if (valid) {
      if (this.state.phone) {
        let updateObj = {};
        updateObj.smsPhoneNumber = trimmedNumber;
        this.props.updateUser(updateObj);
      }

      this.props.updateUserSettings(settings);
      this.setState({ phoneError: undefined });
      this.props.onClose();
    } else {
      this.setState({ phoneError: 'Please enter a valid phone number' });
    }
  };

  render() {
    const options = [
      { value: true, label: 'Enabled' },
      { value: false, label: 'Disabled' }
    ];
    let content = (
      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.left}>
            <div className={styles.bigLabel}>SMS</div>
            <div>Receive text message notifications from Smashcut</div>
          </div>
          <div className={styles.right}>
            <SmashcutDropdown
              options={options}
              value={this.state.smsNotifications}
              onChange={this.handleSmsChange}
              ariaLabel="SMS"
              isScrollToViewNotNeed={true}
            />
          </div>
        </div>
        {this.state.smsNotifications && (
          <div className={styles.numberInputSection}>
            <div className={styles.numberInputLabel}>SMS number:</div>
            <SmashcutPhoneInput
              className={styles.numberInputPhone}
              inputStyle={{ color: '#fff', borderBottom: '1px solid #fff' }}
              error={this.state.phoneError}
              value={this.state.phone}
              onChange={this.handlePhoneChange}
            />
          </div>
        )}

        <div className={styles.separator} />

        <div className={styles.section}>
          <div className={styles.left}>
            <div className={styles.bigLabel}>Email</div>
            <div>Receive email notifications from Smashcut</div>
          </div>
          <div className={styles.right}>
            <SmashcutDropdown
              options={options}
              value={this.state.emailNotifications}
              onChange={this.handleEmailChange}
              ariaLabel="Email"
              isScrollToViewNotNeed={true}
            />
          </div>
        </div>

        <div className={styles.separator} />

        <div className={styles.buttons}>
          <MainButton
            label="Save"
            primary
            onClick={this.handleSave}
            aria-label="Save"
          />
          <SecondaryButton
            label="Cancel"
            onClick={this.props.onClose}
            aria-label="Cancel"
          />
        </div>
      </div>
    );

    return (
      <FocusLock returnFocus={true}>
        <div className={styles.topContainer}>
          <div className={styles.overlay} />

          <div className={styles.popup}>
            <div className={styles.header}>
              <div className={styles.title} role="heading" aria-level="2">
                Notification Settings
              </div>
              <div
                className={styles.closeButton}
                onClick={this.props.onClose}
                tabIndex="0"
                onKeyUp={simulateClickOnFocusedElement}
              >
                <i className="fa fa-times" />
              </div>
            </div>
            {content}
          </div>
        </div>
      </FocusLock>
    );
  }
}

export const mapStateToProps = state => {
  let user = getCurrentUser(state);
  return {
    user
  };
};

export const mapDispatchToProps = {
  updateUser,
  updateUserSettings,
  onClose: notificationsActions.hideNotificationsSettings
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationSettingsPopup);
