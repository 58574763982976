import { loadLoggedInUser } from './loadLoggedInUser';
import { insertUserData } from './insertUserData';
import { objectsToMap } from 'common/objectsToMap';

const formatEntities = entities => {
  const notNullEntities = entities.map(entity =>
    Object.keys(entity).reduce((acc, itemKey) => {
      if (entity[itemKey]) {
        acc[itemKey] = entity[itemKey];
      }
      return acc;
    }, {})
  );
  return objectsToMap(notNullEntities);
};

const userData = userId => {
  return (dispatch, getState, { api }) => {
    return api
      .loadUserData(userId)
      .then(data => {
        const { userPrograms, programInstances, programs } = data;
        const userData = {
          userPrograms: formatEntities(userPrograms),
          programInstances: formatEntities(programInstances),
          programs:
            programs &&
            programs.reduce((acc, pr) => {
              const id = pr.id;
              let item = {};
              item['id'] = id;
              item['versions'] = {
                [pr.version]: pr
              };
              acc[id] = item;
              return acc;
            }, {})
        };
        dispatch(insertUserData(userData));
        return data.user;
      })
      .then(user => dispatch(loadLoggedInUser(user)))
      .then(res => res.payload.user);
  };
};

export const loadUserData = userId => {
  return userData(userId);
};
