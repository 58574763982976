import { logAndSendError } from 'utils/sentryHelper';

export const updateDisabledDashboardTabs = (
  programInstanceId,
  disabledDashboardTabs
) => (dispatch, getState, { adminApi }) => {
  adminApi
    .updateDisabledDashboardTabs(programInstanceId, disabledDashboardTabs)
    .catch(e => logAndSendError(e, 'error toggling dashboard tabs'));
};
