import {
  takeEvery,
  take,
  call,
  fork,
  all,
  put,
  select
} from 'redux-saga/effects';
import { types } from '../reducers/scheduleAvailability';
import * as _ from 'lodash';
import { gql } from '@apollo/client';

export function* getAllAvailability(apolloClient, { userId }) {
  try {
    const state = yield select();
    userId = userId || state.auth.user.id;
    // console.log('saga.getAllAvail for ', userId);

    const result = yield call(apolloClient.query, {
      query: gql`
        query getAllAvailabilityAndBookings($userId: String) {
          allAvailability(userId: $userId) {
            id
            isDefault
            ranges
            timeZone
            from
            to
          }
          allBookings(userId: $userId) {
            id
            firstUserId
            secondUserId
            from
            to
            linkedTo {
              mentorSessionId
              courseId
              weekId
              weekIndex
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      variables: {
        userId
      }
    });
    yield put({
      type: types.GET_ALL_AVAILABILITY_SUCCESS,
      userId,
      schedules: _.cloneDeep(result.data.allAvailability),
      bookings: result.data.allBookings
    });
  } catch (e) {
    console.error('Error getting availability', e);
    yield put({ type: types.GET_ALL_AVAILABILITY_FAILURE, error: e });
  }
}

export function* updateAvailability(apolloClient, action) {
  try {
    const { id, begin, end, availability, timezone } = action;
    console.log('[SAGA] saga.updateAvailability', action);

    const result = yield call(apolloClient.mutate, {
      mutation: gql`
        mutation updateAvailability($input: UpdateAvailabilityInput) {
          updateAvailability(input: $input)
        }
      `,
      variables: {
        input: {
          id,
          from: begin,
          to: end,
          ranges: availability,
          timeZone: timezone
        }
      }
    });

    yield put({ type: types.UPDATE_AVAILABILITY_SUCCESS });

    return true;
  } catch (e) {
    console.log('nok', e);
    yield put({
      type: types.UPDATE_AVAILABILITY_FAILURE,
      error: e,
      id: action.id
    });
    return false;
  }
}

export function* addNewAvailability(apolloClient, action) {
  try {
    const state = yield select();
    const userId = action.userId || state.auth.user.id;
    const { begin, end, availability, isBlocked, timezone } = action;
    console.log('saga.addNewAvailability for user ', action);

    const result = yield call(apolloClient.mutate, {
      mutation: gql`
        mutation addNewAvailability($input: AddNewAvailabilityInput) {
          addNewAvailability(input: $input)
        }
      `,
      variables: {
        input: {
          userId,
          from: begin,
          to: end,
          ranges: availability,
          timeZone: timezone,
          isBlocked
        }
      }
    });

    yield put({ type: types.UPDATE_AVAILABILITY_SUCCESS });
    return true;
  } catch (e) {
    console.error('Error adding new availability', e);
    yield put({ type: types.UPDATE_AVAILABILITY_FAILURE, error: e });
    return false;
  }
}

export function* deleteAvailability(apolloClient, action) {
  try {
    const { id } = action;
    console.log('saga.deleteAvailability', action);

    const result = yield call(apolloClient.mutate, {
      mutation: gql`
        mutation deleteAvailability($input: DeleteAvailabilityInput) {
          deleteAvailability(input: $input)
        }
      `,
      variables: {
        input: {
          id
        }
      }
    });

    yield put({ type: types.DELETE_AVAILABILITY_SUCCESS });
    return true;
  } catch (e) {
    console.error('Error deleting availability', e);
    yield put({
      type: types.DELETE_AVAILABILITY_FAILURE,
      error: e,
      id: action.id
    });
    return false;
  }
}

function* availabilityCrudSaga(service) {
  let batchMode = false;
  let calls = [];
  while (true) {
    const action = yield take([
      types.BEGIN_BATCH,
      types.END_BATCH,
      types.UPDATE_AVAILABILITY_REQUEST,
      types.ADD_NEW_AVAILABILITY_REQUEST,
      types.DELETE_AVAILABILITY_REQUEST
    ]);

    function* queueOrFork(type, fn, ...args) {
      if (action.type === type) {
        if (batchMode) {
          calls.push(call(fn, ...args));
          yield;
        } else {
          yield fork(fn, ...args);
        }
      }
    }

    if (action.type === types.BEGIN_BATCH) {
      calls = [];
      batchMode = true;
    }

    if (action.type === types.END_BATCH) {
      const result = yield all(calls);
      if (_.some(result, r => !r)) {
        yield put({ type: types.BATCH_FAILURE });
      } else {
        yield put({ type: types.BATCH_SUCCESS });
      }
    }

    yield queueOrFork(
      types.UPDATE_AVAILABILITY_REQUEST,
      updateAvailability,
      service,
      action
    );
    yield queueOrFork(
      types.ADD_NEW_AVAILABILITY_REQUEST,
      addNewAvailability,
      service,
      action
    );
    yield queueOrFork(
      types.DELETE_AVAILABILITY_REQUEST,
      deleteAvailability,
      service,
      action
    );
  }
}

export function* availabilitySaga({ apis }) {
  const { apolloClient } = apis;
  yield all([
    availabilityCrudSaga(apolloClient),
    takeEvery(
      types.GET_ALL_AVAILABILITY_REQUEST,
      getAllAvailability.bind(this, apolloClient)
    )
  ]);
}
