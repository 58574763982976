import { actionType } from '../constants/actionTypes';
import { logAndSendError } from 'utils/sentryHelper';

export const makeUserMentor = userId => {
  return (dispatch, getState, { api }) => {
    dispatch({ type: actionType.makeUserMentor });
    api.user
      .setIsMentor(userId, true)
      .then(() => dispatch({ type: actionType.makeUserMentorSuccess }))
      .catch(e => logAndSendError(e, 'error making user mentor'));
  };
};
