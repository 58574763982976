import config from 'config';
import { AnalyticsBrowser } from '@segment/analytics-next';

export const getAnalytics = async () => {
  console.log(`get analytics called !!!!!`);
  if (!config.segmentWriteKey) {
    console.warn('no config available for segment');
  } else {
    return await AnalyticsBrowser.load({ writeKey: config.segmentWriteKey });
  }
};

export const getEnv = () => {
  return config.appEnv ? config.appEnv : 'devt';
};
