import React from 'react';
import styles from './Copyright.scss';

const Copyright = () => {
  return (
    <div className={styles.copyright}>
      <i className="fa fa-copyright" /> {new Date().getFullYear()}
    </div>
  );
};

export default Copyright;
