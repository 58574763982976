// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".menu--iconMenu--2Al4mWJG .menu--icon--9g4LQrTM{color:#ffffff !important}.menu--iconMenu--2Al4mWJG .menu--icon--9g4LQrTM span{font-size:30px !important}.menu--iconMenu--2Al4mWJG .menu--menuItem--3WFSkxOf{color:#000000}\n", ""]);
// Exports
exports.locals = {
	"iconMenu": "menu--iconMenu--2Al4mWJG",
	"icon": "menu--icon--9g4LQrTM",
	"menuItem": "menu--menuItem--3WFSkxOf"
};
module.exports = exports;
