import { getCurrentUserSettings } from './getCurrentUserSettings';

export function getRightColumnNarrowness(state) {
  let settings = getCurrentUserSettings(state);
  let columnWidth = (settings && settings.panePosition) || 400;
  let narrowness = '';
  if (columnWidth < 260) {
    narrowness = 'narrowest';
  } else if (columnWidth < 320) {
    narrowness = 'narrower';
  } else if (columnWidth < 380) {
    narrowness = 'narrow';
  }
  return narrowness;
}
