import { gql } from '@apollo/client';

export const updateScreenplay = apolloClient => (screenplayId, description) => {
  const mutation = gql`
    mutation updateScreenplay($input: UpdateScreenplayInput!) {
      updateScreenplay(input: $input)
    }
  `;
  return apolloClient.mutate({
    mutation,
    variables: {
      input: { screenplayId, description }
    }
  });
};
