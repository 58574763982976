import { actionType } from '../constants/actionTypes';
import { logAndSendError } from 'utils/sentryHelper';

export const enrollTrialUser = (userProgram, programInstance) => {
  return (dispatch, getState, { adminApi }) => {
    dispatch({ type: actionType.enrollTrialUser });
    adminApi
      .enrollTrialUser(userProgram, programInstance)
      .then(() => dispatch({ type: actionType.enrollTrialUserSuccess }))
      .catch(e => logAndSendError(e, 'error enrolling trial user'));
  };
};
