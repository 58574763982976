import { actionType } from '../constants';
import { logAndSendError } from 'utils/sentryHelper';

export const confirmPasswordReset = (code, newPassword) => {
  return (dispatch, getState, { api }) => {
    dispatch({ type: actionType.resetPassword });
    api.user
      .confirmPasswordReset(code, newPassword)
      .then(info => {
        dispatch({ type: actionType.resetPasswordComplete, payload: info });
      })
      .catch(error => {
        logAndSendError(error, 'error confirming reset password');
        const code =
          error.graphQLErrors &&
          error.graphQLErrors.length &&
          error.graphQLErrors[0].message;
        dispatch({
          type: actionType.resetPasswordError,
          payload: {
            code,
            message: 'Error resetting password, please try again!'
          }
        });
      });
  };
};
